import React from 'react'

interface ShareIconProps {
    size: string
    color: string
}

export default function ShareIcon({ size, color }: ShareIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M15.2959 18.3333C14.6841 18.3333 14.1632 18.1306 13.7332 17.725C13.3032 17.3206 13.0882 16.8289 13.0882 16.25C13.0882 16.1389 13.0985 16.0242 13.1191 15.9058C13.1391 15.7869 13.1694 15.6753 13.21 15.5708L6.37882 11.7692C6.16294 11.9992 5.91176 12.1783 5.62529 12.3067C5.33941 12.4356 5.03294 12.5 4.70588 12.5C4.09294 12.5 3.57235 12.2978 3.14412 11.8933C2.71471 11.4883 2.5 10.9967 2.5 10.4183C2.5 9.84056 2.71471 9.34861 3.14412 8.9425C3.57176 8.53639 4.09235 8.33333 4.70588 8.33333C5.03294 8.33333 5.33941 8.39778 5.62529 8.52667C5.91176 8.655 6.16294 8.83417 6.37882 9.06417L13.21 5.2625C13.1694 5.15806 13.1391 5.04639 13.1191 4.9275C13.0985 4.80917 13.0882 4.69444 13.0882 4.58333C13.0882 4.00444 13.3026 3.51278 13.7315 3.10833C14.1597 2.70278 14.68 2.5 15.2924 2.5C15.9041 2.5 16.425 2.7025 16.855 3.1075C17.285 3.51194 17.5 4.00333 17.5 4.58167C17.5 5.15944 17.2853 5.65139 16.8559 6.0575C16.4276 6.46361 15.9071 6.66667 15.2941 6.66667C14.9671 6.66667 14.6606 6.60222 14.3747 6.47333C14.0882 6.345 13.8371 6.16583 13.6212 5.93583L6.79 9.7375C6.83059 9.84195 6.86088 9.95306 6.88088 10.0708C6.90147 10.1892 6.91176 10.3033 6.91176 10.4133C6.91176 10.5233 6.90147 10.6386 6.88088 10.7592C6.86088 10.8792 6.83059 10.9914 6.79 11.0958L13.6212 14.8975C13.8371 14.6675 14.0882 14.4883 14.3747 14.36C14.6606 14.2311 14.9671 14.1667 15.2941 14.1667C15.9071 14.1667 16.4276 14.3692 16.8559 14.7742C17.2853 15.1786 17.5 15.67 17.5 16.2483C17.5 16.8261 17.2859 17.3181 16.8576 17.7242C16.4288 18.1303 15.9082 18.3333 15.2959 18.3333Z" fill={color}/>
        </svg>
    )
}
