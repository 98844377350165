import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Column from "../../../Home/components/Column";
import { selectTicketGroups } from "../../redux/selector";
import { actions } from "../../redux/slice";
import CollapseCard from "../../../Home/components/CollapseCard";

export default function Kaban() {
  const dispatch = useDispatch();
  const groups = useSelector(selectTicketGroups);

  useEffect(() => {
    dispatch(actions.fetchTicketGroups());
  }, [dispatch]);

  const handleDragEnd = (
    ticketId: string,
    sourceStatus: string,
    targetStatus: string
  ) => {
    if (sourceStatus === targetStatus) return;

    dispatch(
      actions.updateTicketStatus({
        ticketId,
        sourceStatus,
        targetStatus,
      })
    );

    // Here you would also make an API call to update the backend
    // For now, we're just updating the Redux state
  };

  return (
    <div className="flex-shrink-0 w-full h-[calc(100vh-160px)] overflow-x-auto">
      <div className="w-full max-w-[calc(100vw-76px)] h-full flex flex-row  gap-[16px]">
        <CollapseCard />
        <div className="grid grid-cols-5 gap-[16px] h-full min-w-[max-content]">
          {groups.map((group) => {
            return (
              !group.status.isCollapse && !group.status.isHidden &&(
                <div key={group.status.name} className="w-[255px]">
                  <Column group={group} onDragEnd={handleDragEnd} />
                </div>
              )
            );
          })}
        </div>
      </div>
    </div>
  );
}
