import { PriorityFilterEnum, StatusFilterEnum } from "../Ticket/types";

export interface ITag {
  id: string;
  name: string;
  color: string;
  backgroundColor: string;
}

export interface BoardsState {
  boardsData: Board[];
  boardsForm: IBoardForm;
  favorites: Object[];
  qaBoards: Object[];
  recent: Object[];
  defaultTicketValueData: IDefaultTicketValueData;
  draftTicket: IDraftTicket[];
  ticketForm: ITicketFormData;
  selectedTickets: string[];
  isFileUploading: boolean;
  isFilePreview: boolean;
  uploadProgress: number;
  tagsList :ITag[];
  sortByColumn: string;
  sortByOrder: string;
  isLoading: boolean;
  
}

export interface ITicketFormData {
  id: string;
  name: string;
  teamName: string;
  projectName: string;
  type: TicketType;
  status: StatusFilterEnum;
  assignee: string;
  startDate: string;
  endDate: string;
  priority: PriorityFilterEnum;
  tags: ITag[];
  description: string;
  file: IFileType[];
}

export interface IFileType {
  id: string;
  name: string;
  type: string;
}

export interface IDueDate {
  startDate: string;
  endDate: string;
}
export interface IDraftTicket {
  id: string;
  name: string;
}
export interface Board {
  _id: string;
  type: BoardType;
  name: string;
  projects: Project[];
  owner: Owner;
  createdAt: string;
  lastUpdated: string;
  lastViewed: string;
  permission: BoardPermission;
}
export interface IBoardForm {
  id: string;
  type: BoardType;
  name: string;
  projects: Project[];
  owner: Owner;
  createdAt: string;
  lastUpdated: string;
  lastViewed: string;
  permission: BoardPermission;
  description: string;
  ticketType: TicketType;
  groupsBy: GroupByTypeEnum;
  subGroups: SubGroupEnum;
  visibleGroups: IVisibleGroups[];
  customizeCard: IVisibleGroups[];
  defaultTicketValue: IDefaultTicketValue[];
  customPermissions: ICustomPermissions[];
}

export interface ICustomPermissions {
  id: string;
  access: CustomAccessEnum;
}
export interface IDefaultTicketValue {
  data?: IVisibleGroups;
  id: string;
  value: string;
  type: DefaultTicketType;
}
export enum DefaultTicketType {
  "CUSTOM_FIELDS" = "customFields",
  "CONTEXT_FIELD" = "contextFields",
}
export interface IDefaultTicketValueData {
  customFields: IVisibleGroups[];
  contextFields: IVisibleGroups[];
}
export interface IVisibleGroups {
  id: number | string;
  label: string;
  color?: string;
  className?: string;
  icon?: React.ReactNode;
  isVisible?: boolean;
  type?: GroupByTypeEnum;
}

export enum BoardPermission {
  PRIVATE = "private",
  PUBLIC = "public",
  CUSTOM = "custom",
}
export enum CustomAccessEnum {
  NONE = "none",
  VIEW_ONLY = "viewOnly",
  FULL_ACCESS = "fullAccess",
  DELETE = "delete",
}

export enum BoardType {
  LIST = "list",
  KANBAN = "kanban",
}
export enum SubMenuTypeEnum {
  GROUP_BY = "groupBy",
  CUSTOMIZE_CARD = "customizeCard",
  BOARD_VIEW = "boardView",
  MORE_DETAILS = "moreDetails",
  TICKET_TYPE = "ticketTypeSelection",
  ARCHIVE = "archive",
  SUB_GROUPS = "subGroups",
  VISIBLE_GROUP = "visibleGroups",
  DEFAULT_TICKET_VALUE = "defaultTicketValue",
  SHARING_PERMISSIONS = "sharingPermissions",
}
export interface Project {
  id: string;
  projectName: string;
  color: string;
}

export interface Owner {
  _id: string;
  name: string;
  online: boolean;
  color: string;
  role: string;
}

export enum TicketType {
  TASK = "task",
  BUG = "bug",
  STORY = "story",
  EPIC = "epic",
}
export enum RelationType {
  BLOCKED_BY = "blockedBy",
  BLOCKS = "blocks",
  DUPLICATE = "duplicate",
}
export enum SubGroupEnum {
  NONE = "none",
  ASSIGNEE = "assignee",
  PRIORITY = "priority",
  TAGS = "tags",
  DUE_DATE = "dueDate",
  EPIC = "epic",
}
export enum GroupByTypeEnum {
  TICKET_NAME = "ticketName",
  TICKET_ID = "ticketId",
  STATUS = "status",
  TAGS = "tags",
  ASSIGNEE = "assignee",
  TICKET_TYPE = "ticketType",
  DUE_DATE = "dueDate",
  PRIORITY = "priority",
  SPRINT = "sprint",
  RELEASE = "release",
  TARGET_RELEASE = "targetRelease",
  ENVIRONMENT = "environment",
  SEVERITY = "severity",
  COMPONENT = "component",
  CREATOR = "creator",
  CREATED_DATE = "createdDate",
  EFFORTS_ESTIMATION = "effortsEstimation",
  EFFORT_ACTUAL = "effortsActual",
  CUSTOM_TITLES = "customTitles",
}
export enum TicketValueEnum {
  TICKET_NAME = "ticketName",
  STATUS = "status",
  ASSIGNEE = "assignee",
  DUE_DATE = "dueDate",
  PRIORITY = "priority",
  TAGS = "tags",
  TICKET_DESCRIPTION = "ticketDescription",
  COMMENT = "comment",
  NOTES = "notes",
  CUSTOM_PRIORITY = "customPriority",
  CREATED_DATE = "createdDate",
  NUMBER = "number",
  ID = "id",
  URL = "url",
}

export interface Project {
  id: string;
  projectName: string;
  color: string;
}

export interface Owner {
  _id: string;
  name: string;
  online: boolean;
  color: string;
}

export interface Label {
  name: string;
  color: string;
  backgroundColor: string;
}

export interface Status {
  name: string;
  backgroundColor: string;
}

export interface Ticket {
  name: string;
  id: string;
  labels: Label[];
  status: Status;
  assignee: Assignee;
  membersCount: number;
  attachmentsCount: number;
  completedCount: number;
  totalCount: number;
  priority: string;
  dueDate: string;
  type: TicketType;
  description?: string;
  relation?: IRelation[];
  attachment?: IAttachment[];
  checkList?: ICheckList[];
}

export interface IRelation {
  borderColor: string;
  category: string;
  categoryIcon: RelationType;
  relationList: IRelationData[];
}
export interface IRelationData {
  name: string;
  id: string;
  type: TicketType;
}
export interface ICheckList {
  name: string;
  assignee: string;
  isCompleted: boolean;
}
export interface IAttachment {
  id: string;
  file: string;
}
export interface Assignee {
  name: string;
  color: string;
  online: boolean;
}


export interface TicketGroupStatus {
  name: string;
  color: string;
  isCollapse: boolean;
  isHidden: boolean;
  ticketLimit: number;
}

export interface TicketGroup {
  status: TicketGroupStatus;
  tickets: Ticket[];
}

export interface TicketHeader {
  name: string;
  id: string;
  className: string;
}

export enum SprintStatus {
  CURRENT = "current",
  PREVIOUS = "previous",
  NEXT = "next",
}

export type ContainerState = BoardsState;

export interface Filter {
  id: string;
  name: string;
  icon: React.ReactNode;
  options: string[];
  isSearchable: boolean;
}
export enum TicketStatus {
  OPENED = "opened",
  IN_PROCESS = "in process",
  DUPLICATED = "duplicated",
  CLOSED = "closed",
}

export enum TicketPriorities {
  NORMAL = "normal",
  HIGH = "high",
  URGENT = "urgent",
  LOW = "low",
}
export enum GroupAction {
  COLLAPSE = "collapse",
  UN_COLLAPSE = "uncollapse",
  SELECT_ALL = "select_all",
  Un_SELECT_ALL = "unselect_all",
  HIDE = "hide",
  EDIT = "edit",
  REMOVE = "remove",
  ADD = "add",
  ADD_NEW_GROUP = "add_new_group",
}
