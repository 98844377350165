import Button from "app/components/Button";
import Input from "app/components/Input";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectProfileForm } from "app/containers/OnBoarding/redux/selector";
import { actions } from "../../redux/slice";
import { actions as actionsAuth} from "../../../Auth/redux/slice";
import PasswordIcon from "app/components/Icons/PasswordIcon";
import UserIcon from "app/components/Icons/UserIcon";
import { validateProfileForm, validatePasswordField } from "../../helpers";
import { useNavigate } from "react-router-dom";
import Logo from "app/components/Icons/Logo";
import TimeZoneSelection from "./TimeZoneSelection";
import { useQueryState } from "nuqs";

export default function CreateProfile() {
  const dispatch = useDispatch();
  // const isGoogleLogin
  const [isGoogleLogin, ] = useQueryState("isGoogleLogin");
  const [userName, ] = useQueryState("userName");
  const [hash ] = useQueryState("hash");
  const [email ] = useQueryState("email");
  const { name, password, confirmPassword, timezone, isLoading } =
    useSelector(selectProfileForm);
  const navigate = useNavigate();
  useEffect(() => {
    if (userName) {
     dispatch(actions.setProfileFormValue({ name: "name", value: userName }));
      }
      if (email || hash) {
          
      }
    return () => {};
  }, [userName]);

    useEffect(() => {
        if (hash) {
          dispatch(actionsAuth.verifyEmailRequest({code:hash as string}));
      }
    }, [hash])
    

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(
      actions.setProfileFormValue({
        name,
        value,
      })
    );
    if (name === "password") {
      const errors = validatePasswordField({ value, error: "" });
      if (errors.length > 0) {
        dispatch(actions.setProfileFormErrors(errors));
      }
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    var data;
    if (isGoogleLogin) {
      data = {
        name: {
          value: name.value,
          error: name.error,
        },
        timezone: {
          value: timezone.value,
          error: timezone.error,
        },
      };
    } else {
      data = {
        name: {
          value: name.value,
          error: name.error,
        },
        password: {
          value: password.value,
          error: password.error,
        },
        confirmPassword: {
          value: confirmPassword.value,
          error: confirmPassword.error,
        },
        timezone: {
          value: timezone.value,
          error: timezone.error,
        },
      };
    }

    const errors = validateProfileForm(data, isGoogleLogin ? true : false);
    if (errors.length > 0) {
      dispatch(actions.setProfileFormErrors(errors));
      return;
    }
    dispatch(
      actions.saveProfileRequest({
        callback: () => navigate("/onboarding/welcome"),
      })
    );
  };

  const isDisabled = () => {
    if (!isGoogleLogin) {
      if (
        name.value === "" ||
        password.value === "" ||
        confirmPassword.value === "" ||
        timezone.value === ""
      ) {
        return true;
      }
    }else{
      if (name.value === "" || timezone.value === "") {
        return true;
      }
    }

    return false;
  };
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center gap-[20px] mb-[56px]">
        <Logo size={72} />
        <h1 className="text-[40px] leading-[50px] font-semibold mb-4 text-center">
          Create a profile
        </h1>
      </div>
      <form className="flex flex-col gap-[20px] min-w-[440px] mb-[56px]">
        <Input
          id="email"
          label="Full Name"
          placeholder="Enter your name"
          icon={<UserIcon color="#BDC0C4" size={20} />}
          value={name.value}
          onChange={handleChange}
          error={name.error}
          name="name"
          disabled={isLoading}
        />
        <TimeZoneSelection />
        {!isGoogleLogin && (
          <>
            <Input
              id="password"
              label="Password"
              type="password"
              placeholder="Enter your password"
              icon={<PasswordIcon color="#BDC0C4" size="24" />}
              value={password.value}
              onChange={handleChange}
              error={password.error}
              name="password"
              disabled={isLoading}
              isShowWarningIcon={true}
            />
            <Input
              id="confirmPassword"
              label="Confirm Password"
              type="password"
              placeholder="Confirm your password"
              icon={<PasswordIcon color="#BDC0C4" size="24" />}
              value={confirmPassword.value}
              onChange={handleChange}
              error={confirmPassword.error}
              name="confirmPassword"
              disabled={isLoading}
              isShowWarningIcon={true}
            />
          </>
        )}

        <Button
          className="w-full h-[56px] mt-2"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={isLoading || isDisabled()}
        >
          Create profile
        </Button>
      </form>
    </div>
  );
}
