import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectBoards } from "app/containers/Features/containers/Boards/redux/selector";
import Row from "./Row";
import Archive from "./Archive";
import { Board } from "../../../types";
import { selectUserDetailsData } from "app/redux/selector";
import { selectIsLoading } from "../../Tickets/redux/selector";
import { handeCopyBoardLink } from "../../../helpers";
import { useParams } from "react-router-dom";

export default function Rows() {
  const params = useParams();
  const findType = new URLSearchParams(window.location.search);
  const type = findType.get("type");
  const boards = useSelector(selectBoards);
  const selectUserDetail = useSelector(selectUserDetailsData);
  const [archiveModal, setArchiveModal] = useState(false);  
  const [selectedBoard, setSelectedBoard] = useState<Board | null>(null);
  const [editingBoardId, setEditingBoardId] = useState<string | null>(null);
  const filterBoardsByType  = (type === "my-boards") ? boards.filter((board) => board.owner._id === '1') : boards;
  const handleOnArchive = (board: Board) => {
    setArchiveModal(true);
    setSelectedBoard(board);
  };

  const handleBoardRename = (board: Board) => {
  // Toggle edit mode for the clicked board, ensuring only one board is editable at a time
    setEditingBoardId(prevId => (prevId === board?._id ? null : board?._id));
  };
  return (
    <>
      {(filterBoardsByType?.length > 0) && filterBoardsByType?.map((board) => (
        <Row
          key={board._id}
          board={board}
          onArchive={() => handleOnArchive(board)}
          handleBoardRename={() => handleBoardRename(board)}
          handeCopyBoardLink={() => handeCopyBoardLink(params?.project,params?.team,board?.name)}
          isEditing={editingBoardId === board._id}
          setEditingBoardId={setEditingBoardId}
        />
      ))}
      {archiveModal && <Archive onClose={() => setArchiveModal(false)} boardName={selectedBoard?.name} />}
    </>
  );
}