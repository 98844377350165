import React from "react";

interface HarshIconProps {
    size?: number;
    color?: string;
}

export const HarshIcon = ({ size = 16, color = "#79808A" }: HarshIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <path d="M2.78521 9.26732C2.72145 9.26732 2.66927 9.31624 2.66927 9.37602L2.66927 10.1912C2.66927 10.251 2.72145 10.2999 2.78521 10.2999L5.16202 10.2999L5.16202 12.5554C5.16202 12.6151 5.2142 12.6641 5.27797 12.6641L6.2055 12.6641C6.26927 12.6641 6.32144 12.6151 6.32144 12.5554L6.32145 10.2999L9.62579 10.2999L9.62579 12.5554C9.62579 12.6151 9.67797 12.6641 9.74173 12.6641L10.6693 12.6641C10.733 12.6641 10.7852 12.6151 10.7852 12.5554L10.7852 10.2999L13.22 10.2999C13.2838 10.2999 13.3359 10.251 13.3359 10.1912L13.3359 9.37602C13.3359 9.31624 13.2838 9.26732 13.22 9.26732L10.7852 9.26732L10.7852 6.0608L13.22 6.0608C13.2838 6.0608 13.3359 6.01189 13.3359 5.95211L13.3359 5.13689C13.3359 5.07711 13.2838 5.02819 13.22 5.02819L10.7852 5.02819L10.7852 2.77276C10.7852 2.71298 10.733 2.66406 10.6693 2.66406L9.74174 2.66406C9.67797 2.66406 9.62579 2.71298 9.62579 2.77276L9.62579 5.02819L6.32145 5.02819L6.32145 2.77276C6.32145 2.71297 6.26927 2.66406 6.2055 2.66406L5.27797 2.66406C5.2142 2.66406 5.16203 2.71297 5.16203 2.77276L5.16203 5.02819L2.78521 5.02819C2.72145 5.02819 2.66927 5.07711 2.66927 5.13689L2.66927 5.95211C2.66927 6.01189 2.72145 6.0608 2.78521 6.0608L5.16203 6.0608L5.16202 9.26732L2.78521 9.26732ZM6.32145 6.0608L9.62579 6.0608L9.62579 9.26732L6.32145 9.26732L6.32145 6.0608Z" fill={color}/>
        </svg>
    )
};
