import { CheckIcon, Input, SearchIcon, UserIcon } from "app/components/Icons";
import React from "react";
import Avatar from "../Avatar";
import CustomTooltip from "app/components/Tooltip";
import Dropdown from "app/components/Dropdown";
import MemberProfile from "app/components/MemberProfile";
import { IMember } from "../../containers/ProjectSettings/types";
import MenuItem from "./MenuItem";

interface AssigneeDropdownProps {
  assignee: {
    name: string;
    color: string;
    status?: "online" | "offline";
    online: boolean;
  };
  searchKeyword: string;
  setSearchKeyword: (val: string) => void;
  data: IMember[];
  selectedValue: string;
  updateValue: (id: string) => void;
  trigger?: React.ReactNode;
  dropdownContainerClassName?: string;
  className?: string;
  align?: "start" | "end" | "center";
  isHeader?: string;
  searchPlaceholder?: string;
}

const Index: React.FC<AssigneeDropdownProps> = ({
  assignee,
  searchKeyword,
  setSearchKeyword,
  data,
  selectedValue,
  updateValue,
  trigger,
  align = "start",
  className,
  dropdownContainerClassName,
  isHeader,
  searchPlaceholder,
}) => {
  const MenuItems = data?.map((ele) => {
    if (ele)
      return {
        label: <MenuItem data={ele} selectedValue={selectedValue} iconTextClassName=" !text-[10px] font-normal" />,
        action: () => {
          updateValue && updateValue(ele.id);
        },
      };
    else return { label: "", action: () => {} };
  });
  return (
    <React.Fragment>
      <Dropdown
        trigger={trigger}
        projectInfoIcon={
          <div className="flex flex-col gap-2">
            <Input
              noBorder
              id={searchKeyword ?? "search"}
              icon={<SearchIcon size="17" color="#79808A" />}
              placeholder={searchPlaceholder ?? "Search by name"}
              value={searchKeyword}
              onChange={(e) => {
                e.stopPropagation();
                setSearchKeyword(e.target.value);
              }}
              innerContainerClassName="px-2 pb-1 border-b-[1px] border-[#79808A]"
            />
            {isHeader && (
              <div className="flex items-center justify-between px-4 py-3 text-secondary tracking-[0.56px] text-sm font-semibold leading-[21px] uppercase border-b border-[#E9ECF2]">
                <span>{isHeader}</span>
              </div>
            )}
          </div>
        }
        menuItems={MenuItems ? MenuItems : []}
        align={align ?? "start"}
        className={`${className}  py-2`}
        dropdownContainerClassName={`${dropdownContainerClassName} max-h-[280px] overflow-scroll`}
      />
    </React.Fragment>
  );
};

export default Index;
