import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "app/components/Popover";
import React from "react";
import { fixedColorCode } from "../../containers/Boards/data";
import { ColorResult, SketchPicker } from "react-color";
import ColorPlate from "./ColorPlate";

interface ColorPickerProps {
  trigger: React.ReactNode;
  selectedColor: string;
  updateCode: (val: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  trigger,
  selectedColor,
  updateCode,
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>{trigger}</PopoverTrigger>
        <PopoverContent
          align="start"
          alignOffset={50}
          className={`bg-white border border-[#E9ECF2] cursor-pointer`}
        >
          <div className="flex flex-wrap gap-2">
            {fixedColorCode.map((color, index) => (
              <div
                key={index}
                className={`${
                  color == selectedColor ? ` border rounded-md p-[1px]` : ""
                }  `}
                style={{ borderColor: selectedColor }}
              >
                <div
                  className="w-5 h-5 rounded-md cursor-pointer"
                  style={{ backgroundColor: color }}
                  onClick={() => updateCode(color)}
                />
              </div>
            ))}

            <ColorPlate
              trigger={
                <button className="w-5 h-5 border border-dashed border-gray-400 rounded-md cursor-pointer flex items-center justify-center relative">
                  +
                </button>
              }
              color={selectedColor}
              updateColor={updateCode}
            />
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default ColorPicker;
