import MemberProfile from "app/components/MemberProfile";
import React from "react";
import { IMember } from "../../containers/ProjectSettings/types";
import { CheckIcon } from "app/components/Icons";
interface MenuItemProps {
  data: IMember;
  selectedValue: string;
  iconClassName?: string;
  iconTextClassName?: string;
  statusClassName?: string;
  titleClassName?: string;
  roleClassName?: string;
}

const MenuItem: React.FC<MenuItemProps> = ({
  data,
  selectedValue,
  iconClassName,
  iconTextClassName,
  roleClassName,
  statusClassName,
  titleClassName,
}) => {
  return (
    <>
      <MemberProfile
        iconColor={data.icon.color}
        iconBgColor={data.icon.bgColor}
        iconText={data.icon.initials}
        name={data.name}
        statusColor="#6FCF97"
        role={data.role}
        endIcon={<CheckIcon color="#5A8DF1" />}
        showEndIcon={selectedValue === data.name}
        iconClassName={`${iconClassName} !w-7 !h-7`}
        iconTextClassName={`${iconTextClassName}`}
        statusClassName={`${statusClassName} !w-2.5 !h-2.5 bottom-[-2px]`}
        titleClassName={`${titleClassName} text-sm`}
        roleClassName={`${roleClassName} !text-[12px]`}
        className="!p-0 gap-3"
      />
    </>
  );
};

export default MenuItem;
