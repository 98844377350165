import {
  PriorityFilterEnum,
  StatusFilterEnum,
} from "app/containers/Features/containers/Ticket/types";
import React from "react";
import { useSelector } from "react-redux";
import { selectBoardsFormData } from "../../../../redux/selector";
import {
  GroupByTypeEnum,
  ITag,
  IVisibleGroups,
  Ticket,
  TicketType,
} from "../../../../types";
import AssigneeSelection from "./Assignee";
import Component from "./Component";
import CreatedDate from "./CreatedDate";
import Creator from "./Creator";
import DueDate from "./Duedate";
import EffortActual from "./EffortActual";
import EffortEstimated from "./EffortEstimated";
import Environment from "./Environment";
import Priority from "./Priority";
import Release from "./Release";
import Severity from "./Severity";
import Sprint from "./Sprint";
import StatusSelection from "./Status";
import TagSelection from "./TagSelection";
import TargetRelease from "./TargetRelease";
import TicketId from "./TicketId";
import PrioritySelection from "./TicketType";

interface TicketCustomizeProps {
  ticket: Ticket;
  className?:string
}

const Index: React.FC<TicketCustomizeProps> = ({ ticket ,className}) => {
  const boardData = useSelector(selectBoardsFormData);

  const matchTicketFields = (ticket: Ticket, fields: IVisibleGroups[]) => {
    const fieldMappings: Record<string, any> = {
      ticketId: ticket.id,
      ticketType: ticket.type,
      status: ticket.status.name,
      assignee: ticket.assignee.name,
      dueDate: ticket.dueDate,
      Priority: ticket.priority,
      tags: ticket.labels,
    };
    return fields.map((field) => ({
      ...field,
      value: fieldMappings[field.type as any] || "N/A",
    }));
  };

  return (
    <React.Fragment>
      <div className={`${className} flex flex-col w-full`}>
        {matchTicketFields(
          ticket,
          boardData?.customizeCard.filter(
            (x, index) => x?.isVisible && index !== 0
          )
        ).map((ele, index) => {
          return (
            <div key={index} className="flex flex-row justify-start w-full">
              {ele?.type === GroupByTypeEnum.TAGS ? (
                <TagSelection ticketTag={ele.value as ITag[]} />
              ) : ele.type === GroupByTypeEnum.ASSIGNEE ? (
                <AssigneeSelection assignee={ticket.assignee} />
              ) : ele.type === GroupByTypeEnum.STATUS ? (
                <StatusSelection value={StatusFilterEnum.OPENED} />
              ) : ele.type === GroupByTypeEnum.TICKET_TYPE ? (
                <PrioritySelection value={TicketType.TASK} />
              ) : ele.type === GroupByTypeEnum.PRIORITY ? (
                <Priority value={PriorityFilterEnum.NONE} />
              ) : ele.type === GroupByTypeEnum.DUE_DATE ? (
                <DueDate />
              ) : ele.type === GroupByTypeEnum.TARGET_RELEASE ? (
                <TargetRelease value={""} />
              ) : ele.type === GroupByTypeEnum.RELEASE ? (
                <Release value={""} />
              ) : ele.type === GroupByTypeEnum.SEVERITY ? (
                <Severity value={""} />
              ) : ele.type === GroupByTypeEnum.ENVIRONMENT ? (
                <Environment value={""} />
              ) : ele.type === GroupByTypeEnum.SPRINT ? (
                <Sprint />
              ) : ele.type === GroupByTypeEnum.EFFORTS_ESTIMATION ? (
                <EffortEstimated value={""} />
              ) : ele.type === GroupByTypeEnum.EFFORT_ACTUAL ? (
                <EffortActual value="" />
              ) : ele.type === GroupByTypeEnum.TICKET_ID ? (
                <TicketId value={ticket.id} />
              ) : ele.type === GroupByTypeEnum.CREATOR ? (
                <Creator assignee={ticket.assignee} />
              ) : ele.type == GroupByTypeEnum.CREATED_DATE ? (
                <CreatedDate value={ticket.dueDate} />
              ) :ele.type===GroupByTypeEnum.COMPONENT?<Component />: (
                ""
              )}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default Index;
