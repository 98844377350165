import { FlagIcon } from "app/components/Icons";
import CustomTooltip from "app/components/Tooltip";
import CommonPrioritySelection from "app/containers/Features/components/CommonPrioritySelection";
import { PriorityFilterEnum } from "app/containers/Features/containers/Ticket/types";
import React from "react";
import { priorityOptions } from "../../../../data";

interface CustomProps {
  value: PriorityFilterEnum;
}

const Priority: React.FC<CustomProps> = ({ value }) => {
  const selectedOptions =
  priorityOptions.find((p) => p.value === value) || priorityOptions[0];

  return (
    <React.Fragment>
      <CommonPrioritySelection
        trigger={
          <button className="flex items-center justify-between w-full ">
            <CustomTooltip text="Set priority" className="flex items-center " position="left">
              <div className="flex items-center gap-2 w-full">
               <FlagIcon size={16} color={selectedOptions.color} />
                <span className="text-sm font-normal text-black">
                  {selectedOptions.label}
                </span>
              </div>
            </CustomTooltip>
          </button>
        }
        isHeader="priorities"
        value={value}
        align="start"
      />
    </React.Fragment>
  );
};

export default Priority;
