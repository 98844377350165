import CustomTooltip from "app/components/Tooltip";
import React from "react";
import { TicketTypeOptions } from "../../../../data";
import { TicketType } from "../../../../types";
import CommonTicketTypeSelection from "app/containers/Features/components/CommonTicketTypeSelection";

interface CustomProps {
  value: TicketType;
}

const TicketTypeSelection: React.FC<CustomProps> = ({ value }) => {
  const selectedOptions =
    TicketTypeOptions.find((p) => p.type === value) || TicketTypeOptions[0];

  return (
    <React.Fragment>
      <CommonTicketTypeSelection
        trigger={
          <button className="flex items-center justify-between w-full ">
            <CustomTooltip text="Set ticket type" className="flex items-center " position="left">
              <div className="flex items-center gap-2 w-full">
                <img
                  src={selectedOptions.icon}
                  alt="icon"
                  className="size-[16px]"
                />
                <span className="text-sm font-normal text-black">
                  {selectedOptions.label}
                </span>
              </div>
            </CustomTooltip>
          </button>
        }
        isHeader="ticket types"
        value={value}
      />
    </React.Fragment>
  );
};

export default TicketTypeSelection;
