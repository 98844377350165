import { TicketsIcon } from "app/components/Icons";
import PlusIcon from "app/components/Icons/PlusIcon";
import Spinner from "app/components/Icons/Spinner";
import IconText from "app/components/IconText";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { getTicketIcon } from "../../../Boards/containers/Home/containers/helpers";
import { TicketType } from "../../../Boards/types";
import Fields from "./Fields";
import TeamTicketTypesSaga from "./redux/saga";
import {
  selectFieldsLoading,
  selectLoading,
  selectTicketTypesForm,
  selectTicketTypesList,
} from "./redux/selector";
import { actions, reducer, sliceKey } from "./redux/slice";
import TaskName from "./TaskName";
import TicketTypes from "./TicketTypes";
import { ITicketTypeFields } from "./types";

const Index = () => {
  useInjectReducer({
    key: sliceKey,
    reducer: reducer,
  });
  useInjectSaga({ key: sliceKey, saga: TeamTicketTypesSaga });
  const [selected, setSelected] = useState(0);
  const params = useParams();
  const tId = params?.id;
  const dispatch = useDispatch();
  const ticketType = useSelector(selectTicketTypesList);
  const selectedTicketType = useSelector(selectTicketTypesForm);
  const isLoading = useSelector(selectLoading);
  const fieldsLoading = useSelector(selectFieldsLoading);
  useEffect(() => {
    if (tId) {
      dispatch(actions.doFetchTicketTypes(tId));
    }
    return () => {};
  }, [tId]);

  useEffect(() => {
    if (ticketType?.length > 0) {
      const selectedId = ticketType[selected]?._id;
      dispatch(actions.doFetchTicketFields(selectedId));
    }

    return () => {};
  }, [selected, ticketType?.length > 0]);

  const handleNewCreate = () => {
  dispatch(actions.doNewTypeCreate())
}

  return (
    <div className="min-h-screen bg-[#F4F5F7]">
      {/* Header with Dropdown */}
      <div className="flex items-center gap-2 mb-4">
        <span className="w-5 h-5 text-[#42526E] cursor-pointer">
          <TicketsIcon size={20} color="#42526E" />
        </span>
        <h1 className="text-sm font-semibold text-[#42526E]">Tickets types</h1>
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center p-4 h-[50vh]">
          <Spinner size="small" />
        </div>
      ) : (
        <div className="grid grid-cols-12 gap-8 ">
          <div className="col-span-2 bg-white h-[max-content]">
            {ticketType?.map((item, index) => {
              return (
                <TicketTypes
                  setSelected={setSelected}
                  selected={selected}
                  index={index}
                  item={item}
                />
              );
            })}
            <div className={`w-full py-2 text-center cursor-pointer `}>
              <div className={` flex flex-row w-full`}>
                <IconText
                  className="py-2 ps-10 text-center justify-center flex"
                  icon={<PlusIcon color="#5A8DF1" size={20} />}
                  text="Ticket type"
                  textClassName={` text-sm text-primary font-medium`}
                />
              </div>
            </div>
          </div>
          <div className="col-span-10">
            {fieldsLoading ? (
              <div className="flex justify-center items-center p-4 h-[50vh]">
                <Spinner size="small" />
              </div>
            ) : (
              <div className="flex flex-col gap-4 w-full">
                <TaskName
                  data={{
                    name: selectedTicketType?.name,
                    icon: (
                      <img
                        src={getTicketIcon(
                          selectedTicketType?.name?.toLowerCase() as TicketType
                        )}
                        alt="task-icon"
                        className="size-[20px]"
                      />
                    ),
                    description: selectedTicketType?.description,
                  }}
                />
                <Fields
                  data={
                    selectedTicketType?.ticketTypeFields as ITicketTypeFields[]
                  }
                  fId={selectedTicketType?._id}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
