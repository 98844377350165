import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Menu } from "app/components/Icons";
import ArchiveIcon from "app/components/Icons/ArchiveIcon";
import { DuplicateIcon } from "app/components/Icons/DuplicateIcon";
import React from "react";

interface ColumnOptionsMenuProps {
  ticketId: string;
  buttonClassName?: string;
  handleArchive?: () => void;
  handleDuplicate?: () => void;
}

const ColumnOptionsMenu: React.FC<ColumnOptionsMenuProps> = ({
  ticketId,
  buttonClassName,
  handleArchive,
  handleDuplicate,
}) => {
  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <button
            className={`${buttonClassName} hover:border-[0.8px] hover:border-[#79808A] hover:rounded-[4px] `}
          >
            <Menu size="16" color="#79808A" />
          </button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className="bg-white shadow-lg rounded-md z-50 w-64 border border-[#E9ECF2] max-w-[180px] py-2"
            side="bottom"
            align="start"
          >
            <DropdownMenu.Item
              className="flex w-full items-center gap-3 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 py-3 text-sm text-secondary outline-none px-4"
              onClick={(e) => {
                handleDuplicate?.();
              }}
            >
              <DuplicateIcon size={20} color="#79808A" />
              <span>Duplicate</span>
            </DropdownMenu.Item>
            <DropdownMenu.Item
              className="flex w-full items-center gap-3 text-sm text-gray-700 cursor-pointer hover:bg-gray-100 py-3 text-sm text-secondary outline-none px-4"
              onClick={(e) => {
                e.stopPropagation();
                handleArchive?.();
              }}
            >
              <ArchiveIcon size={20} color="#79808A" />
              <span>Archive</span>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </>
  );
};

export default ColumnOptionsMenu;
