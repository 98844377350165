import { selectUser } from "app/redux/selector";
import { actions as appActions } from "app/redux/slice";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import { request } from "utils/request";
import { IGoogleLogin } from "../types";
import {
  selectForgotPasswordForm,
  selectLoginForm,
  selectResestPasswordForm,
  selectSignupForm,
} from "./selector";
import { actions } from "./slice";
import toastService from "utils/toast";
import { userOnboardingStage } from "app/types";

export function* loginRequest(action: {
  payload: { callback?: (nextPage) => void };
}) {
  try {
    yield delay(500);
    const loginForm = yield select(selectLoginForm);
    const requestURL = `${API_URL}auth/login`;
    const options = {
      method: "POST",
      body: JSON.stringify({
        email: loginForm.email.value,
        password: loginForm.password.value,
      }),
    };
    const { data, err } = yield call(request, requestURL, options);
    if (err || !data) {
      throw JSON.parse(err?.message);
    }
    yield put(
      appActions.setUserOnboardingProgress(data?.data?.userOnboardingProgress)
    );
    yield put(appActions.setUserToken(data?.data.accessToken));
    yield put(appActions.setIsAuthenticated(true));
    yield put(actions.loginRequestComplete());
    yield localStorage.setItem("accessToken", data?.data?.accessToken);
    if (action.payload.callback) {
      yield call(action.payload.callback, data?.data?.userOnboardingProgress);
    }
  } catch (err: any) {
    yield put(actions.loginRequestComplete());
    if (err?.message == "Entered email doesn’t exist") {
      yield put(
        actions.setLoginFormErrors([
          { name: "email", error: err?.message },
        ])
      );
    } else if(err?.message == "Incorrect password") {
      yield put(
        actions.setLoginFormErrors([{ name: "password", error: err?.message }])
      );
    }else{
      toastService.error(err?.message);
    }
  }
}

export function* forgotPasswordRequest({
  payload,
}: {
  payload: { callback?: () => void };
}) {
  try {
    yield delay(500);
    const forgotPasswordForm = yield select(selectForgotPasswordForm);
    const requestURL = `${API_URL}auth/passwords/change-password`;
    const options = {
      method: "PATCH",
      body: JSON.stringify({ email: forgotPasswordForm.email.value }),
    };
    const {data, err}= yield call(request, requestURL, options);
    if(err || !data){
      throw JSON.parse(err?.message);
    }
    if (data) {
      yield put(actions.forgotPasswordRequestComplete());
      payload.callback?.();
      return;
    }
    yield put(actions.resetStore());
  } catch (err: any) {
    yield put(actions.setForgotPasswordFormErrors([{name: "email", error: err?.message}]));
    yield put(actions.forgotPasswordRequestComplete());
    console.log(err);
  }

}

export function* signupRequest({
  payload,
}: {
  payload: { callback?: () => void };
}) {
  try {
    yield delay(500);
    const requestURL = `${API_URL}auth/signup`;
    const signupForm = yield select(selectSignupForm);
    const options = {
      method: "POST",
      body: JSON.stringify({ email: signupForm.email.value }),
    };
    const { data, err } = yield call(request, requestURL, options);
    if (err || !data) {
      throw JSON.parse(err?.message);
    }
    yield put(
      appActions.setUser({
        email: data?.data?.email,
        _id: data?.data?._id,
        initials: "",
        role: "",
        status: "",
        lastLogin: "",
        localTime: "",
        location: "",
        spokenLanguages: [],
        creationDate: "",
        mobileNumber: "",
        members: [],
      })
    );
    yield put(actions.signupRequestComplete());
    payload.callback?.();
    yield put(actions.resetStore());
  } catch (err: any) {
    yield put(actions.signupRequestComplete());
    console.log(err);
  }
 

}

export function* verifyEmailRequest({
  payload,
}: {
  payload: { code: string; callback?: () => void };
}) {
  try {
    yield delay(500);
    const userDetail = yield select(selectUser);
    const requestURL = `${API_URL}auth/verification`;
    const params = new URLSearchParams(window.location.search);
    const token = params.get("hash");
    const tokenData={
      token: token,
    }
    const formData = {
      verificationCode: payload?.code,
      id: userDetail?._id,
    }
    const options = {
      method: "POST",
      body: JSON.stringify(token ? tokenData:formData),
    };
    const { data, err } = yield call(request, requestURL, options);
    if (err || !data) {
      throw JSON.parse(err?.message);
    }
    yield put(actions.verifyEmailRequestComplete());
    yield put(appActions.setUserToken(data?.data.accessToken));
    yield put(
      appActions.setUserOnboardingProgress(data?.data?.userOnboardingProgress)
    );
    yield localStorage.setItem("accessToken", data?.data?.accessToken);
    payload.callback?.();
    yield put(actions.resetStore());
  } catch (err: any) {
    yield put(actions.verifyEmailRequestComplete());
    yield put(actions.setVerifyEmailFormError(err?.message));
  }
}

export function* resetPasswordRequest({
  payload,
}: {
  payload: { callback?: () => void };
}) {
  try {
    const params = new URLSearchParams(window.location.search);
    const token = params.get("hash");
    const email = params.get("email");
    yield delay(500);
    const resetPasswordForm = yield select(selectResestPasswordForm);
    const requestURL = `${API_URL}auth/passwords/change-password`;
    const options = {
      method: "PATCH",
      body: JSON.stringify({
        newPassword: resetPasswordForm.password.value,
        email: email,
        token: token,
      }),
    };
    const { data, err } = yield call(request, requestURL, options);
    if (err || !data) {
      throw JSON.parse(err?.message);
    }
    yield put(actions.resetPasswordRequestComplete());
    payload.callback?.();
    yield put(actions.resetStore());
  } catch (err: any) {
    yield put(actions.resetPasswordRequestComplete());
    toastService.error(err?.message);
  }
}

export function* doGoogleLoginRequest(action: {
  payload: { data: IGoogleLogin; callback: (nextPage: userOnboardingStage, fullName: string,token:string)=>void };
}) {
  try {
    yield delay(500);
    yield put(actions.setIsisGoogleLogin(true));
    const requestURL = `${API_URL}auth/google-login`;
    const options = {
      method: "POST",
      body: JSON.stringify(action.payload.data),
    };
    const { data, err } = yield call(request, requestURL, options);
    if (err || !data) {
      throw JSON.parse(err?.message);
    }
    yield put(actions.setIsisGoogleLogin(false));
    yield localStorage.setItem("accessToken", data?.data?.accessToken);
    yield put(appActions.setUserToken(data?.data.accessToken));
    yield put(
      appActions.setUserOnboardingProgress(data?.data?.userOnboardingProgress)
    );
    yield call(
      yield call(
        action.payload.callback,
        data?.data?.userOnboardingProgress,
        data?.data?.fullName,
        data?.data?.accessToken
      )
    );
  } catch (err: any) {
    yield put(actions.setIsisGoogleLogin(false));
    console.log(err?.message);
  }
}


export function* authSaga() {

  yield takeLatest(actions.loginRequest, loginRequest);
  yield takeLatest(actions.forgotPasswordRequest, forgotPasswordRequest);
  yield takeLatest(actions.signupRequest, signupRequest);
  yield takeLatest(actions.verifyEmailRequest, verifyEmailRequest);
  yield takeLatest(actions.resetPasswordRequest, resetPasswordRequest);
  yield takeLatest(actions.doGoogleLogin, doGoogleLoginRequest);
 
}
