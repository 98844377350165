import { FormError, userOnboardingStage } from "app/types";
import {
  LoginForm,
  ForgotPasswordForm,
  SignupForm,
  ResestPasswordForm,
} from "./types";
import { useNavigate } from "react-router-dom";

export const validateEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const validateLoginForm = (loginForm: Omit<LoginForm, "isLoading">) => {
  const { email, password } = loginForm;
  const errors: FormError[] = [];

  if (!validateEmail(email.value)) {
    errors.push({
      name: "email",
      error: "Incorrect email",
    });
  }

  return errors;
};

export const validateForgotPasswordForm = (
  forgotPasswordForm: Omit<ForgotPasswordForm, "isLoading">
) => {
  const { email } = forgotPasswordForm;
  const errors: FormError[] = [];

  if (!validateEmail(email.value)) {
    errors.push({
      name: "email",
      error: "Please enter a valid email address",
    });
  }

  return errors;
};

export const validateSignupForm = (
  signupForm: Omit<SignupForm, "isLoading">
) => {
  const { email } = signupForm;
  const errors: FormError[] = [];

  if (!validateEmail(email.value)) {
    errors.push({
      name: "email",
      error: "Please enter a valid email address",
    });
  }

  return errors;
};

export const validateOtp = (otp: string) => {
  // OTP must be 6 digits
  const otpRegex = /^[0-9]{6}$/;
  return otpRegex.test(otp);
};

export const validateResetPasswordForm = (
  form: Omit<ResestPasswordForm, "isLoading">
) => {
  const { password, confirmPassword } = form;
  const errors: FormError[] = [];

  if (!password.value) {
    errors.push({
      name: "password",
      error: "Password is required",
    });
  }

  if (!confirmPassword.value) {
    errors.push({
      name: "confirmPassword",
      error: "Confirm password is required",
    });
  }
  if (password.value !== confirmPassword.value) {
    errors.push({
      name: "confirmPassword",
      error: "Passwords do not match",
    });
  }

  return errors;
};

