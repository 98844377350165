import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "app/components/Popover";
import React from "react";

interface CustomPopoverProps {
  trigger: React.ReactNode;
  content: React.ReactNode;
  className?: string;
  align?: "start" | "center" | "end";
  onClose?: (val: boolean) => void;
  show?: boolean;
}

const CustomPopover: React.FC<CustomPopoverProps> = ({
  content,
  trigger,
  className,
  align = "start",
  onClose,
  show,
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <Popover open={show ?? open} onOpenChange={onClose ?? setOpen}>
        <PopoverTrigger asChild>{trigger}</PopoverTrigger>
        <PopoverContent
          align={align}
          className={`${className} w-[377px] bg-white border border-[#E9ECF2] overflow-scroll`}
        >
          {content}
        </PopoverContent>
      </Popover>
    </React.Fragment>
  );
};

export default CustomPopover;
