import Dropdown from "app/components/Dropdown";
import {
  CheckboxActiveIcon,
  Input,
  SearchIcon,
  TagIcon,
} from "app/components/Icons";
import CheckboxIcon from "app/components/Icons/CheckboxIcon";
import {TagList} from "../data"
import Pill from "app/components/Pill";
import React, { useState } from "react";
import PlusIcon from "app/components/Icons/PlusIcon";
import LabelIcon from "app/components/Icons/LabelIcon";
import AddNewTag from "../../Ticket/components/TicketDetailsComponent/TicketDetails/AddNewTag";
import CustomTooltip from "app/components/Tooltip";
import { ITag } from "../types";



export const LabelsButton = () => {
  const [selectedItems, setSelectedItems] = React.useState<ITag[]>([]);
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const filteredTag =  searchTags(TagList, searchKeyword);

  
  const handleCheckboxChange = (ele: ITag) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(ele)
        ? prevSelected.filter((item) => item.name !== ele.name)
        : [...prevSelected, ele]
    );
  };



  function searchTags(tags: ITag[] | undefined, keyword: string): ITag[] {
    if (!keyword || !tags) {
      return tags ? tags : [];
    }

    const lowerCaseKeyword = keyword.toLowerCase();

    return tags.filter((member) => {
      return member.name.toLowerCase().includes(lowerCaseKeyword);
    });
  }

  const MenuItems = filteredTag.map((option) => {
    if (option) {
      return {
        label: (
          <div
            key={option.name}
            className="flex flex-row items-center  py-1 w-full gap-2"
            onClick={(e) => {
              e.preventDefault();
              handleCheckboxChange(option);
            }}
          >
            {selectedItems.includes(option) ? (
              <CheckboxActiveIcon />
            ) : (
              <CheckboxIcon />
            )}
            <Pill
              backgroundColor={option.backgroundColor}
              textColor={option.color}
              name={option.name}
              className="px-[6px] py-[2px] text-[10px] leading-[18px] font-medium"
            />
          </div>
        ),
        action: () => {},
      };
    } else return { label: "", action: () => handleCheckboxChange(option)};
  });
  return (
    <>
      <Dropdown
        trigger={
          <button className="flex flex-row items-center justify-center h-[28px] min-w-[28px] rounded-[4px] border border-[#E9ECF2] hover:bg-[#ECEEF2] hover:text-[#79808A] hover:border-[#E9ECF2] transition-all duration-200">
            <CustomTooltip text="Set tags" position="bottom">
            <div className="flex items-center gap-1">
            <LabelIcon />
            {selectedItems.length > 0 && (
              <span className="text-sm text-gray-700 bg-gray-200 px-1 py-1rounded">
                {selectedItems.length} tag
              </span>
            )}
          </div>
          </CustomTooltip>
          </button>
        }
        projectInfoIcon={
          <div>
          <Input
            containerClassName="px-2 pb-1"
            noBorder
            id="search"
            icon={<SearchIcon size="17" color="#79808A" />}
            placeholder="Search by tag name"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            className="w-full p-1 text-sm border-none focus:ring-0"
          />
          <div className="flex items-center gap-2 pl-4 pr-2 pt-2 pb-2 text-secondary text-sm font-semibold uppercase border-t border-b border-[#E9ECF2]">
              TAGS
           </div>
          </div>
        }
        menuItems={MenuItems ? MenuItems : []}
        align="end"
        className="py-2"
        dropdownContainerClassName="max-h-[200px] overflow-y-auto"
        addSubContent={
          <AddNewTag
            trigger={
              <button className="w-full flex items-center text-md text-primary font-normal border-t py-2 px-3 gap-3 border-[#E9ECF2] ">
                <PlusIcon color="#5A8DF1" size={20} />
                New tag
              </button>
            }
          />
        }
      />
    </>
  );
};