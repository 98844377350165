import CommonSprints from "app/containers/Features/components/CommonSprints";
import React, { useState } from "react";
import { SprintList } from "../../../Backlog/data";
import CustomTooltip from "app/components/Tooltip";
import { SprintIcon } from "app/components/Icons";

const Sprint = () => {
  const [search, setSearch] = useState("");
  function searchMembers(members: any[] | undefined, keyword: string): any[] {
    if (!keyword || !members) {
      return members ? members : [];
    }

    const lowerCaseKeyword = keyword.toLowerCase();

    return members.filter((member) => {
      return member.name.toLowerCase().includes(lowerCaseKeyword);
    });
  }

  const filteredMembers = searchMembers(SprintList, search);
  return (
    <>
      <CommonSprints
        searchKeyword={search}
        setSearchKeyword={(val) => {
          setSearch(val);
        }}
        data={filteredMembers}
        selectedValue={""}
        updateValue={function (id: string): void {
          throw new Error("Function not implemented.");
        }}
        trigger={
          <button className="flex items-center justify-between w-full ">
            <CustomTooltip
              text="Set sprint"
              className="flex items-center "
            >
              <div className="flex items-center gap-2 w-full">
                <SprintIcon size={16} />
                        <span className="text-sm font-normal text-black">{filteredMembers[0].name}</span>
              </div>
            </CustomTooltip>
          </button>
        }
        searchPlaceholder="Search by sprint name"
        isHeader="sprints"
        align="start"
      />
    </>
  );
};

export default Sprint;
