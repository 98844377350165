import { TagIcon } from "app/components/Icons";
import Pill from "app/components/Pill";
import CustomTooltip from "app/components/Tooltip";
import CommonTagSelection from "app/containers/Features/components/CommonTagSelection";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectDefaultTagList } from "../../../../redux/selector";
import { actions } from "../../../../redux/slice";
import { ITag } from "../../../../types";

interface TagProps {
  ticketTag: ITag[];
}

const TagSelection: React.FC<TagProps> = ({ ticketTag }) => {
  const dispatch = useDispatch();
  const tags = useSelector(selectDefaultTagList);
  useEffect(() => {
    dispatch(actions.fetchDefaultTagValue());
    return () => {};
  }, []);

  return (
    <>
      <CommonTagSelection
        options={tags}
        selectedTags={ticketTag}
        trigger={
          <button className="flex items-center px-2 py-2 w-full gap-1 rounded-md hover:bg-[#F4F5F7] rounded-[4px] data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A] ">
            <CustomTooltip
              text="Set tags"
              className="flex flex-row items-start gap-1 "
              position="left"
            >
              <TagIcon size={16} />
              <div className="w-full flex flex-row items-center flex-wrap gap-1">
                {ticketTag?.map((label) => {
                  return (
                    <Pill
                      key={label.name}
                      className="!px-[6px] "
                      backgroundColor={label.backgroundColor}
                      textColor={label.color}
                      name={label.name}
                    />
                  );
                })}
              </div>
            </CustomTooltip>
          </button>
        }
      />
    </>
  );
};

export default TagSelection;
