import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { TICKET_API_URL } from "utils/constants";
import { request } from "utils/request";
import toastService from "utils/toast";
import { selectTicketTypesForm } from "./selector";
import { actions } from "./slice";

export function* doFetchTicketTypesRequest(action: { payload: string }) {
  try {
    yield delay(500);
    yield put(actions.setLoading(true));
    const id = action.payload;
    const requestURL = `${TICKET_API_URL}ticket/type/list/${id}`;
    const options = {
      method: "GET",
    };
    const { data, err } = yield call(request, requestURL, options);
    yield put(actions.setLoading(false));
    if (err || !data) {
      throw JSON.parse(err?.message);
    }
    yield put(actions.setTicketTypes(data.data));
  } catch (err: any) {
    yield put(actions.setLoading(false));
    toastService.error(err.message);
  }
}
export function* doFetchTicketFieldsRequest(action: { payload: string }) {
  try {
    yield delay(500);
    yield put(actions.setFieldsLoading(true));
    const id = action.payload;
    const requestURL = `${TICKET_API_URL}ticket/type/${id}`;
    const options = {
      method: "GET",
    };
    const { data, err } = yield call(request, requestURL, options);
    yield put(actions.setFieldsLoading(false));
    if (err || !data) {
      toastService.error(err.message);
    }
    yield put(actions.setTicketTypesForm(data.data));
  } catch (err: any) {
    yield put(actions.setFieldsLoading(false));
    toastService.error(err.message);
  }
}

export function* doUpdateRequest(action: {
  payload: { id: string; callback: () => void };
}) {
  try {
    yield delay(500);
    const form = yield select(selectTicketTypesForm);
    const requestURL = `${TICKET_API_URL}ticket/type/update/${action.payload.id}`;
    const nData = {
      name: form.name,
      description: form.description,
      icon: form.icon,
    };
    const options = {
      method: "PUT",
      body: JSON.stringify(nData),
    };
    const { data, err } = yield call(request, requestURL, options);
    if (err || !data) {
      toastService.error(err.message);
    }
    yield call(action.payload.callback);
  } catch (err: any) {
    toastService.error(err.message);
  }
}
export function* doUpdateFiledRequest(action: {
  payload: { id: string; callback: () => void };
}) {
  try {
    yield delay(500);
    const requestURL = `${TICKET_API_URL}ticket/type/fields/update/${action.payload.id}`;
    const options = {
      method: "PATCH",
    };
    const { data, err } = yield call(request, requestURL, options);
    if (err || !data) {
      toastService.error(err.message);
    }
    yield call(action.payload.callback);
  } catch (err: any) {
    toastService.error(err.message);
  }
}

export default function* TeamTicketTypesSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.doFetchTicketTypes, doFetchTicketTypesRequest);
  yield takeLatest(actions.doFetchTicketFields, doFetchTicketFieldsRequest);
  yield takeLatest(actions.doUpdate, doUpdateRequest);
  yield takeLatest(actions.doUpdateFields, doUpdateFiledRequest);
}
