// src/app/components/Spinner.tsx
import React from "react";

interface SpinnerProps {
    size?: "small" | "medium" | "large";
}

export default function Spinner({ size = "large" }: SpinnerProps) {
    const spinnerSize = size === "small" ? "h-10 w-10" : size === "large" ? "h-20 w-20" : "h-15 w-15";
    return (
        <div className={`animate-spin rounded-full border-t-2 border-b-2 border-gray-900 ${spinnerSize}`} />
    );
}
