import {
  getCustomCardIcon,
  getSubGroupIcon,
  getTicketValueIcon,
} from "../../containers/Home/containers/helpers";
import { GroupByTypeEnum, SubGroupEnum, TicketValueEnum } from "../../types";

export const VisibleGroupsOptions = [
  {
    id: 1,
    label: "In review",
    color: "#18BFCE",
    className: "text-[#18BFCE]",
    isVisible: true,
  },
  {
    id: 2,
    label: "Ready for development",
    color: "#D581F0",
    className: "text-[#D581F0]",
    isVisible: true,
  },
  {
    id: 3,
    label: "Not started",
    color: "#F57ECB",
    className: "text-[#F57ECB]",
    isVisible: true,
  },
  {
    id: 4,
    label: "Opened",
    color: "#43D98C",
    className: "text-[#43D98C]",
    isVisible: false,
  },
  {
    id: 5,
    label: "In Process",
    color: "#FEB355",
    className: "text-[#FEB355]",
    isVisible: false,
  },
  {
    id: 6,
    label: "Closed",
    color: "#A394FB",
    className: "text-[#A394FB]",
    isVisible: false,
  },
];
export const CustomizeCardOptions = [
  {
    id: 1,
    label: "Ticket Name",
    isVisible: true,
    icon: getCustomCardIcon(GroupByTypeEnum.TICKET_NAME),
    type: GroupByTypeEnum.TICKET_NAME,
  },
  {
    id: 2,
    label: "Tags",
    isVisible: true,
    icon: getCustomCardIcon(GroupByTypeEnum.TAGS),
    type: GroupByTypeEnum.TAGS,
  },
  {
    id: 3,
    label: "Assignee",
    isVisible: true,
    icon: getCustomCardIcon(GroupByTypeEnum.ASSIGNEE),
    type: GroupByTypeEnum.ASSIGNEE,
  },
  {
    id: 4,
    label: "Ticket ID",
    isVisible: false,
    icon: getCustomCardIcon(GroupByTypeEnum.TICKET_ID),
    type: GroupByTypeEnum.TICKET_ID,
  },
  {
    id: 5,
    label: "Ticket type",
    isVisible: false,
    icon: getCustomCardIcon(GroupByTypeEnum.TICKET_TYPE),
    type: GroupByTypeEnum.TICKET_TYPE,
  },
  {
    id: 6,
    label: "Status",
    isVisible: false,
    icon: getCustomCardIcon(GroupByTypeEnum.STATUS),
    type: GroupByTypeEnum.STATUS,
  },
  {
    id: 7,
    label: "Due date",
    isVisible: false,
    icon: getCustomCardIcon(GroupByTypeEnum.DUE_DATE),
    type: GroupByTypeEnum.DUE_DATE,
  },
  {
    id: 8,
    label: "Priority",
    isVisible: false,
    icon: getCustomCardIcon(GroupByTypeEnum.PRIORITY),
    type: GroupByTypeEnum.PRIORITY,
  },
  {
    id: 9,
    label: "Sprint",
    icon: getCustomCardIcon(GroupByTypeEnum.SPRINT),
    isVisible: false,
    type: GroupByTypeEnum.SPRINT,
  },
  {
    id: 10,
    label: "Release",
    isVisible: false,
    icon: getCustomCardIcon(GroupByTypeEnum.RELEASE),
    type: GroupByTypeEnum.RELEASE,
  },
  {
    id: 11,
    label: "Target release",
    icon: getCustomCardIcon(GroupByTypeEnum.TARGET_RELEASE),
    isVisible: false,
    type: GroupByTypeEnum.TARGET_RELEASE,
  },
  {
    id: 12,
    label: "Environment",
    icon: getCustomCardIcon(GroupByTypeEnum.ENVIRONMENT),
    isVisible: false,
    type: GroupByTypeEnum.ENVIRONMENT,
  },
  {
    id: 13,
    label: "Severity",
    icon: getCustomCardIcon(GroupByTypeEnum.SEVERITY),
    isVisible: false,
    type: GroupByTypeEnum.SEVERITY,
  },
  {
    id: 14,
    label: "Component",
    icon: getCustomCardIcon(GroupByTypeEnum.COMPONENT),
    isVisible: false,
    type: GroupByTypeEnum.COMPONENT,
  },
  {
    id: 15,
    label: "Creator",
    icon: getCustomCardIcon(GroupByTypeEnum.CREATOR),
    isVisible: false,
    type: GroupByTypeEnum.CREATOR,
  },
  {
    id: 16,
    label: "Creation date",
    icon: getCustomCardIcon(GroupByTypeEnum.CREATED_DATE),
    isVisible: false,
    type: GroupByTypeEnum.CREATED_DATE,
  },
  {
    id: 17,
    label: "Efforts Estimation",
    icon: getCustomCardIcon(GroupByTypeEnum.EFFORTS_ESTIMATION),
    isVisible: false,
    type: GroupByTypeEnum.EFFORTS_ESTIMATION,
  },
  {
    id: 18,
    label: "Efforts Actual",
    icon: getCustomCardIcon(GroupByTypeEnum.EFFORT_ACTUAL),
    isVisible: false,
    type: GroupByTypeEnum.EFFORT_ACTUAL,
  },
];

export const SubGroupsOptions = [
  {
    label: `None (default)`,
    type: SubGroupEnum.NONE,
    icon: getSubGroupIcon(SubGroupEnum.NONE),
  },
  {
    label: "Assignee",
    type: SubGroupEnum.ASSIGNEE,
    icon: getSubGroupIcon(SubGroupEnum.ASSIGNEE),
  },
  {
    label: "Priority",
    type: SubGroupEnum.PRIORITY,
    icon: getSubGroupIcon(SubGroupEnum.PRIORITY),
  },
  {
    label: "Tags",
    type: SubGroupEnum.TAGS,
    icon: getSubGroupIcon(SubGroupEnum.TAGS),
  },
  {
    label: "Due Date",
    type: SubGroupEnum.DUE_DATE,
    icon: getSubGroupIcon(SubGroupEnum.DUE_DATE),
  },
  {
    label: "Epic",
    type: SubGroupEnum.EPIC,
    icon: getSubGroupIcon(SubGroupEnum.EPIC),
  },
];


export const TicketValueOptions = {
  contextFields: [
    {
      id: "1",
      label: "Ticket Name",
      icon: getTicketValueIcon(TicketValueEnum.TICKET_NAME),
    },
    {
      id: "2",
      label: "Status",
      icon: getTicketValueIcon(TicketValueEnum.STATUS),
    },
    {
      id: "3",
      label: "Assignee",
      icon: getTicketValueIcon(TicketValueEnum.ASSIGNEE),
    },
    {
      id: "4",
      label: "Due date",
      icon: getTicketValueIcon(TicketValueEnum.DUE_DATE),
    },
    {
      id: "5",
      label: "Priority",
      icon: getTicketValueIcon(TicketValueEnum.PRIORITY),
    },
    {
      id: "6",
      label: "Tags",
      icon: getTicketValueIcon(TicketValueEnum.TAGS),
    },
    {
      id: "7",
      label: "Ticket description",
      icon: getTicketValueIcon(TicketValueEnum.TICKET_DESCRIPTION),
    },
  ],
  customFields: [
    {
      id: "1",
      label: "Comment",
      icon: getTicketValueIcon(TicketValueEnum.COMMENT),
    },
    {
      id: "2",
      label: "Notes",
      icon: getTicketValueIcon(TicketValueEnum.NOTES),
    },
    {
      id: "3",
      label: "Custom priority",
      icon: getTicketValueIcon(TicketValueEnum.CUSTOM_PRIORITY),
    },
    {
      id: "4",
      label: "Creation date",
      icon: getTicketValueIcon(TicketValueEnum.CREATED_DATE),
    },
    {
      id: "5",
      label: "Number",
      icon: getTicketValueIcon(TicketValueEnum.NUMBER),
    },
    {
      id: "6",
      label: "ID",
      icon: getTicketValueIcon(TicketValueEnum.ID),
    },
    {
      id: "7",
      label: "Tags",
      icon: getTicketValueIcon(TicketValueEnum.TAGS),
    },
    {
      id: "8",
      label: "URL",
      icon: getTicketValueIcon(TicketValueEnum.URL),
    },
  ],
};
export const targetReleasesData = [
  { id: "1", label: "2.0.0", status: "Last" },
  { id: "2", label: "2.0.1", status: "Current" },
  { id: "3", label: "2.0.2", status: "Next" },
  { id: "4", label: "2.0.3" },
  { id: "5", label: "2.0.4" },
  { id: "6", label: "2.0.5" },
  { id: "7", label: "2.0.6" },
];
export const releasesData = [
  { id: "1", label: "1.0", status: "Last" },
  { id: "2", label: "2.0", status: "Current" },
  { id: "3", label: "3.0", status: "Next" },
  { id: "4", label: "4.0" },
  { id: "5", label: "5.0" },
  { id: "6", label: "6.0" },
  { id: "7", label: "7.0" },
];
export const severityData = [
  { id: "1", label: "Moderate", iconColor: "#6FCF97" },
  { id: "2", label: "Minor", iconColor: "#2D9CDB" },
  { id: "3", label: "Major", iconColor: "#F2994A" },
  { id: "14", label: "Critical", iconColor: "#EB5757" },
];
export const environmentData = [
  { id: "1", label: "Development" },
  { id: "2", label: "Production" },
  { id: "3", label: "Staging" },
  { id: "4", label: "QA" },
  { id: "5", label: "Docker Container" },
];
