import FileUploadingView from "app/components/FileUploadingView";
import PlusIcon from "app/components/Icons/PlusIcon";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsFileUploading, selectTicketForm, selectUploadProgress } from "../../redux/selector";
import { actions } from "../../redux/slice";

const Attachments = () => {
  const dispatch = useDispatch();
  const form = useSelector(selectTicketForm);
  const isUploading = useSelector(selectIsFileUploading);
  const progress = useSelector(selectUploadProgress);
  const handlePreview = () => {
    dispatch(actions.toggleIsFilePreview(true));
  };
  return (
    <div className="flex flex-col gap-4 px-[24px] pb-4">
      <div className="flex flex-row justify-between items-center">
        <p className="text-xs leading-[21px] font-medium text-black">
          Attachments
        </p>
        <button className="focus:outline-none">
          <PlusIcon size={24} color="#79808A" />
        </button>
      </div>
      <p className="text-xs leading-[18px] font-normal text-secondary text-center cursor-pointer">
        Drop your files here to <span className="underline">upload</span>
      </p>
      <div className="flex w-full">
        <FileUploadingView
          isUploading={isUploading}
          data={form.file}
          onPreview={handlePreview}
          progress={progress}
        />
      </div>
    </div>
  );
};

export default Attachments;
