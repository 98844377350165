import React from "react";
import { NumericFormat } from "react-number-format";

interface NumberInputProps {
  readonly id: string;
  readonly label?: string;
  readonly placeholder?: string;
  readonly value?: string | number;
  readonly onChange?: (value: string | number) => void;
  readonly className?: string;
  readonly containerClassName?: string;
  readonly innerContainerClassName?: string;
  readonly noBorder?: boolean;
  readonly labelClassName?: string;
  readonly inputClassName?: string;
}

const Index: React.FC<NumberInputProps> = ({
  id = "",
  label = "",
  placeholder = "Enter number",
  value,
  onChange,
  className = "",
  containerClassName,
  innerContainerClassName,
  inputClassName,
  labelClassName,
  noBorder,
}) => {
  return (
    <>
      <div className={`relative ${containerClassName}`}>
        <div
          className={`flex flex-col gap-[6px] group ${innerContainerClassName}`}
        >
          {label && (
            <label
              htmlFor={id}
              className={`text-xs leading-[18px] text-[#79808A] ${labelClassName}`}
            >
              {label}
            </label>
          )}
          <div
            className={`flex items-center gap-[8px] h-[48px] flex-row ${
              noBorder ? "" : "border rounded-[4px]"
            } py-[16px] px-[10px] ${inputClassName} ${
              noBorder ? "" : "border-[#E9ECF2]"
            } `}
          >
            <NumericFormat
              id={id}
              thousandSeparator=","
              allowNegative={false}
              decimalScale={0} // No decimal places
              value={value}
              onValueChange={(values) => onChange?.(values.value)}
              placeholder={placeholder}
              className={`text-sm rounded w-full text-gray-700 leading-[21px] focus:outline-none placeholder:text-[#BDC0C3] placeholder:font-normal placeholder:text-sm placeholder:leading-[21px] ${className}`}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
