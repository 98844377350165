/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import React, { Suspense, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Login from "./containers/Auth/containers/login";
import ForgotPassword from "./containers/Auth/containers/forgotPassword";
import RecoverySuccess from "./containers/Auth/containers/recoverySuccess";
import ResetPassword from "./containers/Auth/containers/resetPassword";
import Signup from "./containers/Auth/containers/signup";
import VerifyEmail from "./containers/Auth/containers/verifyEmail";
import CreateProfile from "./containers/OnBoarding/containers/Profile";
import Welcome from "./containers/OnBoarding/containers/Profile/components/welcome";
import Setup from "./containers/OnBoarding/containers/Profile/components/setup";
import TeamOnBoarding from "./containers/OnBoarding/containers/Team";
import Homepage from "./containers/Features/containers/Home";
import Boards from "./containers/Features/containers/Boards";
import BoardView from "./containers/Features/containers/Boards/containers/Tickets";
import Backlog from "./containers/Features/containers/Boards/containers/Backlog";
import { Auth } from "./containers/Auth/Loadable";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { OnBoarding } from "./containers/OnBoarding/Loadable";
import { Features } from "./containers/Features/Loadable";
import ProjectSettings from "./containers/Features/containers/ProjectSettings";
import { useDispatch } from "react-redux";
import {
  teamActions,
  teamReducer,
  teamSlice,
} from "./containers/Features/containers/ProjectSettings/redux/slice";
import TeamSaga from "./containers/Features/containers/ProjectSettings/redux/saga";
import ProjectSettingRouting from "./containers/Features/containers/ProjectSettings/ProjectSettingRouting";
import {
  authReducer,
  authSlice,
} from "./containers/Auth/redux/slice";
import { authSaga } from "./containers/Auth/redux/saga";
import appSaga from "./redux/saga";
import {actions,
  sliceKey as appSlice,
  reducer as appReducer,
} from "../app/redux/slice";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/auth/login" />,
  },
  {
    path: "/auth",
    element: <Auth />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "recovery-success",
        element: <RecoverySuccess />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "signup",
        element: <Signup />,
      },
      {
        path: "verify-email",
        element: <VerifyEmail />,
      },
    ],
  },
  {
    path: "/onboarding",
    element: <OnBoarding />,
    children: [
      {
        path: "create-profile",
        element: <CreateProfile />,
      },
      {
        path: "welcome",
        element: <Welcome />,
      },
      {
        path: "setup",
        element: <Setup />,
      },
      {
        path: "team",
        element: <TeamOnBoarding />,
      },
    ],
  },
  {
    path: "/app",
    element: <Features />,
    children: [
      {
        path: "home",
        element: <Homepage />,
      },
      {
        path: ":project/:team/boards",
        element: <Boards />,
      },
      {
        path: ":project/:team/boards/:id/board",
        element: <BoardView />,
      },
      {
        path: ":project/:team/boards/backlog",
        element: <Backlog />,
      },
      {
        path: "project",
        element: <ProjectSettings />,
        children: ProjectSettingRouting,
      },
    ],
  },
]);

export function App() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessToken");
  useInjectReducer({
    key: appSlice,
    reducer: appReducer,
  });
  useInjectSaga({ key: appSlice, saga: appSaga });


  useInjectReducer({ key: authSlice, reducer: authReducer });
  useInjectSaga({ key: authSlice, saga: authSaga });
  useInjectReducer({ key: teamSlice, reducer: teamReducer });
  useInjectSaga({ key: teamSlice, saga: TeamSaga });

// update due to mange me request for home state remove form auth state
  useEffect(() => {
    if (token) {
      dispatch(actions.doGetMyProfile());
      dispatch(actions.doGetUserWorkspace());
    }
    return () => {};
  }, [token]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Helmet titleTemplate="%s - Teambox" defaultTitle="Teambox">
        <meta
          name="description"
          content="Transform Your Software Development"
        />
      </Helmet>
      <RouterProvider router={router} />
    </Suspense>
  );
}
