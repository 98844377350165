import { TeamTicketTypeState } from "../types";

export const initialState: TeamTicketTypeState = {
  loading: false,
  fieldsLoading: false,
  buttonLoading: false,
  ticketTypesList: [],
  ticketTypesForm: {
    _id: "",
    createdAt: "",
    updatedAt: "",
    name: "",
    description: "",
    icon: "",
    ticketTypeFields: [],
  },
};
