import { authorizedAccounts } from "app/containers/Auth/data";
import { AppHomeState, PLAN_TYPE, sortOrder, userOnboardingStage } from "app/types";
import {
    BoardPermission,
    BoardType,
    ContainerState,
    CustomAccessEnum,
    DefaultTicketType,
    GroupByTypeEnum,
    IDefaultTicketValueData,
    ITag,
    IVisibleGroups,
    SubGroupEnum,
    TicketGroup,
    TicketType,
  } from "../types";
import { PriorityFilterEnum, StatusFilterEnum } from "../../Ticket/types";
import {
  CustomizeCardOptions,
  VisibleGroupsOptions,
} from "../components/CreateBoardsComponent/data";
export const initialState: ContainerState = {
  boardsData: [],
  favorites: [],
  qaBoards: [],
  recent: [],
  boardsForm: {
    id: "",
    type: BoardType.LIST,
    name: "",
    projects: [],
    owner: {
      _id: "",
      name: "",
      online: false,
      color: "",
      role: "",
    },
    createdAt: "",
    lastUpdated: "",
    lastViewed: "",
    permission: BoardPermission.PRIVATE,
    description: "",
    ticketType: TicketType.BUG,
    subGroups: SubGroupEnum.NONE,
    visibleGroups: VisibleGroupsOptions,
    customizeCard: CustomizeCardOptions,
    groupsBy: GroupByTypeEnum.STATUS,
    defaultTicketValue: [],
    customPermissions: [],
  },
  defaultTicketValueData: {
    customFields: [],
    contextFields: [],
  },
  draftTicket: [],
  ticketForm: {
    id: "",
    name: "",
    teamName: "",
    projectName: "",
    type: TicketType.TASK,
    status: StatusFilterEnum.OPENED,
    assignee: "",
    startDate: "",
    endDate: "",
    priority: PriorityFilterEnum.NONE,
    tags: [] as ITag[],
    description: "",
    file: [],
  },
  selectedTickets: [],
  isFileUploading: false,
  isFilePreview: false,
  uploadProgress: 0,
  tagsList: [],
  sortByColumn: "",
  sortByOrder: "",
  isLoading: false,
};