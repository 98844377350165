import Dropdown from "app/components/Dropdown";
import { CheckIcon, Input, SearchIcon, UserIcon } from "app/components/Icons";
import MemberProfile from "app/components/MemberProfile";
import { members } from "app/containers/Features/containers/ProjectSettings/data";
import { IMember } from "app/containers/Features/containers/ProjectSettings/types";
import React from "react";
import StatusSelectionButton from "./StatusSelectionButton";

interface AssigneeDropdownProps {
  value?: string;
  setValue?: (value: string) => void;
}

const AssigneeDropdown: React.FC<AssigneeDropdownProps> = ({
  setValue,
  value,
}) => {
  const selectedOptions = members.find((p) => p.id === value) || members[0];

  const [searchKeyword, setSearchKeyword] = React.useState("");
  function searchMembers(
    members: IMember[] | undefined,
    keyword: string
  ): IMember[] {
    if (!keyword || !members) {
      return members ? members : [];
    }

    const lowerCaseKeyword = keyword.toLowerCase();

    return members.filter((member) => {
      return (
        member.name.toLowerCase().includes(lowerCaseKeyword) ||
        member.role.toLowerCase().includes(lowerCaseKeyword)
      );
    });
  }

  const filteredMembers = searchMembers(members, searchKeyword);
  const MenuItems = filteredMembers?.map((ele) => {
    if (ele)
      return {
        label: (
          <MemberProfile
            iconColor={ele.icon.color}
            iconBgColor={ele.icon.bgColor}
            iconText={ele.icon.initials}
            name={ele.name}
            statusColor="#6FCF97"
            role={ele.role}
            endIcon={<CheckIcon color="#5A8DF1" />}
            showEndIcon={value === ele.id}
            iconClassName="!w-7 !h-7"
            statusClassName="!w-2.5 !h-2.5 bottom-[-2px]"
            titleClassName="text-sm"
            roleClassName="text-xs"
          />
        ),
        action: () => {
          setValue && setValue(ele.id);
        },
      };
    else return { label: "", action: () => {} };
  });
  return (
    <>
      <Dropdown
        trigger={
          <button className="flex items-center justify-between w-full px-2 py-2 rounded-md hover:bg-[#F4F5F7] rounded-[4px] data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A]">
            <StatusSelectionButton
              label="Assignee:"
              selectedLabel={selectedOptions?.name}
              avatarColor={selectedOptions?.icon.bgColor}
              icon={<UserIcon size={20} color="#79808A" />}
              className="text-[12px] leading-[18px] font-medium text-secondary mt-[2px]"
              showAvatar
            />
          </button>
        }
        projectInfoIcon={
          <Input
            noBorder
            id="search"
            icon={<SearchIcon size="17" color="#79808A" />}
            placeholder="Search by name"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            innerContainerClassName="px-2 pb-1 border-b-[1px] border-[#79808A]"
          />
        }
        menuItems={MenuItems ? MenuItems : []}
        align="end"
        className="min-w-[240px] py-2"
        dropdownContainerClassName="max-h-[360px] overflow-scroll "
      />
    </>
  );
};

export default AssigneeDropdown;
