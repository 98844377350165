import PlusIcon from "app/components/Icons/PlusIcon";
import IconText from "app/components/IconText";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import list from "assets/imgs/list.png";
import kanban from "assets/imgs/kanban.png";
import agile from "assets/imgs/agile.png";
import releases from "assets/imgs/releases.png";
import bugs from "assets/imgs/bugs.png";
import design from "assets/imgs/design.png";
import roadmap from "assets/imgs/roadmap.png";
import management from "assets/imgs/management.png";
import { useQueryState } from "nuqs";
import { BoardTemplateEnum } from "app/types";
import { useDispatch } from "react-redux";
import { actions } from "app/redux/slice";
import { useSelector } from "react-redux";
import { selectIsBoardCreate } from "app/redux/selector";

const OPTIONS = [
  {
    title: "List",
    caption: "Organized & simple list of tasks",
    img: list,
    type: "",
  },
  {
    title: "Kanban",
    caption: "Move tasks between columns",
    img: kanban,
  },
  {
    title: "Agile Development",
    caption: "Streamline Agile workflows from backlog to completion.",
    img: agile,
    type: BoardTemplateEnum.AGILE_DEVELOPMENT,
  },
  {
    title: "Release Management",
    caption:
      "Manage software releases, CI/CD pipelines, and deployment processes.",
    img: releases,
    type: BoardTemplateEnum.RELEASE_MANAGEMENT,
  },
  {
    title: "Bug Tracking",
    caption:
      "Track and resolve bugs and issues efficiently through their lifecycle.",
    img: bugs,
    type: BoardTemplateEnum.BUG_TRACKING,
  },
  {
    title: "Design and Prototyping",
    caption: "Streamline design tasks from concept to user testing.",
    img: design,
    type: BoardTemplateEnum.DESIGN_AND_PROTOTYPING,
  },
  {
    title: "Product Roadmap",
    caption: "Plan and track feature development and product releases.",
    img: roadmap,
    type: BoardTemplateEnum.PRODUCT_ROADMAP,
  },
  {
    title: "General Project Management",
    caption: "Oversee software project from start to end.",
    img: management,
    type: BoardTemplateEnum.GENERAL_PROJECT_MANAGEMENT,
  },
];

export default function AddBoard() {
  const { project, team } = useParams();
  const [open, setOpen] = useState(false);
  const first2Options = OPTIONS.slice(0, 2);
  const remainingOptions = OPTIONS.slice(2);
  const [, setSettings] = useQueryState("settings");
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsBoardCreate);
  const handleCreate = (type: BoardTemplateEnum) => {
    dispatch(
      actions.doCreateFixTempBoard({
        data: {
          projectId: project as string,
          teamId: team as string,
          templateType: type,
        },
        callback() {},
      })
    );
  };

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Link
          to="#"
          className={`hover:bg-gray-100 py-2 px-[10px] rounded-[4px] focus:outline-none ${
            open ? "border border-secondary" : ""
          }`}
        >
          <IconText
            className="gap-[6px]"
            text="View"
            textClassName="text-secondary text-sm leading-[21px] font-normal"
            icon={<PlusIcon size={20} />}
          />
        </Link>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        align="start"
        className="p-4 py-[26px] !w-[594px] bg-danger gap-[10px] z-10 !bg-white rounded-[4px] shadow-[2px_2px_6px_0px_rgba(237,239,242,0.70)] border border-[#E9ECF2]" // move to the right by 50%
        sideOffset={4}
      >
        <div className="grid grid-cols-2 gap-y-[10px]">
          {first2Options.map((option, index) => (
            <DropdownMenuItem
              key={index}
              className="flex flex-row gap-[6px] p-[8px] focus:outline-none hover:bg-[#F4F5F7] rounded-[4px] cursor-pointer"
              onClick={() => setSettings(option.title?.toLowerCase())}
            >
              <img
                className="size-[40px] min-w-[40px]"
                src={option.img}
                alt={option.title}
              />
              <div className="flex flex-col">
                <p className="text-black text-sm leading-[21px] font-medium">
                  {option.title}
                </p>
                <p className="text-secondary text-xs leading-[18px] font-normal">
                  {option.caption}
                </p>
              </div>
            </DropdownMenuItem>
          ))}
        </div>

        <div className="bg-gray-100 h-[1px] w-full my-[10px]" />

        <p className="text-secondary text-xs leading-[18px] font-medium">
          Templates
        </p>

        <div className="grid grid-cols-2 gap-y-[10px]">
          {remainingOptions.map((option, index) => (
            <DropdownMenuItem
              key={index}
              className="flex flex-row gap-[6px] p-[8px] focus:outline-none hover:bg-[#F4F5F7] rounded-[4px] cursor-pointer"
              onClick={() => {
                handleCreate(option.type as BoardTemplateEnum);
              }}
              disabled={isLoading}
            >
              <img
                className="size-[40px] min-w-[40px]"
                src={option.img}
                alt={option.title}
              />
              <div className="flex flex-col">
                <p className="text-black text-sm leading-[21px] font-medium">
                  {option.title}
                </p>
                <p className="text-secondary text-xs leading-[18px] font-normal">
                  {option.caption}
                </p>
              </div>
            </DropdownMenuItem>
          ))}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
