import Card from "app/components/Card";
import { StatusIcon } from "app/components/Icons";
import CustomTooltip from "app/components/Tooltip";
import expendIcon from "assets/svgs/ic_menu_toggle.svg";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectTicketGroups } from "../../Tickets/redux/selector";
import { useDispatch } from "react-redux";
import { actions } from "../../Tickets/redux/slice";
import CollapseCardTile from "./CollapseCardTile";

const CollapseCard = () => {
  const [show, setShow] = React.useState(false);
  const dispatch = useDispatch();
  const groupData = useSelector(selectTicketGroups);
  const collapseCard = groupData.filter((x) => x.status.isCollapse);
  const handleUnCollapsed = (group) => {
    dispatch(actions.toggleGroupsIsCollapse(group));
  };
  useEffect(() => {
    if (collapseCard.length === 1 && show) {
      setShow(false);
    }

    return () => {};
  }, [collapseCard]);

  return (
    <>
      {collapseCard.length > 0 && (
        <React.Fragment>
          {show ? (
            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-3">
                {collapseCard.map((ele) => {
                  return (
                    <Card className="rounded-[6px] !p-[10px]  hover:bg-[#E9ECF2] h-[max-content] w-12  ">
                      <CollapseCardTile
                        tooltip="Expand group"
                        length={ele?.tickets?.length}
                        color={ele?.status?.color}
                        name={ele?.status?.name}
                        handleClick={() => handleUnCollapsed(ele)}
                        icon={
                          <StatusIcon size={20} color={ele?.status?.color} />
                        }
                      />
                    </Card>
                  );
                })}
              </div>

              <Card className="rounded-[6px] !p-[10px]  hover:bg-[#E9ECF2] h-[max-content] w-12">
                <CustomTooltip
                  className="h-full w-full"
                  position="bottom"
                  text="Combine hidden groups "
                >
                  <div
                    className="flex flex-col items-center gap-3 -rotate-180"
                    onClick={() => setShow(false)}
                  >
                    <button>
                      <img
                        src={expendIcon}
                        alt={expendIcon}
                        className="h-[30px] w-[30px]"
                      />
                    </button>
                  </div>
                </CustomTooltip>
              </Card>
            </div>
          ) : (
            <>
              {collapseCard?.length > 1 ? (
                <Card className="rounded-[6px] !p-[10px] hover:bg-[#E9ECF2] h-[max-content] w-12 relative">
                  <CollapseCardTile
                    tooltip="Expand group"
                    length={collapseCard?.length}
                    name="collapsed"
                    handleClick={() => setShow(true)}
                    icon={
                      <img
                        src={expendIcon}
                        alt={expendIcon}
                        className="h-6 w-6 text-center"
                      />
                    }
                  />
                </Card>
              ) : (
                <Card className="rounded-[6px] !p-[10px]  hover:bg-[#E9ECF2] h-[max-content] w-12 flex flex-col items-center py-4 px-2 ">
                  <CollapseCardTile
                    tooltip="Expand group"
                    length={collapseCard[0]?.tickets?.length}
                    color={collapseCard[0]?.status?.color}
                    name={collapseCard[0]?.status?.name}
                    handleClick={() => handleUnCollapsed(collapseCard[0])}
                    icon={
                      <StatusIcon
                        size={20}
                        color={collapseCard[0]?.status?.color}
                      />
                    }
                  />
                </Card>
              )}
            </>
          )}
        </React.Fragment>
      )}
    </>
  );
};

export default CollapseCard;
