import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAkjHr7F1J882w6z5bZAmESy9e3_Iv2HqY",
  authDomain: "teambox-fe-webapp.firebaseapp.com",
  projectId: "teambox-fe-webapp",
  storageBucket: "teambox-fe-webapp.firebasestorage.app",
  messagingSenderId: "336498789884",
  appId: "1:336498789884:web:5f7fe7c927d73144e03679",
  measurementId: "G-M9VRD2JE99",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth();

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });
export const signInWithGoogle = () => signInWithPopup(auth, provider);

export default app;
