import React, { Suspense } from "react";
import TicketsTypes from "./components/TicketsTypes";

// Lazy load components
const GeneralInfo = React.lazy(() => import("./components/GeneralInfo"));
const Projects = React.lazy(() => import("./components/Projects"));
const ProjectsTable = React.lazy(
  () => import("./components/Projects/ProjectsTable")
);
const ProjectDetails = React.lazy(
  () => import("./components/Projects/ProjectDetails")
);
const ProjectMembers = React.lazy(
  () => import("./components/Projects/ProjectMembers")
);
const ProjectComponents = React.lazy(
  () => import("./components/Projects/ProjectComponents")
);
const ProjectFeatures = React.lazy(
  () => import("./components/Projects/ProjectFeatures")
);
const ProjectActiveWorkFlow = React.lazy(
  () => import("./components/Projects/ProjectActiveWorkFlow")
);

const ProjectSettingRouting = [
  {
    path: ":id/general-info",
    element: (
      <Suspense fallback={<div>Loading General Info...</div>}>
        <GeneralInfo />
      </Suspense>
    ),
  },
  {
    path: ":id/projects",
    element: (
      <Suspense fallback={<div>Loading Projects...</div>}>
        <Projects />
      </Suspense>
    ),
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<div>Loading Projects Table...</div>}>
            <ProjectsTable />
          </Suspense>
        ),
      },
      {
        path: "details",
        element: (
          <Suspense fallback={<div>Loading Projects Table...</div>}>
            <ProjectDetails />
          </Suspense>
        ),
      },
      {
        path: "members",
        element: (
          <Suspense fallback={<div>Loading Projects Table...</div>}>
            <ProjectMembers />
          </Suspense>
        ),
      },
      {
        path: "components",
        element: (
          <Suspense fallback={<div>Loading Projects Components...</div>}>
            <ProjectComponents />
          </Suspense>
        ),
      },
      {
        path: "features",
        element: (
          <Suspense fallback={<div>Loading Projects Features...</div>}>
            <ProjectFeatures />
          </Suspense>
        ),
      },
      {
        path: "active-workflow",
        element: (
          <Suspense fallback={<div>Loading Projects active workflow...</div>}>
            <ProjectActiveWorkFlow />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: ":id/tickets",
    element: (
      <Suspense fallback={<div>Loading tickets types...</div>}>
        <TicketsTypes />
      </Suspense>
    ),
  },
  {
    path: ":id/members",
    element: (
      <Suspense fallback={<div>Loading tickets types...</div>}>
        <></>
      </Suspense>
    ),
  },
  {
    path: ":id/billing",
    element: (
      <Suspense fallback={<div>Loading tickets types...</div>}>
        <></>
      </Suspense>
    ),
  },
  {
    path: ":id/addons",
    element: (
      <Suspense fallback={<div>Loading tickets types...</div>}>
        <></>
      </Suspense>
    ),
  },
  {
    path: ":id/import-export",
    element: (
      <Suspense fallback={<div>Loading tickets types...</div>}>
        <></>
      </Suspense>
    ),
  },
  {
    path: ":id/security",
    element: (
      <Suspense fallback={<div>Loading tickets types...</div>}>
        <></>
      </Suspense>
    ),
  },
  {
    path: ":id/audit-logs",
    element: (
      <Suspense fallback={<div>Loading tickets types...</div>}>
        <></>
      </Suspense>
    ),
  },
  {
    path: ":id/archives",
    element: (
      <Suspense fallback={<div>Loading tickets types...</div>}>
        <></>
      </Suspense>
    ),
  },
  { path: "*", element: <h1>Not Found</h1> },
];

export default ProjectSettingRouting;
