import { createSlice } from "utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { ContainerState, IGoogleLogin } from "../types";
import { FormError, userOnboardingStage } from "app/types";
import { set } from "lodash";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  loginForm: {
    email: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
    isLoading: false,
  },
  forgotPasswordForm: {
    email: {
      value: "",
      error: "",
    },
    isLoading: false,
  },
  signupForm: {
    email: {
      value: "",
      error: "",
    },
    isLoading: false,
  },
  verifyEmailForm: {
    code: {
      value: "",
      error: "",
    },
    isLoading: false,
  },
  resestPasswordForm: {
    password: {
      value: "",
      error: "",
    },
    confirmPassword: {
      value: "",
      error: "",
    },
    isLoading: false,
  },
  isGoogleLogin: false,
};

const formSlice = createSlice({
  name: "AuthState",
  initialState,
  reducers: {
    setIsisGoogleLogin: (state, action: PayloadAction<boolean>) => {
      state.isGoogleLogin = action.payload;
    },
    setLoginFormValues: (
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      set(
        state.loginForm,
        `${action.payload.name}.value`,
        action.payload.value
      );
      set(state.loginForm, `${action.payload.name}.error`, "");
    },
    setLoginFormErrors: (state, action: PayloadAction<FormError[]>) => {
      action.payload.forEach((error) => {
        state.loginForm[error.name].error = error.error;
      });
    },
    loginRequest: (
      state,
      action: PayloadAction<{
        callback: (nextPage: userOnboardingStage) => void;
      }>
    ) => {
      state.loginForm.isLoading = true;
    },
    loginRequestComplete: (state) => {
      state.loginForm.isLoading = false;
    },
    setForgotPasswordFormErrors: (
      state,
      action: PayloadAction<FormError[]>
    ) => {
      action.payload.forEach((error) => {
        state.forgotPasswordForm[error.name].error = error.error;
      });
    },
    resetForgotPasswordFormError: (state) => {
      state.forgotPasswordForm.email.error = "";
    },
    forgotPasswordRequest: (
      state,
      action: PayloadAction<{ email: string; callback?: () => void }>
    ) => {
      state.forgotPasswordForm.isLoading = true;
      state.forgotPasswordForm.email.value = action.payload.email;
    },
    forgotPasswordRequestComplete: (state) => {
      state.forgotPasswordForm.isLoading = false;
    },
    setSignupFormErrors: (state, action: PayloadAction<FormError[]>) => {
      action.payload.forEach((error) => {
        set(state.signupForm, `${error.name}.error`, error.error);
      });
    },
    resetSignupFormError: (state) => {
      state.signupForm.email.error = "";
    },
    signupRequest: (
      state,
      action: PayloadAction<{ email: string; callback?: () => void }>
    ) => {
      state.signupForm.isLoading = true;
      state.signupForm.email.value = action.payload.email;
    },
    signupRequestComplete: (state) => {
      state.signupForm.isLoading = false;
    },
    setVerifyEmailFormError: (state, action: PayloadAction<string>) => {
      state.verifyEmailForm.code.error = action.payload;
    },
    resetVerifyEmailFormError: (state) => {
      state.verifyEmailForm.code.error = "";
    },
    verifyEmailRequest: (
      state,
      action: PayloadAction<{ code: string; callback?: () => void }>
    ) => {
      state.verifyEmailForm.isLoading = true;
      state.verifyEmailForm.code.value = action.payload.code;
    },
    verifyEmailRequestComplete: (state) => {
      state.verifyEmailForm.isLoading = false;
    },
    setResetPasswordFormValues: (
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      set(
        state.resestPasswordForm,
        `${action.payload.name}.value`,
        action.payload.value
      );
      set(state.resestPasswordForm, `${action.payload.name}.error`, "");
    },
    setResetPasswordFormErrors: (state, action: PayloadAction<FormError[]>) => {
      action.payload.forEach((error) => {
        set(state.resestPasswordForm, `${error.name}.error`, error.error);
      });
    },
    resetPasswordRequest: (
      state,
      action: PayloadAction<{
        password: string;
        confirmPassword: string;
        callback?: () => void;
      }>
    ) => {
      state.resestPasswordForm.isLoading = true;
      state.resestPasswordForm.password.value = action.payload.password;
      state.resestPasswordForm.confirmPassword.value =
        action.payload.confirmPassword;
    },
    resetPasswordRequestComplete: (state) => {
      state.resestPasswordForm.isLoading = false;
    },

    // google login API
      doGoogleLogin: (
      state,
      action: PayloadAction<{
        data: IGoogleLogin;
        callback: (nextPage: userOnboardingStage, fullName: string,token:string) => void;
      }>
    ) => {},
   
    resetStore: () => initialState
  },
});

export const { actions, reducer: authReducer, name: authSlice } = formSlice;
