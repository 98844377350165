import AdaptiveDatePicker from "app/components/AdaptiveDatePicker";
import { CalendarIcon } from "app/components/Icons";
import IconText from "app/components/IconText";
import React, { useState } from "react";

const DateSelection = () => {
  const [value, setValue] = useState<Date | null>(new Date());

  const handleChange = (val) => {
    setValue(val);
  };
  return (
    <>
      <div className="w-[196px] border border-gray-100 border-r-0 border-t-0 rounded-r-none h-[42px] flex flex-col justify-center pl-4 pr-2 rounded-none">
        <IconText
          icon={<CalendarIcon size={16} color="#79808A" />}
          text="Date"
          className="gap-[4px]"
          textClassName="text-[12px] leading-[18px] font-medium text-secondary"
        />
      </div>
      <div className="w-full">
        <AdaptiveDatePicker className="!h-[42px] border-t-0 rounded-none"
          value={value}
          onChange={(v) => {
            handleChange(v);
          }}
        />
      </div>
    </>
  );
};

export default DateSelection;
