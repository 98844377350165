import basecamp from 'assets/svgs/ic_basecamp.svg';
import monday from 'assets/svgs/ic_monday.svg';
import trello from 'assets/svgs/ic_trello.svg';
import jira from 'assets/svgs/ic_jira.svg';
import asana from 'assets/svgs/ic_asana.svg';
import notion from 'assets/svgs/ic_notion.svg';
import excel from 'assets/svgs/ic_excel.svg';
import { Option } from 'app/types';
import { id } from 'date-fns/locale';

export const toolPreferencesList = [
    {
        name: 'Basecamp',
        _id:'',
        src: basecamp
    }, 
    {
        name: 'Todolist',      
        _id:'',
        src: monday
    }, 
    {
        name: 'Monday',
        _id:'',
        src: monday
    }, 
    {
        name: 'Trello',
        _id:'',
        src: trello
    }, 
    {
        name: 'Jira',
        _id:'',
        src: jira
    }, 
    {
        name: 'Asana',
        _id:'',
        src: asana
    }, 
    {
        name: 'Wrike',
        _id:'',
        src: monday
    }, 
    {
        name: 'Notion',
        _id:'',
        src: notion
    }, 
    {
        name: 'Excel&CSV',
        _id:'',
        src: excel
    }, 
    {
        name: 'Confluence',
        _id:'',
        src: monday
    }
]

export const Roles: Option[] = [
    {
        label: 'Team Owner',
        value: 'Team Owner',
        description: 'Has unrestricted access to all features, including billing and team deletion.'
    },
    {
        label: 'Administrator',
        value: 'Administrator',
        description: 'Has comprehensive control over all aspects of the system except billing and team deletion.    '
    },
    {
        label: 'Project Manager',
        value: 'Project Manager',
        description: 'Oversees specific projects with full control, including access to team-wide timesheets, planning modules, and the ability to report time for all users.'
    },
    {
        label: 'Scrum Master',
        value: 'Scrum Master',
        description: 'Facilitates Agile processes with permissions to create sprints, access team-wide timesheets, and use planning modules.'
    }
]