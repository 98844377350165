import { TableHeader } from "app/components/Table/types";
import {
  IComponent,
  IMember,
  IProject,
  ITeam,
  MemberStatus,
  ProjectStatus,
  TeamPlanType,
  TeamStatus,
} from "./types";

const DEFAULT_MEMBER: IMember = {
  id: "m1",
  name: "Alice Johnson",
  icon: { initials: "AJ", color: "#FFF", bgColor: "#007BFF" },
  email: "alice.johnson@example.com",
  role: "Project Manager",
  status: MemberStatus.ACTIVE,
  lastLogin: "2025-01-10T14:30:00Z",
};

const getMemberById =
  (memberlist: IMember[]) =>
  (id: IMember["id"]): IMember =>
    memberlist?.find((m) => m.id === id) ?? DEFAULT_MEMBER;

const getMembersByIds = (memberlist: IMember[], ids: string[]): IMember[] =>
  ids.map((id) => getMemberById(memberlist)(id));

const getProjectsByIds = (projectList: IProject[], ids: string[]): IProject[] =>
  ids.map((id) => projectList.find((project) => project.id === id) as IProject);

const headers: TableHeader[] = [
  {
    name: "Project name",
    id: "boardName",
    align: "left",
    width: "200px",
    sortable: true,
  },
  { name: "Status", id: "lastViewed", align: "left" },
  { name: "Progress", id: "lastUpdated", align: "left" },
  { name: "Members", id: "owner", align: "left" },
];

const members: IMember[] = [
  {
    id: "m1",
    name: "Alice Johnson",
    icon: { initials: "AJ", color: "#FFF", bgColor: "#007BFF" },
    email: "alice.johnson@example.com",
    role: "Project Manager",
    status: MemberStatus.ACTIVE,
    lastLogin: "2025-01-10T14:30:00Z",
    hours: "5h 2m",
    date: "25 Sep,2024",
  },
  {
    id: "m2",
    name: "Bob Smith",
    icon: { initials: "BS", color: "#FFF", bgColor: "#28A745" },
    email: "bob.smith@example.com",
    role: "Developer",
    status: MemberStatus.ACTIVE,
    lastLogin: "2025-01-12T08:45:00Z",
    hours: "7h 15m",
    date: "24 Jul,2024",
  },
  {
    id: "m3",
    name: "Charlie Brown",
    icon: { initials: "CB", color: "#FFF", bgColor: "#FFC107" },
    email: "charlie.brown@example.com",
    role: "Tester",
    status: MemberStatus.INVITATION_PENDING,
    lastLogin: "",
    hours: "4h 15m",
    date: "23 Jul,2024",
  },
  {
    id: "m4",
    name: "Diana Prince",
    icon: { initials: "DP", color: "#FFF", bgColor: "#DC3545" },
    email: "diana.prince@example.com",
    role: "UX Designer",
    status: MemberStatus.ACTIVE,
    lastLogin: "2025-01-11T09:15:00Z",
    hours: "8h 15m",
    date: "20 Sep,2024",
  },
  {
    id: "m5",
    name: "Ethan Hunt",
    icon: { initials: "EH", color: "#FFF", bgColor: "#6F42C1" },
    email: "ethan.hunt@example.com",
    role: "Scrum Master",
    status: MemberStatus.ACTIVE,
    lastLogin: "2025-01-12T14:00:00Z",
    hours: "8h 15m",
    date: "20 Sep,2024",
  },
  {
    id: "m6",
    name: "Fiona Gallagher",
    icon: { initials: "FG", color: "#FFF", bgColor: "#17A2B8" },
    email: "fiona.gallagher@example.com",
    role: "Content Writer",
    status: MemberStatus.ACTIVE,
    lastLogin: "2025-01-13T10:20:00Z",
    hours: "8h 15m",
    date: "20 Sep,2024",
  },
  {
    id: "m7",
    name: "George Lucas",
    icon: { initials: "GL", color: "#FFF", bgColor: "#20C997" },
    email: "george.lucas@example.com",
    role: "Marketing Specialist",
    status: MemberStatus.ACTIVE,
    lastLogin: "2025-01-09T12:15:00Z",
  },
  {
    id: "m8",
    name: "Hannah Baker",
    icon: { initials: "HB", color: "#FFF", bgColor: "#6C757D" },
    email: "hannah.baker@example.com",
    role: "Frontend Developer",
    status: MemberStatus.INVITATION_PENDING,
    lastLogin: "",
    hours: "8h 15m",
    date: "20 Sep,2024",
  },
  {
    id: "m9",
    name: "Ian Wright",
    icon: { initials: "IW", color: "#FFF", bgColor: "#343A40" },
    email: "ian.wright@example.com",
    role: "Backend Developer",
    status: MemberStatus.ACTIVE,
    lastLogin: "2025-01-14T07:50:00Z",
    hours: "8h 15m",
    date: "20 Sep,2024",
  },
  {
    id: "m10",
    name: "Jessica Pearson",
    icon: { initials: "JP", color: "#FFF", bgColor: "#FF5733" },
    email: "jessica.pearson@example.com",
    role: "HR Manager",
    status: MemberStatus.ACTIVE,
    lastLogin: "2025-01-08T15:40:00Z",
    hours: "8h 15m",
    date: "20 Sep,2024",
  },
  {
    id: "m11",
    name: "Kevin Malone",
    icon: { initials: "KM", color: "#FFF", bgColor: "#C70039" },
    email: "kevin.malone@example.com",
    role: "Finance Specialist",
    status: MemberStatus.ACTIVE,
    lastLogin: "2025-01-10T09:25:00Z",
  },
  {
    id: "m12",
    name: "Laura Wilson",
    icon: { initials: "LW", color: "#FFF", bgColor: "#900C3F" },
    email: "laura.wilson@example.com",
    role: "QA Engineer",
    status: MemberStatus.INVITATION_PENDING,
    lastLogin: "",
    hours: "8h 15m",
    date: "20 Sep,2024",
  },
];

const getMember = getMemberById(members);

const components: IComponent[] = [
  {
    id: "c1",
    name: "Web app, login process",
    lead: getMember("m1"),
    defaultAssignee: getMember("m2"),
    description: `The Login Process component handles user authentication by allowing registered users to securely access their accounts.
It includes the following key elements:
1. **Login Form**:
   - **Input Fields**:
     - Email/Username
     - Password (masked)
   - **Form Validation**: Ensures fields are not left empty and follow the correct format (e.g., valid email structure).
   - **Submit Button**: Triggers the authentication process.

2. **Authentication Logic**:
   - **Credential Validation**: User input is sent to the server via a POST request, checked against stored credentials in a secure database.
   - **Error Handling**: If credentials are incorrect, an error message is displayed (e.g., "Invalid email or password").
   - **Success Handling**: Upon successful authentication, the user is redirected to the dashboard or their desired page.

3. **Security Features**:
   - **Password Encryption**: Passwords are encrypted during transmission using SSL.
   - **Rate Limiting**: Limits the number of failed login attempts to prevent brute-force attacks.
   - **CAPTCHA**: Optionally integrated after multiple failed attempts to ensure human verification.

4. **Additional Features**:
   - **Remember Me Option**: Allows users to stay logged in across sessions using secure cookies.
   - **Forgot Password Link**: Provides access to a password recovery process for users who forget their credentials.

This description outlines the functionality, security, and user interaction of the login process for a web app.`,
    isArchived: false,
    creationDate: new Date("2025-01-02T08:00:00Z"),
  },
  {
    id: "c2",
    name: "Web app, onboarding & sign-up process",
    lead: getMember("m3"),
    defaultAssignee: getMember("m4"),
    description: `The Onboarding & Sign-up process component helps new users create accounts and get familiar with the application.
It includes the following key elements:
1. Sign-up Form
2. Guided Onboarding Flow
3. Integration with Authentication Logic`,
    isArchived: false,
    creationDate: new Date("2025-01-06T08:00:00Z"),
  },
];

const projects: IProject[] = [
  {
    id: "p1",
    name: "Project Alpha",
    icon: { initials: "PA", color: "#FFF", bgColor: "#6C757D" },
    owner: getMember("m2"),
    projectLead: getMember("m2"),
    defaultAssignee: getMember("m3"),
    status: ProjectStatus.ACTIVE,
    isHidden: false,
    isArchived: false,
    progress: { current: 5, total: 10 },
    members: getMembersByIds(members, ["m1", "m2", "m3"]),
    boards: [{ name: "Tickets" }, { name: "Sprints" }, { name: "Releases" }],
    features: ["F1", "F2", "F4"],
    components: components,
    description: "Initial phase of Project Alpha.",
    creationDate: new Date("2025-01-01T10:00:00Z"),
    startDate: new Date("2025-01-02T08:00:00Z"),
    // endDate: new Date("2025-01-31T18:00:00Z"),
  },
  {
    id: "p2",
    name: "Project Beta",
    icon: { initials: "PB", color: "#FFF", bgColor: "#17A2B8" },
    owner: getMember("m2"),
    projectLead: getMember("m3"),
    defaultAssignee: getMember("m1"),
    status: ProjectStatus.ACTIVE,
    isHidden: false,
    isArchived: false,
    progress: { current: 0, total: 5 },
    members: getMembersByIds(members, ["m1", "m2"]),
    boards: [{ name: "Tickets" }, { name: "Sprints" }, { name: "Releases" }],
    features: [],
    components: [],
    description: "Preparation stage for Project Beta.",
    creationDate: new Date("2025-01-05T12:00:00Z"),
    startDate: undefined,
    endDate: undefined,
  },
];

const TEAM_DATA: ITeam[] = [
  {
    id: "t1",
    name: "Development Team A",
    icon: { initials: "DT", color: "#FFF", bgColor: "#343A40" },
    owner: getMember("m1"),
    plan: TeamPlanType.BUSINESS,
    statuses: [
      TeamStatus.TODO,
      TeamStatus.IN_PROGRESS,
      TeamStatus.DONE,
      TeamStatus.IN_CODE_REVIEW,
    ],
    members: getMembersByIds(members, ["m1", "m2", "m3", "m4", "m5", "m6"]),
    projects: getProjectsByIds(projects, ["p1", "p2"]),
    activeProjectId: "p1",
    creationDate: new Date("2024-12-01T09:00:00Z"),
    storageUsage: { current: 5000, total: 10000 },
  },
];


const ticketTypesFields = [
  {
    fieldName: "Team name",
    fieldToolTip: "Team name",
    isMandatory: false,
    fieldType: "Header fields",
    required: true,
    order: 1,
  },
  {
    fieldName: "Team name",
    fieldToolTip: "Team name",
    isMandatory: false,
    fieldType: "Header fields",
    required: true,
    order: 2,
  },
  {
    fieldName: "Project name",
    fieldToolTip: "Project name",
    isMandatory: false,
    fieldType: "Header fields",
    required: true,
    order: 3,
  },
  {
    fieldName: "Component name",
    fieldToolTip: "Component name",
    isMandatory: false,
    fieldType: "Header fields",
    required: true,
    order: 4,
  },
  {
    fieldName: "Release name",
    fieldToolTip: "Release name",
    isMandatory: false,
    fieldType: "Header fields",
    required: true,
    order: 5,
  },
  {
    fieldName: "Sprint name",
    fieldToolTip: "Sprint name",
    isMandatory: false,
    fieldType: "Header fields",
    required: true,
    order: 6,
  },
  {
    fieldName: "Epic name",
    fieldToolTip: "Epic name",
    isMandatory: false,
    fieldType: "Header fields",
    required: true,
    order: 7,
  },
  {
    fieldName: "Ticket ID",
    fieldToolTip: "Ticket ID",
    isMandatory: false,
    fieldType: "Context fields",
    required: true,
    order: 1,
  },
  {
    fieldName: "Ticket name",
    fieldToolTip: "Ticket name",
    isMandatory: true,
    fieldType: "Context fields",
    required: true,
    order: 2,
  },
  {
    fieldName: "Ticket type",
    fieldToolTip: "Ticket type",
    isMandatory: true,
    fieldType: "Context fields",
    required: true,
    order: 3,
  },
  {
    fieldName: "Status",
    fieldToolTip: "Status",
    isMandatory: true,
    fieldType: "Context fields",
    required: true,
    order: 4,
  },
  {
    fieldName: "Assignee",
    fieldToolTip: "Assignee",
    isMandatory: false,
    fieldType: "Context fields",
    required: true,
    order: 5,
  },
  {
    fieldName: "Due date",
    fieldToolTip: "Due date",
    isMandatory: false,
    fieldType: "Context fields",
    required: true,
    order: 6,
  },
  {
    fieldName: "Priority",
    fieldToolTip: "Priority",
    isMandatory: false,
    fieldType: "Context fields",
    required: true,
    order: 7,
  },
  {
    fieldName: "Efforts Estimated",
    fieldToolTip: "Efforts Estimated",
    isMandatory: false,
    fieldType: "Context fields",
    required: true,
    order: 8,
  },
  {
    fieldName: "Efforts Actual",
    fieldToolTip: "Efforts Actual",
    isMandatory: false,
    fieldType: "Context fields",
    required: true,
    order: 9,
  },
  {
    fieldName: "Tags",
    fieldToolTip: "Tags",
    isMandatory: true,
    fieldType: "Context fields",
    required: true,
    order: 10,
  },
  {
    fieldName: "Ticket description",
    fieldToolTip: "Ticket description",
    isMandatory: false,
    fieldType: "Context fields",
    required: true,
    order: 11,
  },
  {
    fieldName: "Action items",
    fieldToolTip: "Action items",
    isMandatory: false,
    fieldType: "Action items",
    required: true,
    order: 1,
  },
  {
    fieldName: "Figma assets",
    fieldToolTip: "Figma assets",
    isMandatory: false,
    fieldType: "Figma assets",
    required: true,
    order: 1,
  },
];

const ticketTypes = [
  {
    name: "Task",
    description: "Tasks track small, distinct pieces of work",
    icon: "",
  },
  {
    name: "Bug",
    description: "Bug tract an identified issue or problem",
    icon: "",
  },
  {
    name: "Story",
    description:
      "Story enabling teams to capture and organize requirements from the perspective of end-users",
    icon: "",
  },
  {
    name: "Epic",
    description: "Epic is a large, high-level user story",
    icon: "",
  },
];

export {
  headers,
  members,
  projects,
  TEAM_DATA,
  ticketTypes,
  ticketTypesFields,
};
