import { CreatorIcon } from "app/components/Icons";
import CustomTooltip from "app/components/Tooltip";
import Avatar from "app/containers/Features/components/Avatar";
import React from "react";

interface CustomProps {
  assignee: {
    name: string;
    color: string;
    status?: "online" | "offline";
    online: boolean;
  };
}

const Creator: React.FC<CustomProps> = ({ assignee }) => {
  return (
    <>
      <button className="flex items-center justify-between w-full px-2 py-2 rounded-md hover:bg-[#F4F5F7] rounded-[4px] data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A] ">
        <CustomTooltip text="Creator" className="flex items-center ">
          <div className="flex items-center gap-2 w-full">
            <CreatorIcon size={16} />
            <Avatar
              color={assignee?.color}
              name={assignee?.name}
              circleClassName="text-[6.4px] leading-[19.2px] font-normal size-[12.8px]"
              statusClassName="size-[3.2px]"
              nameClassName="text-xs leading-[18px] font-normal text-secondary"
            />
          </div>
        </CustomTooltip>
      </button>
    </>
  );
};

export default Creator;
