import React from "react";
import { IAttachment, Ticket } from "../../../../types";
import Attachments from "./Attachments";
import CheckList from "./CheckList";
import Description from "./Description";
import Relations from "./Relations";

interface TicketActionsProps {
  ticket: Ticket;
  status:string
}

const Index: React.FC<TicketActionsProps> = ({ ticket ,status}) => {
  return (
    <div className="flex flex-row items-center justify-start gap-[4px]">
      <Description description={ticket?.name} />
      <Relations
        membersCount={ticket?.membersCount}
        relation={ticket.relation}
      />
      <Attachments
        count={ticket?.attachmentsCount}
        attachments={ticket.attachment as IAttachment[]}
      />
      <CheckList data={ticket} status={status}/>
    </div>
  );
};

export default Index;
