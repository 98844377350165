import { CheckIcon, CrossIcon } from "app/components/Icons";
import { SettingIcon } from "app/components/Icons/Setting";
import Input from "app/components/Input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "app/components/Popover";
import CustomTooltip from "app/components/Tooltip";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TicketGroupStatus } from "../../../types";
import { actions } from "../../Tickets/redux/slice";
import toastService from "utils/toast";

interface TicketLimitProps {
  count: number;
  maxCount: number;
  groupStatus: TicketGroupStatus;
}

const TicketLimit: React.FC<TicketLimitProps> = ({
  count,
  maxCount,
  groupStatus,
}) => {
  const [value, setValue] = useState<string>(String(maxCount));
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleUpdate = (group, val) => {
    dispatch(
      actions.toggleGroupsUpdateTicketLimit({
        group: group,
        value: Number(val),
      })
    );
    toastService.success("Ticket limit has been successfully set for Opened group.")
  };
useEffect(() => {
  if (maxCount) {
    setValue(String(maxCount));
  }

  return () => {};
}, [maxCount]);

  return (
    <React.Fragment>
      <CustomTooltip
        text={
          <div className="flex w-full px-3 py-2  ">
            <div className="flex flex-col gap-1 w-full">
              <div>
                <div className="text-sm font-normal flex flex-row justify-between items-center w-full text-white">
                  <span>Tickets amount:</span>
                </div>
              </div>
              <div className="flex  items-center">
                <SettingIcon color="#5A8DF1" size={20} />
                <span className="text-sm font-normal text-primary">
                  Set tickets’ limit
                </span>
              </div>
            </div>
            <div>
              <div className="text-sm font-normal flex flex-row justify-between items-center w-full text-white">
                <span>
                  {count}/{maxCount}
                </span>
              </div>
            </div>
          </div>
        }
        contentClassName="min-w-[200px] "
      >
        <button
          onClick={() => setOpen(true)}
          className="flex items-center justify-center size-[20px] border border-[#E9ECF2] rounded-full text-sm leading-[12px] text-secondary font-normal"
        >
          {count}
        </button>
      </CustomTooltip>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <div />
        </PopoverTrigger>
        <PopoverContent
          align="start"
          alignOffset={30}
          className={`!p-0 bg-white border border-[#E9ECF2] cursor-pointer`}
        >
          <div className="py-2">
            <div className="border-b border-[#E9ECF2] py-3 px-4">
              <span className="text-sm font-semibold uppercase text-[#79808A]">
                Ticket Limit
              </span>
            </div>
            <div className="py-3 px-4 flex flex-col gap-3">
              <div>
                <span className="text-xs text-normal text-[#79808A]">
                  Tickets amount will be highlighted if it exceeds the specified
                  limit
                </span>
              </div>
              <div>
                <Input
                  id="value"
                  label="Tickets limit"
                  name="value"
                  value={value}
                  type="number"
                  onChange={(e) => setValue(e.target.value)}
                  placeholder="No limit"
                  autoComplete="off"
                  autoFocus={false}
                  endIcon={
                    value?.length > 0 && (
                      <div className="flex justify-end items-center gap-3">
                        <button
                          onClick={() => {
                            handleUpdate(groupStatus, value);
                            setValue("");
                            setOpen(false);
                          }}
                        >
                          <CheckIcon color="#5A8DF1" />
                        </button>
                        <button
                          onClick={() => {
                            setValue("");
                            setOpen(false);
                          }}
                        >
                          <CrossIcon color="#79808A" />
                        </button>
                      </div>
                    )
                  }
                />
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </React.Fragment>
  );
};

export default TicketLimit;
