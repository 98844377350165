import React from "react";
import { TicketType } from "../../../Boards/types";
import { getTicketIcon } from "../../../Boards/containers/Home/containers/helpers";
import { ITicketTypes } from "./types";
import IconText from "app/components/IconText";

interface TicketTypesProps {
  setSelected: (index: number) => void;
  selected: number;
  index: number;
  item: ITicketTypes;
}

const TicketTypes: React.FC<TicketTypesProps> = ({
    setSelected,
    selected,
    index,
    item,
}) => {
  return (
    <div
      className={`w-full py-2 text-center cursor-pointer ${
        selected == index ? " bg-[#5DA2FF1A]" : ""
      }`}
      onClick={() => setSelected(index)}
      key={index}
    >
      <div className={` flex flex-row w-full`}>
        {selected == index && (
          <div className="w-[4px] border-r-4 border-primary rounded-br-full rounded-tr-full"></div>
        )}
        <IconText
          className={`py-2 text-center justify-center flex ${
            index == selected ? " ps-9 " : " ps-10"
          }`}
          icon={
            <img
              src={getTicketIcon(item.name.toLowerCase() as TicketType)}
              alt="task-icon"
              className="size-[20px]"
            />
          }
          text={item.name}
          textClassName={`${
            index == selected ? "font-medium " : "font-normal"
          } text-sm`}
        />
      </div>
    </div>
  );
};

export default TicketTypes;
