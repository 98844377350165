import { authorizedAccounts } from "app/containers/Auth/data";
import { AppHomeState, PLAN_TYPE, userOnboardingStage } from "app/types";


export const initialState: AppHomeState = {
  isAuthenticated: localStorage.getItem("accessToken") ? true : false,
  authorizedAccounts: authorizedAccounts,
  user: null,
  loading: false,
  error: null,
  drawerOpen: false,
  teamFormActiveIndex: 1,
  project: null,
  teamForm: {
    name: {
      value: "",
      error: "",
    },
    project: {
      name: {
        value: "",
        error: "",
      },
      defaultCustomization: {
        agileDevelopmentAndSprints: false,
        epicsAndStories: false,
        releaseManagement: false,
      },
    },
    members: [
      {
        name: {
          value: "",
          error: "",
        },
        role: {
          value: "",
          error: "",
        },
      },
    ],
    plan: PLAN_TYPE.BUSINESS,
    coupon: {
      value: "",
      error: "",
    },
    isLoading: false,
  },
  userOnboardingProgress: null,
  access_token: localStorage.getItem("accessToken"),
  userFormData: {
    _id: "",
    fullName: "",
    email: "",
    role: "",
    lastLoginDate: "",
    userVerifications: [],
    profilePic: "",
    googleId: "",
    userOnboardingProgress: userOnboardingStage.CODE_VERIFICATION,
    timeZone: "",
    questions: ""
  },
  userDetails: {
    _id: "",
    fullName: "",
    email: "",
    isActive: false,
    lastLoginDate: "",
    profilePic: "",
    userOnboardingProgress: userOnboardingStage.CODE_VERIFICATION,
    timeZone: "",
    teams: [],
    teamIds: []
  },
  selectedTeam: null,
  selectedProject: null,
  isBoardCreate:false
};
