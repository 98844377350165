import FlagIcon from "app/components/Icons/FlagIcon";
import CustomTooltip from "app/components/Tooltip";
import React, { FC, useState } from "react";
import checkMark from "assets/svgs/checkmark.svg";
import { TicketPriorities } from "../types";
import Dropdown from "app/components/Dropdown";

export const PriorityButton: FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<TicketPriorities | null>(null);  

    const TicketPriorityList = [
        { label: "Normal", type: TicketPriorities.NORMAL, icon: <FlagIcon color="#2AD95B" /> },
        { label: "High", type: TicketPriorities.HIGH, icon: <FlagIcon color="#FF8A00" /> },
        { label: "Urgent", type: TicketPriorities.URGENT, icon: <FlagIcon color="#FF4F4F" /> },
        { label: "Low", type: TicketPriorities.LOW, icon: <FlagIcon color="#20C6EB" /> },
    ];

    const handleSelect = (selected) => {
        setSelectedType(selected);
        setOpen(false);
    };

    const selectedOption = TicketPriorityList.find(option => option.type === selectedType);    

    const menuItems = TicketPriorityList.map((option) => ({
        label: (
            <div className="cursor-pointer flex flex-row  items-center pt-1 gap-[8px] py-3 text-sm text-secondary">
                {option.icon}
                <span>{option.label}</span>

                {selectedType === option.type && (
                    <img src={checkMark} alt="selected" className="size-[20px] ml-10" />
                )}
            </div>
        ),
        action: () => handleSelect(option.type),
    }));

    return (
        <Dropdown
            trigger={
                <button className="flex flex-row items-center justify-center h-[28px] min-w-[28px] rounded-[4px] border border-[#E9ECF2] hover:bg-[#ECEEF2] hover:text-[#79808A] hover:border-[#E9ECF2] transition-all duration-200">
                        <CustomTooltip text="Set priority" position="bottom">
                        <div className="flex items-center gap-1 min-w-[16px]">
                        {selectedOption ? selectedOption.icon : <FlagIcon />}
                        {selectedOption && <span className="text-sm p-1">{selectedOption.label}</span>}
                        </div>
                        </CustomTooltip>
                    </button>
            }
            menuItems={menuItems}
            align="start"
            className="max-w-[180px] py-2"
            projectInfoIcon={
                <div className="flex items-center gap-2 pl-4 pr-2 py-2 text-secondary text-sm font-semibold uppercase border-b border-[#E9ECF2]">
                    PRIORITIES
                </div>
            }
        />
    );
};
