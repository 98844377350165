import React from "react";

interface MemberIconProps {
  iconColor?: string; // Color of the text inside the icon
  iconBgColor?: string; // Background color of the circular icon
  iconText: string; // Text inside the icon (e.g., initials)
  statusColor?: string; // Status indicator color (e.g., green for online)
  iconClassName?: string;
  statusClassName?: string;
  iconTextClassName?: string;
}

const MemberIcon: React.FC<MemberIconProps> = ({
  iconColor = "white",
  iconBgColor = "#6A60F7",
  iconText,
  statusColor,
  iconClassName,
  statusClassName,
  iconTextClassName
}) => {
  return (
    <div className="relative">
      <div
        className={`flex items-center justify-center w-8 h-8 rounded-full ${iconClassName}`}
        style={{ backgroundColor: iconBgColor }}
      >
        <span className={`${iconTextClassName} text-sm font-medium `} style={{ color: iconColor }}>
          {iconText}
        </span>
      </div>
      {/* Status Indicator (optional) */}
      {statusColor && (
        <div
          className={`${statusClassName} absolute bottom-0 right-0 w-3 h-3 border-2 border-white rounded-full`}
          style={{ backgroundColor: statusColor }}
        />
      )}
    </div>
  );
};

export default MemberIcon;
