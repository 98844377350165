import CustomTooltip from "app/components/Tooltip";
import React from "react";

interface CollapseCardTileProps {
  tooltip: string;
  length: number;
  color?: string;
  name: string;
  handleClick: () => void;
  icon: React.ReactNode;
}

const CollapseCardTile: React.FC<CollapseCardTileProps> = ({
  tooltip,
  length,
  color = "#000",
  name,
  handleClick,
  icon,
}) => {
  return (
    <div onClick={() => handleClick()}>
      <CustomTooltip className="h-full w-full" position="bottom" text={tooltip}>
        <div className="w-8 h-8 flex items-center justify-center text-gray-400">
          {icon}
        </div>

        {/* Middle count */}
        <div className="flex items-center justify-center">
          <div
            className="text-center"
            style={{
              writingMode: "vertical-rl",
              transform: "rotate(180deg)",
            }}
          >
            <span className=" text-sm font-medium" style={{ color: color }}>
              {name}
            </span>
          </div>
        </div>

        {/* Bottom text */}
        <div className="my-2  flex items-center justify-center">
          <span className="border border-[#E9ECF2] rounded-full h-8 w-8  text-sm flex justify-center items-center font-normal">
            {length}
          </span>
        </div>
      </CustomTooltip>
    </div>
  );
};

export default CollapseCardTile;
