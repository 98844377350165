import {
  draggable
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSelectedTickets } from "../../../redux/selector";
import { actions } from "../../../redux/slice";
import { Ticket } from "../../../types";
import TicketCard from "./Ticket";

interface DraggableColumnProps {
  ticket: Ticket;
  status: string;
}

const DraggableColumn: React.FC<DraggableColumnProps> = ({
  ticket,
  status,
}) => {
  const ticketRef = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const selectedTickets = useSelector(selectSelectedTickets);

  const handleToggleSelection = (ticketId: string) => {
    dispatch(actions.toggleSelectTicket(ticketId));
  };
  React.useEffect(() => {
    if (!ticketRef.current) return;

    return draggable({
      element: ticketRef.current,
      getInitialData: () => ({
        ticketId: ticket.id,
        status: status,
      }),
    });
  }, [ticket.id, status]);
  return (
    <div ref={ticketRef}>
      <TicketCard
        ticket={ticket}
        isSelected={selectedTickets.includes(ticket.id)}
        onCheckboxClick={handleToggleSelection}
        status={status}
      />
    </div>
  );
};

export default DraggableColumn;
