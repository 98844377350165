import React from 'react'
import { selectFavorites, selectQaBoards, selectRecents,selectIsLoading } from '../../../redux/selector'
import { useSelector } from 'react-redux'
import boardIcon from 'assets/svgs/ic_boards_ico.svg'
import IconText from 'app/components/IconText'
import CopyButton from '../../../components/CopyButton'
import { BoardType } from '../../../types'
import ListIcon from 'app/components/Icons/ListIcon'
import KabanIcon from 'app/components/Icons/KabanIcon'
import Spinner from 'app/components/Icons/Spinner'
import { useParams } from 'react-router-dom'
import { handeCopyBoardLink } from '../../../helpers'
interface Board {
    _id: string;
    name: string;
    boards: Board[];
    title: string;
    viewType: BoardType;
}
export default function Cards() {
    const params = useParams();
    const recents = useSelector(selectRecents)
    const favorites = useSelector(selectFavorites)
    const qaBoards = useSelector(selectQaBoards)
    const loading = useSelector(selectIsLoading)
    const items = [
        {
            title: "Recent boards",
            boards: recents as Board[]
        },
        {
            title: "Favorite boards",
            boards: favorites as Board[]
        },
        {
            title: "QA boards",
            boards: qaBoards as Board[]
        }
    ]
 const getBoardIcon = (type: BoardType) => {
   return type === BoardType.LIST ? <ListIcon color="#79808A" /> : <KabanIcon color="#79808A" />;
 };   
    return (
        <div className="grid grid-cols-3 gap-4 mb-4">
            {
                items?.length > 0 && items?.map((item) => (
                    <div key={item?.title} className="flex flex-col py-6 bg-white rounded-[4px] border-[1px] border-[#E9ECF2]">
                        <div className="px-6">
                            <h5 className="font-medium text-black leading-[24px]">{item?.title}</h5>
                        </div>
                        {
                            loading ? (
                                <div className="w-full flex flex-col items-center justify-center h-[160px]">
                                    <Spinner size="small" />
                                </div>
                            ) : item?.boards?.length > 0 ? (
                                <div className="w-full flex flex-col h-[160px] mt-[12px] overflow-y-auto px-[10px]">
                                    {item?.boards.map((board) => (
                                        <div key={board?._id} className="group flex items-center justify-between flex-row gap-[4px] py-2 hover:bg-[#F4F5F7] px-[14px] rounded-[4px]">
                                            <IconText 
                                                text={board?.name}
                                                icon={getBoardIcon(board?.viewType)}
                                                className="text-secondary leading-[21px] text-sm"
                                            />
                                            <CopyButton
                                                copyText={board?.name}
                                                onClick={() => handeCopyBoardLink(params?.project,params?.team,board?.name)}
                                                className="opacity-0 group-hover:opacity-100"
                                            />
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="w-full flex flex-col items-center justify-center h-[160px]">
                                    <p className="text-secondary leading-[21px] text-center text-sm">There are no any boards</p>
                                </div>
                            )
                        }
                    </div>
                ))
            }
        </div>
    )
}
