import { FormElement } from "app/types";

export enum PLANS {
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export interface PLAN {
  title: PLAN_TYPE;
  caption: string;
  price: number;
  features: string[];
}

export enum PLAN_TYPE {
  FREE = "free",
  BUSINESS = "business",
  PROFESSIONAL = "professional",
}

export interface OnBoardingState {
  profileForm: ProfileForm;
  setupForm: SetupForm;
  timeZones: TimeZones[];
  timeZonePagination: {
    search?: string;
    page: number;
    perPage: number;
    total: number;
    totalPage: number;
  };
  isloading: boolean;
  onboardingList: {
    companySizes: [];
    referalSources: [];
    toolPreferences: [];
  };
}

export type ProfileForm = {
  name: FormElement;
  password: FormElement;
  confirmPassword: FormElement;
  timezone: FormElement;
  isLoading: boolean;
};

export type SetupForm = {
  companySize: FormElement;
  referalSource: FormElement;
  toolPreferences: FormElement;
  isLoading: boolean;
};

export type TeamForm = {
  name: FormElement;
  project: {
    name: FormElement;
    defaultCustomization: {
      agileDevelopmentAndSprints: boolean;
      epicsAndStories: boolean;
      releaseManagement: boolean;
    };
  };
  members: TeamMember[];
  plan: PLAN_TYPE;
  coupon: FormElement;
  isLoading: boolean;
};

export type TeamMember = {
  name: FormElement;
  role: FormElement;
};

export type ContainerState = OnBoardingState;

export type TimeZones = {
  _id: string
  createdAt:  string
  updatedAt:  string
  text:  string
  offset:number
  abbr:  string
  utc: string[];
  value:  string
};
