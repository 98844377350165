import CommonFileUpload from "app/components/CommonFileUpload";
import Modal from "app/components/Modal";
import React from "react";

interface ProjectIconUploadModalProps {
  open: boolean;
  onClose: () => void;
}

const FileUpload: React.FC<ProjectIconUploadModalProps> = ({
  open,
  onClose,
}) => {
  const handleDeleteImage = () => {};

  if (!open) return null;
  const handleFileUpload = (img) => {
    console.log(img,"handleFileUpload");
    
  
}
  return (
    <React.Fragment>
      <Modal
        onClose={onClose}
        submitText="Upload"
        title="Choose ticket type sign"
        modalClassName="flex flex-col items-center p-0 gap-6 absolute w-[200px] h-[182px] left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border border-[#E9ECF2] rounded-lg"
      >
        <CommonFileUpload onDelete={handleDeleteImage} onClose={onClose} onUpload={handleFileUpload} onCancel={function (): void {
          throw new Error("Function not implemented.");
        } } />
      </Modal>
    </React.Fragment>
  );
};

export default FileUpload;
