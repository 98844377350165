import { StatusIcon } from "app/components/Icons";
import CustomTooltip from "app/components/Tooltip";
import CommonStatusSelection from "app/containers/Features/components/CommonStatusSelection";
import { StatusFilterEnum } from "app/containers/Features/containers/Ticket/types";
import React from "react";
import { StatusTypeOptions } from "../../../../data";
import Pill from "app/components/Pill";

interface StatusSelectionProps {
  value: StatusFilterEnum;
}

const Status: React.FC<StatusSelectionProps> = ({ value }) => {
  const selectedOptions =
    StatusTypeOptions.find((p) => p.value === value) || StatusTypeOptions[0];

  return (
    <React.Fragment>
      <CommonStatusSelection
        trigger={
          <button className="flex items-center justify-between w-full ">
            <CustomTooltip
              text="Set status"
              className="flex items-center "
              position="left"
            >
              <Pill
                backgroundColor={selectedOptions?.color}
                className="px-[16px] py-[8px] w-[109px] text-sm leading-[21px] font-normal"
                textColor="#FFFFFF"
                name={selectedOptions.label}
              />
            </CustomTooltip>
          </button>
        }
        isHeader="statuses"
        value={value}
        align="start"
      />
    </React.Fragment>
  );
};

export default Status;
