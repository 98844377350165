import ReleaseIcon from "app/components/Icons/ReleaseIcon";
import CustomTooltip from "app/components/Tooltip";
import CommonTargetRelease from "app/containers/Features/components/CommonRelease";
import React from "react";
import { releasesData } from "../../../../components/CreateBoardsComponent/data";

interface CustomProps {
  value: string;
}
const TargetRelease: React.FC<CustomProps> = ({ value }) => {
  //
  const selectedOptions =
    releasesData.find((p) => p.id === value) || releasesData[0];

  return (
    <>
      <CommonTargetRelease
        trigger={
          <button className="flex items-center justify-between w-full px-2 py-2 rounded-md hover:bg-[#F4F5F7] rounded-[4px] data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A] ">
            <CustomTooltip
              text="Set release"
              className="flex items-center "
              position="left"
            >
              <div className="flex items-center gap-2 w-full">
                <ReleaseIcon size={16} />
                <span className="text-sm font-normal text-black">
                  {selectedOptions.label}
                </span>
              </div>
            </CustomTooltip>
          </button>
        }
        isHeader="releases"
        data={releasesData}
      />
    </>
  );
};

export default TargetRelease;
