import IconText from "app/components/IconText";
import workflowIcon from "assets/svgs/ic_tree.svg";
import { useQueryState } from "nuqs";
import React, { useState } from "react";
import { IRelation } from "../../../../types";
import { getRelationIcon, getTicketIcon } from "../../containers/helpers";
import CustomPopover from "./CustomPopover";

interface RelationsProps {
  membersCount: number;
  relation?: IRelation[];
}

const Relations: React.FC<RelationsProps> = ({ membersCount, relation }) => {
  const [, setTid] = useQueryState("tid");
  const [open, setOpen] = useState(false);
  const handleOpenTicket = (id) => {
    setOpen(false);
    setTid(id);
  };
  return (
    <React.Fragment>
      <CustomPopover
        trigger={
          <div
            className="flex flex-row items-center hover:bg-[#ECEEF2] hover:px-1 hover:py-[1px] rounded"
            onMouseEnter={() => {
              setOpen(true);
            }}
            onMouseLeave={() => {
              setOpen(false);
            }}
          >
            <div className="min-w-[16px]">
              <img src={workflowIcon} alt="task-icon" className="size-[14px]" />
            </div>
            <div className="text-xs leading-[18px] text-secondary font-normal mt-[2px] ml-[2px]">
              {membersCount}
            </div>
          </div>
        }
        show={open}
        onClose={() => setOpen(!open)}
        content={
          <div className="flex flex-col gap-4">
            <div className="sticky top-0 bg-white pt-4">
              <p className="text-md font-medium text-[#253858]">Relations</p>
            </div>
            {relation?.map((ticket) => {
              return (
                <div
                  key={ticket.categoryIcon}
                  className={`flex flex-col gap-1 border border-l-4 p-2 rounded ${ticket.borderColor}`}
                >
                  <IconText
                    icon={getRelationIcon(ticket?.categoryIcon)}
                    text={ticket.category + ":"}
                    textClassName="text-[#253858] font-medium"
                    className="mb-2"
                  />
                  {ticket.relationList.map((ele) => {
                    return (
                      <div
                        onClick={() => {
                          handleOpenTicket(ele.id);
                        }}
                        className={`flex items-center gap-1 overflow-hidden whitespace-nowrap text-ellipsis hover:bg-gray-100  cursor-pointer group`}
                      >
                        <span
                          className={`flex items-center overflow-hidden whitespace-nowrap text-ellipsis`}
                        >
                          <img
                            src={getTicketIcon(ele?.type)}
                            alt={ele.name}
                            className="size-[16px]"
                          />
                          <span
                            className={`ml-1 overflow-hidden whitespace-nowrap text-ellipsis text-sm text-[#79808A] group-hover:underline`}
                          >
                            {ele?.id + ": " + ele?.name}
                          </span>
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        }
        className="max-h-[300px] !pt-0"
      />
    </React.Fragment>
  );
};

export default Relations;
