// // src/app/containers/Features/containers/User/redux/saga.ts
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { userDetailsData, memberData, FavList } from "../data";
import { IBoardFixedTemp, User } from "../types";
import { selectUser } from "./selector";
import { PROJECTS } from "app/containers/Features/containers/Boards/data";
import { API_URL, TICKET_API_URL } from "utils/constants";
import { request } from "utils/request";
import toastService from "utils/toast";

// user my profile request
export function* doGetMyProfileRequest() {
  try {
    yield delay(500);
    const requestURL = `${API_URL}auth/me`;
    const options = {
      method: "GET",
    };
    const { data, err } = yield call(request, requestURL, options);
    if (err || !data) {
      toastService.error(err.mes);
    }
    yield put(actions.setUserFormData(data?.data));
  } catch (err: any) {
    toastService.error(err.mes);
  }
}
export function* doGetUserWorkspaceRequest() {
  yield delay(500);
  try {
    const requestURL = `${API_URL}auth/workspace`;
    const options = {
      method: "GET",
    };
    const { data, err } = yield call(request, requestURL, options);
    if (err || !data) {
      toastService.error(err.mes);
    }

    yield put(actions.setUserWorkspaceData(data?.data));
  } catch (err: any) {
    toastService.error(err.mes);
  }
}

// board create

export function* doCreateFixTempBoardRequest(action: {
  payload: { data: IBoardFixedTemp; callback: () => void };
}) {
  yield delay(500);
  try {
    yield put(actions.setIsBoardCreate(true));
    const requestURL = `${TICKET_API_URL}board/template/create`;
    const options = {
      method: "POST",
      body: JSON.stringify(action.payload.data),
    };
    const { data, err } = yield call(request, requestURL, options);
    yield put(actions.setIsBoardCreate(false));
    if (err || !data) {
      toastService.error(err.message);
    }
    toastService.error(data.message);

    yield call(yield call(action.payload.callback));
    // yield put(actions.setUserWorkspaceData(data?.data));
  } catch (err: any) {
    yield put(actions.setIsBoardCreate(false));
    console.log(err.message);
  }
}

// Selector to get the current index
const selectTeamFormActiveIndex = (state: any) =>
  state.AppState.teamFormActiveIndex;

function* handleNextStep() {
  // Retrieve the current index from the state
  const prevIndex: number = yield select(selectTeamFormActiveIndex);

  // Calculate the next index
  const nextIndex = Math.min(prevIndex + 1, 4);

  // Dispatch the action with the new index
  yield put(actions.setTeamFormActiveIndex(nextIndex));
}

function* handlePreviousStep() {
  // Retrieve the current index from the state
  const prevIndex: number = yield select(selectTeamFormActiveIndex);

  // Calculate the previous index
  const prevStepIndex = Math.max(prevIndex - 1, 1);

  // Dispatch the action with the new index
  yield put(actions.setTeamFormActiveIndex(prevStepIndex));
}

function* fetchUserDetailsSaga() {
  try {
    // Simulate API call with static data
    const currentUserState = yield select(selectUser);
    const data: User = {
      ...currentUserState,
      name: userDetailsData.name,
      initials: userDetailsData.initials,
      email: userDetailsData.email,
      role: userDetailsData.role,
      status: userDetailsData.status,
      lastLogin: userDetailsData.lastLogin,
      localTime: userDetailsData.localTime,
      location: memberData[0].location,
      spokenLanguages: memberData[0].spokenLanguages.split(", "),
      creationDate: memberData[0].creationDate,
      mobileNumber: memberData[0].mobileNumber,
      members: memberData,
      project: PROJECTS,
    };

    yield put(actions.setUser(data));
  } catch (error) {
    yield put({ type: "FETCH_USER_DETAILS_FAILURE", error });
  }
}

function* onLogout() {
  yield delay(0);
  localStorage.removeItem("accessToken");
}

export default function* appSaga() {
  // user my profile request
  yield takeLatest(actions.doGetMyProfile, doGetMyProfileRequest);
  // user initial workspace like teams and project
  yield takeLatest(actions.doGetUserWorkspace, doGetUserWorkspaceRequest);
  // create board via tamp
  yield takeLatest(actions.doCreateFixTempBoard, doCreateFixTempBoardRequest);

  yield takeLatest(actions.fetchUser.type, fetchUserDetailsSaga);
  yield takeLatest("onboarding/nextStep", handleNextStep);
  yield takeLatest("onboarding/previousStep", handlePreviousStep);
  yield takeLatest(actions.onLogout, onLogout);
}
