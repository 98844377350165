import React from "react";

interface CustomProps {
  size: number;
  color?: string;
}

const CloseFiled: React.FC<CustomProps> = ({ size, color }) => {
  return (
    <div>
      <svg
        width={size}
        height={size}
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 1.375C3.5875 1.375 0.875 4.0875 0.875 7.5C0.875 10.9125 3.5875 13.625 7 13.625C10.4125 13.625 13.125 10.9125 13.125 7.5C13.125 4.0875 10.4125 1.375 7 1.375ZM9.3625 10.5625L7 8.2L4.6375 10.5625L3.9375 9.8625L6.3 7.5L3.9375 5.1375L4.6375 4.4375L7 6.8L9.3625 4.4375L10.0625 5.1375L7.7 7.5L10.0625 9.8625L9.3625 10.5625Z"
          fill={color ? color : "#5A8DF1"}
        />
      </svg>
    </div>
  );
};

export default CloseFiled;
