import TimeIcon from "app/components/Icons/TimeIcon";
import Input from "app/components/Input";
import CustomTooltip from "app/components/Tooltip";
import React, { useState } from "react";

interface CustomProps {
  value: string;
}

const EffortEstimated: React.FC<CustomProps> = ({ value }) => {
  const [edit, setEdit] = useState(false);
  const [newValue, setNewValue] = useState(value);
  return (
     <>
         {edit ? (
           <Input
             id={value}
             icon={<TimeIcon size={16} />}
             placeholder={"Enter efforts estimation"}
             value={newValue}
             onChange={(e) => {
               e.stopPropagation();
               setNewValue(e.target.value);
             }}
             inputClassName=" !py-1 !h-[36px] "
             onKeyDown={() => {
               setEdit(false);
             }}
           />
         ) : (
           <CustomTooltip
             text="Set efforts estimation"
             className="flex items-center flex items-center justify-between w-full px-2 py-2 rounded-md hover:bg-[#F4F5F7] rounded-[4px]"
             position="left"
           >
             <div
               className="flex items-center gap-2 w-full"
               onClick={() => {
                 setEdit(true);
               }}
             >
               <TimeIcon size={16} />
               <span className="text-sm font-normal text-[#BDC0C3]">
                 {newValue.length > 0 ? newValue : "Enter efforts estimation"}
               </span>
             </div>
           </CustomTooltip>
         )}
       </>
  );
};

export default EffortEstimated;
