import React, { useState } from "react";
import Dropdown from "app/components/Dropdown";
import { getTicketIcon } from "app/containers/Features/containers/Boards/containers/Home/containers/helpers";
import { TicketType } from "../types";
import { DropdownIcon } from "app/components/Icons";
import {TicketTypeOptions} from "../data"
const AddTicketsDropdown = ({ setShowAddTask }) => {
  const [selectedValue, setSelectedValue] = useState("");

  const menuItems = TicketTypeOptions.map((option) => ({
    label: (
      <div className="flex items-center gap-3 p-2 w-full">
        <img src={option.icon} alt="icon" className="size-[20px]" />
        <span className="text-sm font-normal text-black">{option.label}</span>
      </div>
    ),
    action: () => {
      setSelectedValue(option.label);
      setShowAddTask({ show: true, type: option.type });
    },
  }));

  return (
    <Dropdown
      trigger={
        <button
          className="w-[24px] h-[24px] flex hover:bg-[#E9ECF2] focus:outline-none"
        >
          <DropdownIcon size={20} />
        </button>
      }
      menuItems={menuItems}
      align="start"
      className="max-w-[180px] py-2"
      projectInfoIcon={ <div className="flex items-center gap-2 pl-4 pr-2 py-2 text-secondary text-sm font-semibold uppercase border-b border-[#E9ECF2]">
        TICKET TYPES
      </div>}
    />
  );
};

export default AddTicketsDropdown;
