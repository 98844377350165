import Dropdown from "app/components/Dropdown";
import { Menu } from "app/components/Icons";
import ArchiveIcon from "app/components/Icons/ArchiveIcon";
import { DuplicateIcon } from "app/components/Icons/DuplicateIcon";
import React, { useState } from "react";
import ArchiveTicketModal from "./ArchiveTicketModal";
import Modal from "app/components/Modal";

interface RowMenuOptionsProps {
  ticketId: string;
  buttonClassName?: string;
  handleArchive?: () => void;
  handleDuplicate?: () => void;
}

const RowMenuOptions: React.FC<RowMenuOptionsProps> = ({
  ticketId,
  buttonClassName,
  handleArchive,
  handleDuplicate,
}) => {
  const [show, setShow] = useState(false);
  const menuItems = [
    {
      label: (
        <div
          className="cursor-pointer flex flex-row items-center gap-[8px] pt-1 py-3 text-sm text-secondary"
          onClick={(e) => {
            handleDuplicate?.();
            setShow(false);
          }}
        >
          <DuplicateIcon size={20} color="#79808A" />
          <span>Duplicate</span>
        </div>
      ),
      action: () => {},
    },
    {
      label: (
        <div
          className="cursor-pointer flex flex-row items-center gap-[8px] pt-1 py-3 text-sm text-secondary"
          onClick={(e) => {
            e.stopPropagation();
            handleArchive?.();
            setShow(false);
          }}
        >
          <ArchiveIcon size={20} color="#79808A" />
          <span>Archive</span>
        </div>
      ),
      action: () => {},
    },
  ];

  return (
    <>
      <Dropdown
        open={show}
        setOpen={()=>setShow(true)}
        trigger={
          <button
            className={`${buttonClassName} hover:border hover:border-[#79808A] hover:rounded`}
          >
            <Menu size="20" color="#79808A" />
          </button>
        }
        menuItems={menuItems}
        align="start"
        className="max-w-[180px] py-2"
      />
    </>
  );
};

export default RowMenuOptions;
