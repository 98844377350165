import React from "react";
import {
  CommentIcon,
  BorderedDropdownIcon,
  TagIcon,
} from "app/components/Icons";
import { HarshIcon } from "app/components/Icons/HarshIcon";
import { NotesIcon } from "app/components/Icons/NotesIcon";
import IconText from "app/components/IconText";
import Input from "app/components/Input";
import CalendarIcon from "app/components/Icons/CalendarIcon";
import TextArea from "app/components/TextArea";
import NumericFormatInput from "app/components/NumericFormatInput";
import DropdownNameSelect from "./DropdownNameSelect";
import TicketIDSelect from "./TicketIDSelect";
import LabelSelection from "./LabelSelection";
import DateRangePicker from "app/components/DateRangePicker";
import { DateRange } from "react-day-picker";
import DatePicker from "app/components/DatePicker";
import DateSelection from "./DateSelection";

const Index = () => {
  return (
    <div className="flex flex-col gap-[4px] w-full">
      <p className="text-xs leading-[21px] font-medium text-black">
        Custom fields
      </p>
      <div className="flex flex-col">
        <div className="flex flex-row items-center w-full">
          <div className="w-[196px] border border-gray-100 border-r-0 rounded-r-none h-[42px] flex flex-col justify-center pl-4 pr-2 rounded-[4px] rounded-b-none">
            <IconText
              icon={<CommentIcon color="#79808A" />}
              text="Comments*"
              className="gap-[4px]"
              textClassName="text-[12px] leading-[18px] font-medium text-secondary"
            />
          </div>
          <div className="w-full">
            <Input
              placeholder="Enter comments"
              id="task-description"
              inputClassName="!h-[42px] w-full rounded-l-none rounded-b-none"
            />
          </div>
        </div>

        <div className="flex flex-row items-center w-full min-h-[82px]">
          <div className="w-[196px] min-h-[82px] border border-gray-100 border-r-0 border-t-0 rounded-r-none h-[42px] flex flex-col justify-start pt-2 pl-4 pr-2 rounded-none">
            <IconText
              icon={<NotesIcon color="#79808A" />}
              text="Notes"
              className="gap-[4px] items-start"
              textClassName="text-[12px] leading-[18px] font-medium text-secondary"
            />
          </div>
          <div className="w-full min-h-[82px]">
            <TextArea
              placeholder="Enter notes"
              id="task-notes"
              textAreaClassName="!h-[82px] w-full rounded-l-none rounded-r-none border-t-0"
            />
          </div>
        </div>

        <div className="flex flex-row items-center w-full">
          <DropdownNameSelect />
        </div>

        <div className="flex flex-row items-center w-full">
          <DateSelection />
        </div>

        <div className="flex flex-row items-center w-full">
          <div className="w-[196px] border border-gray-100 border-r-0 border-t-0 rounded-r-none h-[42px] flex flex-col justify-center pl-4 pr-2 rounded-none">
            <IconText
              icon={<HarshIcon color="#79808A" />}
              text="Number*"
              className="gap-[4px]"
              textClassName="text-[12px] leading-[18px] font-medium text-secondary"
            />
          </div>
          <div className="w-full">
            <NumericFormatInput
              placeholder="Enter number"
              id="task-number"
              inputClassName="!h-[42px] w-full rounded-l-none rounded-r-none border-t-0"
            />
          </div>
        </div>

        <div className="flex flex-row items-center w-full">
          <TicketIDSelect />
        </div>

        <div className="flex flex-row items-center w-full">
          <LabelSelection />
        </div>
      </div>
    </div>
  );
};

export default Index;
