import Dropdown from "app/components/Dropdown";
import { CheckIcon, TicketsIcon } from "app/components/Icons";
import MemberProfile from "app/components/MemberProfile";
import { getTicketIcon } from "app/containers/Features/containers/Boards/containers/Home/containers/helpers";
import { TicketType } from "app/containers/Features/containers/Boards/types";
import React from "react";
import StatusSelectionButton from "./StatusSelectionButton";

interface TicketTypeDropdownProps {
  value?: TicketType;
  setValue?: (value: TicketType) => void;
}


const TicketTypeDropdown:React.FC<TicketTypeDropdownProps> = ({setValue,value}) => {
  const optionsNew = [
    {
      label: "Task",
      type: TicketType.TASK,
      icon: getTicketIcon(TicketType.TASK),
    },
    {
      label: "Bug",
      type: TicketType.BUG,
      icon: getTicketIcon(TicketType.BUG),
    },
    {
      label: "Story",
      type: TicketType.STORY,
      icon: getTicketIcon(TicketType.STORY),
    },
    {
      label: "Epic",
      type: TicketType.EPIC,
      icon: getTicketIcon(TicketType.EPIC),
    },
  ];
  const selectedOptions =
    optionsNew.find((p) => p.type === value) || optionsNew[0];

  const MenuItems = optionsNew?.map((ele) => {
    if (ele)
      return {
        label: (
          <MemberProfile
            showStartIcon
            icon={ele.icon}
            showStartIconImg
            name={ele.label}
            statusColor="#F00"
            endIcon={<CheckIcon color="#5A8DF1" />}
            showEndIcon={value === ele?.type}
            iconHide
          />
        ),
        action: () => {
          setValue && setValue(ele?.type);
        },
      };
    else return { label: "", action: () => {} };
  });
  return (
    <>
      <Dropdown 
        trigger={
          <button className="flex items-center justify-between w-full px-2 py-2 rounded-md hover:bg-[#F4F5F7] rounded-[4px] data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A]">
            <StatusSelectionButton
              label="Ticket type:"
              icon={<TicketsIcon size={18} color="#79808A" />}
              className="text-[12px] leading-[18px] font-medium text-secondary mt-[2px]"
              selectedIcon={<img src={selectedOptions.icon} alt="task-icon" className="size-[20px]" />}
              selectedLabel={selectedOptions.label}
            />
          </button>
        }
        menuItems={MenuItems ? MenuItems : []}
        align="end"
        className="py-2"
      />
    </>
  );
};

export default TicketTypeDropdown;
