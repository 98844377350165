import CustomTooltip from "app/components/Tooltip";
import Avatar from "app/containers/Features/components/Avatar";
import CommonAssigneeSelection from "app/containers/Features/components/CommonAssigneeSelection";
import { members } from "app/containers/Features/containers/ProjectSettings/data";
import { IMember } from "app/containers/Features/containers/ProjectSettings/types";
import { UserIcon } from "lucide-react";
import React, { useState } from "react";

interface AssigneeSelectionProps {
  assignee: {
    name: string;
    color: string;
    status?: "online" | "offline";
    online: boolean;
  };
}

const Assignee: React.FC<AssigneeSelectionProps> = ({ assignee }) => {
  const [search, setSearch] = useState("");
  function searchMembers(
    members: IMember[] | undefined,
    keyword: string
  ): IMember[] {
    if (!keyword || !members) {
      return members ? members : [];
    }

    const lowerCaseKeyword = keyword.toLowerCase();

    return members.filter((member) => {
      return (
        member.name.toLowerCase().includes(lowerCaseKeyword) ||
        member.role.toLowerCase().includes(lowerCaseKeyword)
      );
    });
  }

  const filteredMembers = searchMembers(members, search);
  return (
    <>
      <CommonAssigneeSelection
        trigger={
          <button className="flex items-center justify-between w-full px-2 py-2 rounded-md hover:bg-[#F4F5F7] rounded-[4px] data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A]">
            <CustomTooltip
              text="Set assignee"
              className="flex items-center gap-1 "
              position="left"
            >
              <UserIcon size={16} />
              <Avatar
                color={assignee?.color}
                name={assignee?.name}
                circleClassName="text-[6.4px] leading-[19.2px] font-normal size-[12.8px]"
                statusClassName="size-[3.2px]"
                nameClassName="text-xs leading-[18px] font-normal text-secondary"
              />
            </CustomTooltip>
          </button>
        }
        align="center"
        assignee={assignee}
        searchKeyword={search}
        setSearchKeyword={(val) => {
          setSearch(val);
        }}
        data={filteredMembers}
        selectedValue={assignee.name}
        updateValue={function (id: string): void {
          throw new Error("Function not implemented.");
        }}
        isHeader="Assignees"
        searchPlaceholder="Search by assignee or role "
      />
    </>
  );
};

export default Assignee;
