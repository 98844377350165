import DragIcon from "app/components/Icons/DragIcon";
import React, { useState } from "react";
import RowMenuOptions from "../../Backlog/components/RowMenuOptions";
import CheckboxIcon from "app/components/Icons/CheckboxIcon";
import { CheckboxActiveIcon } from "app/components/Icons";
import Modal from "app/components/Modal";
import ArchiveTicketModal from "../../Backlog/components/ArchiveTicketModal";
import ColumnOptionsMenu from "./ColumnOptionsMenu";

interface ItemActionProps {
  id: string;
  isSelected: boolean;
  onSelectTicket: (id: string) => void;
}

const ItemAction: React.FC<ItemActionProps> = ({
  id,
  isSelected,
  onSelectTicket,
}) => {
  const [show, setShow] = useState(false);
  return (
    <React.Fragment>
      {isSelected ? (
        <div
          className="absolute z-10 right-1 flex flex-row items-center opacity-100"
          onClick={(e) => {
            e.stopPropagation();
            onSelectTicket?.(id);
          }}
        >
          <CheckboxActiveIcon  size={18}/>
        </div>
      ) : (
        <div className="absolute z-10 right-0 bg-white border-2 gap-1 px-1 py-1 border-[#E9ECF2] rounded flex flex-row items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200 rounded-md w-[52px] h-[32px]">
          <ColumnOptionsMenu
            ticketId={id}
            buttonClassName="hover:border-[1.5px] border-[#79808A] rounded p-0 outline-none"
            handleArchive={() => {
              setShow(true);
            }}
            handleDuplicate={() => {}}
          />
          <button
            className="p-1 hover:bg-gray-200 rounded"
            onClick={(e) => {
              e.stopPropagation();
              onSelectTicket?.(id);
            }}
          >
            <CheckboxIcon color="#79808A" size={16} />
          </button>
        </div>
      )}
      {show && (
        <ArchiveTicketModal
          onClose={() => setShow(false)}
          selectionCount={1}
          onSubmit={() => {}}
        />
      )}
    </React.Fragment>
  );
};

export default ItemAction;
