import React from "react";
import Button from "app/components/Button";
import { Link, useSearchParams } from "react-router-dom";
import Logo from "app/components/Icons/Logo";
import { useDispatch } from "react-redux";
import { actions as appActions} from "../../.../../../../../redux/slice";

export default function Welcome() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const callback = searchParams.get("callback") ?? "/onboarding/setup";
  const handleNext = () => {
    dispatch(appActions.doGetMyProfile());
    dispatch(appActions.doGetUserWorkspace());
  };
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center gap-[20px] mb-[56px]">
        <Logo size={72} />
        <h1 className="text-[40px] leading-[50px] font-semibold mb-4 text-center">
          Welcome to Teambox.Dev
        </h1>
      </div>
      <div className="w-full max-w-[520px] space-y-[20px]">
        <p className="text-center text-secondary text-md leading-[24px] font-normal">
          Teambox.Dev is a purpose-built system for developing products.
          Streamline issues, projects and product roadmaps.
        </p>
        <Link
          className="w-full flex flex-col items-center justify-center w-full"
          replace={callback === "/app/home"}
          to={callback}
        >
          <Button
            className="w-full max-w-[390px] h-[56px]"
            onClick={handleNext}
          >
            Get started
          </Button>
        </Link>
      </div>
    </div>
  );
}
