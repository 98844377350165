import React from "react";

interface CheckListProgressProps {
  progress: number;
}

const CheckListProgress: React.FC<CheckListProgressProps> = ({ progress }) => {
  return (
    <div className="w-10 bg-[#E4E8ED] rounded-full h-2 overflow-hidden">
      <div
        className="bg-[#36BC69] h-full transition-all duration-300"
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

export default CheckListProgress;
