import React, { Component, useEffect } from "react";
import {
  GroupByTypeEnum,
  ITag,
  IVisibleGroups,
  Ticket,
  TicketType,
} from "../../../../types";
import CreatedDate from "./CreatedDate";
import Creator from "./Creator";
import TicketId from "./TicketId";
import EffortActual from "./EffortActual";
import EffortEstimated from "./EffortEstimated";
import Sprint from "./Sprint";
import Environment from "./Environment";
import Severity from "./Severity";
import Release from "./Release";
import DueDate from "./Duedate";
import TargetRelease from "./Release";
import Priority from "./Priority";
import {
  PriorityFilterEnum,
  StatusFilterEnum,
} from "app/containers/Features/containers/Ticket/types";
import TicketTypeSelection from "./TicketType";
import Assignee from "./Assignee";
import Status from "./Status";
import { TableHeader } from "app/components/Table/types";
import { useSelector } from "react-redux";
import { selectBoardsFormData } from "../../../../redux/selector";

interface RowCustomizeProps {
  td: TableHeader[];
  ticket: Ticket;
}
const RowCustomize: React.FC<RowCustomizeProps> = ({ td, ticket }) => {
  const boardData = useSelector(selectBoardsFormData);
  const allColumns = boardData.customizeCard;

  const tableHeaderIds = new Set(td.map((header) => header.id));

  // Matched aur unmatched columns filter karenge
  const matchedColumns = allColumns.filter((column) =>
    tableHeaderIds.has(column.type as string)
  );
  
  return (
    <React.Fragment>
      {matchedColumns
        .filter((x) => x.type !== GroupByTypeEnum.TICKET_NAME)
        .map((ele, index) => {
          return (
            <td className="p-[4px]">
              {ele.type === GroupByTypeEnum.ASSIGNEE ? (
                <Assignee assignee={ticket.assignee} />
              ) : ele.type === GroupByTypeEnum.STATUS ? (
                <Status value={StatusFilterEnum.OPENED} />
              ) : ele.type === GroupByTypeEnum.TICKET_TYPE ? (
                <TicketTypeSelection value={TicketType.TASK} />
              ) : ele.type === GroupByTypeEnum.PRIORITY ? (
                <Priority value={PriorityFilterEnum.NONE} />
              ) : ele.type === GroupByTypeEnum.DUE_DATE ? (
                <DueDate />
              ) : ele.type === GroupByTypeEnum.TARGET_RELEASE ? (
                <TargetRelease value={""} />
              ) : ele.type === GroupByTypeEnum.RELEASE ? (
                <Release value={""} />
              ) : ele.type === GroupByTypeEnum.SEVERITY ? (
                <Severity value={""} />
              ) : ele.type === GroupByTypeEnum.ENVIRONMENT ? (
                <Environment value={""} />
              ) : ele.type === GroupByTypeEnum.SPRINT ? (
                <Sprint />
              ) : ele.type === GroupByTypeEnum.EFFORTS_ESTIMATION ? (
                <EffortEstimated value={""} />
              ) : ele.type === GroupByTypeEnum.EFFORT_ACTUAL ? (
                <EffortActual value="" />
              ) : ele.type === GroupByTypeEnum.TICKET_ID ? (
                <TicketId value={ticket.id} />
              ) : ele.type === GroupByTypeEnum.CREATOR ? (
                <Creator assignee={ticket.assignee} />
              ) : ele.type == GroupByTypeEnum.CREATED_DATE ? (
                <CreatedDate value={ticket.dueDate} />
              ) : ele.type === GroupByTypeEnum.COMPONENT ? (
                <Component />
              ) : (
                ""
              )}
            </td>
          );
        })}
    </React.Fragment>
  );
};

export default RowCustomize;
