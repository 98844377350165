/**
 * Boards selectors
 */

import { RootState } from "types";

import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./initialState";

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state?.BoardsState || initialState;

export const selectBoards = createSelector(
  [selectDomain],
  (BoardsState) => BoardsState.boardsData
);

export const selectFavorites = createSelector(
  [selectDomain],
  (BoardsState) => BoardsState.favorites
);

export const selectQaBoards = createSelector(
  [selectDomain],
  (BoardsState) => BoardsState.qaBoards
);

export const selectRecents = createSelector(
  [selectDomain],
  (BoardsState) => BoardsState.recent
);
export const selectBoardsFormData = createSelector(
  [selectDomain],
  (BoardsState) => BoardsState.boardsForm
);
export const selectDefaultTicketValueData = createSelector(
  [selectDomain],
  (BoardsState) => BoardsState.defaultTicketValueData
);
export const selectDraftTicket = createSelector(
  [selectDomain],
  (BoardsState) => BoardsState.draftTicket
);
export const selectTicketForm = createSelector(
  [selectDomain],
  (BoardsState) => BoardsState.ticketForm
);

export const selectSelectedTickets = createSelector(
  [selectDomain],
  (BoardsState) => BoardsState.selectedTickets
);
export const selectIsFileUploading = createSelector(
  [selectDomain],
  (BoardsState) => BoardsState.isFileUploading
);
export const selectIsFilePreview = createSelector(
  [selectDomain],
  (BoardsState) => BoardsState.isFilePreview
);
export const selectUploadProgress = createSelector(
  [selectDomain],
  (BoardsState) => BoardsState.uploadProgress
);
export const selectDefaultTagList= createSelector(
  [selectDomain],
  (BoardsState) => BoardsState.tagsList
);

export const selectSortByColumn = createSelector(
  [selectDomain],
  (BoardsState) => BoardsState.sortByColumn
);

export const selectSortByOrder = createSelector(
  [selectDomain],
  (BoardsState) => BoardsState.sortByOrder
);

export const selectIsLoading = createSelector(
  [selectDomain],
  (BoardsState) => BoardsState.isLoading
);
