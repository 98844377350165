/**
 * Gets the repositories of the user from Github
 */

import { call, delay, put, takeLatest } from "redux-saga/effects";
import { FILE_API_URL } from "utils/constants";
import { Res, request } from "utils/request";
import toastService from "utils/toast";
import { CreateAssetResponse, PreSignedUrlResponse } from "../types";
import { actions } from "./slice";

export function* uploadMediaRequest(action) {
  try {
    const file = action.payload.media;
    const { data, err }: Res<PreSignedUrlResponse> = yield getPreSignedUrlApi({
      fileName: file.name,
    });
    if (!data || err) {
      throw err || "Something went wrong";
    }
    yield call(uploadFile, data?.signedUrl, file?.file, data.contentType);
    const asset: CreateAssetResponse = yield createAsset({
      type: file.type,
      mimeType: file.mimeType,
      fileName: file.name,
      filePath: data.fileNameWithPrefix,
    });
    yield put(actions.uploadMediaSuccess(asset?.url));
    action?.payload?.callback?.(asset);
    // toastService.success(translate("MEDIA.UPLOAD.SUCCESS"));
  } catch (err: any) {
    yield put(actions.uploadMediaError());
    let errorMessage = err?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message || errorMessage || "Something went wrong"
    );
  }
}

export function* getPreSignedUrlApi({ fileName }: { fileName: string }) {
  try {
    yield delay(500);
    const options = {
      method: "POST",
      body: JSON.stringify({
        fileName,
      }),
    };
    const url = `${FILE_API_URL}asset/signed-url`;
    const { data, err }: Res<PreSignedUrlResponse> = yield call(
      request,
      url,
      options
    );
    if (err || !data) {
      throw err;
    }
    return data;
  } catch (err: any) {
    yield put(actions.uploadMediaError());
    let errorMessage = err?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message || errorMessage || "Something went wrong"
    );
  }
}

export function* createAsset({
  type,
  mimeType,
  fileName,
  filePath,
}: {
  type: string;
  mimeType: string;
  fileName: string;
  filePath: string;
}) {
 
  try {
    yield delay(500);
    const options = {
      method: "POST",
      body: JSON.stringify({
        type,
        mimeType,
        fileName,
        filePath,
      }),
    };
    const url = `${FILE_API_URL}asset/create`;
    const { data, err }: Res<{ data: CreateAssetResponse }> = yield call(
      request,
      url,
      options
    );
    if (err || !data) {
      throw err;
    }
    return data?.data;
  } catch (err: any) {
    yield put(actions.uploadMediaError());
    let errorMessage = err?.message;
    try {
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {}
    toastService.error(
      errorMessage?.message || errorMessage || "Something went wrong"
    );
  }
}

async function uploadFile(
  s3SignedUrl: string,
  file: File,
  contentType: string
): Promise<void> {
  return new Promise((resolve, reject) => {
    try {
      const xhr = new XMLHttpRequest();
   
      xhr.open("PUT", s3SignedUrl, true);
      xhr.setRequestHeader("Content-Type", 'application/octet-stream');

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve();
          } else {
            console.error("Failed to upload file to S3");
            reject(new Error("Failed to upload file to S3"));
          }
        }
      };

      xhr.send(file);
    } catch (error) {
      console.error("Error:", error);
      reject(error);
    }
  });
}

/**
 * Root saga manages watcher lifecycle
 */
export function* mediaUploadSaga() {
  yield takeLatest(actions.uploadMedia, uploadMediaRequest);
}
