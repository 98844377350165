import React, { useState } from "react";
import { ITag } from "../../containers/Boards/types";
import { CheckboxActiveIcon, Input, SearchIcon } from "app/components/Icons";
import CheckboxIcon from "app/components/Icons/CheckboxIcon";
import Dropdown from "app/components/Dropdown";
import AddNew from "./AddNew";
import PlusIcon from "app/components/Icons/PlusIcon";
import Pill from "app/components/Pill";
import { fixedColorCode } from "../../containers/Boards/data";

interface CustomProps {
  selectedTags?: any;
  onTagsChange?: (tags: string[]) => void;
  options: ITag[];
  trigger?: React.ReactNode;
  dropdownContainerClassName?: string;
  className?: string;
  align?: "start" | "end" | "center";
  isHeader?: string;
  searchPlaceholder?: string;
}

const Index: React.FC<CustomProps> = ({
  onTagsChange,
  selectedTags,
  options,
  trigger,
  align = "center",
  className,
  dropdownContainerClassName,
  isHeader,
  searchPlaceholder,
}) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const filteredTags = searchMembers(options, searchKeyword);
  const [selectedColor, setSelectedColor] = useState<string>(fixedColorCode[2]);
  const selectedTagObjects = options?.filter((tag) =>
    selectedTags?.includes(tag.id)
  );

  const handleCheckboxChange = (ele: ITag) => {
    const newSelectedTags = selectedTags?.includes(ele.id)
      ? selectedTags?.filter((id) => id !== ele.id)
      : [...(selectedTags as string[]), ele.id];

    onTagsChange?.(newSelectedTags);
  };

  function searchMembers(tags: ITag[] | undefined, keyword: string): ITag[] {
    if (!keyword || !tags) {
      return tags ? tags : [];
    }

    const lowerCaseKeyword = keyword.toLowerCase();

    return tags?.filter((tag) => {
      return tag.name.toLowerCase().includes(lowerCaseKeyword);
    });
  }

  const MenuItems = filteredTags?.map((option) => {
    if (option) {
      return {
        label: (
          <div
            key={option.name}
            className="flex flex-row items-center  py-1 w-full gap-2"
            onClick={(e) => {
              e.preventDefault();
              handleCheckboxChange(option);
            }}
          >
            {selectedTags?.includes(option.id) ? (
              <CheckboxActiveIcon />
            ) : (
              <CheckboxIcon />
            )}
            <Pill
              backgroundColor={option.backgroundColor}
              textColor={option.color}
              name={option.name}
              className="px-[6px] py-[2px] text-[10px] leading-[18px] font-medium"
            />
          </div>
        ),
        action: () => {},
      };
    } else return { label: "", action: () => {} };
  });
  return (
    <React.Fragment>
      <Dropdown
        trigger={
          trigger ?? (
            <button className="flex items-center justify-between w-full px-2 py-2 rounded-md hover:bg-[#F4F5F7] rounded-[4px] data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A]">
              {selectedTagObjects?.map((label) => {
                return (
                  <Pill
                    key={label.name}
                    className="!px-[6px]"
                    backgroundColor={label.backgroundColor}
                    textColor={label.color}
                    name={label.name}
                  />
                );
              })}
            </button>
          )
        }
        projectInfoIcon={
          <Input
            noBorder
            id={searchKeyword ?? "search"}
            icon={<SearchIcon size="17" color="#79808A" />}
            placeholder={searchPlaceholder ?? "Search by tag name"}
            value={searchKeyword}
            onChange={(e) => {
              e.stopPropagation();
              setSearchKeyword(e.target.value);
            }}
            innerContainerClassName="px-2 pb-1 border-b-[1px] border-[#E9ECF2]"
          />
        }
        menuItems={MenuItems ? MenuItems : []}
        align={align ?? "start"}
        className={`${className}  py-2`}
        dropdownContainerClassName={`${dropdownContainerClassName} max-h-[280px] overflow-scroll`}
        addSubContent={
          <AddNew
            trigger={
              <button className="w-full flex items-center text-md text-primary font-normal border-t py-2 px-3 gap-3 border-[#E9ECF2] ">
                <PlusIcon color="#5A8DF1" size={20} />
                New tag
              </button>
            }
            selectedColor={selectedColor}
            setSelectedColor={(val) => {
              setSelectedColor(val);
            }}
          />
        }
      />
    </React.Fragment>
  );
};

export default Index;
