import Dropdown from "app/components/Dropdown";
import {
  CheckboxActiveIcon,
  Input,
  SearchIcon,
  TagIcon,
} from "app/components/Icons";
import CheckboxIcon from "app/components/Icons/CheckboxIcon";
import Pill from "app/components/Pill";
import React from "react";
import StatusSelectionButton from "./StatusSelectionButton";
import PlusIcon from "app/components/Icons/PlusIcon";
import AddNewTag from "./AddNewTag";
import { ITag } from "app/containers/Features/containers/Boards/types";



interface TagsSelectionProps {
  selectedTags?: string[];
  onTagsChange?: (tags: string[]) => void;
}

const TagsSelection: React.FC<TagsSelectionProps> = ({
  selectedTags = [],
  onTagsChange,
}) => {
  const [searchKeyword, setSearchKeyword] = React.useState("");
  const filteredTags = searchMembers(options, searchKeyword);
  const selectedTagObjects = options.filter(tag => selectedTags.includes(tag.id));

  const handleCheckboxChange = (ele: ITag) => {
    const newSelectedTags = selectedTags.includes(ele.id)
      ? selectedTags.filter(id => id !== ele.id)
      : [...selectedTags, ele.id];
    
    onTagsChange?.(newSelectedTags);
  };

  function searchMembers(tags: ITag[] | undefined, keyword: string): ITag[] {
    if (!keyword || !tags) {
      return tags ? tags : [];
    }

    const lowerCaseKeyword = keyword.toLowerCase();

    return tags.filter((tag) => {
      return tag.name.toLowerCase().includes(lowerCaseKeyword);
    });
  }

  const MenuItems = filteredTags?.map((option) => {
    if (option) {
      return {
        label: (
          <div
            key={option.name}
            className="flex flex-row items-center  py-1 w-full gap-2"
            onClick={(e) => {
              e.preventDefault();
              handleCheckboxChange(option);
            }}
          >
            {selectedTags.includes(option.id) ? (
              <CheckboxActiveIcon />
            ) : (
              <CheckboxIcon />
            )}
            <Pill
              backgroundColor={option.backgroundColor}
              textColor={option.color}
              name={option.name}
              className="px-[6px] py-[2px] text-[10px] leading-[18px] font-medium"
            />
          </div>
        ),
        action: () => {},
      };
    } else return { label: "", action: () => {} };
  });
  return (
    <>
      <Dropdown
        trigger={
          <button className="flex items-center justify-between w-full px-2 py-2 rounded-md hover:bg-[#F4F5F7] rounded-[4px] data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A]">
            <StatusSelectionButton
              label="Tags:"
              icon={<TagIcon size={18} color="#79808A" />}
              className="text-[12px] leading-[18px] font-medium text-secondary mt-[2px]"
              selectedTag={selectedTagObjects}
              maxSelectedTag={2}
            />
          </button>
        }
        projectInfoIcon={
          <Input
            containerClassName="px-2 pb-1"
            noBorder
            id="search"
            icon={<SearchIcon size="17" color="#79808A" />}
            placeholder="Search by label name"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        }
        menuItems={MenuItems ? MenuItems : []}
        align="end"
        className="py-2"
        addSubContent={
          <AddNewTag
            trigger={
              <button className="w-full flex items-center text-md text-primary font-normal border-t py-2 px-3 gap-3 border-[#E9ECF2] ">
                <PlusIcon color="#5A8DF1" size={20} />
                New tag
              </button>
            }
          />
        }
      />
    </>
  );
};

export default TagsSelection;

const options: ITag[] = [
  {
    id: "1",
    name: "internal bug",
    color: "#FF1A7F",
    backgroundColor: "#FF1A7F2E",
  },
  {
    id: "2",
    name: "change request",
    color: "#FEC700",
    backgroundColor: "#FEC7002E",
  },
  {
    id: "3",
    name: "figma needed",
    color: "#00ABE5",
    backgroundColor: "#00ABE52E",
  },
  {
    id: "4",
    name: "design",
    color: "#00AB7B",
    backgroundColor: "#00AB7B2E",
  },
];
