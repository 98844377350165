import React, { useEffect } from "react";
import AdaptiveDateRangePicker from "app/components/AdaptiveDateRangePicker";
import StatusSelectionButton from "./StatusSelectionButton";
import { CalendarIcon } from "app/components/Icons";
import { format } from "date-fns/format";

interface DueDateSelectionProps {
  startDate?: string;
  endDate?: string;
  onDateChange?: (startDate?: string, endDate?: string) => void;
}

const DueDateSelection: React.FC<DueDateSelectionProps> = ({
  endDate,
  startDate,
  onDateChange,
}) => {
  const [selectedDates, setSelectedDates] = React.useState<Date[]>([]);

  useEffect(() => {
    const dates: Date[] = [];
    if (startDate) {
      dates.push(new Date(startDate));
    }
    if (endDate) {
      dates.push(new Date(endDate));
    }
    setSelectedDates(dates);
  }, [startDate, endDate]);

  const handleDateChange = (dates: Date[]) => {
    setSelectedDates(dates);

    const formattedStartDate = dates[0]
      ? format(dates[0], "dd MMM, yyyy")
      : undefined;
    const formattedEndDate = dates[1]
      ? format(dates[1], "dd MMM, yyyy")
      : undefined;

    onDateChange?.(formattedStartDate, formattedEndDate);
  };

  const dateLabel = () => {
    if (selectedDates.length > 0) {
      const formattedStart = selectedDates[0]
        ? format(selectedDates[0], "dd MMM, yyyy")
        : undefined;
      const formattedEnd = selectedDates[1]
        ? format(selectedDates[1], "dd MMM, yyyy")
        : undefined;

      if (!formattedStart && !formattedEnd) return "-";
      return `${formattedStart || ""} ${
        formattedEnd ? "- " + formattedEnd : ""
      }`.trim();
    }
    return "-";
  };

  return (
    <>
      <AdaptiveDateRangePicker
        trigger={
          <button className="flex items-center justify-between w-full px-2 py-2 rounded-md hover:bg-[#F4F5F7] rounded-[4px] data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A]">
            <StatusSelectionButton
              label="Due date:"
              selectedLabel={dateLabel()}
              icon={<CalendarIcon size={20} color="#79808A" />}
              className="text-[12px] leading-[18px] font-medium text-secondary mt-[2px]"
            />
          </button>
        }
        selectedDates={selectedDates}
        setSelectedDates={handleDateChange}
      />
    </>
  );
};

export default DueDateSelection;
