import Card from "app/components/Card";
import { UserIcon } from "app/components/Icons";
import IconText from "app/components/IconText";
import React, { useEffect } from "react";
import { ITicketTypeFields } from "./types";
import { Switch } from "app/components/Switch";
import { useDispatch } from "react-redux";
import { actions } from "./redux/slice";

interface FieldsProps {
  data: ITicketTypeFields[];
  fId:string
}

const Fields: React.FC<FieldsProps> = ({ data ,fId}) => {
  const dispatch = useDispatch();
  const groupedFields = data.reduce((acc, field) => {
    if (!acc[field.fieldType]) acc[field.fieldType] = [];
    acc[field.fieldType].push(field);
    return acc;
  }, {} as Record<string, typeof data>);

  // Sort fields within each group
  Object.keys(groupedFields).forEach((key) => {
    groupedFields[key].sort((a, b) => a.order - b.order);
  });

  const handleChange = (id: string) => {
    dispatch(
      actions.doUpdateFields({
        id: id,
        callback() { dispatch(actions.doFetchTicketFields(fId));},
      })
    );
  };
  return (
    <React.Fragment>
      {Object.entries(groupedFields).map(([section, fields]) => (
        <React.Fragment key={section}>
          <div className="flex flex-col gap-3">
            <div className="text-sm font-medium ">
              <span>{section}</span>
            </div>
          </div>

          <Card className="rounded-lg border border-[#DFE1E6] shadow-sm bg-white">
            {fields.map((field) => (
              <div className="flex w-full flex-row items-center justify-between py-3 border-[#DFE1E6] h-12">
                <IconText
                  icon={<UserIcon />}
                  text={field.fieldName}
                  textClassName=""
                />
                <Switch
                  style={{
                    backgroundColor: field?.isMandatory
                      ? "bg-primary"
                      : "#79808A",
                  }}
                  className="!h-5 !w-10"
                  thumbClassName={` !h-4 !w-4`}
                  checked={field?.isMandatory}
                  onCheckedChange={() => handleChange(field?._id as string)}
                />
              </div>
            ))}
          </Card>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default Fields;
