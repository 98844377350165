import React, { useState } from "react";
import { IAttachment } from "../../../../types";
import CustomPopover from "./CustomPopover";
import attachmentIcon from "assets/svgs/ic_attachment.svg";
import { getFilePreview } from "../../containers/helpers/getFilePreview";

interface AttachmentsProps {
  count: number;
  attachments: IAttachment[];
}

const Attachments: React.FC<AttachmentsProps> = ({ count, attachments }) => {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <CustomPopover
        show={open}
        onClose={() => {
          setOpen(!open);
        }}
        trigger={
          <div
            className="flex flex-row items-center hover:bg-[#ECEEF2] hover:px-1 hover:py-[1px] rounded"
            onMouseEnter={() => {
              setOpen(true);
            }}
            onMouseLeave={() => {
              setOpen(false);
            }}
          >
            <div className="min-w-[16px]">
              <img
                src={attachmentIcon}
                alt="task-icon"
                className="size-[16px]"
              />
            </div>
            <div className="text-xs leading-[18px] text-secondary font-normal mt-[2px] ml-[2px]">
              {count}
            </div>
          </div>
        }
        content={
          <div className="flex flex-col gap-4">
            <div className="sticky top-0 bg-white pt-4">
              <p className="text-md font-medium text-[#253858]">
                {count} attachment
              </p>
            </div>
            {attachments?.map((ele) => {
              return (
                <div className="flex flex-col">
                  {/* {getFilePreview(ele?.file)} */}
                  {ele.file.includes("docx") ? (
                    <div className="flex bg-[#253858] rounded p-2 text-white  w-[max-content] text-sm">
                      {ele.file}
                    </div>
                  ) : (
                    <img
                      src={ele?.file}
                      alt="Uploaded preview"
                      className="max-w-full h-auto rounded-lg"
                    />
                  )}
                </div>
              );
            })}
          </div>
        }
        className="max-h-[300px] !pt-0"
      />
    </React.Fragment>
  );
};

export default Attachments;
