import React from "react";
import IconText from "../IconText";
import { CalendarIcon } from "../Icons";
import CloseFiled from "../Icons/CloseFiled";
interface CustomProps {
  date: string;
  onClear?: () => void;
  iconShow?: boolean;
}
const DatePill: React.FC<CustomProps> = ({ date, onClear, iconShow }) => {
  return (
    <>
      <div className="p-4 flex justify-between items-center">
        <IconText
          className="gap-[4px]"
          text={date ? date : "date..."}
          textClassName="text-[12px] leading-[18px] font-medium text-secondary"
          icon={<CalendarIcon size={16} color="#79808A" />}
        />
        {iconShow && (
          <button
            onClick={onClear}
            className="text-gray-400 hover:text-gray-600 transition-colors ml-2"
          >
            <CloseFiled size={18} />
          </button>
        )}
      </div>
    </>
  );
};

export default DatePill;
