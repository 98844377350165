import React, { FC, useState } from "react";
import { TicketType } from "../types";
import CustomTooltip from "app/components/Tooltip";
import { getTicketIcon } from "../containers/Home/containers/helpers";
import Dropdown from "app/components/Dropdown"; 
import checkMark from "assets/svgs/checkmark.svg";
import {TicketTypeOptions} from "../data"
interface TicketTypeButtonProps {
  readonly type: TicketType; 
  readonly onTypeChange: (type: TicketType) => void;
}

export const TicketTypeButton: FC<TicketTypeButtonProps> = ({ type, onTypeChange }) => {

  const handleSelect = (selectedType: TicketType) => {
    onTypeChange(selectedType);
  };

  const menuItems = TicketTypeOptions.map((option) => ({
    label: (
      <div className="cursor-pointer flex flex-row gap-[8px] items-center pt-1 py-3 text-sm text-secondary">
        <img src={option.icon} alt={`${option.label}-icon`} className="size-[20px]" />
        <span>{option.label}</span>
        {type === option.type && (
          <img src={checkMark} alt="selected" className="size-[20px] ml-14" />
        )}
      </div>
    ),
    action: () => handleSelect(option.type),
  }));
  
  return (
    <Dropdown
    trigger={
      <button className="flex flex-row items-center gap-[4px] h-[28px] px-[6px] capitalize rounded-[4px] border border-[#E9ECF2] text-xs leading-[18px] font-medium text-secondary hover:bg-[#ECEEF2] hover:text-[#79808A] hover:border-[#E9ECF2] transition-all duration-200">
          <CustomTooltip text="Set ticket type" position="bottom">
            <div className="flex items-center gap-1 min-w-[16px]">
              <img src={getTicketIcon(type)} alt="task-icon" className="size-[16px]" />
              <span className="text-sm">{type}</span>
            </div>

          </CustomTooltip>
          </button>
      }
      menuItems={menuItems}
      align="start"
      className="max-w-[180px]"
      projectInfoIcon={
        <div className="flex items-center gap-2 pl-4 pr-2 py-2 text-secondary text-sm font-semibold uppercase border-b border-[#E9ECF2]">
          TICKET TYPES
        </div>
      }
      />
  );
};
