import React from "react"

export default function TimeIcon({
    size = 20,
    color = '#79808A'
}: {
    readonly size?: number,
    readonly color?: string
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <g clipPath="url(#clip0_19760_362896)">
                <circle cx="8.11765" cy="8.11765" r="5.64706" stroke={color} strokeWidth="0.941176"/>
                <path d="M8 5.5C8 5.22386 7.77614 5 7.5 5C7.22386 5 7 5.22386 7 5.5V8.39702C7 8.73006 7.176 9.0383 7.46282 9.20757L10.1013 10.7647C10.3229 10.8955 10.6084 10.8252 10.7439 10.6065C10.8842 10.3802 10.8103 10.0827 10.5803 9.94849L8.46678 8.71504C8.17773 8.54635 8 8.23684 8 7.90217V5.5Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_19760_362896">
                <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}