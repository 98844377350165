import React from "react";
import PlusIcon from "../Icons/PlusIcon";

interface UploadImageProps {
  onDragOver: (e: React.DragEvent) => void;
  onDragLeave: (e: React.DragEvent) => void;
  onDrop: (e: React.DragEvent) => void;
  className?: string;
}

const Index: React.FC<UploadImageProps> = ({
  onDragLeave,
  onDragOver,
  onDrop,
  className,
}) => {
  return (
    <div
      className={`${className} z-10 bg-[#DAE6FFCC] flex flex-col items-center justify-center w-full h-full border-2 border-dashed border-[#5A8DF1] rounded`}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div className="relative flex flex-col items-center justify-center">
        <div className="border border-primary rounded p-4">
          <PlusIcon color="#5A8DF1" size={50} />
        </div>
        <div className="text-sm mt-4 text-secondary text-center">
          <span>
            Drop File here <br />
            to attach to this file
          </span>
        </div>
      </div>
    </div>
  );
};

export default Index;
