import Dropdown from "app/components/Dropdown";
import { CheckIcon, TagIcon } from "app/components/Icons";
import IconText from "app/components/IconText";
import clsx from "clsx";
import React, { useState } from "react";
import Badge from "./Badge";

type Option = {
  value: string;
  label: string;
  color: string;
};
const LabelSelection = () => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const MenuItems = options?.map((ele) => {
    if (ele)
      return {
        label: (
          <>
            <div
              className={`flex w-full items-center justify-between gap-4 p-1 hover:bg-gray-50 rounded-md transition-colors`}
            >
              <Badge className={ele.color} title={ele.label} />
              {selectedValues.includes(ele.value) && (
                <CheckIcon color="#5A8DF1" />
              )}
            </div>
          </>
        ),
        action: () => {
          setSelectedValues((prev) => {
            const newValues = prev.includes(ele.value)
              ? prev.filter((value) => value !== ele.value)
              : [...prev, ele.value];
            return newValues;
          });
        },
      };
    else return { label: "", action: () => {} };
  });
  return (
    <>
      <div className="w-[196px] border border-gray-100 border-r-0 border-t-0 h-[42px] flex flex-col justify-center pl-4 pr-2 rounded-t-none rounded-l-[4px]">
        <IconText
          icon={<TagIcon size={16} color="#79808A" />}
          text="Labels*"
          className="gap-[4px]"
          textClassName="text-[12px] leading-[18px] font-medium text-secondary"
        />
      </div>
      <div className="w-full !h-[42px]">
        <Dropdown
          trigger={
            <button className="flex flex-wrap gap-2 h-full w-full w-full data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A] border-[1px] border-t-0 border-gray-100 text-start px-3 text-[12px] leading-[18px] font-sm text-gray-400 items-center">
              {selectedValues.length > 0 ? (
                selectedValues.map((value) => {
                  const option = options.find((opt) => opt.value === value);
                  return (
                    <div className="flex " key={value}>
                      <Badge className={option?.color} title={option?.label} />
                    </div>
                  );
                })
              ) : (
                <span className="text-muted-foreground">-</span>
              )}
            </button>
          }
          projectInfoIcon={
            <div className="w-full text-md font-small text-gray-400 p-4 border-b border-gray-100">
              SELECT AN OPTIONS
            </div>
          }
          menuItems={MenuItems}
          align="start"
          className="h-60 overflow-y-auto"
          //   sideOffset={10}
        />
      </div>
    </>
  );
};

export default LabelSelection;

const options: Option[] = [
  {
    value: "option-1",
    label: "Label name",
    color: "bg-[#ff6b6b] text-white hover:bg-[#ff6b6b]/90",
  },
  {
    value: "option-2",
    label: "Label name",
    color: "bg-[#4dabf7] text-white hover:bg-[#4dabf7]/90",
  },
  {
    value: "option-3",
    label: "Label name",
    color: "bg-[#40c057] text-white hover:bg-[#40c057]/90",
  },
  {
    value: "option-4",
    label: "Label name",
    color: "bg-[#40c057] text-white hover:bg-[#40c057]/90",
  },
  {
    value: "option-5",
    label: "Label name",
    color: "bg-[#40c057] text-white hover:bg-[#40c057]/90",
  },
  {
    value: "option-6",
    label: "Label name",
    color: "bg-[#40c057] text-white hover:bg-[#40c057]/90",
  },
];
