import { UserIcon } from "app/components/Icons";
import Avatar from "app/containers/Features/components/Avatar";
import CheckListProgress from "app/containers/Features/components/CheckListProgress";
import circleCheckedIcon from "assets/svgs/circleCheckedIcon.svg";
import circleIcon from "assets/svgs/circleIcon.svg";
import historyIcon from "assets/svgs/ic_checklist.svg";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Ticket } from "../../../../types";
import { actions } from "../../../Tickets/redux/slice";
import CustomPopover from "./CustomPopover";

interface CheckListProps {
  data: Ticket;
  status: string;
}

const CheckList: React.FC<CheckListProps> = ({ data, status }) => {
  const [isCompleted, setIsCompleted] = useState(0);
  const [open, setOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const handleUpdate = (val) => {
    const newValue = !val;
    dispatch(
      actions.updateTicketActivity({
        status: status,
        ticket: data,
        value: newValue,
      })
    );
  };
  useEffect(() => {
    const isCompleted = data?.checkList?.filter((X) => X?.isCompleted)?.length;
    const totalCheckList = data?.checkList?.length;
    if (isCompleted === undefined) {
      setIsCompleted(0);
    } else {
      setIsCompleted(isCompleted);
    }
    if (totalCheckList === undefined) {
      setTotal(0);
    } else {
      setTotal(totalCheckList);
    }
    return () => {};
  }, [data.checkList]);

  return (
    <React.Fragment>
      <CustomPopover
        show={open}
        onClose={() => {
          setOpen(!open);
        }}
        trigger={
          <div
            className="flex flex-row items-center hover:bg-[#ECEEF2] hover:px-1 hover:py-[1px] rounded"
            onMouseEnter={() => {
              setOpen(true);
            }}
            onMouseLeave={() => {
              setOpen(false);
            }}
          >
            <div className="min-w-[16px]">
              <img src={historyIcon} alt="task-icon" className="size-[16px]" />
            </div>
            <div className="text-xs leading-[18px] text-secondary font-normal mt-[2px] ml-[2px]">{`${isCompleted}/${total}`}</div>
          </div>
        }
        content={
          <div className="flex flex-col ">
            <div className="sticky top-0 bg-white pt-4 flex gap-2 items-center">
              <p className="text-md font-medium text-[#253858]">Checklists</p>
              <CheckListProgress
                progress={
                  total === 0 ? 0 : (Number(isCompleted) / Number(total)) * 100
                }
              />
              <p className="text-xs leading-[21px] font-medium text-black">
                {`${isCompleted}/${total}`}
              </p>
            </div>
            <div className="border border-[#E9ECF2] rounded-t-[8px] bg-[#F4F5F7] flex gap-2 items-center h-12 p-4 mt-4">
              <p className="text-md font-medium text-[#253858]">Checklists</p>
              <p className="text-xs leading-[21px] font-medium text-black">
                {`${isCompleted}/${total}`}
              </p>
            </div>
            {data?.checkList?.map((ele, index) => {
              return (
                <div
                  key={index}
                  className={`flex items-center justify-between gap-2 cursor-pointer h-[48px] border border-t-o border-gray-100 group ps-4 pe-2`}
                >
                  <button
                    className={`focus:outline-none flex items-center gap-2`}
                    onClick={() => {
                      handleUpdate(ele?.isCompleted);
                    }}
                  >
                    <span className={`flex items-center`}>
                      {ele?.isCompleted ? (
                        <img
                          src={circleCheckedIcon}
                          alt="circleCheckedIcon"
                          sizes="20"
                        />
                      ) : (
                        <img src={circleIcon} alt="circleIcon" sizes="20" />
                      )}
                    </span>
                    <span
                      className={`ml-1 text-nowrap overflow-hidden text-ellipsis `}
                    >
                      {ele.name}
                    </span>
                  </button>

                  {ele?.assignee ? (
                    <Avatar name={ele?.assignee} showIconOnly />
                  ) : (
                    <UserIcon />
                  )}
                </div>
              );
            })}
          </div>
        }
        className="max-h-[300px] !pt-0"
      />
    </React.Fragment>
  );
};

export default CheckList;
