import Modal from "app/components/Modal"
import React, { useCallback } from "react"
import Textarea from "app/components/Icons/Textarea"
import Input from "app/components/Input"
import { useSelector } from "react-redux"
import { selectGroupForm } from "../../Tickets/redux/selector"
import { useDispatch } from "react-redux"
import { actions } from "../../Tickets/redux/slice"

interface AddTicketGroupModalProps {
    onClose: () => void
    isEditGroup: boolean
}

export default function AddTicketGroupModal({ onClose, isEditGroup }: AddTicketGroupModalProps) {
    const dispatch = useDispatch();
    const groupForm = useSelector(selectGroupForm);
    const isDisabled = useCallback(() => {
        if (groupForm.name.value === "") {
            return true;
        }
        return false;
    }, [groupForm.name.value]);

    const handleAddGroup = () => {
        dispatch(actions.createTicketGroup());
    }
    
    return (
        <Modal
            onClose={onClose}
            submitText="Save"
            isSubmitDisabled={isDisabled()}
            onSubmit={()=>{handleAddGroup()}}
            title={`${isEditGroup ? ' Edit group' : 'Add new group'}`}
            contentClassName="!p-0"
            modalClassName="max-w-[400px]"
        >
            <div className="flex flex-col px-[24px] py-4 pt-[12px] gap-4">
                <div className="flex flex-col gap-y-4">
                    <Input
                        placeholder="Enter group name"
                        id='name'
                        name='name'
                        inputClassName='!h-[48px] !gap-[0px]'
                        innerContainerClassName="!gap-[0px]"
                        defaultValue={groupForm.name.value}
                        error={groupForm.name.error}
                        onChange={(e) => {
                            dispatch(actions.setGroupFormValues({
                                name: e.target.name,
                                value: e.target.value,
                            })
                            );
                        }}
                    />
                    <Textarea
                        placeholder="Group description"
                        id='description'
                        name="description"
                        inputClassName='!gap-[0px]'
                        innerContainerClassName="!gap-[0px]"
                        value={groupForm.description.value}
                        error={groupForm.description.error}
                        maxLength={100}
                        onChange={(e) => {
                            dispatch(actions.setGroupFormValues({
                                name: e.target.name,
                                value: e.target.value,
                            })
                            );
                        }}
                    />
                </div>
            </div>
        </Modal>
    )
}