import React from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";
import DateOptions from "../AdaptiveDatePicker/DateOptions";
import DatePill from "../AdaptiveDatePicker/DatePill";
import { Calendar } from "../Calendar";

interface CustomProps {
  trigger: React.ReactNode;
  align?: "start" | "center" | "end";
  selectedDates: Date[];
  setSelectedDates: (dates: Date[]) => void;  
  placeholderStartDate?: string;
  placeholderEndDate?: string;
  className?: string;
}

const Index: React.FC<CustomProps> = ({
  trigger,
  align = "start",
  selectedDates,
  setSelectedDates,
  placeholderStartDate = "From date",
  placeholderEndDate = "Due date",
  className = "",
}) => {
  const [open, setOpen] = React.useState(false);

  const handleSelect = (dates: Date[] | undefined) => {
    setSelectedDates(dates ? dates.slice(0, 2) : []);
  };

  const formattedDates = selectedDates.map((date) =>
    date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
  );
  const clearStartDate = () => {
    setSelectedDates([selectedDates[1]].filter(Boolean) as Date[]);
  };

  // Clear End Date
  const clearEndDate = () => {
    setSelectedDates([selectedDates[0]].filter(Boolean) as Date[]);
  };
  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>{trigger}</PopoverTrigger>
        <PopoverContent
          align={align}
          className={`w-[377px] !px-0 !py-0 bg-white border border-[#E9ECF2] ${className}`}
        >
          <div className="flex gap-10 border-b-[#E9ECF2] ">
            <DatePill
              date={
                selectedDates[0] === undefined
                  ? placeholderStartDate
                  : formattedDates[0]
              }
              onClear={() => {
                clearStartDate();
              }}
              iconShow={selectedDates[0] !== undefined}
            />
            <DatePill
              date={
                selectedDates[1] !== undefined
                  ? formattedDates[1]
                  : placeholderEndDate
              }
              onClear={() => {
                clearEndDate();
              }}
              iconShow={selectedDates[1] !== undefined}
            />
          </div>
          <div className="flex items-center justify-between">
            <DateOptions />
            <Calendar
              mode="multiple"
              selected={selectedDates}
              onSelect={handleSelect}
              required={true}
              className="w-[200px] h-full"
            />
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default Index;
