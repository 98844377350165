export enum PLAN_TYPE {
  FREE = "free",
  BUSINESS = "business",
  PROFESSIONAL = "professional",
}

export interface FormElement {
  value: any;
  error: string;
}

export interface FormError {
  name: string;
  error: string;
}

export interface AuthorizedAccount {
  email: string;
  password: string;
}

export type Project = {
  id: string;
  name: string;
  icon: {
    avatar: string;
    color: string;
    backgroundColor: string;
  };
  owner: Owner;
  plan: PLAN_TYPE;
  statuses: Statuses[];
  members: TeamMember[];
  createdAt: string;
  storageUsage: number;
};

export enum Statuses {
  TODO = "TO DO",
  IN_PROGRESS = "IN PROGRESS",
  DONE = "DONE",
  REOPENED = "REOPENED",
  IN_CODE_REVIEW = "IN CODE REVIEW",
  ABANDONED = "ABANDONED",
  IN_STAGING = "IN STAGING",
  TESTING_IN_PRODUCTION = "TESTING IN PRODUCTION",
}

export type Owner = {
  id: string;
  name: string;
  avatar: string;
  isOnline: boolean;
};

export type TeamForm = {
  name: FormElement;
  project: {
    name: FormElement;
    defaultCustomization: {
      agileDevelopmentAndSprints: boolean;
      epicsAndStories: boolean;
      releaseManagement: boolean;
    };
  };
  members: TeamMemberType[];
  plan: PLAN_TYPE;
  coupon: FormElement;
  isLoading: boolean;
};

export interface User {
  _id: string;
  email: string;
  fullName?: string;
  companySize?: string;
  referalSource?: string;
  toolPreferences?: string[];
  team?: Team;
  initials: string;
  role: string;
  status: string;
  lastLogin: string;
  localTime: string;
  location: string;
  spokenLanguages: string[];
  creationDate: string;
  mobileNumber: string;
  members: TeamMember[];
}

export interface Team {
  name: string;
  members: TeamMember[];
  plan: PLAN_TYPE;
}

export interface TeamMember {
  status?: string;
  email?: string;
  localTime?: string;
  name?: string;
  role?: string;
  id?: string;
  team?: string;
  lastLogin?: string;
  location?: string;
  spokenLanguages?: string;
  mobileNumber?: string;
  creationDate?: string;
}

export type Option = {
  label: string;
  value: string;
  description?: string;
  icon?: React.ReactNode;
  searchText?: string;
  tick?: string;
  role?: string;
};

export type TeamMemberType = {
  name: FormElement;
  role: FormElement;
};

export enum userOnboardingStage {
  CODE_VERIFICATION = "CODE_VERIFICATION",
  CREATE_PROFILE = "CREATE_PROFILE",
  ON_BOARDING_INFO = "ON_BOARDING_INFO",
  CREATE_TEAM = "CREATE_TEAM",
  CREATE_PROJECT = "CREATE_PROJECT",
  PROJECT_MEMBER_CREATION = "PROJECT_MEMBER_CREATION",
  PLAN_SELECTION = "PLAN_SELECTION",
  COMPLETED = "COMPLETED",
}
export enum TeamRole {
  TEAM_OWNER = "Team Owner",
  ADMINISTRATOR = "Administrator",
  PROJECT_MANAGER = "Project Manager",
  SCRUM_MASTER = "Scrum Master",
  PRODUCT_OWNER = "Product Owner",
  DEVELOPER = "Developer",
  QA_TESTER = "QA Tester",
  DESIGNER = "Designer",
  TEAM_MEMBER = "Team Member",
  GUEST_USER = "Guest User",
  ANALYST = "Analyst",
  CUSTOMER_SUCCESS_MANAGER = "Customer Success Manager",
  STAKEHOLDER = "Stakeholder",
}
export interface IUserData {
  _id: string;
  fullName: string;
  email: string;
  role: string;
  lastLoginDate: string;
  userVerifications: [];
  profilePic: string;
  googleId: string;
  userOnboardingProgress: userOnboardingStage;
  timeZone: string;
  questions: string;
}

type ISODateString = Date;

export interface IStorageUsage {
  current: number;
  total: number;
}
export enum TeamPlanType {
  FREE = "free",
  BUSINESS = "business",
  PROFESSIONAL = "professional",
}

export enum TeamStatus {
  ACTIVE = "active",
  TODO = "TO DO",
  IN_PROGRESS = "IN PROGRESS",
  DONE = "DONE",
  REOPENED = "REOPENED",
  IN_CODE_REVIEW = "IN CODE REVIEW",
  ABANDONED = "ABANDONED",
  IN_STAGING = "IN STAGING",
  TESTING_IN_PRODUCTION = "TESTING IN PRODUCTION",
}

export enum ProjectStatus {
  ACTIVE = "Active",
  IN_ACTIVE = "Inactive",
}

export enum MemberStatus {
  ACTIVE = "Active",
  INVITATION_PENDING = "Invitation pending",
  INVITATION_REJECTED = "Invitation rejected",
}

export interface IIcon {
  initials: string;
  color: string;
  bgColor: string;
}

export interface IMember {
  id: string;
  name: string;
  // icon: IIcon;
  email: string;
  role: string;
  // status: MemberStatus;
  // lastLogin: string;
  // hours?: string;
  // date?: string;
}

export interface IProjectProgress {
  current: number;
  total: number;
}
export enum BoardType {
  Backlog = "Backlog",
  QADashboard = "QA Dashboard",
  DevBoard = "Dev Board",
  Custom = "Custom",
  HOME="Home"
}
export enum BoardViewType {
  KANBAN = "Kanban",
  LIST = "List",
}
export interface IBoard {
  _id: string;
  name: "BACKLOG";
  viewType: BoardViewType;
  order: "";
  Type: BoardType;
}

export interface IComponent {
  id: string;
  name: string;
  lead?: IMember;
  defaultAssignee?: IMember;
  description?: string;
  isArchived: boolean;
  creationDate: ISODateString;
}
export interface IUserTeams {
  _id: string;
  teamName: string;
  teamAvatar: string;
  projects: IUserTeamsProjects[];
  teamMembers: IMember[];
  icon: IIcon;
  // owner: IMember;
  // plan: TeamPlanType;
  // statuses: TeamStatus[];
  // members: IMember[];
  // creationDate: ISODateString;
  // storageUsage: IStorageUsage;
}
export interface IUserTeamsProjects {
  _id: string;
  projectName: string;
  status: ProjectStatus;
  icon: IIcon;
  // owner?: IMember;
  // projectLead?: IMember;
  // defaultAssignee?: IMember;
  // isHidden: boolean;
  // isArchived: boolean;
  // progress: IProjectProgress;
  // members?: IMember[];
  boards?: IBoard[];
  // boards?:[{ name: "Tickets" }, { name: "Sprints" }, { name: "Releases" }];
  // features: string[];
  // components: IComponent[];
  // description?: string;
  // creationDate: ISODateString;
  // startDate?: ISODateString;
  // endDate?: ISODateString;
}
export interface IUserTeamsMembers {
  _id: string;
  email: string;
  role: TeamRole;
}
export interface IUserBasicDetails {
  _id: string;
  fullName: string;
  email: string;
  isActive: boolean;
  lastLoginDate: string;
  profilePic: string;
  userOnboardingProgress: userOnboardingStage;
  timeZone: string;
  teams: IUserTeams[];
  teamIds: string[];
}
export enum sortOrder {
  ASC = "asc",
  DESC = "desc",
}
export enum BoardTemplateEnum {
  AGILE_DEVELOPMENT = "Agile Development",
  BUG_TRACKING = "Bug Tracking",
  PRODUCT_ROADMAP = "Product Roadmap",
  RELEASE_MANAGEMENT = "Release Management",
  DESIGN_AND_PROTOTYPING = "Design and Prototyping",
  GENERAL_PROJECT_MANAGEMENT = "General Project Management",
}
export interface IBoardFixedTemp {
  templateType: BoardTemplateEnum
  projectId: string
  teamId:string
}
export interface AppHomeState {
  userFormData: IUserData; // user initial data
  isAuthenticated: boolean;
  authorizedAccounts: AuthorizedAccount[];
  user: User | null;
  loading: boolean;
  error: string | null;
  drawerOpen: boolean;
  drawerPayload?: any;
  teamForm: TeamForm;
  teamFormActiveIndex: number;
  project: Project | null;
  userOnboardingProgress: userOnboardingStage | null;
  access_token: string | null;

  userDetails: IUserBasicDetails;
  selectedTeam: IUserTeams | null;
  selectedProject: IUserTeamsProjects | null;
  isBoardCreate:boolean
}


