import React from "react";

interface NotesIconProps {
    size?: number;
    color?: string;
}

export const NotesIcon = ({ size = 16, color = "#79808A" }: NotesIconProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#clip0_17559_1144035)">
                <path d="M12.4401 13.3385L2.66233 13.3385L2.66233 3.56076L8.53788 3.56076L9.42677 2.67187L2.66233 2.67188C2.42658 2.67188 2.20049 2.76553 2.03379 2.93222C1.86709 3.09892 1.77344 3.32502 1.77344 3.56076L1.77344 13.3385C1.77344 13.5743 1.86709 13.8004 2.03379 13.9671C2.20049 14.1338 2.42658 14.2274 2.66233 14.2274L12.4401 14.2274C12.6759 14.2274 12.9019 14.1338 13.0686 13.9671C13.2353 13.8004 13.329 13.5743 13.329 13.3385L13.329 6.67188L12.4401 7.56076L12.4401 13.3385Z" fill="#79808A"/>
                <path d="M14.9011 2.59868L13.4033 1.1009C13.3369 1.03425 13.2579 0.981364 13.171 0.945281C13.084 0.909198 12.9908 0.890625 12.8967 0.890625C12.8025 0.890625 12.7093 0.909198 12.6224 0.945281C12.5354 0.981364 12.4565 1.03425 12.39 1.1009L6.29667 7.22979L5.80334 9.36757C5.78232 9.47119 5.78453 9.57819 5.80981 9.68086C5.83509 9.78353 5.8828 9.87932 5.94953 9.96135C6.01625 10.0434 6.10033 10.1096 6.1957 10.1552C6.29108 10.2009 6.39538 10.2248 6.50112 10.2253C6.55577 10.2313 6.61091 10.2313 6.66556 10.2253L8.82112 9.74979L14.9011 3.61201C14.9678 3.54554 15.0207 3.46657 15.0567 3.37963C15.0928 3.29269 15.1114 3.19948 15.1114 3.10534C15.1114 3.01121 15.0928 2.918 15.0567 2.83106C15.0207 2.74412 14.9678 2.66515 14.9011 2.59868ZM8.3589 8.92757L6.73223 9.28757L7.11001 7.67423L11.6967 3.05646L12.95 4.30979L8.3589 8.92757ZM13.4522 3.80757L12.1989 2.55423L12.8878 1.85201L14.15 3.11423L13.4522 3.80757Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_17559_1144035">
                    <rect width={size} height={size} fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
};
