import React, { useEffect } from 'react'
import { TICKET_HEADERS } from 'app/containers/Features/containers/Boards/data'
import StatusIcon from 'app/components/Icons/StatusIcon'
import TicketGroup from 'app/containers/Features/containers/Boards/components/TicketGroup'
import Row from '../../../Home/containers/components/Row';
import { useDispatch } from 'react-redux';
import { selectTicketGroups } from '../../redux/selector';
import { useSelector } from 'react-redux';
import { actions } from '../../redux/slice';
import { selectBoardsFormData } from '../../../../redux/selector';
import { GroupByTypeEnum } from '../../../../types';
import { TableHeader } from 'app/components/Table/types';
import {actions as listAction} from '../../../../redux/slice';
import { selectSelectedTickets } from '../../../../redux/selector';

export default function List() {
    const dispatch = useDispatch();
    const groups = useSelector(selectTicketGroups);
    const boardData = useSelector(selectBoardsFormData);
    const selectedTickets = useSelector(selectSelectedTickets);
    useEffect(() => {
        dispatch(actions.fetchTicketGroups());
    }, [dispatch]);

    const handleDrop = (ticketId: string, targetStatus: string) => {
        // Find the source status by looking through all groups
        const sourceGroup = groups.find(group => 
            group.tickets.some(ticket => ticket.id === ticketId)
        );
        
        if (!sourceGroup || sourceGroup.status.name === targetStatus) return;

        dispatch(actions.updateTicketStatus({
            ticketId,
            sourceStatus: sourceGroup.status.name,
            targetStatus
        }));
    };
    const handleToggleSelection = (ticketId: string) => {
        dispatch(listAction.toggleSelectTicket(ticketId));
      };

    const updatedColumns = boardData.customizeCard
      .filter((x) => x.isVisible && x.type !== GroupByTypeEnum.TAGS)
      .map((field) => {
        // Check if field already exists in tableColumns

        const existingColumn = TICKET_HEADERS.find(
          (col) => col.name === field.label
        );
        return (
          existingColumn || {
            name:
              field.type === GroupByTypeEnum.TICKET_NAME ? "Name" : field.label,
            id: field.type,
            resizable: true,
            className: "!p-[4px] text-xs leading-[18px] font-medium",
            width:'150'
          }
        );
      });

    

    return (
        <div className="pl-[38px]">         
            {
                groups.map((group) => (
                    <TicketGroup 
                        key={group.status.name}
                        icon={<StatusIcon color={group.status.color} />}
                        title={group.status.name}
                        count={group.tickets.length}
                        headers={updatedColumns as TableHeader[]}
                        color={group.status.color}
                        onDrop={(ticketId) => handleDrop(ticketId, group.status.name)}
                        groupStatus={group.status}
                        maxCount={group?.status?.ticketLimit}
                        group={group}
                    >
                        {
                            group.tickets.map((ticket) => (
                                <Row key={ticket.id} ticket={ticket} 
                                td={updatedColumns as any}
                                isSelected={selectedTickets.includes(ticket.id)}
                                onCheckboxClick={handleToggleSelection}
                                />
                            ))
                        }
                    </TicketGroup>
                ))
            }
        </div>
    )
}