import React, { useCallback, useRef } from "react";
import Input from "app/components/Input";
import { Link, useNavigate } from "react-router-dom";
import Button from "app/components/Button";
import rightArrow from "assets/svgs/ic_right_arrow_primary.svg";
import { selectIsGoogleLogin, selectLoginForm } from "../redux/selector";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../redux/slice";
import { actions as appActions } from "../../../redux/slice";
import { validateLoginForm } from "../helpers";
import EmailIcon from "app/components/Icons/EmailIcon";
import PasswordIcon from "app/components/Icons/PasswordIcon";
import Logo from "app/components/Icons/Logo";
import { GoogleIcon } from "app/components/Icons/GoogleIcon";
import { auth, signInWithGoogle } from "utils/firebase";
import { userOnboardingStage } from "app/types";

export default function Login() {
  let unsubscribeFromAuth;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const loginForm = useSelector(selectLoginForm);
  const isGoogleLogin = useSelector(selectIsGoogleLogin);
 
  React.useEffect(() => {
    if (token) {
      navigate('/app/home')
    }
  
    return () => {
      
    }
  }, [token])
  


  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const errors = validateLoginForm(loginForm);
    if (errors.length > 0) {
      dispatch(actions.setLoginFormErrors(errors));
      return;
    }
    dispatch(
      actions.loginRequest({
        callback(nextPage) {
          dispatch(appActions.doGetMyProfile());
          
          userLoginRedirection(nextPage);
          if (nextPage === userOnboardingStage.COMPLETED) {
            dispatch(appActions.doGetUserWorkspace());
          }
        },
      })
    );
  };

  const isDisabled = useCallback(() => {
    if (loginForm.email.value === "" || loginForm.password.value === "") {
      return true;
    }
    return false;
  }, [
    loginForm.email.error,
    loginForm.password.error,
    loginForm.email.value,
    loginForm.password.value,
  ]);
  const userLoginRedirection = (key: userOnboardingStage) => {
    switch (key) {
      case userOnboardingStage.CODE_VERIFICATION:
        return navigate("/auth/login");
      case userOnboardingStage.COMPLETED:
        return navigate("/app/home");
      case userOnboardingStage.CREATE_PROFILE:
        return navigate("/onboarding/create-profile");
      case userOnboardingStage.CREATE_TEAM:
        return navigate("/onboarding/team");
      case userOnboardingStage.ON_BOARDING_INFO:
        return navigate("/onboarding/setup");
      default:
        return navigate("/auth/login");
    }
  };
  React.useEffect(() => {
    unsubscribeFromAuth = auth.onAuthStateChanged((user: any) => {
      if (user) {
        responseGoogle(user.providerData);
      }
    });

    return () => {
      unsubscribeFromAuth();
      auth.signOut();
    };
  }, []);
  const responseGoogle = (response: any) => {
    let googleData = {
      email: response[0].email,
      fullName: response[0].displayName,
      profilePic: response[0].photoURL,
      googleId: response[0].uid,
    };

    dispatch(
      actions.doGoogleLogin({
        data: googleData,
        callback: (nextPage: userOnboardingStage, fullName, token) => {
          localStorage.setItem("accessToken", token);
          if (nextPage === userOnboardingStage.CREATE_PROFILE) {
            navigate(
              `/onboarding/create-profile?isGoogleLogin=true&userName=${fullName}`
            );
          } else if (nextPage === userOnboardingStage.COMPLETED) {
            navigate(`/app/home`);
            dispatch(appActions.doGetMyProfile());
            dispatch(appActions.doGetUserWorkspace());
          }
        },
      })
    );
  };
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center gap-[20px] mb-[56px]">
        <Logo size={72} />
        <h1 className="text-[40px] leading-[50px] font-semibold mb-4 text-center">
          Welcome back
        </h1>
      </div>
      <form className="flex flex-col gap-[20px] w-full min-w-[390px] mb-[56px]">
        <Input
          id="email"
          label="Email"
          type="email"
          name="email"
          placeholder="Enter email address"
          icon={<EmailIcon />}
          inputRef={emailInputRef}
          defaultValue={loginForm.email.value}
          error={loginForm.email.error}
          disabled={loginForm.isLoading}
          onChange={(e) => {
            dispatch(
              actions.setLoginFormValues({
                name: e.target.name,
                value: e.target.value,
              })
            );
          }}
        />
        <div>
          <Input
            id="password"
            label="Password"
            type="password"
            name="password"
            placeholder="Password"
            icon={<PasswordIcon />}
            inputRef={passwordInputRef}
            defaultValue={loginForm.password.value}
            error={loginForm.password.error}
            disabled={loginForm.isLoading}
            onChange={(e) => {
              dispatch(
                actions.setLoginFormValues({
                  name: e.target.name,
                  value: e.target.value,
                })
              );
            }}
          />
          <div className="flex justify-end mt-[6px]">
            <Link
              to="/auth/forgot-password"
              className="text-right text-sm text-primary"
            >
              Forgot password?
            </Link>
          </div>
        </div>
        <div className="flex flex-col gap-[10px] items-center justify-between">
          <Button
            disabled={isGoogleLogin ? isGoogleLogin : isDisabled()}
            onClick={handleSubmit}
            isLoading={loginForm.isLoading}
            className="w-full h-[56px]"
          >
            Log in
          </Button>
          <span className="text-base leading-[24px] text-secondary">or</span>
          <Button
            className="w-full h-[56px] flex items-center justify-center bg-white border border-secondary text-secondary"
            type="button"
            isLoading={isGoogleLogin}
            onClick={() => {
              signInWithGoogle().catch((error: any) => {});
            }}
          >
            <div className="flex items-center gap-[10px]">
              <GoogleIcon size={24} />
              <span className="text-base font-medium leading-[22.4px] text-secondary">
                Continue with Google
              </span>
            </div>
          </Button>
        </div>
      </form>
      <div className="flex flex-col items-center gap-[16px]">
        <p className="text-secondary text-md leading-[24px] flex flex-row items-center gap-[6px]">
          Don’t have an account?{" "}
          <Link
            to="/auth/signup"
            className="text-primary text-md leading-[24px] font-medium flex flex-row items-center"
          >
            Sign up <img sizes="16px" src={rightArrow} alt="Arrow" />
          </Link>
        </p>
      </div>
    </div>
  );
}
