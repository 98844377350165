import React from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { useDispatch } from "react-redux";
import { actions } from "app/redux/slice";
import { useNavigate } from "react-router-dom";

// Define the type for a single menu item in the dropdown
interface MenuItem {
  label: React.ReactNode;
  action: string | (() => void);
  isDisabled?: boolean;
  icon?: string | React.ReactNode;
  isUnPaddedX?: boolean;
  isUnPaddedY?: boolean;
}

interface DropdownProps {
  trigger: React.ReactNode;
  menuItems: MenuItem[];
  userInfo?: {
    initials?: React.ReactNode |string;
    name?: string;
    color?: string;
  };
  projectInfoIcon?: React.ReactNode;
  align?: "start" | "center" | "end";
  sideOffset?: number;
  className?: string;
  dropDownClassName?: string;
  dropdownContainerClassName?: string;
  addSubContent?: React.ReactNode;
  setOpen?: ()=>void;
  open?: boolean;

}

const Dropdown: React.FC<DropdownProps> = ({
  trigger,
  menuItems,
  userInfo,
  projectInfoIcon,
  align = "start",
  sideOffset = 5,
  className = "",
  dropDownClassName = "",
  dropdownContainerClassName = "",
  addSubContent,
  setOpen,
  open
}) => {
  const dispatch = useDispatch();
const navigate = useNavigate();
  const handleMenuItemClick = (action: string | (() => void)) => {
     
    if (action === "profile") {
      dispatch(actions.openDrawer({ section: "profile" }));
    } else if (action === "logout") {
      dispatch(actions.onLogout());
      navigate('/')
    } else if (typeof action === "function") {
      action();
    }
  };

  return (
    <DropdownMenu.Root open={ open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger asChild>{trigger}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align={align}
          sideOffset={sideOffset}
          side="bottom"
          className={`bg-white shadow-lg rounded-md z-50 w-64 border border-[#E9ECF2]  ${className}`}
        >
          {userInfo && (
            <div className="px-4 py-2 border-b border-gray-100">
              <div className="flex items-center gap-3">
                <div className="relative">
                  <div
                    style={{ backgroundColor: userInfo.color }}
                    className="text-white w-8 h-8 rounded-full flex items-center justify-center text-sm font-medium"
                  >
                    {userInfo.initials}
                  </div>
                  <div className="w-[8px] h-[8px] bg-[#6FCF97] rounded-full absolute bottom-0 right-0" />
                </div>
                <div>
                  <div className="font-medium text-sm">
                    {userInfo.name || "John Doe"}
                  </div>
                  <div className="text-xs text-green-500">Online</div>
                </div>
              </div>
            </div>
          )}
          {projectInfoIcon && <>{projectInfoIcon}</>}
          <div className={`${dropdownContainerClassName}`}>
            {menuItems.map((item, index) => (
              <DropdownMenu.Item
                key={index}
                onClick={() =>
                  !item.isDisabled && handleMenuItemClick(item.action)
                }
                disabled={item.isDisabled}
                className={`${dropDownClassName} ${
                  item.isUnPaddedX ? "" : "px-4"
                } ${
                  item.isUnPaddedY ? "" : "py-2"
                } flex w-full items-center gap-3 text-sm text-gray-700 cursor-pointer ${
                  item.isDisabled
                    ? "cursor-not-allowed text-gray-400"
                    : "hover:bg-gray-100"
                } outline-none`}
              >
                {item.icon && (
                  <img
                    src={item.icon as string}
                    alt="icon"
                    className="w-6 h-6 text-gray-500 border border-[#E9ECF2] rounded-md"
                  />
                )}

                {item.label}
              </DropdownMenu.Item>
            ))}
          </div>
          {addSubContent && <>{ addSubContent}</>}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default Dropdown;
