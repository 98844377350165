import { FormElement, FormError } from "app/types";
import { PLAN_TYPE, ProfileForm, TeamMember } from "./types";
import basecamp from "assets/svgs/ic_basecamp.svg";
import monday from "assets/svgs/ic_monday.svg";
import trello from "assets/svgs/ic_trello.svg";
import jira from "assets/svgs/ic_jira.svg";
import asana from "assets/svgs/ic_asana.svg";
import notion from "assets/svgs/ic_notion.svg";
import excel from "assets/svgs/ic_excel.svg";
export const validateProfileForm = (
  form: Omit<ProfileForm, "isLoading">,
  isGoggleLogin?: boolean
) => {
  const errors: FormError[] = [];
  if (isGoggleLogin) {
    if (!form.name.value) {
      errors.push({
        name: "name",
        error: "Name is required",
      });
    }
  } else {
    if (!form.name.value) {
      errors.push({
        name: "name",
        error: "Name is required",
      });
    }
    if (!form.timezone.value) {
      errors.push({
        name: "timezone",
        error: "Timezone is required",
      });
    }

    if (!form.password.value) {
      errors.push({
        name: "password",
        error: "Password is required",
      });
    }

    if (!form.confirmPassword.value) {
      errors.push({
        name: "confirmPassword",
        error: "Confirm password is required",
      });
    }

    if (form.password.value !== form.confirmPassword.value) {
      errors.push({
        name: "confirmPassword",
        error: "Passwords do not match",
      });
    }
  }

  return errors;
};

export const validatePasswordField = (password) => {
  const errors: FormError[] = [];
  if (password.value.length < 8) {
    errors.push({
      name: "password",
      error: "Password must be at least 8 characters",
    });
  }

  if (!/(?=.*[a-z])/.test(password.value)) {
    errors.push({
      name: "password",
      error: "Please use at least one lowercase letter",
    });
  }
  if (!/(?=.*[A-Z])/.test(password.value)) {
    errors.push({
      name: "password",
      error: "Please use at least one capital letter",
    });
  }
  if (!/(?=.*\d)(?=.*[!@#$%^&*])/.test(password.value)) {
    errors.push({
      name: "password",
      error: "Please use at least one number or special character",
    });
  }
  return errors;
};

export const validateTeamFormName = (name: FormElement) => {
  const errors: FormError[] = [];

  if (!name.value) {
    errors.push({
      name: "name",
      error: "Name is required",
    });
  }

  return errors;
};

export const validateTeamFormMembers = (members: TeamMember[]) => {
  const errors: FormError[] = [];

  if (members.length > 0) {
    members.forEach((member, index) => {
      if (!member.name.value) {
        errors.push({
          name: `name-${index}`,
          error: "Member name is required",
        });
      }
      if (!member.role.value) {
        errors.push({
          name: `role-${index}`,
          error: "Member role is required",
        });
      }
    });
  }

  return errors;
};

export const validateTeamFormPlan = (plan: PLAN_TYPE) => {
  const errors: FormError[] = [];

  if (!plan) {
    errors.push({
      name: "plan",
      error: "Plan is required",
    });
  }
};

// Define the local icon map
const localIconMap = {
  todolist: monday,
  wrike: monday,
  basecamp: basecamp,
  confluence: monday,
  monday: monday,
  trello: trello,
  jira: jira,
  asana: asana,
  notion: notion,
  excel: excel,
};

// Function to map the payload and set local icons
export const userToolList = (tools = []) => {
  if (!Array.isArray(tools)) {
    return [];
  }
  return tools.map((tool: any) => ({
    _id: tool?._id,
    name:
      tool?.name.charAt(0).toUpperCase() + tool?.name.slice(1).toLowerCase(),
    icon: localIconMap[tool?.icon],
  }));
};
