import React, { useEffect } from 'react'
import Layout from '../../components/Layout'
import Cards from './components/Cards'
import List from './components/List'
import { useDispatch } from 'react-redux';
import { actions } from '../../redux/slice';
export default function Home() {
const dispatch = useDispatch();
useEffect(() => {
    dispatch(actions.fetchBoardsHomePage());
}, []);

    return (
        <Layout>
            <div className="min-w-max">
                <Cards />
                <List/>
            </div>
        </Layout>
    )
}
