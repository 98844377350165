import React from "react";

interface CustomProps {
  readonly className?: string;
  readonly title?: string;
}

const Badge: React.FC<CustomProps> = ({ className, title }) => {
  return (
    <div className={`${className} text-white font-sm rounded px-3 py-1 `}>
      {title}
    </div>
  );
};

export default Badge;
