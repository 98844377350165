import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import Pill from "app/components/Pill";
import Avatar from "app/containers/Features/components/Avatar";
import priorityIcon from "assets/svgs/ic_flag_green.svg";
import { useQueryState } from "nuqs";
import React, { useEffect, useRef, useState } from "react";
import { ITag, Ticket } from "../../../../types";
import TicketActions from "../../components/TicketActions";
import { getTicketIcon } from "../helpers";
import { TableHeader } from "app/components/Table/types";
import TagSelection from "../../components/ColumnCustomize/TagSelection";
import RowCustomize from "../../components/RowCustomize";

import CheckboxIcon from "app/components/Icons/CheckboxIcon";
import CheckboxActiveIcon from "app/components/Icons/CheckboxActiveIcon";
import DragIcon from "app/components/Icons/DragIcon";
import RowMenuOptions from "../../../Backlog/components/RowMenuOptions";

interface RowProps { 
  readonly ticket: Ticket;
  readonly td?: TableHeader[];
  isSelected: boolean,
  onCheckboxClick: (ticketId: string) => void
}

export default function Row({ td,ticket,isSelected,onCheckboxClick}: RowProps) {
  const [, setTid] = useQueryState("tid");
  const rowRef = useRef<HTMLTableRowElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = React.useState(false);

  useEffect(() => {
    if (!rowRef.current) return;

    const dragCleanup = draggable({
      element: rowRef.current,
      getInitialData: () => ({ ticketId: ticket.id }),
      onDragStart: () => setIsDragging(true),
      onDrag: () => setIsDragging(true),
    });

    const dropCleanup = dropTargetForElements({
      element: rowRef.current,
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => {
        setIsDraggedOver(false);
        setIsDragging(false);
      },
    });

    return () => {
      dragCleanup();
      dropCleanup();
    };
  }, [ticket.id]);

  return (
    <tr
      ref={rowRef}
      className={`h-[36px] border-b border-[#E9ECF2] cursor-pointer group hover:bg-[#E7F1FF]
                ${isDragging ? "border-2 border-[#5A8DF1]" : ""}
                ${isDraggedOver ? "border-b-2 border-b-[#5A8DF1]" : ""}`}
    >
      <td className="p-[4px] max-w-[739px]">
        <div className="flex flex-row items-center gap-[4px] relative p-[4px]">
          <div className="min-w-[20px]">
              {isSelected ? (
                          <div
                            className="absolute z-10 left-[-44px] flex flex-row items-center opacity-100"
                            onClick={(e) => {
                              e.stopPropagation();
                              onCheckboxClick(ticket.id);
                            }}
                          >
                            <CheckboxActiveIcon />
                          </div>
                        ) : (
                          <div
                            className="absolute z-10 left-[-70px] flex flex-row items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <button className="pl-1 hover:bg-gray-200 rounded">
                              <DragIcon />
                            </button>
                            <button
                              className="p-1 hover:bg-gray-200 rounded"
                              onClick={(e) => {
                                e.stopPropagation();
                                onCheckboxClick(ticket.id);
                              }}
                            >
                              <CheckboxIcon />
                            </button>
                            <RowMenuOptions
                              ticketId={ticket.id}
                              handleDuplicate={() => {}}
                              handleArchive={() => {
                                setShowArchiveModal(true);
                              }}
                            />
                          </div>
                        )}
            <img
              src={getTicketIcon(ticket?.type)}
              alt="task-icon"
              className="size-[20px]"
            />
          </div>
          <span className="text-sm leading-[18px] font-normal text-black">
            {ticket?.id}
          </span>
          <div
            className="text-sm leading-[18px] font-normal text-black text-ellipsis overflow-hidden whitespace-nowrap"
            onClick={(e) => {
              e.stopPropagation();
              setTid(ticket.id);
            }}
          >
            {ticket?.name}
          </div>
          <TicketActions ticket={ticket} status={""} />
          <div className="flex flex-row items-center gap-[4px]">
          <TagSelection ticketTag={ticket?.labels as ITag[]} />
          </div>
        </div>
      </td>
      <RowCustomize td={td ? td as TableHeader[] : []} ticket={ticket} />
    </tr>
  );
}
