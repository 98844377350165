import Button from "app/components/Button";
import Dropdown from "app/components/Dropdown";
import DropdownIcon from "app/components/Icons/DropdownIcon";
import PlusIcon from "app/components/Icons/PlusIcon";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { TicketTypeOptions } from "../data";
import { actions } from "../redux/slice";
import { TicketType } from "../types";
import AddTaskModal from "./AddTaskModal";

export default function CreateTaskButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const MenuItems = TicketTypeOptions?.map((ele) => {
    if (ele)
      return {
        label: (
          <>
            <div className={`flex items-start gap-3 p-1 w-[100%]`}>
              <div className="min-w-[20px]">
                <img src={ele?.icon} alt="task-icon" className="size-[20px]" />
              </div>

              <div className="text-sm leading-[18px] font-normal text-black text-ellipsis overflow-hidden whitespace-nowrap">
                {ele?.label} {ele?.type === TicketType?.TASK && "(Default)"}
              </div>
            </div>
          </>
        ),
        action: () => {
          handleSelect("type", ele?.type);
          setIsModalOpen(true);
        },
      };
    else return { label: "", action: () => {} };
  });
  const handleSelect = (name: string, value) => {
    dispatch(actions.setTicketFormValue({ name: name, value: value }));
  };
  return (
    <div className="flex flex-col">
      <div className="flex">
        <Button
          onClick={() => setIsModalOpen(true)}
          className="text-secondary !p-0 h-[42px] text-sm leading-[21px] font-normal flex flex-row items-center hover:bg-[#4288E8] !rounded-r-none"
        >
          <div className="flex flex-row items-center gap-[4px] px-[10px]">
            <PlusIcon color="#FFFFFF" />
            <span className="text-sm leading-[21px] font-normal text-white mt-[2px]">
              Task
            </span>
          </div>
        </Button>
        <Dropdown
          trigger={
            <button className="relative bg-primary text-white rounded-md px-4 py-2 focus:outline-none transition-all duration-300 text-secondary !p-0 h-[42px] text-sm leading-[21px] font-normal flex flex-row items-center hover:bg-[#4288E8] !rounded-l-none border-l border-[rgba(255,255,255,0.2)]">
              <DropdownIcon color="#FFFFFF" />
            </button>
          }
          projectInfoIcon={
            <div className="pt-2 flex items-center gap-2 pl-4 pr-2 py-2 text-secondary text-sm font-semibold uppercase border-b border-[#E9ECF2]">
              TICKET TYPES
            </div>
          }
          align="end"
          menuItems={MenuItems}
          className="!w-[200px] py-2"
        />
      </div>
      {isModalOpen && <AddTaskModal onClose={() => setIsModalOpen(false)} />}
    </div>
  );
}
