import { ITag } from "../Boards/types";


export const ACTIVITIES = [
    {
        id: 1,
        type: "assignment",
        description: "Ticket has been assigned to Eric Lorens by John Snow.",
        createdAt: "Just now",
    },
    {
        id: 2,
        type: "status",
        description: "Status New has been added by John Snow.",
        createdAt: "Today, 2:10 pm",
    },
    {
        id: 3,
        type: "comment",
        description: "The comment has been removed by John Snow.",
        createdAt: "Yesterday, 6:10 pm",
    },
    {
        id: 4,
        type: "ticket",
        description: "Tag internal bug has been added by John Snow.",
        createdAt: "15 Sep, 11:10 am",
    },
    {
        id: 5,
        type: "workflow",
        description: "Dependency added by John Snow: Ticket “There is no confirmation message displayed when OTP is required” now depends on “There is no confirmation message displayed when OTP is required”.",
        createdAt: "15 Sep, 11:10 am",
    },
    {
        id: 6,
        type: "priority",
        description: "Priority Normal has been added by John Snow.",
        createdAt: "15 Sep, 11:10 am",
    },
    {
        id: 7,
        type: "dueDate",
        description: "Due date 30 Sep, 2024 has been added by John Snow.",
        createdAt: "15 Sep, 11:10 am",
    },
    {
        id: 8,
        type: "estimation",
        description: "Estimation 40 hours have been added by John Snow.",
        createdAt: "14 Sep, 3:10 pm",
    },
    {
        id: 9,
        type: "description",
        description: "Ticket description has been added by John Snow.",
        createdAt: "14 Sep, 3:10 pm",
    },
    {
        id: 10,
        type: "linking",
        description: "Ticket “There is no confirmation message displayed when OTP is required” linked to ticket “There is no confirmation message displayed when OTP is required” by John Snow.",
        createdAt: "14 Sep, 3:10 pm",
    },
    {
        id: 11,
        type: "release",
        description: "Header field “Release name” has been turned on by John Snow.",
        createdAt: "14 Sep, 3:10 pm",
    },
    {
        id: 12,
        type: "custom-field",
        description: "New custom field “Customer priority” has been added by John Snow.",
        createdAt: "14 Sep, 3:10 pm",
    },

]


export const tagOptions: ITag[] = [
  {
    id: "1",
    name: "internal bug",
    color: "#FF1A7F",
    backgroundColor: "#FF1A7F2E",
  },
  {
    id: "2",
    name: "change request",
    color: "#FEC700",
    backgroundColor: "#FEC7002E",
  },
  {
    id: "3",
    name: "figma needed",
    color: "#00ABE5",
    backgroundColor: "#00ABE52E",
  },
  {
    id: "4",
    name: "design",
    color: "#00AB7B",
    backgroundColor: "#00AB7B2E",
  },
];