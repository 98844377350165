import Button from "app/components/Button";
import Modal from "app/components/Modal";
import { useQueryState } from "nuqs";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "../redux/slice";
import AddTaskModalContent from "./AddTaskComponent/AddTaskModalContent";
import AddTaskHeader from "./AddTaskHeader";

interface AddTaskModalProps {
  onClose: () => void;
  isDrafted?: boolean;
}

export default function AddTaskModal({
  onClose,
  isDrafted,
}: AddTaskModalProps) {
  const [draftId, setDraftId] = useQueryState("did");
  const [deleteDraft, setDeleteDraft] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    if (isDrafted) {
      setDeleteDraft(true);
      return;
    } else {
      onClose();
    }
  };
  const handleSetDraft = () => {
    onClose();
    dispatch(actions.setDraftTicket());
  };
  const handleRemoveDraft = (id: string) => {
    dispatch(actions.removeDraftTicket({ id: id }));
    setDeleteDraft(false);
    setDraftId(null);
  };

  return (
    <>
      <Modal
        onClose={handleClose}
        submitText="Add"
        title={isDrafted ? "Task Draft" : "Add task"}
        contentClassName="!p-0 !max-h-[50vh] overflow-y-auto"
        modalClassName="!w-[721px] !max-w-[721px]"
        renderModalHeader={
          <AddTaskHeader
            isDrafted={isDrafted as boolean}
            handleSetDraft={handleSetDraft}
            handleClose={handleClose}
            handleRemoveDraft={() => {
              handleRemoveDraft(draftId as string);
            }}
          />
        }
      >
        <AddTaskModalContent />
      </Modal>
      {deleteDraft && (
        <Modal
          onClose={() => setDeleteDraft(false)}
          title="Save draft?"
          modalClassName="flex flex-col items-center p-0 gap-6 absolute w-[400px] h-[203px] left-[50%] top-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white border border-[#E9ECF2] rounded-lg"
          optionalFooterClass="bg-[#F4F5F7] rounded-b-md px-6 py-4"
          renderModalFooter={
            <div className="flex justify-end gap-3">
              <Button
                className="!bg-[transparent] !text-[#EB5757] border border-[#EB5757] rounded  h-9 text-sm font-medium"
                onClick={() => handleRemoveDraft(draftId as string)}
              >
                Delete draft
              </Button>
              <Button
                className="text-sm font-medium rounded bg-primary text-white"
                onClick={() => {
                  setDeleteDraft(false);
                  onClose();
                  setDraftId(null);
                }}
              >
                Save draft
              </Button>
            </div>
          }
        >
          <p className="text-left text-[#79808A]">
            Would you like to save a draft of this task? It can be accessed
            within your task tray.
          </p>
        </Modal>
      )}
      
    </>
  );
}
