import React from "react";
import Avatar from "app/containers/Features/components/Avatar";
import docIcon from "assets/imgs/doc_image.png";
import imageIcon from "assets/imgs/image_file.png";
import pngIcon from "assets/imgs/png_image.png";
import expendIcon from "assets/svgs/expand.svg";
import CustomHoverActionDropdown, {
  OptionTypeENum,
} from "../CustomHoverActionDropdown";
import { EditIcon } from "../Icons";
import DeleteIcon from "../Icons/DeleteIcon";
import ShareIcon from "../Icons/ShareIcon";
interface FileItem {
  id: string;
  name: string;
  type: "doc" | "pdf" | "image" | string;
}

interface FileUploadingProps {
  isUploading?: boolean;
  userDetails?: {
    name: string;
    status: "online" | "offline" | string;
  };
  timestamp?: string;
  data?: FileItem[];
  uploadingFile?: {
    name: string;
  };
  onPreview?: () => void;
  progress?: number;
}

const FileUploading: React.FC<FileUploadingProps> = ({
  isUploading,
  userDetails,
  data,
  uploadingFile,
  timestamp,
  onPreview,
  progress,
}) => {
  const [showOptions, setShowOptions] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement | null>(null);
  const [dropdownIndex, setDropdownIndex] = React.useState<number | null>(null);
  const getIcon = (type: FileItem["type"]) => {
    switch (type) {
      case "doc":
        return <img src={docIcon} alt="doc_image" sizes="100%" />;
      case "pdf":
        return <img src={pngIcon} alt="png_image" sizes="100%" />;
      case "image":
        return (
          <img
            src={imageIcon}
            alt="image_file"
            style={{
              width: "-webkit-fill-available",
              height: "-webkit-fill-available",
            }}
          />
        );
    }
  };
  const handleActionClick = (type: OptionTypeENum) => {
    switch (type) {
      case OptionTypeENum.IS_REMOVE:
        return;
      case OptionTypeENum.IS_EDIT:
        return;

      default:
        return;
    }
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownIndex(null);
        setShowOptions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleExpend = () => {
    onPreview?.();
    console.log("onPreview");
  };
  return (
    <div className="grid grid-cols-3 gap-4 flex w-full">
      {isUploading ? (
        <div className="bg-white rounded-md border-[#E9ECF2] border-4 h-[200px]">
          <div className="flex justify-center h-[70%] flex items-center space-x-4">
            <div className="flex justify-center items-center">
              <div className="w-20 h-20 border-4 border-[#5DA2FF4D] border-r-transparent border-t-primary rounded-full animate-spin"></div>
            </div>
          </div>
          <div className="border-t-2 border-[#E9ECF2] flex justify-between items-center px-2 py-2 h-[25%]">
            <div>
              <p className="text-sm text-gray-700 truncate">
                {uploadingFile?.name}
              </p>
              <p className="text-xs text-gray-400">
                Uploading {progress} %
              </p>
            </div>
            <div className="flex items-center mt-2 b">
              <Avatar name={userDetails?.name as string} />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {data?.map((ele, index) => {
        return (
          <div
            className="bg-white rounded-md border-[#E9ECF2] border-2 h-[200px] cursor-pointer"
            key={ele.id}
            onMouseEnter={() => {
              setDropdownIndex(index);
            }}
            onMouseLeave={() => {
              setDropdownIndex(null);
              setShowOptions(false);
            }}
          >
            <div className="flex justify-center h-[70%] flex items-center space-x-4 relative">
              {getIcon(ele.type)}{" "}
              {dropdownIndex === index && (
                <>
                  <div className="absolute inset-0 bg-[#00000099] opacity-60 rounded-t" />
                  <div className="absolute flex items-center justify-center z-10">
                    <button
                      onClick={(e) => {
                        handleExpend();
                        e.preventDefault();
                      }}
                      className="relative"
                    >
                      <img
                        src={expendIcon}
                        alt={expendIcon}
                        className="h-6 w-6 text-center"
                      />
                    </button>
                  </div>
                  <div className="absolute flex justify-end right-1 top-1">
                    <CustomHoverActionDropdown
                      dropdownClassName="w-[150px]"
                      dropdownRef={dropdownRef}
                      options={options}
                      handleClick={handleActionClick}
                      setShowOption={setShowOptions}
                      showOption={showOptions}
                      iconColor="#fff"
                    />
                  </div>
                </>
              )}
            </div>
            <div className="border-t-2 border-[#E9ECF2] flex justify-between items-center px-2 py-2 h-[25%]">
              <div>
                <p className="text-sm text-gray-700 truncate">{ele.name}</p>
                <p className="text-xs text-gray-400">{timestamp}</p>
              </div>
              <div className="flex items-center mt-2 b">
                <Avatar name={userDetails?.name as string} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FileUploading;
const options = [
  {
    label: "Rename",
    icon: <EditIcon size={20} color="#79808A" />,
    type: OptionTypeENum.IS_EDIT,
  },
  {
    label: "Copy URL",
    icon: <ShareIcon size={"20"} color="#79808A" />,
    type: OptionTypeENum.COPY_URL,
  },
  {
    label: "Download",
    icon: <ShareIcon size={"20"} color="#79808A" />,
    type: OptionTypeENum.DOWNLOAD,
  },
  {
    label: "Delete",
    icon: <DeleteIcon size={20} color="#79808A" />,
    type: OptionTypeENum.IS_REMOVE,
  },
];
