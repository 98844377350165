import React from "react";
import { useDispatch } from "react-redux";
import { actions } from "../../redux/slice";
import AddTaskComponent from ".";
import FileDropzone from "app/components/FileDropzone";

const AddTaskModalContent = () => {
  const [isDragging, setIsDragging] = React.useState(false);
  const dispatch = useDispatch();
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      await handleImageUploadWithProgress(file);
    }
  };

  const handleImageUploadWithProgress = async (file) => {
    try {
      dispatch(actions.setIsFileUploading(true));

      dispatch(actions.setUploadProgress(0));
      for (let progress = 0; progress <= 100; progress += 20) {
        await new Promise((resolve) => setTimeout(resolve, 300));

        dispatch(actions.setUploadProgress(progress));
      }

      dispatch(actions.setTicketFilesValue(file));
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      dispatch(actions.setIsFileUploading(false));
      dispatch(actions.setUploadProgress(0));
    }
  };

  return (
    <React.Fragment>
      {isDragging ? (
        <FileDropzone
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          className="min-h-[50vh]"
        />
      ) : (
        <div
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <AddTaskComponent />
        </div>
      )}
    </React.Fragment>
  );
};

export default AddTaskModalContent;
