import React, { useEffect } from "react";
import AdaptiveDateRangePicker from "app/components/AdaptiveDateRangePicker";
import StatusSelectionButton from "../";
import { CalendarIcon } from "app/components/Icons";
import { format } from "date-fns/format";
import CustomTooltip from "app/components/Tooltip";

export const CalendarButton= () => {
  const [selectedDates, setSelectedDates] = React.useState<Date[]>([]);
  let startDate: string;
  let endDate: string;
  const dateLabel = () => {
    if (selectedDates.length > 0) {
      if (selectedDates[0]) {
        startDate = `${format(selectedDates[0], "dd MMM, yyyy")}`;
      }
      if (selectedDates[1]) {
        endDate = `${format(selectedDates[1], "dd MMM, yyyy")}`;
      }
    }
    if (typeof startDate === "undefined" || typeof endDate === "undefined") {
      return "-";
    } else {
      return startDate + " -  " + endDate;
    }
  };
  useEffect(() => {
    console.log(startDate, typeof endDate, "startDate");

    return () => {};
  }, []);

  return (
    <>
      <AdaptiveDateRangePicker
        trigger={
          <button className="flex flex-row items-center justify-center h-[28px] min-w-[28px] rounded-[4px] border border-[#E9ECF2] hover:bg-[#ECEEF2] hover:text-[#79808A] hover:border-[#E9ECF2] transition-all duration-200">
            <CustomTooltip text="Set due date"  position="bottom"> 
            <CalendarIcon />
          </CustomTooltip>
          </button>
        }
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        className="absolute left-[-140px]"

      />
    </>
  );
};
