import TargetIcon from "app/components/Icons/TargetIcon";
import CustomTooltip from "app/components/Tooltip";
import CommonTargetRelease from "app/containers/Features/components/CommonRelease";
import React from "react";
import { targetReleasesData } from "../../../../components/CreateBoardsComponent/data";

interface CustomProps {
  value: string;
}
const TargetRelease: React.FC<CustomProps> = ({ value }) => {
  //
  const selectedOptions =
  targetReleasesData.find((p) => p.id === value) || targetReleasesData[0];

  return (
    <>
      <CommonTargetRelease
        trigger={<button className="flex items-center justify-between w-full px-2 py-2 rounded-md hover:bg-[#F4F5F7] rounded-[4px] data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A] ">
          <CustomTooltip
            text="Set target release"
            className="flex items-center "
            position="left"
          >
            <div className="flex items-center gap-2 w-full">
              <TargetIcon size={16} />
              <span className="text-sm font-normal text-black">
                {selectedOptions.label}
              </span>
            </div>
          </CustomTooltip>
        </button>}
        isHeader="target releases" data={targetReleasesData}      />
    </>
  );
};

export default TargetRelease;
