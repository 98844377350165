import { Label } from "./../Boards/types";
export enum TicketDetailsTabs {
  ACTIVITY = "activity",
  RELATIONS = "relations",
  SUBSCRIBERS = "subscribers",
  CODE = "code",
  INFO = "info",
}

export enum WatcherType {
  CREATOR = "creator",
  ASSIGNEE = "assignee",
  WATCHER = "watcher",
}

export enum PriorityFilterEnum {
  NONE = "None",
  LOW = "Low",
  NORMAL = "Normal",
  HIGH = "High",
  URGENT = "Urgent",
}
export enum StatusFilterEnum {
  OPENED = "Opened",
  IN_PROCESS = "In Process",
  DUPLICATE = "Duplicate",
  CLOSED = "Closed",
}

export interface CustomSelectOptions {
  label: string;
  value: string;
  icon?: React.ReactNode;
  textColor?: string;
}
export interface ITicketDescription {
  typeDescription: boolean;
  createTemplate: boolean;
}
export interface TicketState {
  addNewTemplate: boolean;
  addTicketDes: boolean;
  addTicketActivityDes: boolean;
}
export type initialState = TicketState;
