import React from "react";

interface CheckboxActiveIconProps {
    color?: string;
    size?: number;
}


export default function CheckboxActiveIcon({ color="#5A8DF1",size=20}:CheckboxActiveIconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_19920_154628" fill="white">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83333 2.5C3.99238 2.5 2.5 3.99238 2.5 5.83333V14.1667C2.5 16.0076 3.99238 17.5 5.83333 17.5H14.1667C16.0076 17.5 17.5 16.0076 17.5 14.1667L17.5 6.66667H14.1667L14.1667 2.5H5.83333Z"/>
            </mask>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83333 2.5C3.99238 2.5 2.5 3.99238 2.5 5.83333V14.1667C2.5 16.0076 3.99238 17.5 5.83333 17.5H14.1667C16.0076 17.5 17.5 16.0076 17.5 14.1667L17.5 6.66667H14.1667L14.1667 2.5H5.83333Z" fill="#DFECFF"/>
            <path d="M17.5 14.1667L18.3333 14.1667L18.3333 14.1667L17.5 14.1667ZM17.5 6.66667L18.3333 6.66667L18.3333 5.83333H17.5V6.66667ZM14.1667 6.66667L13.3333 6.66667L13.3333 7.5H14.1667V6.66667ZM14.1667 2.5L15 2.5L15 1.66667H14.1667V2.5ZM3.33333 5.83333C3.33333 4.45262 4.45262 3.33333 5.83333 3.33333V1.66667C3.53215 1.66667 1.66667 3.53215 1.66667 5.83333H3.33333ZM3.33333 14.1667V5.83333H1.66667V14.1667H3.33333ZM5.83333 16.6667C4.45262 16.6667 3.33333 15.5474 3.33333 14.1667H1.66667C1.66667 16.4679 3.53215 18.3333 5.83333 18.3333V16.6667ZM14.1667 16.6667H5.83333V18.3333H14.1667V16.6667ZM16.6667 14.1667C16.6667 15.5474 15.5474 16.6667 14.1667 16.6667V18.3333C16.4679 18.3333 18.3333 16.4679 18.3333 14.1667H16.6667ZM16.6667 6.66667L16.6667 14.1667L18.3333 14.1667L18.3333 6.66667L16.6667 6.66667ZM17.5 5.83333H14.1667V7.5H17.5V5.83333ZM15 6.66667L15 2.5L13.3333 2.5L13.3333 6.66667L15 6.66667ZM5.83333 3.33333H14.1667V1.66667H5.83333V3.33333Z" fill="#5A8DF1" mask="url(#path-1-inside-1_19920_154628)"/>
            <path d="M13.3359 2.5H14.1693C16.0102 2.5 17.5026 3.99238 17.5026 5.83333V7.5H13.3359V2.5Z" fill="#DFECFF"/>
            <path d="M6.25 7.91667L9.38202 11.0487C9.71846 11.3851 10.2678 11.3721 10.5879 11.02L18.3333 2.5" stroke="#5A8DF1" strokeWidth="1.25" strokeLinecap="round"/>
        </svg>
    )
}