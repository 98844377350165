import { createSlice } from "utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { ContainerState, PLAN_TYPE } from "../types";
import { FormError } from "app/types";

// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  profileForm: {
    name: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
    confirmPassword: {
      value: "",
      error: "",
    },
    timezone: {
      value: "",
      error: "",
    },
    isLoading: false,
  },
  setupForm: {
    companySize: {
      value: "",
      error: "",
    },
    referalSource: {
      value: "",
      error: "",
    },
    toolPreferences: {
      value: [],
      error: "",
    },
    isLoading: false,
  },
  timeZones: [],
  timeZonePagination: {
    search: "",
    page: 1,
    perPage: 7,
    total: 0,
    totalPage: 0,
  },
  isloading: false,
  onboardingList:{
    companySizes: [],
    referalSources: [],
    toolPreferences: [],
  }
};

const formSlice = createSlice({
  name: "OnBoardingState",
  initialState,
  reducers: {
    setProfileFormValue: (
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      state.profileForm[action.payload.name].value = action.payload.value;
      state.profileForm[action.payload.name].error = "";
    },
    setProfileFormErrors: (state, action: PayloadAction<FormError[]>) => {
      action.payload.forEach((error) => {
        state.profileForm[error.name].error = error.error;
      });
    },
    saveProfileRequest: (
      state,
      action: PayloadAction<{ callback?: () => void }>
    ) => {
      state.profileForm.isLoading = true;
    },
    saveProfileRequestComplete: (state) => {
      state.profileForm.isLoading = false;
    },
    setSetupFormValue: (
      state,
      action: PayloadAction<{ name: string; value: string | string[] }>
    ) => {
      state.setupForm[action.payload.name].value = action.payload.value;
      state.setupForm[action.payload.name].error = "";
    },
    saveSetupRequest: (
      state,
      action: PayloadAction<{ callback?: () => void }>
    ) => {
      state.setupForm.isLoading = true;
    },
    saveSetupRequestComplete: (state) => {
      state.setupForm.isLoading = false;
    },
    getTimeZones: (state, action: PayloadAction<any>) => {
      state.isloading = true;
    },
    setTimeZones: (state, action: PayloadAction<any>) => {
      const page = action.payload?.data?._metadata?.pagination?.page;
      const newTimezones = action.payload?.data?.data;
      if (page === 1) {
        state.timeZones = newTimezones;
      } else {
        const existingValues = new Set(state.timeZones.map(tz => tz.value));
        const uniqueNewTimezones = newTimezones.filter(tz => !existingValues.has(tz.value));
        state.timeZones = [...state.timeZones, ...uniqueNewTimezones];
      }
      state.timeZonePagination.page = page;
      state.timeZonePagination.total = action.payload?.data?._metadata?.pagination?.total;
      state.timeZonePagination.totalPage = action.payload?.data?._metadata?.pagination?.totalPage;
      state.isloading = false;
    },
    getOnbordingList(state) {
      state.isloading = true;
    },
    setOnboradingList: (state, action: PayloadAction<any>) => {
        state.onboardingList.companySizes = action.payload?.data?.companySizes;
        state.onboardingList.referalSources = action.payload?.data?.referralSources;
        state.onboardingList.toolPreferences = action.payload?.data?.toolPreferences;
        state.isloading = false;
    },
    resetStore: () => initialState,
  },
});

export const {
  actions,
  reducer: onBoardingReducer,
  name: onBoardingSlice,
} = formSlice;
