import { PLAN_TYPE, Statuses } from "app/types";
import { PriorityFilterEnum, StatusFilterEnum } from "../Ticket/types";
import {
  getPriorityIcon,
  getStatusIcon,
  getTicketIcon,
} from "./containers/Home/containers/helpers";
import {
  Board,
  BoardPermission,
  BoardType,
  RelationType,
  TicketGroup,
  TicketType,
} from "./types";

export const fixedColorCode = [
  "#6554C0",
  "#36B37E",
  "#00B8D9",
  "#FF5630",
  "#6B778C",
  "#4C9AFF",
  "#FFC400",
  "#EE5396",
  "#091E42",
  "#008DA6",
  "#DE350B",
  "#FF991F",
  "#403294",
  "#008274",
  "#1D7AFC",
];

export const BOARDS: Board[] = [
  {
    _id: "1",
    name: "QA bugs board",
    lastViewed: "2024-02-14 12:00:00",
    lastUpdated: "2024-02-14 12:00:00",
    type: BoardType.LIST,
    permission: BoardPermission.PRIVATE,
    projects: [
      {
        id: "1",
        projectName: "TechMate",
        color: "#9B51E0",
      },
    ],
    owner: {
      _id: "1",
      name: "Emerson Philips",
      online: true,
      color: "#FFC803",
      role: "Developer",
    },
    createdAt: "2024-02-14 12:00:00",
  },
  {
    _id: "2",
    name: "Dev board",
    type: BoardType.KANBAN,
    permission: BoardPermission.PUBLIC,
    projects: [
      {
        id: "1",
        projectName: "TechMate",
        color: "#9B51E0",
      },
    ],
    owner: {
      _id: "1",
      name: "Talan Kenter",
      online: true,
      color: "#FF7C03",
      role: "Developer",
    },
    createdAt: "2024-02-14 12:00:00",
    lastUpdated: "2024-02-14 12:00:00",
    lastViewed: "2024-02-14 12:00:00",
  },
  {
    _id: "3",
    name: "Dev tasks board",
    type: BoardType.LIST,
    permission: BoardPermission.PUBLIC,
    projects: [
      {
        id: "1",
        projectName: "TechMate",
        color: "#9B51E0",
      },
    ],
    owner: {
      _id: "1",
      name: "Talan Kenter",
      online: true,
      color: "#FF7C03",
      role: "Developer",
    },
    createdAt: "2024-02-14 12:00:00",
    lastUpdated: "2024-02-14 12:00:00",
    lastViewed: "2024-02-14 12:00:00",
  },
  {
    _id: "4",
    name: "Dev tasks Board 1.1.1",
    type: BoardType.KANBAN,
    permission: BoardPermission.CUSTOM,
    projects: [
      {
        id: "1",
        projectName: "EcoPower",
        color: "#2F80ED",
      },
      {
        id: "2",
        projectName: "TrendTrack",
        color: "#6FCF97",
      },
    ],
    owner: {
      _id: "1",
      name: "Talan Kenter",
      online: true,
      color: "#FF7C03",
      role: "Developer",
    },
    createdAt: "2024-02-14 12:00:00",
    lastUpdated: "2024-02-14 12:00:00",
    lastViewed: "2024-02-14 12:00:00",
  },
  {
    _id: "5",
    name: "Tasks 2.0",
    type: BoardType.LIST,
    permission: BoardPermission.CUSTOM,
    projects: [
      {
        id: "1",
        projectName: "TechMate",
        color: "#9B51E0",
      },
      {
        id: "2",
        projectName: "TrendTrack",
        color: "#6FCF97",
      },
    ],
    owner: {
      _id: "1",
      name: "Talan Kenter",
      online: true,
      color: "#FF7C03",
      role: "Developer",
    },
    createdAt: "2024-02-14 12:00:00",
    lastUpdated: "2024-02-14 12:00:00",
    lastViewed: "2024-02-14 12:00:00",
  },
  {
    _id: "6",
    name: "QA bugs  1.0.0",
    type: BoardType.LIST,
    permission: BoardPermission.PUBLIC,
    projects: [
      {
        id: "1",
        projectName: "TechMate",
        color: "#9B51E0",
      },
      {
        id: "2",
        projectName: "TrendTrack",
        color: "#6FCF97",
      },
    ],
    owner: {
      _id: "1",
      name: "Talan Kenter",
      online: true,
      color: "#FF7C03",
      role: "Developer",
    },
    createdAt: "2024-02-14 12:00:00",
    lastUpdated: "2024-02-14 12:00:00",
    lastViewed: "2024-02-14 12:00:00",
  },
];

export const TICKET_HEADERS = [
  {
    name: "Name",
    id: "name",
    resizable: true,
    className: "!p-[4px] text-xs leading-[18px] font-medium",
  },
  {
    name: "Status",
    id: "status",
    width: "150",
    resizable: true,
    className: "!p-[4px] text-xs leading-[18px] font-medium",
  },
  {
    name: "Assignee",
    id: "assignee",
    resizable: true,
    className: "!p-[4px] text-xs leading-[18px] font-medium",
  },
  {
    name: "Priority",
    id: "priority",
    resizable: true,
    className: "!p-[4px] text-xs leading-[18px] font-medium",
  },
  {
    name: "Due Date",
    id: "dueDate",
    resizable: true,
    className: "!p-[4px] text-xs leading-[18px] font-medium",
  },
];

export const TICKET_GROUPS: TicketGroup[] = [
  {
    status: {
      name: "To Do",
      color: "#43D98C",
      isCollapse: false,
      isHidden: false,
      ticketLimit:10
    },
    tickets: [
      {
        type: TicketType.STORY,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000004",
        labels: [
          {
            name: "internal bug",
            color: "#FF1A7F",
            backgroundColor: "#FF1A7F2E",
          },
          {
            name: "change request",
            color: "#FEC700",
            backgroundColor: "#FEC7002E",
          },
        ],
        status: {
          name: "Opened",
          backgroundColor: "#43D98C",
        },
        assignee: {
          name: "Phillip Kenter",
          color: "#FFC803",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "20 Feb, 2024",
        attachment: [
          { id: "001", file: "Doc 12584_test_sql_001.docx" },
          { id: "002", file: require("assets/imgs/image_file.png") },
        ],
        description: `The login page is not loading properly on Internet Explorer browser. Users are reporting that they are unable to login using Internet Explorer 
Steps to Reproduce:
Open Internet Explorer browser
Go to the login page URL
Attempt to log in with valid credentials
Notice that the page is not loading properly and users are unable to login`,
        relation: [
          {
            borderColor: "border-[#FA3F77]",
            category: "Blocked by",
            categoryIcon: RelationType.BLOCKED_BY,
            relationList: [
              {
                type: TicketType.STORY,
                name: "Login module: There is no confirmation message displayed when user request again for OTP",
                id: "000001",
              },
              {
                type: TicketType.BUG,
                name: "Login module: There is no confirmation message displayed when user request again for OTP",
                id: "000002",
              },
              {
                type: TicketType.STORY,
                name: "Login module: There is no confirmation message displayed when user request again for OTP",
                id: "000003",
              },
              {
                type: TicketType.TASK,
                name: "Login module: There is no confirmation message displayed when user request again for OTP",
                id: "000004",
              },
            ],
          },
          {
            borderColor: "border-[#6B7DF8]",
            category: "Blocks",
            categoryIcon: RelationType.BLOCKS,
            relationList: [
              {
                type: TicketType.STORY,
                name: "Login module: There is no confirmation message displayed when user request again for OTP",
                id: "000001",
              },
              {
                type: TicketType.BUG,
                name: "Login module: There is no confirmation message displayed when user request again for OTP",
                id: "000002",
              },
            ],
          },
          {
            borderColor: "border-[#D16DEA]",
            category: "Duplicate",
            categoryIcon: RelationType.DUPLICATE,
            relationList: [
              {
                type: TicketType.STORY,
                name: "Login module: There is no confirmation message displayed when user request again for OTP",
                id: "000001",
              },
            ],
          },
        ],
        checkList: [
          { assignee: "John Snow", name: "Checklist name", isCompleted: false },
          { assignee: "", name: "Checklist", isCompleted: false },
        ],
      },
      {
        type: TicketType.TASK,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000005",
        labels: [
          {
            name: "figma needed",
            color: "#00ABE5",
            backgroundColor: "#00ABE52E",
          },
          {
            name: "design",
            color: "#00AB7B",
            backgroundColor: "#00AB7B2E",
          },
        ],
        status: {
          name: "Opened",
          backgroundColor: "#43D98C",
        },
        assignee: {
          name: "Chance Saris",
          color: "#FF7C03",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "15 Mar, 2024",
      },
      {
        type: TicketType.TASK,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000005",
        labels: [
          {
            name: "figma needed",
            color: "#00ABE5",
            backgroundColor: "#00ABE52E",
          },
          {
            name: "design",
            color: "#00AB7B",
            backgroundColor: "#00AB7B2E",
          },
        ],
        status: {
          name: "Opened",
          backgroundColor: "#43D98C",
        },
        assignee: {
          name: "Chance Saris",
          color: "#FF7C03",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "15 Mar, 2024",
      },
      {
        type: TicketType.TASK,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000006",
        labels: [
          {
            name: "figma needed",
            color: "#00ABE5",
            backgroundColor: "#00ABE52E",
          },
          {
            name: "design",
            color: "#00AB7B",
            backgroundColor: "#00AB7B2E",
          },
        ],
        status: {
          name: "Opened",
          backgroundColor: "#43D98C",
        },
        assignee: {
          name: "Chance Saris",
          color: "#FF7C03",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "15 Mar, 2024",
      },
      {
        type: TicketType.TASK,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000007",
        labels: [
          {
            name: "figma needed",
            color: "#00ABE5",
            backgroundColor: "#00ABE52E",
          },
          {
            name: "design",
            color: "#00AB7B",
            backgroundColor: "#00AB7B2E",
          },
        ],
        status: {
          name: "Opened",
          backgroundColor: "#43D98C",
        },
        assignee: {
          name: "Chance Saris",
          color: "#FF7C03",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "15 Mar, 2024",
      },
    ],
  },
  {
    status: {
      name: "In Process",
      color: "#FEB355",
      isCollapse: false,
      isHidden: false,
      ticketLimit:6
    },
    tickets: [
      {
        type: TicketType.TASK,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000004",
        labels: [
          {
            name: "internal bug",
            color: "#FF1A7F",
            backgroundColor: "#FF1A7F2E",
          },
          {
            name: "change request",
            color: "#FEC700",
            backgroundColor: "#FEC7002E",
          },
        ],
        status: {
          name: "In process",
          backgroundColor: "#FEB355",
        },
        assignee: {
          name: "Phillip Kenter",
          color: "#FFC803",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "20 Feb, 2024",
      },
      {
        type: TicketType.TASK,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000003",
        labels: [
          {
            name: "internal bug",
            color: "#FF1A7F",
            backgroundColor: "#FF1A7F2E",
          },
          {
            name: "change request",
            color: "#FEC700",
            backgroundColor: "#FEC7002E",
          },
        ],
        status: {
          name: "In process",
          backgroundColor: "#FEB355",
        },
        assignee: {
          name: "Phillip Kenter",
          color: "#FFC803",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "20 Feb, 2024",
      },
      {
        type: TicketType.STORY,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000001",
        labels: [
          {
            name: "figma needed",
            color: "#00ABE5",
            backgroundColor: "#00ABE52E",
          },
          {
            name: "design",
            color: "#00AB7B",
            backgroundColor: "#00AB7B2E",
          },
        ],
        status: {
          name: "In Progress",
          backgroundColor: "#FEB355",
        },
        assignee: {
          name: "Chance Saris",
          color: "#FF7C03",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "15 Mar, 2024",
      },
      {
        type: TicketType.STORY,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000001",
        labels: [
          {
            name: "figma needed",
            color: "#00ABE5",
            backgroundColor: "#00ABE52E",
          },
          {
            name: "design",
            color: "#00AB7B",
            backgroundColor: "#00AB7B2E",
          },
        ],
        status: {
          name: "In Progress",
          backgroundColor: "#FEB355",
        },
        assignee: {
          name: "Chance Saris",
          color: "#FF7C03",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "15 Mar, 2024",
      },
      {
        type: TicketType.BUG,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000002",
        labels: [
          {
            name: "figma needed",
            color: "#00ABE5",
            backgroundColor: "#00ABE52E",
          },
          {
            name: "design",
            color: "#00AB7B",
            backgroundColor: "#00AB7B2E",
          },
          {
            name: "change request",
            color: "#FEC700",
            backgroundColor: "#FEC7002E",
          },
        ],
        status: {
          name: "In Progress",
          backgroundColor: "#FEB355",
        },
        assignee: {
          name: "Chance Saris",
          color: "#FF7C03",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "15 Mar, 2024",
      },
    ],
  },
  {
    status: {
      name: "Done",
      color: "#A394FB",
      isCollapse: false,
      isHidden: false,
      ticketLimit:5
    },
    tickets: [
      {
        type: TicketType.TASK,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000006",
        labels: [
          {
            name: "internal bug",
            color: "#FF1A7F",
            backgroundColor: "#FF1A7F2E",
          },
          {
            name: "change request",
            color: "#FEC700",
            backgroundColor: "#FEC7002E",
          },
        ],
        status: {
          name: "Closed",
          backgroundColor: "#A394FB",
        },
        assignee: {
          name: "Phillip Kenter",
          color: "#FFC803",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "20 Feb, 2024",
      },
      {
        type: TicketType.TASK,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000006",
        labels: [
          {
            name: "internal bug",
            color: "#FF1A7F",
            backgroundColor: "#FF1A7F2E",
          },
          {
            name: "change request",
            color: "#FEC700",
            backgroundColor: "#FEC7002E",
          },
        ],
        status: {
          name: "Closed",
          backgroundColor: "#A394FB",
        },
        assignee: {
          name: "Phillip Kenter",
          color: "#FFC803",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "20 Feb, 2024",
      },
      {
        type: TicketType.TASK,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000006",
        labels: [
          {
            name: "internal bug",
            color: "#FF1A7F",
            backgroundColor: "#FF1A7F2E",
          },
          {
            name: "change request",
            color: "#FEC700",
            backgroundColor: "#FEC7002E",
          },
        ],
        status: {
          name: "Closed",
          backgroundColor: "#A394FB",
        },
        assignee: {
          name: "Phillip Kenter",
          color: "#FFC803",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "20 Feb, 2024",
      },
      {
        type: TicketType.STORY,
        name: "Login module: There is no confirmation message displayed when user request again for OTP",
        id: "000007",
        labels: [
          {
            name: "figma needed",
            color: "#00ABE5",
            backgroundColor: "#00ABE52E",
          },
          {
            name: "design",
            color: "#00AB7B",
            backgroundColor: "#00AB7B2E",
          },
        ],
        status: {
          name: "Closed",
          backgroundColor: "#A394FB",
        },
        assignee: {
          name: "Chance Saris",
          color: "#FF7C03",
          online: true,
        },
        membersCount: 2,
        attachmentsCount: 1,
        completedCount: 0,
        totalCount: 2,
        priority: "Normal",
        dueDate: "15 Mar, 2024",
      },
    ],
  },
];

export const PROJECTS = [
  {
    id: "1",
    name: "Digital Innovators Collective",
    icon: {
      avatar: "",
      color: "#FFFFFF",
      backgroundColor: "#5A8DF1",
    },
    owner: {
      id: "1",
      name: "Emerson Philips",
      avatar: "",
      isOnline: true,
    },
    plan: PLAN_TYPE.FREE,
    statuses: [
      Statuses.ABANDONED,
      Statuses.DONE,
      Statuses.IN_CODE_REVIEW,
      Statuses.IN_PROGRESS,
      Statuses.IN_STAGING,
      Statuses.REOPENED,
      Statuses.TODO,
      Statuses.TESTING_IN_PRODUCTION,
    ],
    members: [
      {
        id: "1",
        name: "Emerson Philips",
        status: "active",
        role: "Owner",
      },
      {
        id: "2",
        name: "Phillip Kenter",
        status: "active",
        role: "Developer",
      },
    ],
    createdAt: "2024-02-14 12:00:00",
    storageUsage: 30,
    teams: [
      {
        id: "1",
        name: "TechMate",
        icon: {
          letter: "T",
          backgroundColor: "#B3E8E9",
          color: "#79808A",
        },
        boards: [
          {
            name: "Tickets",
          },
          {
            name: "Sprints",
          },
          {
            name: "Releases",
          },
        ],
      },
      {
        id: "2",
        name: "Tasty Tech",
        icon: {
          letter: "T",
          backgroundColor: "#C5D8F8",
          color: "#79808A",
        },
        boards: [
          {
            name: "Tickets",
          },
          {
            name: "Sprints",
          },
          {
            name: "Releases",
          },
        ],
      },
      {
        id: "3",
        name: "Smart Style",
        icon: {
          letter: "T",
          backgroundColor: "#D8CEFA",
          color: "#79808A",
        },
        boards: [
          {
            name: "Tickets",
          },
          {
            name: "Sprints",
          },
          {
            name: "Releases",
          },
        ],
      },
    ],
  },
];
export const TicketTypeOptions = [
  {
    label: "Task",
    type: TicketType.TASK,
    icon: getTicketIcon(TicketType.TASK),
  },
  {
    label: "Bug",
    type: TicketType.BUG,
    icon: getTicketIcon(TicketType.BUG),
  },
  {
    label: "Story",
    type: TicketType.STORY,
    icon: getTicketIcon(TicketType.STORY),
  },
  {
    label: "Epic",
    type: TicketType.EPIC,
    icon: getTicketIcon(TicketType.EPIC),
  },
];

export const TagList = [
  {
    id: "1",
    name: "internal bug",
    backgroundColor: "#FEC7002E",
    color: "#FF197F",
  },
  {
    id: "2",
    name: "change request",
    backgroundColor: "#FEC7002E",
    color: "#FEC700",
  },
  {
    id: "3",
    name: "figma needed",
    backgroundColor: "#00ABE52E",
    color: "#00ABE5",
  },
  { id: "4", name: "design", backgroundColor: "#00AB7B2E", color: "#00AB7B" },
  {
    id: "5",
    name: "need to fix",
    backgroundColor: "#0022E52E",
    color: "#0022E5",
  },
];
export const StatusTypeOptions = [
  {
    label: StatusFilterEnum.OPENED,
    value: StatusFilterEnum.OPENED,
    icon: getStatusIcon(StatusFilterEnum.OPENED),
    textColor: "text-[#43D98C]",
    color: "#43D98C",
  },
  {
    label: StatusFilterEnum.IN_PROCESS,
    value: StatusFilterEnum.IN_PROCESS,
    icon: getStatusIcon(StatusFilterEnum.IN_PROCESS),
    textColor: "text-[#FEB355]",
    color: "#FEB355",
  },
  {
    label: StatusFilterEnum.DUPLICATE,
    value: StatusFilterEnum.DUPLICATE,
    icon: getStatusIcon(StatusFilterEnum.DUPLICATE),
    textColor: "text-[#FF8FC5]",
    color: "#FF8FC5",
  },
  {
    label: StatusFilterEnum.CLOSED,
    value: StatusFilterEnum.CLOSED,
    icon: getStatusIcon(StatusFilterEnum.CLOSED),
    textColor: "text-[#A394FB]",
    color: "#A394FB",
  },
];
export const priorityOptions = [
  {
    label: PriorityFilterEnum.NORMAL,
    value: PriorityFilterEnum.NORMAL,
    icon: getPriorityIcon(PriorityFilterEnum.NORMAL),
    color: "#2AD95B",
  },
  {
    label: PriorityFilterEnum.HIGH,
    value: PriorityFilterEnum.HIGH,
    icon: getPriorityIcon(PriorityFilterEnum.HIGH),
    color: "#FF8A00",
  },
  {
    label: PriorityFilterEnum.URGENT,
    value: PriorityFilterEnum.URGENT,
    icon: getPriorityIcon(PriorityFilterEnum.URGENT),
    color: "#FF4F4F",
  },
  {
    label: PriorityFilterEnum.LOW,
    value: PriorityFilterEnum.LOW,
    icon: getPriorityIcon(PriorityFilterEnum.LOW),
    color: "#20C6EB",
  },
];
