import { Label, TicketType } from "../../types";

export interface BacklogTicket {
    id: string;
    type: TicketType;
    name: string;
    release: string;
    sprint: string;
    epic: {
        name: string;
        color: string;
    };
    labels: Label[];
}

export enum PlanningTypes {
    COMPONENTS = 'components',
    EPICS = 'epics',
    STORIES = 'stories',
    SPRINT = 'sprint',
    RELEASES = 'releases'
}

export enum SelectionTopBarOptionsEnum {    
    DUPLICATE = 'duplicate',
    ARCHIVE = 'archive',
    EXPORT = 'export',
    STATUS = 'status',
    USER = 'assignee',
    CALENDAR = 'duedate',
    RELEASE = 'release',
    SPRINT = 'sprint'
  }
  