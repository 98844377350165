import { RootState } from "types";
import { initialState } from "./initialState";
import { createSelector } from "@reduxjs/toolkit";


// Select domain from the state
const selectDomain = (state: RootState) => state?.ticketTypeState || initialState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectFieldsLoading = createSelector(
  [selectDomain],
  (state) => state.fieldsLoading
);
export const selectButtonLoading = createSelector(
  [selectDomain],
  (state) => state.buttonLoading
);
export const selectTicketTypesForm = createSelector(
  [selectDomain],
  (state) => state.ticketTypesForm
);
export const selectTicketTypesList = createSelector(
  [selectDomain],
  (state) => state.ticketTypesList
);