import React from 'react'
import TicketCustomFields from '../../../Ticket/components/TicketDetailsComponent/TicketCustomFields'

const CustomFields = () => {
  return (
    <div className="flex flex-col gap-[4px] w-full">
    <TicketCustomFields />
  </div>
  )
}

export default CustomFields
