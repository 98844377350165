import React, { useRef } from 'react'
import Input from 'app/components/Input';
import Button from 'app/components/Button';
import { Link, useNavigate } from 'react-router-dom';
import rightArrow from '../../../../assets/svgs/ic_right_arrow_primary.svg';
import EmailIcon from 'app/components/Icons/EmailIcon';
import { selectForgotPasswordForm } from '../redux/selector';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../redux/slice';
import { validateForgotPasswordForm } from '../helpers';
import Logo from 'app/components/Icons/Logo';

export default function ForgotPassword() {
    const forgotPasswordForm = useSelector(selectForgotPasswordForm);
    const emailInputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const errors = validateForgotPasswordForm({
            email: {
                value: emailInputRef.current?.value || "",
                error: ""
            }
        });
        if (errors.length > 0) {
            dispatch(actions.setForgotPasswordFormErrors(errors));
            return;
        }
        dispatch(actions.forgotPasswordRequest({email: emailInputRef.current?.value || "", callback: () => {
            navigate("/auth/recovery-success");
        }}));
    }
    return (
        <div className='flex flex-col items-center'>
            <div className='flex flex-col items-center gap-[20px] mb-[56px]'>
                <Logo size={72}  />
                <h1 className="text-[40px] leading-[50px] font-semibold mb-4 text-center">Forgot your password?</h1>
            </div>
            <form className='flex flex-col gap-[20px] w-full max-w-[390px] mb-[56px]' onSubmit={handleSubmit}>
                <Input 
                    id="email" 
                    label="Email" 
                    type="email" 
                    placeholder="Enter email address" 
                    icon={<EmailIcon />}
                    defaultValue={forgotPasswordForm.email.value}
                    error={forgotPasswordForm.email.error}
                    disabled={forgotPasswordForm.isLoading}
                    inputRef={emailInputRef}
                    onChange={(e) => {
                        dispatch(actions.resetForgotPasswordFormError());
                    }}
                />
                <div className="flex items-center justify-between">
                    {/* <Link to="/auth/recovery-success" className="w-full"> */}
                        <Button 
                            className='w-full h-[56px] mt-2'
                            disabled={forgotPasswordForm.isLoading || !!forgotPasswordForm.email.error}
                            isLoading={forgotPasswordForm.isLoading}
                        >
                            Send me link
                        </Button>
                    {/* </Link> */}
                </div>
            </form>
            <div className='flex flex-col items-center gap-[16px]'>
                <p className='text-secondary text-md leading-[24px] flex flex-row items-center gap-[6px]'>or <Link to="/auth/login" className="text-primary text-md leading-[24px] font-medium flex flex-row items-center">Log in <img sizes='16px' src={rightArrow} alt="Arrow" /></Link></p>
            </div>
        </div>
    )
}
