import Dropdown from "app/components/Dropdown";
import checkmarkDouble from "assets/svgs/checkmark-double.svg";
import collapseIcon from "assets/svgs/collapse.svg";
import menuIcon from "assets/svgs/ic_menu-grey.svg";
import invisibleIcon from "assets/svgs/invisible.svg";
import React from "react";
import { GroupAction, TicketGroup } from "../../../types";
import { useDispatch } from "react-redux";
import { actions } from "../../Tickets/redux/slice";
import { actions as actionsTicket } from "../../../redux/slice";
import { useSelector } from "react-redux";
import { selectSelectedTickets } from "../../../redux/selector";
import { CloseIcon } from "app/components/Icons";

interface GroupActionProps {
  group: TicketGroup;
}

const ColumnGroupActions: React.FC<GroupActionProps> = ({ group }) => {
  const dispatch = useDispatch();
  const selectedList = useSelector(selectSelectedTickets);

  const options = [
    {
      icon: (
        <img src={collapseIcon} alt="collapseIcon" className="size-[20px]" />
      ),
      label: "Collapse group",
      type: GroupAction.COLLAPSE,
    },
    selectedList.length > 0
      ? {
          icon: <CloseIcon color="#79808A" />,
          label: "Unselect all",
          type: GroupAction.Un_SELECT_ALL,
        }
      : {
          icon: (
            <img
              src={checkmarkDouble}
              alt="checkmarkDouble"
              className="size-[20px]"
            />
          ),
          label: "Select all",
          type: GroupAction.SELECT_ALL,
        },
    {
      icon: (
        <img src={invisibleIcon} alt="invisibleIcon" className="size-[20px]" />
      ),
      label: "Hide group",
      type: GroupAction.HIDE,
    },
  ];

  const MenuItems = options?.map((ele) => {
    if (ele)
      return {
        label: (
          <>
            <div className={`flex items-start gap-3 p-2 w-[100%]`}>
              <div className="min-w-[20px]">{ele.icon}</div>
              <div className="text-sm leading-[18px] ita font-normal text-[#79808A] text-ellipsis overflow-hidden whitespace-nowrap">
                {ele?.label}
              </div>
            </div>
          </>
        ),
        action: () => {
          handleSelect(ele?.type);
        },
      };
    else return { label: "", action: () => {} };
  });

  const handleSelect = (type) => {
    switch (type) {
      case GroupAction.COLLAPSE:
        return handleCollapsed();
      case GroupAction.SELECT_ALL:
        return handleSelectAll();
      case GroupAction.Un_SELECT_ALL:
        return handleUnSelectAll();
      case GroupAction.HIDE:
        return handleHidden();
      default:
        break;
    }
  };
  const handleCollapsed = () => {
    dispatch(actions.toggleGroupsIsCollapse(group));
  };
  const handleHidden = () => {
    dispatch(actions.toggleGroupsIsHidden(group));
  };
  const handleSelectAll = () => {
    dispatch(actionsTicket.toggleSelectTicketByGroup(group));
  };
  const handleUnSelectAll = () => {
    dispatch(actionsTicket.resetSelectedTickets());
  };
  return (
    <>
      <Dropdown
        trigger={
          <button className="flex flex-row items-center hover:bg-[#ECEEF2] hover:px-1 hover:py-[1px] rounded outline-none">
            <img src={menuIcon} alt="edit" className="size-[20px]" />{" "}
          </button>
        }
        menuItems={MenuItems ? MenuItems : []}
        align="end"
        dropdownContainerClassName="py-2"
      />
    </>
  );
};

export default ColumnGroupActions;
