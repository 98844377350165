import React from 'react'
import AssigneeDropdown from "../../../Ticket/components/TicketDetailsComponent/TicketDetails/AssigneeDropdown";
import DueDateSelection from "../../../Ticket/components/TicketDetailsComponent/TicketDetails/DueDateSelection";
import PriorityDropdown from "../../../Ticket/components/TicketDetailsComponent/TicketDetails/PriorityDropdown";
import StatusDropdown from "../../../Ticket/components/TicketDetailsComponent/TicketDetails/StatusDropdown";
import TagsSelection from "../../../Ticket/components/TicketDetailsComponent/TicketDetails/TagsSelection";
import TicketTypeDropdown from "../../../Ticket/components/TicketDetailsComponent/TicketDetails/TicketTypeDropdown";
import { selectTicketForm } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { tagOptions } from '../../../Ticket/data';
import { useDispatch, useSelector } from 'react-redux';

const ContextFields = () => {
    const dispatch = useDispatch();
    const form = useSelector(selectTicketForm);
  
 
    const handleSelect = (name: string, value) => {
      dispatch(actions.setTicketFormValue({ name: name, value: value }));
    };
    const handleDateChange = (startDate?: string, endDate?: string) => {
      // Update start date
      dispatch(
        actions.setTicketFormValue({
          name: "startDate",
          value: startDate || "",
        })
      );
  
      // Update end date
      dispatch(
        actions.setTicketFormValue({
          name: "endDate",
          value: endDate || "",
        })
      );
    };
    
  return (
    <div className="grid grid-cols-2 gap-[10px]">
    <div className="flex flex-col gap-1">
      <div className="flex flex-row items-center justify-between gap-[8px]">
        <TicketTypeDropdown
          value={form.type}
          setValue={(val) => {
            handleSelect("type", val);
          }}
        />
      </div>
      <div className="flex flex-row items-center justify-between gap-[8px]">
        <StatusDropdown
          value={form.status}
          setValue={(val) => {
            handleSelect("status", val);
          }}
        />
      </div>

      <div className="flex flex-row items-center justify-between gap-[8px]">
        <AssigneeDropdown
          value={form.assignee}
          setValue={(val) => {
            handleSelect("assignee", val);
          }}
        />
      </div>
    </div>

    <div className="flex flex-col gap-1">
      <div className="flex flex-row items-center justify-between gap-[8px]">
        <DueDateSelection
          startDate={form.startDate}
          endDate={form.endDate}
          onDateChange={handleDateChange}
        />
      </div>

      <div className="flex flex-row items-center justify-between gap-[8px]">
        <PriorityDropdown
          value={form.priority}
          setValue={(val) => {
            handleSelect("priority", val);
          }}
        />
      </div>

      <div className="flex flex-row items-center justify-between gap-[8px]">
        <TagsSelection
          selectedTags={form.tags?.map((tag) => tag.id) || []}
          onTagsChange={(tagIds) => {
            const selectedTags = tagOptions.filter((tag) =>
              tagIds.includes(tag.id)
            );
            handleSelect("tags", selectedTags);
          }}
        />
      </div>
    </div>
  </div>
  )
}

export default ContextFields
