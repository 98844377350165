import { StatusIcon } from "app/components/Icons";
import CustomTooltip from "app/components/Tooltip";
import CommonStatusSelection from "app/containers/Features/components/CommonStatusSelection";
import { StatusFilterEnum } from "app/containers/Features/containers/Ticket/types";
import React from "react";
import { StatusTypeOptions } from "../../../../data";

interface StatusSelectionProps {
  value: StatusFilterEnum;
}

const Status: React.FC<StatusSelectionProps> = ({ value }) => {
  const selectedOptions =
    StatusTypeOptions.find((p) => p.value === value) || StatusTypeOptions[0];

  return (
    <React.Fragment>
      <CommonStatusSelection
        trigger={
          <button className="flex items-center justify-between w-full px-2 py-2 rounded-md hover:bg-[#F4F5F7] rounded-[4px] data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A] ">
            <CustomTooltip text="Set status" className="flex items-center " position="left">
              <div className="flex  gap-2">
                <StatusIcon size={16} color={selectedOptions.color} />
                <span className={`${selectedOptions.textColor}   text-xs`}>
                  {selectedOptions.label}
                </span>
              </div>
            </CustomTooltip>
          </button>
        }
        isHeader="statuses"
        value={value}
      />
    </React.Fragment>
  );
};

export default Status;
