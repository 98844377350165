import React from "react";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const DateOptions = () => {
  const getQuickSelectDates = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const thisWeekend = new Date(today);
    thisWeekend.setDate(today.getDate() + (6 - today.getDay()));

    const nextWeek = new Date(today);
    nextWeek.setDate(today.getDate() + 7);

    const nextWeekend = new Date(thisWeekend);
    nextWeekend.setDate(thisWeekend.getDate() + 7);

    const twoWeeks = new Date(today);
    twoWeeks.setDate(today.getDate() + 14);

    const fourWeeks = new Date(today);
    fourWeeks.setDate(today.getDate() + 28);

    return [
      { label: "Today", date: today, shortDate: "Today" },
      {
        label: "Tomorrow",
        date: tomorrow,
        shortDate: formatDayShort(tomorrow),
      },
      {
        label: "This weekend",
        date: thisWeekend,
        shortDate: formatDayShort(thisWeekend),
      },
      { label: "Next week", date: nextWeek, shortDate: formatDate(nextWeek) },
      {
        label: "Next weekend",
        date: nextWeekend,
        shortDate: formatDate(nextWeekend),
      },
      { label: "2 weeks", date: twoWeeks, shortDate: formatDate(twoWeeks) },
      { label: "4 weeks", date: fourWeeks, shortDate: formatDate(fourWeeks) },
    ];
  };
  const formatDate = (date: Date) => {
    return date.getDate() + " " + monthNames[date.getMonth()].slice(0, 3);
  };
  const formatDayShort = (date: Date) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days[date.getDay()];
  };
  return (
    <div className="h-full w-[160px] flex flex-col bg-[#E9ECF2] p-2 gap-3">
      {getQuickSelectDates().map((day, index) => {
        return (
          <div
            key={index}
            className="flex justify-between items-center text-[11px] font-small text-[#253858] "
          >
            <span>{day.label}</span>
            <span className="text-[#79808A] text-[9px]">{day.shortDate}</span>
          </div>
        );
      })}
    </div>
  );
};

export default DateOptions;
