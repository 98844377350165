import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initialState";
import { ITicketTypeFields, ITicketTypes, ITicketTypesDetails } from "../types";
import { set } from "lodash";
import { ticketTypesFields } from "../../../data";

export const useClinicSlice = createSlice({
  name: "ticketTypeState",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFieldsLoading: (state, action: PayloadAction<boolean>) => {
      state.fieldsLoading = action.payload;
    },
    setButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.buttonLoading = action.payload;
    },
    doFetchTicketTypes: (state, action: PayloadAction<string>) => {
      state.loading = true;
    },
    doFetchTicketFields: (state, action: PayloadAction<string>) => {
      state.fieldsLoading = true;
    },
    setTicketTypes: (state, action: PayloadAction<Array<ITicketTypes>>) => {
      state.ticketTypesList = action.payload;
    },
    setTicketTypeFields: (
      state,
      action: PayloadAction<Array<ITicketTypeFields>>
    ) => {
      state.ticketTypesForm.ticketTypeFields = action.payload;
    },
    doFetchTicketTypesForm: () => {},
    setTicketTypesForm: (state, action: PayloadAction<ITicketTypesDetails>) => {
      state.ticketTypesForm = action.payload;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state, `ticketTypesForm.${action.payload.key}`, action.payload.value);
    },

    doUpdate: (state, action: PayloadAction<{ id: string; callback: () => void }>) => {},
    doUpdateFields: (state, action: PayloadAction<{ id: string; callback: () => void }>) => { },
    
    doNewTypeCreate: (state) => {
      state.ticketTypesForm.name = "new ticket type"
      state.ticketTypesForm.description = ""
      state.ticketTypesForm.ticketTypeFields = ticketTypesFields
      
      
    }
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;
