import { PayloadAction } from "@reduxjs/toolkit";
import { sortOrder } from "app/types";
import { set } from "lodash";
import { createSlice } from "utils/@reduxjs/toolkit";
import {
  Board,
  CustomAccessEnum,
  DefaultTicketType,
  IDefaultTicketValueData,
  ITag,
  IVisibleGroups,
  TicketGroup
} from "../types";
import { initialState } from "./initialState";

const formSlice = createSlice({
  name: "BoardsState",
  initialState,
  reducers: {
    setUploadProgress: (state, action: PayloadAction<number>) => {
      state.uploadProgress = action.payload;
    },
    setIsFileUploading: (state, action: PayloadAction<boolean>) => {
      state.isFileUploading = action.payload;
    },
    toggleIsFilePreview: (state, action: PayloadAction<boolean>) => {
      state.isFilePreview = action.payload;
    },
    setFavorites: (state, action: PayloadAction<string[]>) => {
      state.favorites = action.payload;
    },
    setQaBoards: (state, action: PayloadAction<string[]>) => {
      state.qaBoards = action.payload;
    },
    setRecents: (state, action: PayloadAction<string[]>) => {
      state.recent = action.payload;
    },
    setBoardsFormValue: (
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      set(state, `boardsForm.${action.payload.name}`, action.payload.value);
    },
    setBoardsCustomPermissionValue: (
      state,
      action: PayloadAction<{
        id: string;
        value: CustomAccessEnum;
        index?: number;
      }>
    ) => {
      if (action.payload.index) {
        state.boardsForm.customPermissions[action.payload.index].access =
          action.payload.value;
      } else {
        state.boardsForm.customPermissions.push({
          id: action.payload.id,
          access: action.payload.value,
        });
      }
    },
    setBoardsGroupVisibility: (state, action: PayloadAction<number>) => {
      state.boardsForm.visibleGroups[action.payload].isVisible =
        !state.boardsForm.visibleGroups[action.payload].isVisible;
    },
    setBoardsGroupAllVisibility: (state, action: PayloadAction<boolean>) => {
      state.boardsForm.visibleGroups.forEach((item) => {
        item.isVisible = action.payload;
      });
    },
    setVisibleGroupOrder: (state, action: PayloadAction<IVisibleGroups[]>) => {
      state.boardsForm.visibleGroups = action.payload;
    },
    setBoardsCustomizeCard: (state, action: PayloadAction<number>) => {
      state.boardsForm.customizeCard[action.payload].isVisible =
        !state.boardsForm.customizeCard[action.payload].isVisible;
    },
    setBoardsCustomizeCardAllVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.boardsForm.customizeCard.forEach((item) => {
        item.isVisible = action.payload;
      });
    },
    setCustomizeCardOrder: (state, action: PayloadAction<IVisibleGroups[]>) => {
      state.boardsForm.customizeCard = action.payload;
    },
    setDefaultTicketValue: (
      state,
      action: PayloadAction<{
        id: string;
        value: string;
        type: DefaultTicketType;
        data: IVisibleGroups;
      }>
    ) => {
      state.boardsForm.defaultTicketValue.push({
        data: action.payload.data,
        id: action.payload.id,
        value: "",
        type: action.payload.type,
      });
    },
    fetchDefaultTicketValue: (state) => {},
    setDefaultTicketValueData: (
      state,
      action: PayloadAction<IDefaultTicketValueData>
    ) => {
      state.defaultTicketValueData = action.payload;
    },
    fetchDefaultTagValue: (state) => {},
    setDefaultTagValueData: (state, action: PayloadAction<Array<ITag>>) => {
      state.tagsList = action.payload;
    },
    updateNewTag: (state, action: PayloadAction<ITag>) => {
      state.tagsList.push(action.payload);
    },
    setDraftTicket: (state) => {
      const draftId = state.draftTicket.length + 1;
      state.draftTicket.push({
        id: String(draftId),
        name: "",
      });
    },
    removeDraftTicket: (state, action: PayloadAction<{ id: string }>) => {
      const items = state.draftTicket.filter((e) => e.id !== action.payload.id);
      state.draftTicket = items;
    },

    setTicketFormValue: (
      state,
      action: PayloadAction<{ name: string; value: any }>
    ) => {
      set(state, `ticketForm.${action.payload.name}`, action.payload.value);
    },
    setTicketFilesValue: (state, action: PayloadAction<File>) => {
      const newId = state.ticketForm.file.length + 1;
      state.ticketForm.file.push({
        id: String(newId),
        name: action.payload.name,
        type: action.payload.type,
      });
    },
    toggleSelectTicket: (state, action: PayloadAction<string>) => {
      const ticketId = action.payload;
      state.selectedTickets = state.selectedTickets.includes(ticketId)
        ? state.selectedTickets.filter((id) => id !== ticketId)
        : [...state.selectedTickets, ticketId];
    },
    toggleSelectTicketByGroup: (state, action: PayloadAction<TicketGroup>) => {
      const selectedGroup = action.payload;

      const selectedId = selectedGroup.tickets.map((x) => x.id);
      state.selectedTickets = selectedId;
    },
    resetSelectedTickets: (state) => {
      state.selectedTickets = [];
    },
    fetchBoardsHomePage: (state) => {
      state.isLoading = true;
    },
    setBoardsHomePage: (state, action: PayloadAction<{ favorites: Object[],
      qaBoards: Object[],
      recent: Object[],
      boardsData: Board[]
     }>) => {
      state.favorites = action.payload.favorites;
      state.qaBoards = action.payload.qaBoards;
      state.recent = action.payload.recent;
      state.boardsData = action.payload.boardsData;
      state.isLoading = false;
    },

    setSortByColumn: (state, action: PayloadAction<string>) => {
      state.sortByColumn = action.payload;
    },
    setSortByOrder: (state, action: PayloadAction<string>) => {
      state.sortByOrder = action.payload as sortOrder;
    },
    setSortedBoardsData: (state) => {
      const sortedData = [...state.boardsData].sort((a, b) => {
        let valA = a[state.sortByColumn]
          ? a[state.sortByColumn].toString().toLowerCase()
          : "";
        let valB = b[state.sortByColumn]
          ? b[state.sortByColumn].toString().toLowerCase()
          : "";

        if (valA < valB) return state.sortByOrder === sortOrder.ASC ? -1 : 1;
        if (valA > valB) return state.sortByOrder === sortOrder.ASC ? 1 : -1;
        return 0;
      });
      state.boardsData = sortedData;
    },
    renameBoardName: (state, action: PayloadAction<{ id: string | undefined; name: string }>) => {
      const boardIndex = state.boardsData.findIndex((board) => board._id === action.payload.id);
      state.boardsData[boardIndex].name = action.payload.name;
    }
  },
});

export const { actions, reducer: boardsReducer, name: boardsSlice } = formSlice;
