import Card from "app/components/Card";
import React from "react";
import { Ticket } from "../../../types";
import { getTicketIcon } from "../containers/helpers";

import { useQueryState } from "nuqs";
import ItemAction from "./ItemAction";
import TicketActions from "./TicketActions";
import ColumnCustomize from "./ColumnCustomize";


interface TicketProps {
  ticket: Ticket;
  isSelected?: boolean;
  onCheckboxClick?: (ticketId: string) => void;
  status: string;
}

export default function TicketCard({
  ticket,
  isSelected,
  onCheckboxClick,
  status,
}: TicketProps) {
  const [, setTid] = useQueryState("tid");
  return (
    <Card
      key={ticket.id}
      className={`${
        isSelected ? "border-primary !bg-[#F1F7FF]" : ""
      } rounded-[6px] pl-[10px] pr-[8px] py-[12px] cursor-pointer hover:border-primary hover:border  relative`}
    >
      <div className="flex flex-col gap-[12px]">
        <div className="flex flex-row gap-[4px] group relative">
          <div className="min-w-[20px]">
            <img
              src={getTicketIcon(ticket?.type)}
              alt="task-icon"
              className="size-[16px]"
            />
          </div>
          <div className="text-xs leading-[15px] font-normal text-black text-wrap line-clamp-2 group-hover:!text-primary"  onClick={() => {
              setTid(ticket.id);
            }}>
            {ticket?.name}
          </div>
          <ItemAction
            id={ticket?.id}
            isSelected={isSelected as boolean}
            onSelectTicket={(id) => {
              onCheckboxClick?.(id);
            }}
          />
        </div>

        <TicketActions ticket={ticket} status={status} />
        <ColumnCustomize ticket={ticket} />
      </div>
    </Card>
  );
}
