import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import { actions } from "app/containers/OnBoarding/redux/slice";
import {
  selectTimezones,
  selectIsloading,
  selectPagination,
} from "app/containers/OnBoarding/redux/selector";
import { LocationIcon } from "app/components/Icons/LocationIcon";
import { SearchIcon } from "app/components/Icons";
import RightCaretIcon from "app/components/Icons/RightCaretIcon";
import { TimeZones } from "app/containers/OnBoarding/types";
import Spinner from "app/components/Icons/Spinner";

const TimeZoneSelection = () => {
  const [searchValue, setSearchValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState<string | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // User's timezone
  const dispatch = useDispatch();
  const timeZoneList = useSelector(selectTimezones);
  const loading = useSelector(selectIsloading);
  const pagination = useSelector(selectPagination);
  const hasMorePages = pagination && pagination.page < pagination.totalPage;

  // Debounced search handler
  const debouncedSearch = debounce((searchTerm: string) => {
    dispatch(
      actions.getTimeZones({
        search: searchTerm,
        page: 1,
        perPage: pagination.perPage,
      })
    );
  }, 500);

  // Auto-select timezone on mount
  useEffect(() => {
    dispatch(
      actions.getTimeZones({
        search: systemTimeZone,
        page: 1,
        perPage: pagination.perPage,
      })
    );

    setTimeout(() => {
      dispatch(
        actions.getTimeZones({
          search: "",
          page: 1,
          perPage: pagination.perPage,
        })
      );
    }, 2500);
  }, [systemTimeZone]);

  // Update selected timezone when list updates
  useEffect(() => {
    if (!selectedTimezone && timeZoneList.length > 0) {
      const match = timeZoneList.map((ele) => {
        if (ele.utc[0] === systemTimeZone) {
          ele.utc[0] = systemTimeZone;
        }
        return ele;
      });

      if (match) {
        setSelectedTimezone(match[0].text);
        dispatch(
          actions.setProfileFormValue({ name: "timezone", value: match[0]?._id })
        );
      }
    }
  }, [timeZoneList, selectedTimezone, systemTimeZone]);



  const handleSelectTimezone = (timezone: TimeZones) => {
    setSelectedTimezone(timezone.text);
    dispatch(
      actions.setProfileFormValue({ name: "timezone", value: timezone._id })
    );
    setShowDropdown(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    debouncedSearch(e.target.value);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleScroll = () => {
    if (!containerRef.current || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollTop + clientHeight >= scrollHeight * 0.9 && hasMorePages) {
      dispatch(
        actions.getTimeZones({
          page: pagination.page + 1,
          perPage: pagination.perPage,
          search: searchValue,
        })
      );
    }
  };

  return (
    <div ref={ref} className="relative w-full">
      <div className="text-xs !leading-[21px] font-normal !text-[#79808A]">
        Country/Timezone
      </div>

      <div
        className="flex items-center justify-between p-3 border border-gray-300 rounded-md cursor-pointer bg-white"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <div className="flex items-center gap-2">
          <LocationIcon size={17} color="#79808A" />
          <span className="text-gray-500 text-sm">
            {selectedTimezone || "Choose country/timezone"}
          </span>
        </div>
        <div
          className={`transform transition-transform ${
            showDropdown ? "rotate-90" : "rotate-0"
          }`}
        >
          <RightCaretIcon size={20} />
        </div>
      </div>

      {showDropdown && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg">
          <div className="p-1 border-b border-[#E9ECF2]">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <SearchIcon color="#BDC0C4" size={"20"} />
              </div>
              <input
                type="text"
                className="w-full pl-10 pr-3 py-2 text-sm focus:outline-none focus:ring-0"
                placeholder="Search by country/timezone name"
                value={searchValue}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div
            className="max-h-[300px] overflow-y-auto"
            ref={containerRef}
            onScroll={handleScroll}
          >
            {timeZoneList.length > 0 ? (
              timeZoneList.map((timezone) => (
                <div
                  key={timezone.value}
                  className="flex flex-row items-center w-full p-3 cursor-pointer hover:bg-gray-100"
                  onClick={() => handleSelectTimezone(timezone)}
                >
                  <span className="text-sm text-[#79808A]">
                    {timezone.text}
                  </span>
                </div>
              ))
            ) : (
              <div className="p-3 text-sm text-[#79808A]">No results found</div>
            )}

            {loading && (
              <div className="flex justify-center items-center p-4">
                <Spinner size="small" />
              </div>
            )}

            {!loading && !hasMorePages && timeZoneList.length > 0 && (
              <div className="p-2 text-xs text-center text-gray-500">
                No more timezones to load
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeZoneSelection;
