import PlusIcon from "app/components/Icons/PlusIcon";
import Input from "app/components/Input";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTicketForm } from "../../redux/selector";
import { actions } from "../../redux/slice";
import ContextFields from "./ContextFields";
import CustomFields from "./CustomFields";
import Attachments from "./Attachments";

const Index = () => {
  const dispatch = useDispatch();
  const form = useSelector(selectTicketForm);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(actions.setTicketFormValue({ name: name, value: value }));
  };

  return (
    <React.Fragment>
      <div className="flex flex-col gap-4 sticky top-0 z-50 bg-white">
        <div className="flex flex-row items-center px-[24px] py-4 border-b border-gray-100">
          <p className="text-xs leading-[21px] font-normal text-secondary">
            <span>Digital Innovators Collective</span> /{" "}
            <span className="text-success">TechMate</span>
          </p>
        </div>
        <div className="flex flex-col px-[24px] py-4 pb-0">
          <Input
            placeholder="Enter task name"
            id="name"
            inputClassName="!h-[27px] border-none !px-0 rounded-none"
            className="!text-lg !leading-[27px] rounded-none placeholder:!text-lg placeholder:!leading-[27px]"
            name="name"
            value={form?.name}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="flex flex-col px-[24px] py-4 pt-[12px] gap-4">
        <ContextFields />
        <div className="flex flex-col">
          <Input
            placeholder="Enter ticket description..."
            id="task-description"
            inputClassName="!h-[42px]"
            name="description"
            value={form.description}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col w-full gap-4">
          <CustomFields />
        </div>
      </div>

      <Attachments />
    </React.Fragment>
  );
};

export default Index;
