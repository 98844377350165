import React from 'react'
import List from '../containers/List'
import Kaban from '../containers/Kaban'
import CollapseCard from '../../Home/components/CollapseCard'

export default function Tickets({
    id
}: {
    id: string
}) {
    return (
        <>
           
            {
                id && (parseInt(id) > 2) ? 
                <Kaban /> : 
                <List />
            }
        </>
    )
}
