import React from "react";
import CustomTooltip from "app/components/Tooltip";
import ic_id from "assets/imgs/id.png";

interface CustomProps {
  value: string;
}

const TicketId: React.FC<CustomProps> = ({ value }) => {
  return (
    <>
      <button className="flex items-center justify-between w-full ">
        <CustomTooltip text="Ticket ID" className="flex items-center ">
          <div className="flex items-center gap-2 w-full">
            <img
              src={ic_id}
              alt="id-icon"
              className="size-[16px] min-w-[16px] min-h-[16px]"
            />
            <span className="text-xs font-normal text-black">{value}</span>
          </div>
        </CustomTooltip>
      </button>
    </>
  );
};

export default TicketId;
