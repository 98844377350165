import Dropdown from "app/components/Dropdown";
import { CheckIcon, Input, SearchIcon } from "app/components/Icons";
import MemberProfile from "app/components/MemberProfile";
import React from "react";

interface CustomProps {
  value?: string;
  setValue?: (value: string) => void;
  searchKeyword: string;
  setSearchKeyword: (val: string) => void;
  data: any;
  selectedValue: string;
  updateValue: (id: string) => void;
  trigger?: React.ReactNode;
  align?: "start" | "end" | "center";
  isHeader?: string;
  searchPlaceholder?: string;
}

const CommonSprints: React.FC<CustomProps> = ({
  setValue,
  value,
  searchKeyword,
  setSearchKeyword,
  data,
  selectedValue,
  updateValue,
  trigger,
  align = "center",
  
  isHeader,
  searchPlaceholder,
}) => {
  const MenuItems = data?.map((ele) => {
    if (ele)
      return {
        label: (
          <MemberProfile
            name={ele.name}
            badge={ele.status}
            statusColor="#F00"
            endIcon={<CheckIcon color="#5A8DF1" />}
            showEndIcon={value === ele.id}
                  iconHide
                  className="ps-0"
            titleClassName="flex flex-row items-center gap-2"
            badgeClassName="rounded-full bg-[#5DA2FF1A] !text-[#5A8DF1] text-xs"
          />
        ),
        action: () => {
          setValue && setValue(ele?.id);
        },
      };
    else return { label: "", action: () => {} };
  });
  return (
    <>
      <Dropdown
        trigger={trigger}
        projectInfoIcon={
          <div className="flex flex-col gap-2">
            <Input
              noBorder
              id={searchKeyword ?? "search"}
              icon={<SearchIcon size="17" color="#79808A" />}
              placeholder={searchPlaceholder ?? "Search by name"}
              value={searchKeyword}
              onChange={(e) => {
                e.stopPropagation();
                setSearchKeyword(e.target.value);
              }}
              innerContainerClassName="px-2 pb-1 border-b-[1px] border-[#79808A]"
            />
            {isHeader && (
              <div className="flex items-center justify-between px-4 py-3 text-secondary tracking-[0.56px] text-sm font-semibold leading-[21px] uppercase border-b border-[#E9ECF2]">
                <span>{isHeader}</span>
              </div>
            )}
          </div>
        }
        menuItems={MenuItems ? MenuItems : []}
        align={align}
        className="py-2 w-[max-content]"
        dropdownContainerClassName="max-h-[250px] overflow-scroll w-[max-content] "
      />
    </>
  );
};

export default CommonSprints;
