import NavMenu from "app/components/NavMenu";
import Table from "app/components/Table";
import React, { useMemo } from "react";
import Rows from "./Rows";
import { TableHeader } from "app/components/Table/types";
import { useQueryState } from "nuqs";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions } from "../../../redux/slice";
import { useSelector } from "react-redux";
import { selectIsLoading, selectSortByColumn, selectSortByOrder } from "../../../redux/selector";
import { sortOrder } from "app/types";

const headers: TableHeader[] = [
  {
    name: "Board name",
    id: "name",
    align: "left",
    sortable: true,
  },
  {
    name: "Last viewed",
    id: "lastViewed",
    align: "left",
  },
  {
    name: "Last updated",
    id: "lastUpdated",
    align: "left",
  },
  {
    name: "Owner",
    id: "owner",
    align: "left",
  },
  {
    name: "Date created",
    id: "createdAt",
    align: "left",
  },
];

export default function List() {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const sortByColumn = useSelector(selectSortByColumn);
  const sortByOrder = useSelector(selectSortByOrder);
  const loading = useSelector(selectIsLoading);
  const pathName = window.location.pathname;
  const type = searchParams.get("type") || "all";
  const options = useMemo(() => {
    return [
      {
        title: "all",
        icon: null,
        link: `${pathName}?type=all`,
        isActive: type === "all",
      },
      {
        title: "my boards",
        icon: null,
        link: `${pathName}?type=my-boards`,
        isActive: type === "my-boards",
      },
    ];
  }, [type]);

  const handleSort = (sortBy:string) => {
    const newSortOrder = sortByOrder === sortOrder.ASC ? sortOrder.DESC : sortOrder.ASC;
    dispatch(actions.setSortByColumn(sortBy));
    dispatch(actions.setSortByOrder(newSortOrder));
    dispatch(actions.setSortedBoardsData());
  };
  return (
    <div className="flex flex-col gap-[10px] overflow-visible">
      <NavMenu options={options} />
      <div className="overflow-visible">
        <Table 
          headers={headers} 
          handleSort={handleSort}
          sortByOrder={sortByOrder}
          sortByColumn={sortByColumn}
          loading={loading}
          >
          <Rows />
        </Table>
      </div>
    </div>
  );
}
