import React from 'react';
import checkbox from '../../../assets/svgs/ic_checkbox_border.svg';
import checked from '../../../assets/svgs/ic_checked.svg';

interface MultiSelectProps {
    readonly options: {
        name: string;
        icon: string;
        _id: string;
    }[];
    readonly selected: string[];
    readonly onChange: (option: string[]) => void;
    readonly className?: string;
}

export default function MultiSelect({
    options,
    selected = [],
    onChange,
    className
}: MultiSelectProps) {
    const handleSelect = (optionId: string) => {
        if (selected.includes(optionId)) {
            onChange(selected.filter((item) => item !== optionId));
        } else {
            onChange([...selected, optionId]);
        }
    };

    return (
        <div className={`flex flex-row items-center justify-center flex-wrap gap-y-[18px] gap-x-[16px] ${className}`}>
            {
                options.map((option) => (
                    <button
                        onClick={() => handleSelect(option._id)}
                        className={`cursor-pointer flex flex-row gap-[10px] h-[65px] items-center justify-center px-8 py-6 border border-gray-300 rounded-[4px] relative hover:bg-[#F4F5F7] ${selected.includes(option._id) ? 'border-primary bg-primary100' : ''}`}
                        key={option._id}
                    >
                        <img src={option.icon} alt={option.name} width={42} height={42} />
                        <span className={`text-[24px] leading-[24px] font-medium text-center ${selected.includes(option._id) ? 'text-primary' : 'text-secondary'}`}>
                            {option.name}
                        </span>
                        {
                            selected.includes(option._id) ? (
                                <img src={checked} alt="Checked" width={32} height={32} />
                            ) : (
                                <img src={checkbox} alt="Checkbox" width={32} height={32} />
                            )
                        }
                    </button>
                ))
            }
        </div>
    );
}
