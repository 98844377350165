/**
 * Boards selectors
 */

import { RootState } from "types";

import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./initialState";



// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state?.appHomeState || initialState;

export const selectIsAuthenticated = createSelector(
  [selectDomain],
  (AppState) => AppState.isAuthenticated
);
export const selectUserFormData = createSelector(
  [selectDomain],
  (AppState) => AppState.userFormData,
);
export const selectUserDetailsData= createSelector(
  [selectDomain],
  (AppState) => AppState.userDetails,
);
export const selectSelectedTeam= createSelector(
  [selectDomain],
  (AppState) => AppState.selectedTeam,
);
export const selectSelectedProject= createSelector(
  [selectDomain],
  (AppState) => AppState.selectedProject,
);
export const selectIsBoardCreate= createSelector(
  [selectDomain],
  (AppState) => AppState.isBoardCreate,
);

export const selectAuthorizedAccounts = createSelector(
  [selectDomain],
  (AppState) => AppState.authorizedAccounts
);

export const selectUser = createSelector(
  [selectDomain],
  (AppState) => AppState.user
);

export const selectDrawerOpen = createSelector(
  [selectDomain],
  (AppState) => AppState.drawerOpen
);

export const selectLoading = createSelector(
  [selectDomain],
  (AppState) => AppState.loading
);
export const selectError = createSelector(
  [selectDomain],
  (AppState) => AppState.error
);

export const selectProjectDetails = createSelector(
  [selectDomain],
  (AppState) => AppState.project
);

export const selectTeamForm = createSelector(
  [selectDomain],
  (AppState) => AppState.teamForm
);

export const selectTeamProjectName = createSelector(
  [selectDomain],
  (AppState) => AppState.teamForm.project.name
);

export const selectTeamProjectDefaultCustomization = createSelector(
  [selectDomain],
  (AppState) => AppState.teamForm.project.defaultCustomization
);

export const selectTeamFormActiveIndex = createSelector(
  [selectDomain],
  (AppState) => AppState.teamFormActiveIndex
);

export const selectUserOnboardingStage = createSelector(
  [selectDomain],
  (AppState) => AppState.userOnboardingProgress
);

export const selectToken = createSelector(
  [selectDomain],
  (AppState) => AppState.access_token
);
