import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import TicketGroup from "../../components/TicketGroup";
import BacklogIcon from "app/components/Icons/BacklogIcon";
import { HEADERS, TICKETS } from "./data";
import Planning from "./components/Planning";
import PaneSwitchOffIcon from "app/components/Icons/PaneSwitchOffIcon";
import { useDispatch, useSelector } from "react-redux";
import { selectBoardsFormData, selectSelectedTickets } from "../../redux/selector";
import { actions } from "../../redux/slice";
import { actions as listAction } from "../Tickets/redux/slice";
import { TICKET_HEADERS } from "../../data";
import { selectTicketGroups } from "../Tickets/redux/selector";
import Row from "../Home/containers/components/Row";
import { TableHeader } from 'app/components/Table/types';
import { GroupByTypeEnum } from "../../types";

export default function Backlog() {
  const dispatch = useDispatch();
  const selectedTickets = useSelector(selectSelectedTickets);
  const [showPlanning, setShowPlanning] = useState(false);
  const groups = useSelector(selectTicketGroups);
  const boardData = useSelector(selectBoardsFormData);

   useEffect(() => {
          dispatch(listAction.fetchTicketGroups());
      }, [dispatch]);

  const handleToggleSelection = (ticketId: string) => {
    dispatch(actions.toggleSelectTicket(ticketId));
  };  
  const handleDrop = (ticketId: string, targetStatus: string) => {
          // Find the source status by looking through all groups
          const sourceGroup = groups.find(group => 
              group.tickets.some(ticket => ticket.id === ticketId)
          );
          
          if (!sourceGroup || sourceGroup.status.name === targetStatus) return;
  
          dispatch(listAction.updateTicketStatus({
              ticketId,
              sourceStatus: sourceGroup.status.name,
              targetStatus
          }));
      };
  const updatedColumns = boardData.customizeCard
        .filter((x) => x.isVisible && x.type !== GroupByTypeEnum.TAGS)
        .map((field) => {
          // Check if field already exists in tableColumns
  
          const existingColumn = TICKET_HEADERS.find(
            (col) => col.name === field.label
          );
          return (
            existingColumn || {
              name:
                field.type === GroupByTypeEnum.TICKET_NAME ? "Name" : field.label,
              id: field.type,
              resizable: true,
              className: "!p-[4px] text-xs leading-[18px] font-medium",
            }
          );
    });


  return (
    <Layout>
      <div className="ml-[38px] flex gap-[16px] h-[calc(100vh-200px)]">
        <div className="flex-grow min-w-[410px] md:min-w-[600px] lg:min-w-[800px]">
          {   
                groups.map((group) => (
                    <TicketGroup 
                        key={group.status.name}
                        icon={<BacklogIcon/>}
                        title={group.status.name}
                        count={group.tickets.length}
                        headers={updatedColumns as TableHeader[]}
                        color={group.status.color}
                        onDrop={(ticketId) => handleDrop(ticketId, group.status.name)}
                        groupStatus={group.status}
                        maxCount={group?.status?.ticketLimit}
                        group={group}
                    >
                        {
                            group.tickets.map((ticket) => (
                                <Row key={ticket.id} 
                                ticket={ticket} 
                                isSelected={selectedTickets.includes(ticket.id)}
                                onCheckboxClick={handleToggleSelection}
                                td={updatedColumns as any}
                                />
                            ))
                        }
                    </TicketGroup>
                ))
            }
        </div>
        {!showPlanning && (
          <button className="size-[20px] mt-[3px]" onClick={() => setShowPlanning(true)}>
            <PaneSwitchOffIcon />
          </button>
        )}
        {showPlanning && <Planning onClose={() => setShowPlanning(false)} />}
      </div>
    </Layout>
  );
}
