import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";

const ProjectSettings = () => {
 const params = useParams();
  const id = params?.id;
  useEffect(() => {
    if (!id) {
      console.log(id, "Outletid");
    }

    return () => {};
  }, [id]);
  



  return <Outlet />;
};

export default ProjectSettings;
