import React from "react";
import { CrossIcon } from "app/components/Icons";
import CloseFiled from "app/components/Icons/CloseFiled";
import CustomTooltip from "app/components/Tooltip";
import checkFiledIcon from "assets/svgs/circleCheckedIcon.svg";
import minimizeIcon from "assets/svgs/minimize-icon.svg";

interface AddTaskHeaderProps {
  isDrafted: boolean;
  handleSetDraft: () => void;
  handleClose: () => void;
  handleRemoveDraft?: () => void;
}

const AddTaskHeader: React.FC<AddTaskHeaderProps> = ({
  isDrafted,
  handleSetDraft,
  handleClose,
  handleRemoveDraft,
}) => {
  const [mouseEnter, setMouseEnter] = React.useState(false);
  return (
    <div className="flex justify-between items-center bg-[#F4F5F7] border-b border-[#E9ECF2] px-6 py-4">
      <h3 className="text-lg font-semibold text-gray-800">Add task</h3>
      <div className="flex items-center gap-3">
        <div className="relative ">
          <button
            className="flex items-center justify-center size-[25px] min-w-[25px]"
            onClick={() => {
              handleSetDraft();
            }}
          >
            <CustomTooltip text="Minimize draft" position="bottom">
              <img src={minimizeIcon} alt={minimizeIcon} sizes="20px" />
            </CustomTooltip>
          </button>
          {isDrafted && (
            <div
              className={`size-[10px]  rounded-full absolute bottom-[0px] right-[0px] cursor-pointer`}
              onMouseEnter={() => setMouseEnter(true)}
              onMouseLeave={() => setMouseEnter(false)}
            >
              {mouseEnter ? (
                <CustomTooltip
                  text="Delete from tray"
                  position="bottom"
                  className="flex items-center justify-center size-[10px] min-w-[10px]"
                >
                  <button onClick={() => handleRemoveDraft?.()}>
                    <CloseFiled size={10} color="red" />
                  </button>
                </CustomTooltip>
              ) : (
                <img src={checkFiledIcon} alt={checkFiledIcon} sizes="40px" />
              )}
            </div>
          )}
        </div>

        <button onClick={handleClose} className="p-1">
          <CrossIcon size="15" />
        </button>
      </div>
    </div>
  );
};

export default AddTaskHeader;
