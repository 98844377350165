import Dropdown from "app/components/Dropdown";
import { CheckIcon } from "app/components/Icons";
import MemberProfile from "app/components/MemberProfile";
import React from "react";
import {
  priorityOptions
} from "../containers/Boards/data";
import { PriorityFilterEnum } from "../containers/Ticket/types";

interface CustomProps {
  value?: PriorityFilterEnum;
  setValue?: (value: PriorityFilterEnum) => void;
  trigger: React.ReactNode;
  align?: "start" | "end" | "center";
  isHeader?: string;
}

const CommonPrioritySelection: React.FC<CustomProps > = ({
  setValue,
  value,
  trigger,
  align="center",
  isHeader,
}) => {
  const MenuItems = priorityOptions?.map((ele) => {
    if (ele)
      return {
        label: (
          <MemberProfile
            showStartIcon
            startIcon={
              ele.icon
            }
            name={ele.label}
            statusColor="#F00"
            endIcon={<CheckIcon color="#5A8DF1" />}
            showEndIcon={value === ele.value}
            iconHide
          />
        ),
        action: () => {
          setValue && setValue(ele?.value);
        },
      };
    else return { label: "", action: () => {} };
  });
  return (
    <>
      <Dropdown
        trigger={trigger}
        projectInfoIcon={
          <div className="flex flex-col gap-2">
            {isHeader && (
              <div className="flex items-center justify-between px-4 py-3 text-secondary tracking-[0.56px] text-sm font-semibold leading-[21px] uppercase border-b border-[#E9ECF2]">
                <span>{isHeader}</span>
              </div>
            )}
          </div>
        }
        menuItems={MenuItems ? MenuItems : []}
        align={align}
        className="py-2"
      />
    </>
  );
};

export default CommonPrioritySelection;
