import {
  BarChartIcon,
  BlocksIcon,
  CalendarIcon,
  ComponentIcon,
  CreatorIcon,
  DuplicateIcon,
  EpicIcon,
  FlagIcon,
  SprintIcon,
  StatusIcon,
  TagIcon,
  TicketsIcon,
  UserIcon,
} from "app/components/Icons";
import EnvironmentIcon from "app/components/Icons/EnvironmentIcon";
import NoneIcon from "app/components/Icons/NoneIcon";
import ReleaseIcon from "app/components/Icons/ReleaseIcon";
import TargetIcon from "app/components/Icons/TargetIcon";
import TimeIcon from "app/components/Icons/TimeIcon";
import epicIcon from "assets/svgs/ic_epic.svg";
import descriptionIcon from "assets/svgs/ic_description.svg";
import dropdownIcon from "assets/svgs/dropdown-icon.svg";
import storyIcon from "assets/svgs/ic_task_book.svg";
import bugIcon from "assets/svgs/ic_task_bug.svg";
import taskIcon from "assets/svgs/ic_task_check.svg";
import longTextIcon from "assets/svgs/long-text-input.svg";
import TicketNameIcon from "assets/svgs/ticket-name.svg";
import attachmentIcon from "assets/svgs/vectoricon.svg";
import ic_id from "assets/imgs/id.png";
import React from "react";
import {
  GroupByTypeEnum,
  RelationType,
  SubGroupEnum,
  TicketType,
  TicketValueEnum,
} from "../../../../types";
import { HarshIcon } from "app/components/Icons/HarshIcon";
import BlockIcon from "app/components/Icons/BlockIcon";
import { PriorityFilterEnum, StatusFilterEnum } from "app/containers/Features/containers/Ticket/types";

export const getTicketIcon = (type: TicketType) => {
  switch (type) {
    case TicketType.BUG:
      return bugIcon;
    case TicketType.STORY:
      return storyIcon;
    case TicketType.TASK:
      return taskIcon;
    case TicketType.EPIC:
      return epicIcon;
    default:
      return taskIcon;
  }
};
export const getRelationIcon = (type: RelationType) => {
  switch (type) {
    case RelationType.BLOCKED_BY:
      return <BlockIcon />;
    case RelationType.BLOCKS:
      return <BlocksIcon />;
    case RelationType.DUPLICATE:
      return <DuplicateIcon />;
  }
};
export const getSubGroupIcon = (type: SubGroupEnum) => {
  switch (type) {
    case SubGroupEnum.NONE:
      return <NoneIcon size={20} color="#79808A" />;
    case SubGroupEnum.ASSIGNEE:
      return <UserIcon size={20} color="#79808A" />;

    case SubGroupEnum.PRIORITY:
      return <FlagIcon size={20} color="#79808A" />;
    case SubGroupEnum.TAGS:
      return <TagIcon size={18} color="#79808A" />;
    case SubGroupEnum.DUE_DATE:
      return <CalendarIcon size={20} color="#79808A" />;
    case SubGroupEnum.EPIC:
      return <EpicIcon size={20} color="#79808A" />;
    default:
      return;
  }
};
export const getStatusIcon = (type: StatusFilterEnum) => {
  switch (type) {
    case StatusFilterEnum.CLOSED:
      return <StatusIcon size={ 18 } color = "#A394FB" />
    case StatusFilterEnum.DUPLICATE:
      return <StatusIcon size={18} color="#FF8FC5" />

    case StatusFilterEnum.IN_PROCESS:
      return  <StatusIcon size={18} color="#FEB355" />;
    case StatusFilterEnum.OPENED:
      return <StatusIcon size={18} color="#43D98C" />;
    default:
      return;
  }
};
export const getPriorityIcon = (type: PriorityFilterEnum) => {
  switch (type) {
    case PriorityFilterEnum.HIGH:
      return <StatusIcon size={ 18 } color = "#FF8A00" />
    case PriorityFilterEnum.LOW:
      return <StatusIcon size={18} color="#20C6EB" />

    case PriorityFilterEnum.NORMAL:
      return  <StatusIcon size={18} color="#2AD95B" />;
    case PriorityFilterEnum.URGENT:
      return <FlagIcon size={18} color="#FF4F4F" />;
    default:
      return;
  }
};
export const getCustomCardIcon = (id: GroupByTypeEnum) => {
  switch (id) {
    case GroupByTypeEnum.TICKET_NAME:
      return <TicketsIcon size={20} color="#e7e7e7" />;
    case GroupByTypeEnum.TAGS:
      return <TagIcon size={20} color="#79808A" />;
    case GroupByTypeEnum.ASSIGNEE:
      return <UserIcon size={20} color="#79808A" />;
    case GroupByTypeEnum.TICKET_ID:
      return<img
      src={ic_id}
      alt="id-icon"
      className="size-[16px] min-w-[16px] min-h-[16px]"
    />
    case GroupByTypeEnum.TICKET_TYPE:
      return <TicketsIcon size={20} color="#79808A" />;
    case GroupByTypeEnum.STATUS:
      return <StatusIcon size={20} color="#79808A" />;
    case GroupByTypeEnum.DUE_DATE:
      return <CalendarIcon size={20} color="#79808A" />;
    case GroupByTypeEnum.PRIORITY:
      return <FlagIcon size={20} color="#79808A" />;
    case GroupByTypeEnum.SPRINT:
      return <SprintIcon size={20} color="#79808A" />;
    case GroupByTypeEnum.RELEASE:
      return <ReleaseIcon size={20} color="#79808A" />;
    case GroupByTypeEnum.TARGET_RELEASE:
      return <TargetIcon size={20} color="#79808A" />;
    case GroupByTypeEnum.ENVIRONMENT:
      return <EnvironmentIcon size={20} color="#79808A" />;
    case GroupByTypeEnum.SEVERITY:
      return <BarChartIcon size={20} color="#79808A" />;
    case GroupByTypeEnum.COMPONENT:
      return <ComponentIcon size={20} color="#79808A" />;
    case GroupByTypeEnum.CREATOR:
      return <CreatorIcon size={20} color="#79808A" />;
    case GroupByTypeEnum.CREATED_DATE:
      return <CalendarIcon size={20} color="#79808A" />;
    case GroupByTypeEnum.EFFORTS_ESTIMATION:
      return <TimeIcon size={20} color="#79808A" />;
    case GroupByTypeEnum.EFFORT_ACTUAL:
      return <TimeIcon size={20} color="#79808A" />;

    default:
      return;
  }
};
export const getTicketValueIcon = (type: TicketValueEnum) => {
  switch (type) {
    case TicketValueEnum.TICKET_NAME:
      return (
        <img src={TicketNameIcon} alt="ticket-name" height={22} width={22} />
      );
    case TicketValueEnum.STATUS:
      return <StatusIcon size={20} color="#79808A" />;
    case TicketValueEnum.ASSIGNEE:
      return <UserIcon size={20} color="#79808A" />;
    case TicketValueEnum.DUE_DATE:
      return <CalendarIcon size={20} color="#79808A" />;
    case TicketValueEnum.PRIORITY:
      return <FlagIcon size={20} color="#79808A" />;
    case TicketValueEnum.TAGS:
      return <TagIcon size={20} color="#79808A" />;
    case TicketValueEnum.TICKET_DESCRIPTION:
      return (
        <img
          src={descriptionIcon}
          alt={descriptionIcon}
          height={22}
          width={22}
        />
      );
    case TicketValueEnum.COMMENT:
      return (
        <img src={TicketNameIcon} alt={TicketNameIcon} height={22} width={22} />
      );
    case TicketValueEnum.NOTES:
      return (
        <img src={longTextIcon} alt={longTextIcon} height={22} width={22} />
      );
    case TicketValueEnum.CUSTOM_PRIORITY:
      return (
        <img src={dropdownIcon} alt={dropdownIcon} height={22} width={22} />
      );
    case TicketValueEnum.CREATED_DATE:
      return <CalendarIcon size={20} color="#79808A" />;
    case TicketValueEnum.NUMBER:
      return <HarshIcon size={20} color="#79808A" />;
    case TicketValueEnum.ID:
      return <HarshIcon size={20} color="#79808A" />;
    case TicketValueEnum.URL:
      return (
        <img src={attachmentIcon} alt={attachmentIcon} height={22} width={22} />
      );

    default:
      return;
  }
};
