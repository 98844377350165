import AdaptiveDateRangePicker from "app/components/AdaptiveDateRangePicker";
import TimeIcon from "app/components/Icons/TimeIcon";
import CustomTooltip from "app/components/Tooltip";
import { format } from "date-fns";
import React, { useEffect } from "react";

interface CustomProps {
  startDate?: string;
  endDate?: string;
}

const DueDate: React.FC<CustomProps> = ({ endDate, startDate }) => {
  const [selectedDates, setSelectedDates] = React.useState<Date[]>([]);
  const onDateChange = (startDate?: string, endDate?: string) => {

    // Update start date // it's implement when API integrate
    // dispatch(
    //   actions.setTicketFormValue({
    //     name: "startDate",
    //     value: startDate || "",
    //   })
    // );
    // // Update end date
    // dispatch(
    //   actions.setTicketFormValue({
    //     name: "endDate",
    //     value: endDate || "",
    //   })
    // );
  };
  useEffect(() => {
    const dates: Date[] = [];
    if (startDate) {
      dates.push(new Date(startDate));
    }
    if (endDate) {
      dates.push(new Date(endDate));
    }
    setSelectedDates(dates);
  }, [startDate, endDate]);

  const handleDateChange = (dates: Date[]) => {
    setSelectedDates(dates);

    const formattedStartDate = dates[0]
      ? format(dates[0], "dd MMM, yyyy")
      : undefined;
    const formattedEndDate = dates[1]
      ? format(dates[1], "dd MMM, yyyy")
      : undefined;

    onDateChange?.(formattedStartDate, formattedEndDate);
  };

  const dateLabel = () => {
    if (selectedDates.length > 0) {
      const formattedStart = selectedDates[0]
        ? format(selectedDates[0], "dd MMM, yyyy")
        : undefined;
      const formattedEnd = selectedDates[1]
        ? format(selectedDates[1], "dd MMM, yyyy")
        : undefined;

      if (!formattedStart && !formattedEnd) return "-";
      return `${formattedStart || ""} ${
        formattedEnd ? "- " + formattedEnd : ""
      }`.trim();
    }
    return "-";
  };
  return (
    <>
      <AdaptiveDateRangePicker
        trigger={
          <button className="flex items-center justify-between w-full px-2 py-2 rounded-md hover:bg-[#F4F5F7] rounded-[4px] data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A] ">
            <CustomTooltip
              text="Set due date"
              className="flex items-center "
              position="left"
            >
              <div className="flex items-center gap-2 w-full">
                <TimeIcon size={16} color="#79808A" />
                <span
                  className={`text-xs font-normal text-nowrap overflow-hidden text-ellipsis `}
                >
                  {dateLabel()}
                </span>
              </div>
            </CustomTooltip>
          </button>
        }
        selectedDates={selectedDates}
        setSelectedDates={handleDateChange}
      />
    </>
  );
};

export default DueDate;
