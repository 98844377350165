import { CheckIcon, CrossIcon } from "app/components/Icons";
import Input from "app/components/Input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "app/components/Popover";
import React from "react";

interface AddNewTagProps {
  trigger?: React.ReactNode;
  className?: string;
  align?: "start" | "center" | "end";
}

const AddNewTag: React.FC<AddNewTagProps> = ({ align, className, trigger }) => {
  const [value, setValue] = React.useState("");
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>{trigger}</PopoverTrigger>
        <PopoverContent
          align="start"
          alignOffset={50}
          className={`!px-0 !py-0 bg-white border border-[#E9ECF2] cursor-pointer`}
        >
          <Input
            id="value"
            name="value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            icon={<div className="w-4 h-4 bg-[#BB6BD9] rounded" />}
            endIcon={
              <div className="flex justify-end items-center gap-3">
                <button
                  onClick={() => {
                    setOpen(false); setValue("");
                  }}
                >
                  <CheckIcon color="#5A8DF1" />
                </button>
                <button
                  onClick={() => {
                    setOpen(false); setValue("");
                  }}
                >
                  <CrossIcon color="#79808A" />
                </button>
              </div>
            }
          />
        </PopoverContent>
      </Popover>
    </>
  );
};

export default AddNewTag;
