import Dropdown from "app/components/Dropdown";
import { BorderedDropdownIcon, CheckIcon } from "app/components/Icons";
import IconText from "app/components/IconText";
import MemberProfile from "app/components/MemberProfile";
import React, { useState } from "react";
import { PriorityFilterEnum } from "../../../types";
const DropdownNameSelect = () => {
  const [value, setValue] = useState("");

  const MenuItems = data?.map((ele) => {
    if (ele)
      return {
        label: (
          <MemberProfile
            name={ele.name}
            statusColor="#F00"
            endIcon={<CheckIcon color="#5A8DF1" />}
            showEndIcon={value === ele.id}
            iconHide
          />
        ),
        action: () => {
          setValue(ele?.id);
        },
      };
    else return { label: "", action: () => {} };
  });
  return (
    <>
      <div className="w-[196px] border border-gray-100 border-r-0 border-t-0 rounded-r-none h-[42px] flex flex-col justify-center pl-4 pr-2 rounded-none">
        <IconText
          icon={<BorderedDropdownIcon color="#79808A" />}
          text="Dropdown name*"
          className="gap-[4px]"
          textClassName="text-[12px] leading-[18px] font-sm text-gray-300"
        />
      </div>
      <div className="w-full !h-[42px]">
        <Dropdown
          trigger={
            <button className="h-full w-full w-full data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A] border-[1px] border-t-0 border-gray-100 text-start px-3 text-[12px] leading-[18px] font-sm text-gray-400">
              <div className="relative">
                {value ? (
                  value
                ) : (
                  <span className="text-muted-foreground">-</span>
                )}
              </div>
            </button>
          }
          projectInfoIcon={
            <div className="w-full text-md font-small text-gray-400 p-4 border-b border-gray-100">
              SELECT AN OPTIONS
            </div>
          }
          menuItems={MenuItems}
          align="start"
          //   sideOffset={10}
        />
      </div>
    </>
  );
};

export default DropdownNameSelect;

const data = [
  { id: PriorityFilterEnum.NONE, name: PriorityFilterEnum.NONE },
  { id: PriorityFilterEnum.NORMAL, name: PriorityFilterEnum.NORMAL },
  { id: PriorityFilterEnum.HIGH, name: PriorityFilterEnum.HIGH },
  { id: PriorityFilterEnum.URGENT, name: PriorityFilterEnum.URGENT },
];
