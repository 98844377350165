import Dropdown from "app/components/Dropdown";
import IconText from "app/components/IconText";
import { TICKETS } from "app/containers/Features/containers/Boards/containers/Backlog/data";
import { getTicketIcon } from "app/containers/Features/containers/Boards/containers/Home/containers/helpers";
import ic_id from "assets/imgs/id.png";
import React, { useState } from "react";

const TicketIDSelect = () => {
  const [value, setValue] = useState("");
  const MenuItems = TICKETS?.map((ele) => {
    if (ele)
      return {
        label: (
          <>
            <div className={`flex items-start gap-3 p-1 w-[100%]`}>
              <div className="min-w-[20px]">
                <img
                  src={getTicketIcon(ele?.type)}
                  alt="task-icon"
                  className="size-[20px]"
                />
              </div>
              <span className="text-sm leading-[18px] font-normal text-black">
                {ele?.id}
              </span>
              <div className="text-sm leading-[18px] font-normal text-black text-ellipsis overflow-hidden whitespace-nowrap">
                {ele?.name}
              </div>
            </div>
          </>
        ),
        action: () => {
          setValue(ele?.id);
        },
      };
    else return { label: "", action: () => {} };
  });
  return (
    <>
      <div className="w-[196px] border border-gray-100 border-t-0 border-r-0 rounded-r-none h-[42px] flex flex-col justify-center pl-4 pr-2 rounded-none">
        <IconText
          icon={
            <img
              src={ic_id}
              alt="id-icon"
              className="size-[16px] min-w-[16px] min-h-[16px]"
            />
          }
          text="Ticket ID*"
          className="gap-[4px]"
          textClassName="text-[12px] leading-[18px] font-medium text-secondary"
        />
      </div>
      <div className="w-full !h-[42px] relative">
        <Dropdown
          trigger={
            <button className="h-full w-full w-full data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A] border-[1px] border-t-0 border-gray-100 text-start px-3 text-[12px] leading-[18px] font-sm text-gray-400">
              <div className="relative">
                {value ? (
                  value
                ) : (
                  <span className="text-muted-foreground">-</span>
                )}
              </div>
            </button>
          }
          menuItems={MenuItems}
          align="start"
          dropDownClassName=""
          className="w-full"
        />
      </div>
    </>
  );
};

export default TicketIDSelect;
const data = [{ id: "", name: "" }];
