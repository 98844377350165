import { PayloadAction } from "@reduxjs/toolkit";
import { FormError, IBoardFixedTemp, IUserTeamsProjects } from "app/types";
import { createSlice } from "utils/@reduxjs/toolkit";
import {
  AuthorizedAccount,
  IUserBasicDetails,
  IUserData,
  IUserTeams,
  PLAN_TYPE,
  User,
  userOnboardingStage,
} from "../types";
import { initialState } from "./initialState";

const useClinicSlice = createSlice({
  name: "appHomeState",
  initialState,
  reducers: {
    doGetMyProfile: (state) => {},

    setUserFormData: (state, action: PayloadAction<IUserData>) => {
      state.userFormData = action.payload;
    },
    setSelectedTeam: (state, action: PayloadAction<IUserTeams | null>) => {
      state.selectedTeam = action.payload;
    },
    setSelectedProject: (state, action: PayloadAction<string>) => {
      var newData = state.selectedTeam?.projects.filter(
        (x) => x._id == action.payload
      );
      state.selectedProject =
        newData && newData.length > 0
          ? (newData[0] as IUserTeamsProjects)
          : null;
    },
    setUserWorkspaceData: (state, action: PayloadAction<IUserBasicDetails>) => {
      state.userDetails = action.payload;
      state.selectedTeam = action.payload.teams[0];
    },
    doGetUserWorkspace: (state) => {},
    setIsBoardCreate: (state, action: PayloadAction<boolean>) => {
      state.isBoardCreate = action.payload;
    },
    // board create API
    doCreateFixTempBoard: (
      state,
      action: PayloadAction<{ data: IBoardFixedTemp; callback: () => void }>
    ) => {},

    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setAuthorizedAccounts: (
      state,
      action: PayloadAction<AuthorizedAccount[]>
    ) => {
      state.authorizedAccounts = action.payload;
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.loading = false;
    },
    openDrawer(state, action: PayloadAction<any>) {
      state.drawerOpen = true;
      state.drawerPayload = action.payload || null;
    },
    closeDrawer(state) {
      state.drawerOpen = false;
      state.drawerPayload = null;
    },
    fetchUser(state) {
      state.loading = true;
      state.error = null;
    },
    fetchUserFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    setTeamFormValue: (
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      state.teamForm[action.payload.name].value = action.payload.value;
      state.teamForm[action.payload.name].error = "";
    },
    setTeamPlan: (state, action: PayloadAction<{ value: PLAN_TYPE }>) => {
      state.teamForm.plan = action.payload.value;
    },
    setTeamFormErrors: (state, action: PayloadAction<FormError[]>) => {
      action.payload.forEach((error) => {
        state.teamForm[error.name].error = error.error;
      });
    },
    setTeamProjectName: (state, action: PayloadAction<{ value: string }>) => {
      state.teamForm.project.name.value = action.payload.value;
      state.teamForm.project.name.error = "";
    },
    setTeamProjectDefaultCustomization: (
      state,
      action: PayloadAction<{ name: string; value: boolean }>
    ) => {
      state.teamForm.project.defaultCustomization[action.payload.name] =
        action.payload.value;
    },
    setTeamProjectNameError: (
      state,
      action: PayloadAction<{ error: string }>
    ) => {
      state.teamForm.project.name.error = action.payload.error;
    },
    addTeamMember: (state) => {
      state.teamForm.members = [
        ...state.teamForm.members,
        {
          name: {
            value: "",
            error: "",
          },
          role: {
            value: "",
            error: "",
          },
        },
      ];
    },
    setTeamCoupon: (state, action: PayloadAction<{ value: string }>) => {
      state.teamForm.coupon.value = action.payload.value;
      state.teamForm.coupon.error = "";
    },
    removeTeamMember: (state, action: PayloadAction<{ index: number }>) => {
      state.teamForm.members = state.teamForm.members.filter(
        (_, index) => index !== action.payload.index
      );
    },
    updateTeamMember: (
      state,
      action: PayloadAction<{ index: number; name: string; value: string }>
    ) => {
      state.teamForm.members[action.payload.index][action.payload.name].value =
        action.payload.value;
      state.teamForm.members[action.payload.index][action.payload.name].error =
        "";
    },
    updateTeamMemberError: (
      state,
      action: PayloadAction<{ index: number; name: string; error: string }>
    ) => {
      state.teamForm.members[action.payload.index][action.payload.name].error =
        action.payload.error;
    },
    saveTeamRequest: (
      state,
      action: PayloadAction<{ callback?: () => void }>
    ) => {
      state.teamForm.isLoading = true;
    },
    saveTeamRequestComplete: (state) => {
      state.teamForm.isLoading = false;
    },
    nextStep: (state) => {
      state.teamFormActiveIndex = Math.min(state.teamFormActiveIndex + 1, 4);
    },
    previousStep: (state) => {
      state.teamFormActiveIndex = Math.max(state.teamFormActiveIndex - 1, 1);
    },
    setTeamFormActiveIndex: (state, action: PayloadAction<number>) => {
      state.teamFormActiveIndex = action.payload;
    },

    setUserOnboardingProgress: (
      state,
      action: PayloadAction<userOnboardingStage>
    ) => {
      state.userOnboardingProgress = action.payload;
    },
    setUserToken: (state, action: PayloadAction<string>) => {
      state.access_token = action.payload;
    },
    onLogout: (state) => {
      state.user = null;
      state.loading = false;
      state.error = null;
      state.isAuthenticated = false;
      state.access_token = null;
      state.user = null;
      localStorage.removeItem("accessToken");
    },
  },
});

export const { reducer, actions, name: sliceKey } = useClinicSlice;


// i just update due to my better understating 