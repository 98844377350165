import Table from 'app/components/Table'
import { TableHeader } from 'app/components/Table/types';
import React, { useState, useRef, useEffect } from 'react'
import addIcon from "assets/svgs/ic_plus_primary.svg"
import menuIcon from "assets/svgs/ic_menu-grey.svg"
import DropdownIcon from 'app/components/Icons/DropdownIcon';
import { TicketGroup, TicketGroupStatus, TicketType } from '../types';
import CreateTicketRow from './CreateTicketRow';
import {
	draggable,
	dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import CustomTooltip from 'app/components/Tooltip';
import {SettingIcon} from "../../../../../components/Icons/Setting"
import AddTicketsDropdown from './AddTicketDropdown';
import TicketLimit from '../containers/Home/components/TicketLimit';
import RowGroupActions from '../containers/Home/components/RowGroupActions';

interface TicketGroupProps {
    icon: React.ReactNode;
    title: string;
    count: number;
    children: React.ReactNode;
    titleClassName?: string;
    headers: TableHeader[];
    color: string;
    onDrop?: (ticketId: string) => void;
    maxCount: number;
    groupStatus:TicketGroupStatus
    group: TicketGroup;
}

export default function TicketGroups({ 
    icon, 
    title, 
    count, 
    children, 
    titleClassName, 
    headers, 
    color,
    onDrop,
    maxCount,
    groupStatus,
    group 
}: TicketGroupProps) {
    const [showAddTask, setShowAddTask] = useState<{
        show: boolean;
        type: TicketType;
    }>({
        show: false,
        type: TicketType.TASK
    });
     const [open, setOpen] = useState(false);
    const dropRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!dropRef.current) return;
        
        const cleanup = dropTargetForElements({
            element: dropRef.current,
            onDrop: ({ source }) => {
                const ticketId = source.data?.ticketId;
                if (ticketId && onDrop) {
                    onDrop(ticketId as string);
                }
            },
        });

        return () => cleanup();
    }, [onDrop]);

    return (
        <div 
            ref={dropRef}
        >
            <div className="flex flex-row items-center gap-[6px] h-[30px]">
                <div className="flex items-center justify-center">
                    {icon}
                </div>
                <p style={{ color: color }} className={`font-medium text-sm leading-[21px] mb-0 mt-[2px] ${titleClassName}`}>
                    {title}
                </p>
                <TicketLimit groupStatus={groupStatus} count={count} maxCount={maxCount} />
                <div>
                <RowGroupActions group={group} />
                </div>
            </div>
            {(!group?.status?.isCollapse) && (
                <>
                    <Table 
                    headers={headers} 
                    headerClassName="!h-[30px] border-b border-[#E9ECF2]" 
                    tableClassName={`w-[100%]`}
                    >
                    {children}
                    {
                    showAddTask.show && (
                        <CreateTicketRow 
                            selectedType={showAddTask.type} 
                            onCancel={() => setShowAddTask({
                                show: false,
                                type: TicketType.TASK
                            })}
                        />
                    )
                    }
                    </Table>
                <div className="flex flex-row w-fit items-center gap-[4px] mt-[5px] mb-[15px] group">
                <div className="flex justify-center items-center size-[20px]">
                    <img src={addIcon} alt="add" className="size-[20px]" />
                </div>
                <button
                    onClick={() => setShowAddTask({ show: true, type: TicketType.TASK })}
                    className="hover:bg-[#F4F5F7] rounded-l-[4px] h-[20px] px-[6px] pb-2 text-sm leading-[21px] font-normal text-primary group-hover:bg-white rounded-[4px]"
                >
                    Add task
                </button>
                <button
                    className="group-hover:flex hover:bg-[#F4F5F7] bg-white rounded-r-[4px] border-l-[1px] border-[#E9ECF2]  size-[20px]"
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpen((prev) => !prev);
                    }}
                >
                    <AddTicketsDropdown setShowAddTask={setShowAddTask} />
                </button>
                </div>
            </>
            )}            
        </div>
    )
}

// Helper function to make elements draggable
export function makeTicketDraggable(element: HTMLElement, ticketId: string) {
    return draggable({
        element,
        getInitialData: () => ({ ticketId })
    });
}
