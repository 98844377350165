import React from "react";
import Modal from "app/components/Modal";

interface RemoveGroupProps {
  onClose: () => void;
  selectionCount: number;
  title?: string;
  grouptitle?: string;
  onSubmit: () => void;
}

const RemoveGroup: React.FC<RemoveGroupProps> = ({
  onClose,
  selectionCount,
  title,
  onSubmit,
  grouptitle
}) => {
  return (
    <Modal
      onClose={onClose}
      title='Remove Group'
      modalClassName="flex flex-col items-center p-0 gap-6 absolute w-[400px] h-[203px] left-[50%] top-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white border border-[#E9ECF2] rounded-lg"
      submitText="Archive"
      onSubmit={() => {
        onSubmit();
      }}
      renderModalFooter={
        <div className="flex justify-end gap-3">
          <button
            className="bg-[transparent] text-secondary border border-secondary rounded w-[106px] h-9 text-sm font-medium"
            onClick={onClose}
          >
            Revert
          </button>
          <button
            className="w-[106px] h-9 text-sm font-medium rounded bg-primary text-white"
            onClick={() => onSubmit()}
          >
            Remove
          </button>
        </div>
      }
    >
      <p className="text-left text-[#79808A]">
        Are you sure that you want to remove <strong>{grouptitle}</strong> group?{" "}
      </p>
    </Modal>
  );
};

export default RemoveGroup;
