import Input from "app/components/Input";
import CustomTooltip from "app/components/Tooltip";
import React from "react";
import FileUpload from "./FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { selectButtonLoading, selectTicketTypesForm } from "./redux/selector";
import { actions } from "./redux/slice";

interface TaskNameProps {
  data: { name: string; icon: React.ReactNode; description: string };
}

const TaskName: React.FC<TaskNameProps> = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const dispatch = useDispatch();
  const form = useSelector(selectTicketTypesForm);
  const isLoading = useSelector(selectButtonLoading);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && form?.name.trim() !== "") {
      dispatch(actions.doUpdate({ id: form?._id, callback() {} }));
      setIsEdit(false); // Close input field
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(
      actions.updateFormValue({
        key: name,
        value: value,
      })
    );
  };

  return (
    <React.Fragment>
      <div className="flex flex-col gap-3">
        {isEdit ? (
          <Input
            id="name"
            icon={data.icon}
            name="name"
            value={form.name}
            onChange={handleChange}
            style={{ background: "none" }}
            onKeyDown={handleKeyDown}
            disabled={isLoading}
          />
        ) : (
          <div className="flex flex-row items-center gap-1">
            <CustomTooltip
              text="Click for changing ticket type sign"
              position="bottom"
              className="flex items-center"
            >
              <button
                className="focus:outline-none"
                onClick={() => setOpen(true)}
              >
                {data.icon}
              </button>
            </CustomTooltip>
            <CustomTooltip
              text="Click for editing ticket type name"
              position="bottom"
              className="flex items-center"
            >
              <span
                className={`ml-1 text-nowrap overflow-hidden text-ellipsis font-medium text-base`}
                onClick={() => setIsEdit(true)}
              >
                {data.name}
              </span>
            </CustomTooltip>
          </div>
        )}

        <div className="text-sm font-normal text-gray">{data?.description}</div>
      </div>
      {open && <FileUpload open={open} onClose={() => setOpen(false)} />}
    </React.Fragment>
  );
};

export default TaskName;
