import Pill from "app/components/Pill";
import CustomTooltip from "app/components/Tooltip";
import Avatar from "app/containers/Features/components/Avatar";
import React from "react";

interface StatusSelectionButtonProps {
  className?: string;
  label: string;
  textClassName?: string;
  icon?: React.ReactNode;
  selectedIcon?: React.ReactNode;
  selectedLabel?: string;
  showAvatar?: boolean;
  avatarColor?: string;
  selectedTag?: any;
  maxSelectedTag?: number;
}

const StatusSelectionButton: React.FC<StatusSelectionButtonProps> = ({
  className,
  label,
  icon,
  textClassName,
  selectedIcon,
  selectedLabel,
  showAvatar,
  avatarColor,
  selectedTag,
  maxSelectedTag = 3,
}) => {
  return (
    <>
      <div className={`flex items-center gap-2 ${className}`}>
        {icon}
        <span className={`text-nowrap overflow-hidden text-ellipsis `}>
          {label}
        </span>
      </div>

      {showAvatar ? (
        <Avatar name={selectedLabel as string} color={avatarColor} />
      ) : (
        <div className={`flex items-center gap-2 `}>
          {selectedIcon}
          <span
            className={`text-nowrap overflow-hidden text-ellipsis ${className} ${textClassName}`}
          >
            {selectedLabel}
          </span>
        </div>
      )}
      {selectedTag && (
        <>
          <div className="flex flex-row gap-[4px]">
            {selectedTag.slice(0, maxSelectedTag).map((label) => (
              <Pill
                key={label.name}
                backgroundColor={label.backgroundColor}
                textColor={label.color}
                name={label.name}
                className="px-[6px] py-[2px] text-[10px] leading-[18px] font-medium"
              />
            ))}
            {selectedTag?.length > maxSelectedTag && (
              <CustomTooltip
                text={
                  <>
                    {selectedTag.map((label) => {
                      return (
                        <Pill
                          key={label.name}
                          backgroundColor={label.backgroundColor}
                          textColor={label.color}
                          name={label.name}
                          className="px-[6px] py-[2px] text-[10px] leading-[18px] font-medium"
                        />
                      );
                    })}
                  </>
                }
                isWhiteBg
                position="bottom"
                className="flex items-center text-wrap max-w-[200px]"
                contentClassName="break-words max-w-60 text-wrap"
              >
                <div className="size-[18px] min-w-[18px] min-h-[18px] flex items-center justify-center rounded-full bg-secondary text-white text-[10px] leading-[18px] font-medium">
                  + {selectedTag?.length - maxSelectedTag}
                </div>
              </CustomTooltip>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default StatusSelectionButton;
