import React from "react";
import Modal from "app/components/Modal";

interface ArchiveTicketProps {
  onClose: () => void;
  selectionCount: number;
  title?: string;
  onSubmit: () => void;
}

const ArchiveTicket: React.FC<ArchiveTicketProps> = ({
  onClose,
  selectionCount,
  title,
  onSubmit,
}) => {
  return (
    <Modal
      onClose={onClose}
      title={title ? title : "Archive Items"}
      modalClassName="flex flex-col items-center p-0 gap-6 absolute w-[400px] h-[203px] left-[50%] top-[50%] transform -translate-x-1/2 -translate-y-1/2 bg-white border border-[#E9ECF2] rounded-lg"
      submitText="Archive"
      onSubmit={() => {
        onSubmit();
      }}
    >
      <p className="text-left text-[#79808A]">
        Are you sure that you want to archive <strong>{selectionCount}</strong>{" "}
        {selectionCount === 1 ? "item" : "items"}?
      </p>
    </Modal>
  );
};

export default ArchiveTicket;
