import React from "react";
import {
  StatusTypeOptions,
  TicketTypeOptions,
} from "../containers/Boards/data";
import Dropdown from "app/components/Dropdown";
import MemberProfile from "app/components/MemberProfile";
import { CheckIcon } from "app/components/Icons";
import { TicketType } from "../containers/Boards/types";

interface CustomProps {
  value?: TicketType;
  setValue?: (value: TicketType) => void;
  trigger: React.ReactNode;
  align?: "start" | "end" | "center";
  isHeader?: string;
}

const CommonTicketTypeSelection: React.FC<CustomProps> = ({
  setValue,
  value,
  trigger,
  align="center",
  isHeader,
}) => {
  const MenuItems = TicketTypeOptions?.map((ele) => {
    if (ele)
      return {
        label: (
          <MemberProfile
            showStartIcon
            startIcon={
              <img src={ele.icon} alt="icon" className="size-[20px]" />
            }
            name={ele.label}
            statusColor="#F00"
            endIcon={<CheckIcon color="#5A8DF1" />}
            showEndIcon={value === ele.type}
            // titleClassName={`${ele.textColor}`}
            iconHide
          />
        ),
        action: () => {
          setValue && setValue(ele?.type);
        },
      };
    else return { label: "", action: () => {} };
  });
  return (
    <>
      <Dropdown
        trigger={trigger}
        projectInfoIcon={
          <div className="flex flex-col gap-2">
            {isHeader && (
              <div className="flex items-center justify-between px-4 py-3 text-secondary tracking-[0.56px] text-sm font-semibold leading-[21px] uppercase border-b border-[#E9ECF2]">
                <span>{isHeader}</span>
              </div>
            )}
          </div>
        }
        menuItems={MenuItems ? MenuItems : []}
        align={align}
        className="py-2"
      />
    </>
  );
};

export default CommonTicketTypeSelection;
