import React, { useCallback, useRef } from 'react'
import Input from 'app/components/Input';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'app/components/Button';
import rightArrow from 'assets/svgs/ic_right_arrow_primary.svg';
import { selectResestPasswordForm } from '../redux/selector';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/slice';
import { validateResetPasswordForm } from '../helpers';
import PasswordIcon from 'app/components/Icons/PasswordIcon';
import Logo from 'app/components/Icons/Logo';
import { validatePasswordField } from 'app/containers/OnBoarding/helpers';

export default function RessetPassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const passwordInputRef = useRef<HTMLInputElement>(null);
    const resetPasswordForm = useSelector(selectResestPasswordForm);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch(actions.setResetPasswordFormValues({ 
            name, 
            value 
        }));
        if (name === 'password') {
            const errors = validatePasswordField({value,error: ''});
            if (errors.length > 0) {
                dispatch(actions.setResetPasswordFormErrors(errors));
            }
        }   
    };
    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault(); 
        const errors = validateResetPasswordForm(resetPasswordForm);
        console.log('errors',errors);
        if (errors.length > 0) {
            dispatch(actions.setResetPasswordFormErrors(errors));
            return;
        }
        dispatch(actions.resetPasswordRequest({
            password: resetPasswordForm.password.value,
            confirmPassword: resetPasswordForm.confirmPassword.value,
            callback:() => {
                navigate('/app/home');
            }
        }
        ));
    }

    const isDisabled = useCallback(() => {
        if(!!resetPasswordForm.password.error || !!resetPasswordForm.confirmPassword.error) {
            return true
        };
        if(resetPasswordForm.password.value === '' || resetPasswordForm.confirmPassword.value === '') {
            return true;
        };
        return false;
    }, [resetPasswordForm.password.error, resetPasswordForm.confirmPassword.error, resetPasswordForm.password.value, resetPasswordForm.confirmPassword.value]);
    
    return (
        <div className="flex flex-col items-center">
            <div className='flex flex-col items-center gap-[20px] mb-[56px]'>
                <Logo size={72} />
                <h1 className="text-[35px] leading-[50px] font-semibold mb-4 text-center">Create new Passoword</h1>
            </div>
            <form className='flex flex-col gap-[20px] w-full min-w-[390px] mb-[56px]'>
                <Input 
                    id="newPassword" 
                    label="New Password" 
                    type="password" 
                    name="password"
                    placeholder="Enter new password" 
                    icon={<PasswordIcon />}
                    inputRef={passwordInputRef}
                    defaultValue={resetPasswordForm.password.value}
                    error={resetPasswordForm.password.error}
                    disabled={resetPasswordForm.isLoading}
                    onChange={handleChange}
                />
                <div>
                <Input 
                    id="confirmPassword" 
                    label="Confirm Password" 
                    type="password" 
                    name="confirmPassword"
                    placeholder="Confirm password" 
                    icon={<PasswordIcon />}
                    inputRef={passwordInputRef}
                    defaultValue={resetPasswordForm.confirmPassword.value}
                    error={resetPasswordForm.confirmPassword.error}
                    disabled={resetPasswordForm.isLoading}
                    onChange={handleChange}
                />
                </div>
                <div className="flex flex-col gap-[10px] items-center justify-between">
                    <Button 
                        disabled={isDisabled()}
                        onClick={handleSubmit} 
                        isLoading={resetPasswordForm.isLoading}
                        className='w-full h-[56px] mt-2'
                    >
                        Create password
                    </Button>
                </div>
            </form>
            <div className='flex flex-col items-center gap-[16px]'>
                <p className='text-secondary text-md leading-[24px] flex flex-row items-center gap-[6px]'>Don’t have an account? <Link to="/auth/signup" className="text-primary text-md leading-[24px] font-medium flex flex-row items-center">Sign up <img sizes='16px' src={rightArrow} alt="Arrow" /></Link></p>
            </div>
        </div>
    )
}
