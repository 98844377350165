import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import React from "react";
import { ChevronRightIcon } from "lucide-react";
import menuIcon from "assets/svgs/ic_menu-grey.svg";
import collapseIcon from "assets/svgs/collapse.svg";
import uncollpaseIcon from "assets/svgs/uncollpase.svg";
import invisibleIcon from "assets/svgs/invisible.svg";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../Tickets/redux/slice";
import { selectSelectedTickets } from "../../../redux/selector";
import { EditIcon } from "app/components/Icons";
import DeleteIcon from "app/components/Icons/DeleteIcon";
import PlusIcon from "app/components/Icons/PlusIcon";
import AddTicketGroupModal from "./AddTicketGroupModal";
import { GroupAction, TicketGroup } from "../../../types";
import RemoveGroupModal from "./RemoveGroupModal";

interface GroupActionProps {
  group: TicketGroup;
}

const RowGroupActions: React.FC<GroupActionProps> = ({ group }) => {
  const dispatch = useDispatch();
  const [isGroupModalOpen, setIsGroupModalOpen] = React.useState(false);
  const [isEditGroup, setIsEditGroup] = React.useState(false);
  const [isRemoveGroup, setIsRemoveGroup] = React.useState(false);
  const selectedList = useSelector(selectSelectedTickets);
  const isCollapseGroup = group?.status?.isCollapse;
  const options = [
    isCollapseGroup ?
    { icon: uncollpaseIcon, label: "Expand group", type: GroupAction.UN_COLLAPSE } :
    { icon: collapseIcon, label: "Collapse group", type: GroupAction.COLLAPSE },
    { icon: invisibleIcon, label: "Hide Group", type: GroupAction.HIDE },
    { icon: <EditIcon size={20} color="#79808A" />, label: "Edit Group Details", type: GroupAction.EDIT },
    { icon: <DeleteIcon size={20} color="#79808A" />, label: "Remove group", type: GroupAction.REMOVE },
  ];

  const handleClick = (type: GroupAction) => {
    switch (type) {
      case GroupAction.COLLAPSE:
        dispatch(actions.toggleGroupsIsCollapse(group));
        break;
      case GroupAction.UN_COLLAPSE:
        dispatch(actions.toggleGroupsIsCollapse(group));
        break;  
      case GroupAction.HIDE:
        dispatch(actions.toggleGroupsIsHidden(group));
        break;
      case GroupAction.EDIT:
        setIsGroupModalOpen(true);
        setIsEditGroup(true);
        break;
      case GroupAction.REMOVE:
        setIsRemoveGroup(true);
        break;
      case GroupAction.ADD:
        setIsGroupModalOpen(true);
        break;
      default:
        break;
    }
  };

const handleRemoveGroup = () => {
  setIsRemoveGroup(false);
  dispatch(actions.removeTicketGroup());
}

  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <button className="flex flex-row items-center hover:bg-[#ECEEF2] hover:px-1 hover:py-[1px] rounded outline-none">
            <img src={menuIcon} alt="menu" className="size-[20px]" />
          </button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            align="start"
            className="w-[220px] bg-white rounded-[4px] shadow-md py-[10px] z-50"
            sideOffset={5}
          >
            {options.map(({ icon, label, type }) => (
              <DropdownMenu.Item
                key={label}
                className="flex gap-2 pl-4 pr-[8px] py-[12px] text-sm text-secondary cursor-pointer rounded hover:bg-[#E9ECF2]"
                onClick={() => handleClick(type)}
              >
                <div className="min-w-[20px]">{typeof icon === 'string' ? <img src={icon} alt={label} className="size-[20px]" /> : icon}</div>
                <div className="text-sm text-secondary font-medium">{label}</div>
              </DropdownMenu.Item>
            ))}
            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger className="flex gap-2 pl-4 pr-[8px] py-[12px] text-sm text-secondary cursor-pointer rounded hover:bg-[#E9ECF2]">
                <PlusIcon color="#79808A" size={20} />
                <div className="text-sm text-secondary font-medium">Add Group</div>
                <div className="ml-auto pl-5">
                  <ChevronRightIcon />
                </div>
              </DropdownMenu.SubTrigger>
              <DropdownMenu.Portal>
                <DropdownMenu.SubContent className="min-w-[180px] bg-white rounded-md shadow-md p-2 z-50">
                  <DropdownMenu.Item
                    className="flex gap-2 pl-4 pr-[8px] py-[12px] text-sm text-secondary cursor-pointer rounded hover:bg-[#E9ECF2]"
                    onClick={() => handleClick(GroupAction.ADD)}
                  >
                    <PlusIcon color="#79808A" size={20} />
                    New Group
                  </DropdownMenu.Item>
                  <DropdownMenu.Item
                    className="flex gap-2 pl-4 pr-[8px] py-[12px] text-sm text-secondary cursor-pointer rounded hover:bg-[#E9ECF2]"
                  >
                    Hidden Group(s)

                  </DropdownMenu.Item>
                </DropdownMenu.SubContent>
              </DropdownMenu.Portal>
            </DropdownMenu.Sub>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      {isGroupModalOpen && (
        <AddTicketGroupModal onClose={() => setIsGroupModalOpen(false)} isEditGroup={isEditGroup}/>
      )}
      {isRemoveGroup && (
        <RemoveGroupModal
          onClose={() => setIsRemoveGroup(false)}
          selectionCount={selectedList.length}
          grouptitle={group?.status?.name}
          onSubmit={() => {handleRemoveGroup()}}
        />
      )}
    </>
  );
};

export default RowGroupActions;
