import React from "react";
import menuIcon from "assets/svgs/ic_menu-grey.svg";
import { Menu } from "../Icons";

export enum OptionTypeENum {
  DUPLICATE = "duplicate",
  IS_EDIT = "isEdit",
  IS_REMOVE = "isRemove",
  COPY_URL = "copyUrl",
  DOWNLOAD = "download",
  CLOSE = "close",
}
export type optionType = {
  label: string;
  icon: React.ReactNode;
  type: OptionTypeENum;
};

interface ItemActionsProps {
  dropdownRef: React.RefObject<HTMLDivElement>;
  handleClick?: (val) => void;
  options?: optionType[];
  dropdownClassName?: string;
  showOption?: boolean;
  setShowOption?: (val: boolean) => void;
  className?: string;
  iconColor?: string;
}

const Index: React.FC<ItemActionsProps> = ({
  dropdownRef,
  handleClick,
  options,
  dropdownClassName,
  setShowOption,
  showOption,
  className,
  iconColor
}) => {

  return (
    <div ref={dropdownRef} className="relative">
      <button
        className={`${className} w-10  items-center flex justify-center`}
        onClick={() => {
          setShowOption?.(true);
        }}
      >
        <Menu size='30' color={iconColor?iconColor:""} />
      </button>

      {/* Dropdown Menu */}
      {showOption && (
        <div
          className={`${dropdownClassName} absolute right-0 z-[9999] mt-2 w-[200px] border-2 border-[#E9ECF2] rounded-md shadow-lg bg-white py-2`}
        >
          {options?.map((option, i) => (
            <div
              key={i}
              className="cursor-pointer flex flex-row gap-3 items-center py-2 text-sm leading-[21px] hover:bg-gray-100 px-2 py-3"
              onClick={() => {
                handleClick?.(option?.type);
              }}
            >
              {option?.icon}
              <span>{option?.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Index;
