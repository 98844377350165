import Dropdown from "app/components/Dropdown";
import { CheckIcon } from "app/components/Icons";
import MemberProfile from "app/components/MemberProfile";
import React from "react";

interface CustomProps {
  value?: string;
  setValue?: (value: string) => void;
  trigger: React.ReactNode;
  align?: "start" | "end" | "center";
  isHeader?: string;
  data:any
}

const CommonTargetRelease: React.FC<CustomProps> = ({
  setValue,
  value,
  trigger,
  align = "center",
  isHeader,data
}) => {
  const MenuItems = data?.map((ele) => {
    if (ele)
      return {
        label: (
          <MemberProfile
            name={ele.label}
            badge={ele.status}
            statusColor="#F00"
            endIcon={<CheckIcon color="#5A8DF1" />}
            showEndIcon={value === ele.id}
            iconHide
            titleClassName="flex flex-row items-center gap-2"
            badgeClassName="rounded-full bg-[#5DA2FF1A] !text-[#5A8DF1] text-xs"
          />
        ),
        action: () => {
          setValue && setValue(ele?.id);
        },
      };
    else return { label: "", action: () => {} };
  });
  return (
    <>
      <Dropdown
        trigger={trigger}
        projectInfoIcon={
          <div className="flex flex-col gap-2">
            {isHeader && (
              <div className="flex items-center justify-between px-4 py-3 text-secondary tracking-[0.56px] text-sm font-semibold leading-[21px] uppercase border-b border-[#E9ECF2]">
                <span>{isHeader}</span>
              </div>
            )}
          </div>
        }
        menuItems={MenuItems ? MenuItems : []}
        align={align}
        className="py-2"
        dropdownContainerClassName="max-h-[250px] overflow-scroll "
      />
    </>
  );
};

export default CommonTargetRelease;
