import Dropdown from "app/components/Dropdown";
import { CheckIcon } from "app/components/Icons";
import SeverityIcon from "app/components/Icons/SeverityIcon";
import MemberProfile from "app/components/MemberProfile";
import CustomTooltip from "app/components/Tooltip";
import React, { useState } from "react";
import {
  severityData
} from "../../../../components/CreateBoardsComponent/data";
interface CustomProps {
  value: string;
}

const Severity: React.FC<CustomProps> = ({}) => {
  const [value, setValue] = useState("");
  const selectedOptions =
    severityData.find((p) => p.id === value) || severityData[0];

  const MenuItems = severityData?.map((ele) => {
    if (ele)
      return {
        label: (
          <MemberProfile
            name={ele.label}
            showStartIcon
            startIcon={<SeverityIcon size={22} color={ele.iconColor} />}
            statusColor="#F00"
            endIcon={<CheckIcon color="#5A8DF1" />}
            showEndIcon={value === ele.id}
            iconHide
                  titleClassName="flex flex-row items-center gap-2"
                  className="ps-0"
            badgeClassName="rounded-full bg-[#5DA2FF1A] !text-[#5A8DF1] text-xs"
          />
        ),
        action: () => {
          setValue && setValue(ele?.id);
        },
      };
    else return { label: "", action: () => {} };
  });
  return (
    <>
      <Dropdown
        trigger={
          <button className="flex items-center justify-between w-full ">
            <CustomTooltip
              text="Set severity"
              className="flex items-center "
              position="left"
            >
              <div className="flex items-center gap-2 w-full">
                <SeverityIcon size={18} color={selectedOptions.iconColor} />
                <span className="text-sm font-normal text-black">
                  {selectedOptions.label}
                </span>
              </div>
            </CustomTooltip>
          </button>
        }
        projectInfoIcon={
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between px-4 py-3 text-secondary tracking-[0.56px] text-sm font-semibold leading-[21px] uppercase border-b border-[#E9ECF2]">
              <span>severity</span>
            </div>
          </div>
        }
        menuItems={MenuItems ? MenuItems : []}
        align="center"
        className="py-2"
        // dropdownContainerClassName="max-h-[250px] overflow-scroll "
      />
    </>
  );
};

export default Severity;
