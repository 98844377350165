import { CalendarIcon } from "app/components/Icons";
import CustomTooltip from "app/components/Tooltip";
import React from "react";

interface CustomProps {
  value:string
}

const CreatedDate: React.FC<CustomProps> = ({ value }) => {
  return (
    <>
      <button className="flex items-center justify-between w-full px-2 py-2 rounded-md hover:bg-[#F4F5F7] rounded-[4px] data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A] ">
        <CustomTooltip text="Creation date" className="flex items-center ">
          <div className="flex items-center gap-2 w-full">
            <CalendarIcon size={16} />
            <span className="text-xs font-normal text-black">{value}</span>
          </div>
        </CustomTooltip>
      </button>
    </>
  );
};

export default CreatedDate;
