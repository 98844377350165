import { IUserTeams, IUserTeamsProjects } from "app/types";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Avatar from "./Avatar";
import LetterCard from "app/components/LetterCard";

interface BreadcrumbProps {
  readonly traces: {
    title: string;
    icon: React.ReactNode;
    link: string;
  }[];
  teams: IUserTeams;
}

export default function Breadcrumb({ traces, teams }: BreadcrumbProps) {
  const params = useParams();
  const { project, team } = params;
  const [projectDetails, setProjectDetails] = useState<IUserTeamsProjects>();

  useEffect(() => {
    if (teams) {
      const data = teams.projects.map((x) => x._id == project);
      //   setProjectDetails(data);
    }

    return () => {};
  }, []);
  useEffect(() => {
    console.log(projectDetails, "projectDetails");

    return () => {};
  }, []);

  return (
    <div className="flex flex-row items-center gap-[6px] min-w-max">
      <div className="flex flex-row items-center gap-[6px]">
        {/* <LetterCard
          className="!size-[16px] !min-w-[16px]"
          letterClassName="!text-secondary text-[8px] leading-[12px]"
          letter={teams?.icon?.initials}
          backgroundColor={
            teams?.icon?.bgColor ? teams?.icon?.bgColor : "#B3E8E9"
          }
          color={teams?.icon?.color ? teams?.icon?.color : "#79808A"}
        />
        <span className={clsx("text-sm")}>{teams?.teamName}</span>
        <span className="text-sm font-normal text-secondary">{"/"}</span>
        <LetterCard
          className="!size-[16px] !min-w-[16px]"
          letterClassName="!text-secondary text-[8px] leading-[12px]"
          letter={teams.icon?.initials}
          backgroundColor={
            teams?.icon?.bgColor ? teams?.icon?.bgColor : "#B3E8E9"
          }
          color={teams?.icon?.color ? teams?.icon?.color : "#79808A"}
        />
        <Link className={clsx("text-sm")} to={teams?._id}>
          {teams?.teamName}
        </Link> */}
        {/* {
                            index < traces.length - 1 && (
                                <span className="text-sm font-normal text-secondary">
                                    {'/'}
                                </span>
                            )
                        } */}
      </div>
      {traces.map((trace, index) => (
        <div key={index} className="flex flex-row items-center gap-[6px]">
          {trace.icon}
          <Link
            className={clsx(
              "text-sm",
              index === traces.length - 1
                ? "!text-black font-medium"
                : "font-normal text-secondary"
            )}
            to={trace.link}
          >
            {trace.title}
          </Link>
          {index < traces.length - 1 && (
            <span className="text-sm font-normal text-secondary">{"/"}</span>
          )}
        </div>
      ))}
    </div>
  );
}
