import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import closeIcon from "assets/svgs/ic_close.svg";
import HomeIcon from "app/components/Icons/HomeIcon";
import { BoardType, BoardViewType, IBoard } from "app/types";
import { BacklogIcon } from "app/components/Icons";
import KabanIcon from "app/components/Icons/KabanIcon";
import ListIcon from "app/components/Icons/ListIcon";

interface BoardNavMenuProps {
  readonly options: IBoard[];
}

const BoardNavMenu: React.FC<BoardNavMenuProps> = ({ options }) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const [isActive, setIsActive] = useState("home");

  useEffect(() => {
    if (pathname.endsWith("/boards/backlog")) {
      setIsActive("backlog");
    }
    if (pathname.endsWith("/boards")) {
      setIsActive("home");
    }
    if (pathname.endsWith("/board")) {
      setIsActive(params.id as string);
    }
    return () => {};
  }, [pathname, params]);

  const getIcon = (data: IBoard, isActive: boolean) => {
    if (data.Type === BoardType.Backlog) {
      return <BacklogIcon size={20} color={isActive ? "#5A8DF1" : "#79808A"} />;
    }
    switch (data?.viewType) {
      case BoardViewType.KANBAN:
        return <KabanIcon color={isActive ? "#5A8DF1" : "#79808A"} />;
      case BoardViewType.LIST:
        return <ListIcon color={isActive ? "#5A8DF1" : "#79808A"} />;
      default:
        return null;
    }
  };

  const handleNavigate = (data: IBoard) => {
    let newPath = "";
    if (data.Type === BoardType.Backlog) {
      newPath = `/app/${params.project}/${params.team}/boards/backlog`;
    } else {
      newPath = `/app/${params.project}/${
        params.team
      }/boards/${data.viewType?.toLowerCase()}/board`;
    }
    setIsActive(data.name.toLowerCase());
    navigate(newPath);
  };
  useEffect(() => {
    console.log(isActive, "options");

    return () => {};
  }, [isActive]);

  return (
    <div className="flex flex-row bg-white shadow w-fit">
      <Link
        to={`/app/${params?.project}/${params?.team}/boards`}
        className={clsx(
          "relative flex flex-row items-center gap-2 px-4 uppercase text-sm font-normal text-secondary leading-5 py-2 hover:bg-gray-100 group",
          {
            "border-b-2 border-primary": isActive === "home",
          }
        )}
      >
        <HomeIcon
          size={20}
          color={isActive === "home" ? "#5A8DF1" : "#79808A"}
        />
        <span
          className={clsx({
            "text-primary font-semibold": isActive === "home",
          })}
        >
          Home
        </span>
      </Link>
      {options?.map((option, index) => {
        console.log(option.name.toLowerCase() === isActive, "options");

        return (
          <Link
            to="#"
            onClick={() => handleNavigate(option)}
            key={index}
            className={clsx(
              "relative flex flex-row items-center gap-2 px-4 uppercase text-sm font-normal text-secondary leading-5 py-2 hover:bg-gray-100 group",
              {
                "border-b-2 border-primary":
                  isActive === option.name.toLowerCase(),
              }
            )}
          >
            {getIcon(option, isActive === option.name.toLowerCase())}
            <span
              className={clsx({
                "text-primary font-semibold":
                  isActive === option.name.toLowerCase(),
              })}
            >
              {option?.name}
            </span>
            {option.Type !== BoardType.Backlog &&
              isActive === option.name.toLowerCase() && (
                <>
                  <button className="absolute right-0 top-0 w-6 pr-1 h-full hidden group-hover:flex">
                    <img src={closeIcon} alt="close" className="size-5" />
                  </button>
                  <div className="absolute h-full bg-gray-100 opacity-50 right-0 top-0 w-7 hidden group-hover:flex"></div>
                </>
              )}
          </Link>
        );
      })}
    </div>
  );
};

export default BoardNavMenu;
