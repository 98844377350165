import React from 'react'
import check from '../../../assets/svgs/ic_checkmark.svg';
import Spinner from '../Icons/Spinner';

interface SingleSelectProps {
    readonly options: {
        _id: string;
        name: string;
    }[];
    readonly selected: string | string[];
    readonly onChange: (option: string | string[]) => void;
    readonly className?: string;
    readonly loading?: boolean;
}

export default function SingleSelect({
    options,
    selected,
    onChange,
    className,
    loading
}: SingleSelectProps) {
    return (
        <div className={`flex flex-row items-center justify-center flex-wrap gap-y-[18px] gap-x-[16px] ${className}`}>
            {
            loading ? (
                <Spinner size='small'/>
            ) :
                options.map((option) => (
                    <button onClick={() => onChange(option._id)} className={`cursor-pointer h-[65px] flex flex-col items-center justify-center px-8 py-6 border border-gray-300 rounded-[4px] relative transition-all duration-300 hover:bg-[#F4F5F7] ${selected === option._id ? 'border-primary bg-primary100' : ''}`} key={option.name}>
                        <span className={`text-[24px] leading-[24px] font-medium text-center ${selected === option._id ? 'text-primary' : 'text-secondary'}`}>{option.name}</span>
                        <div className={`absolute top-[-10px] right-[-10px] w-[28px] h-[28px] bg-primary rounded-[14px] flex items-center justify-center transition-all duration-300 ${selected === option._id ? 'opacity-100' : 'opacity-0'}`}>
                            <img src={check} alt="Check" width={24} height={24} />
                        </div>
                    </button>
                ))
            }
        </div>
    )
}
