import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "app/components/Popover";
import React, { useState } from "react";
import { fixedColorCode } from "../../containers/Boards/data";
import { ChromePicker } from "react-color";

interface ColorPlateProps {
  trigger: React.ReactNode;
  color: string;
  updateColor: (val) => void;
}

const ColorPlate: React.FC<ColorPlateProps> = ({
  trigger,
  color,
  updateColor,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>{trigger}</PopoverTrigger>
        <PopoverContent
          align="start"
          alignOffset={50}
          className={`bg-none border-none border-[#E9ECF2] cursor-pointer w-[max-content] !p-0`}
        >
          <ChromePicker
            onChange={(newColor) => {
              updateColor(newColor.hex);
            }}
            color={color}
            presetColors={fixedColorCode}
          />
        </PopoverContent>
      </Popover>
    </React.Fragment>
  );
};

export default ColorPlate;
