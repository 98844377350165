import React from "react";
import UploadFileIcon from "app/components/Icons/UploadFileIcon";
import defaultImg from "assets/imgs/default.png";
import defaultImg1 from "assets/imgs/default-img-1.png";
import defaultImg2 from "assets/imgs/default-img-2.png";
import defaultImg3 from "assets/imgs/default-img-3.png";
import defaultImg4 from "assets/imgs/default-img-4.png";
import defaultImg5 from "assets/imgs/default-img-5.png";
import defaultImg6 from "assets/imgs/default-img-6.png";
import defaultImg7 from "assets/imgs/default-img-7.png";
import defaultImg8 from "assets/imgs/default-img-8.png";
import DeleteIcon from "app/components/Icons/DeleteIcon";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, mediaUploadReducer, mediaUploadSlice } from "./redux/slice";
import { mediaUploadSaga } from "./redux/saga";
import { CreateAssetResponse } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoading } from "./redux/selector";

const defaultAvatars = [
  { icon: defaultImg1, label: "Default 1" },
  { icon: defaultImg2, label: "Default 2" },
  { icon: defaultImg3, label: "Default 3" },
  { icon: defaultImg4, label: "Default 4" },
  { icon: defaultImg5, label: "Default 5" },
  { icon: defaultImg6, label: "Default 6" },
  { icon: defaultImg7, label: "Default 7" },
  { icon: defaultImg8, label: "Default 8" },
  { icon: defaultImg, label: "Default" },
];
interface UploadImageProps {
  onDelete: () => void;
  onClose: () => void;
  isProjectImage?: boolean;
  onUpload: (url?: CreateAssetResponse) => void;
  onCancel: () => void;
}

const Index: React.FC<UploadImageProps> = ({
  onDelete,
  onClose,
  isProjectImage,
  onCancel,
  onUpload,
}) => {
  useInjectReducer({ key: mediaUploadSlice, reducer: mediaUploadReducer });
  useInjectSaga({ key: mediaUploadSlice, saga: mediaUploadSaga });
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const [isDragging, setIsDragging] = React.useState(false);
  const [uploadedFile, setUploadedFile] = React.useState<File | null>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      await handleImageUpload(file);
    }
  };

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      await handleImageUpload(file);
    }
  };

  const handleImageUpload = async (file: File) => {
    dispatch(
      actions.uploadMedia({
        media: {
          file: file,
          type: "image",
          // type == MediaTypes.IMAGE_VIDEO
          //   ? file?.[0].type == "video/mp4"
          //     ? "video"
          //     : "image"
          //   : type,
          mimeType: file?.type as string,
          name: file?.name as string,
        },
        callback: onSuccess,
      })
    );
  };

  const handleDeleteImage = () => {
    setUploadedFile(null);
  };

  const onSuccess = (img: CreateAssetResponse) => {
    onUpload(img);
    onCancel();
  };
  return (
    <div className="grid gap-4 py-4">
      {uploadedFile ? (
        <div className="flex justify-center items-center bg-[#79808A] h-[247px] rounded-lg relative">
          {/* Image Container */}
          <div className="relative w-[193px] h-[193px]">
            <img
              src={URL.createObjectURL(uploadedFile)}
              alt="Uploaded"
              className="w-full h-full rounded-full object-cover border bg-white"
            />
          </div>
          {/* Delete Icon */}
          <button
            className="absolute top-2 right-2 w-6 h-6"
            onClick={handleDeleteImage}
            title="Delete image"
          >
            <DeleteIcon size={24} color="#FFFFFF" />
          </button>
        </div>
      ) : (
        <>
          <div
            className={`border-2 border-dashed border-[#E9ECF2] rounded-lg p-4 cursor-pointer transition-colors ${
              isDragging ? "bg-blue-50" : ""
            } ${isLoading ? "pointer-events-none opacity-50" : ""}`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div className="flex flex-col items-center gap-2 p-4">
              <UploadFileIcon size="100" color="#BDC0C3" />
              <p className="text-sm text-[#79808A]">
                Drag and drop an image here
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-sm text-[#79808A]">or</p>
          </div>
          <button
            className="w-full border border-[#79808A] text-[#79808A] px-4 py-2 rounded text-center"
            disabled={isLoading}
            onClick={() => fileInputRef.current?.click()}
          >
            {isLoading ? (
              <>
                <span className="mr-2 h-4 w-4 animate-spin">🔄</span>
                Uploading...
              </>
            ) : (
              "UPLOAD IMAGE"
            )}
          </button>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            className="hidden"
            onChange={handleFileSelect}
          />
          {!isProjectImage && (
            <div className="space-y-2">
              <p className="text-sm text-[#79808A]">Default signs</p>
              <div className="flex justify-center flex-wrap gap-4 border border-[#E9ECF2] p-3 rounded-lg">
                {defaultAvatars.map((avatar, index) => (
                  <button
                    key={index}
                    className="w-8 h-8 rounded-full overflow-hidden hover:ring-2 hover:ring-blue-500 transition-all"
                    onClick={() => {
                      console.log(`Selected: ${avatar.label}`);
                      onClose(); // Close modal after selection
                    }}
                  >
                    <img
                      src={avatar.icon}
                      alt={avatar.label}
                      className="w-full h-full object-cover"
                    />
                  </button>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Index;
