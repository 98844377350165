import React, { useState, useRef } from "react";
import { TableHeader } from "./types";
import SortIcon from "../Icons/SortIcon";
import CustomTooltip from "../Tooltip";
import { sortOrder } from "app/types";
import Spinner from "../Icons/Spinner";
interface TableProps {
  readonly headers: TableHeader[];
  readonly emptyText?: string;
  readonly children?: React.ReactNode;
  readonly headerClassName?: string;
  readonly tableClassName?: string;
  handleSort?: (headerId: string) => void;
  readonly sortByOrder?: string;
  readonly sortByColumn?: string;
  readonly loading?: boolean;
}

export default function Table({
  headers,
  emptyText = "There are no any boards",
  children = null,
  headerClassName,
  tableClassName,
  handleSort,
  sortByOrder,
  sortByColumn,
  loading,
}: TableProps) {
  const tableRef = useRef<HTMLTableElement>(null);
  const resizingRef = useRef<boolean>(false);


  // Store resizable column widths
  const [columnWidths, setColumnWidths] = useState<Record<string, number>>(() => 
    headers.reduce((acc, header) => {
      acc[header.id] = header.width ? Number(header.width) : 200;
      return acc;
    }, {} as Record<string, number>)
  );

  // Enhanced resize logic
  const handleColumnResize = (e: React.MouseEvent, columnId: string) => {
    if (!headers.find((header) => header.id === columnId)?.resizable) return;

    e.preventDefault();
    const table = tableRef.current;
    if (!table) return;

    const th = (e.target as HTMLElement).closest('th');
    if (!th) return;

    const startX = e.clientX;
    const startWidth = th.offsetWidth;
    resizingRef.current = true;

    // Calculate table boundaries
    const tableRect = table.getBoundingClientRect();
    const minWidth = 50; // Minimum column width
    const maxWidth = tableRect.width - (headers.length - 1) * minWidth; // Maximum width

    const handleMouseMove = (moveEvent: MouseEvent) => {
      if (!resizingRef.current) return;

      // Calculate new width with boundaries
      const diff = moveEvent.clientX - startX;
      const newWidth = Math.min(Math.max(startWidth + diff, minWidth), maxWidth);

      // Update column width state
      setColumnWidths(prev => ({
        ...prev,
        [columnId]: newWidth
      }));

     // Update column width in the DOM
      th.style.width = `${newWidth}px`;
      th.style.minWidth = `${newWidth}px`;
    };

    const handleMouseUp = () => {
      // Cleanup event listeners and reset cursor style
      resizingRef.current = false;
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.body.style.cursor = 'default';
    };
    // Add event listeners for mousemove and mouseup
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.body.style.cursor = 'col-resize';
  };

  const renderHeaders = () => {
    return headers.map((header) => (
      <th
        key={header.id}
        style={{
          width: columnWidths[header.id],
          minWidth: columnWidths[header.id]??150, // it's fixed add the due to some time data is NAN
          textAlign: header.align ?? "left",
          position: "relative"
        }}
        className={`px-4 pr-2 py-[12px] text-sm leading-[21px] font-normal text-secondary hover:bg-[#ECEEF2] group ${header.className}`}
      >
        <div className="flex flex-row items-center gap-[2px]">
          <span className="flex-grow">{header.name}</span>
          {header.sortable && (
            <button
              onClick={() => handleSort?.(header?.id)}
              className={`flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity ${
                sortByColumn === header.id ? "opacity-100" : ""
              }`}
            >
              <CustomTooltip text="Sort">
                <div
                  className={`transition-transform ${
                    sortByColumn === header.id && sortByOrder === sortOrder.DESC ? "rotate-180" : ""
                  }`}
                >
                  <SortIcon />
                </div>
              </CustomTooltip>
            </button>
          )}

          {header.resizable && (
            <div
              className="resize-handle absolute right-0 top-0 h-full w-4 cursor-col-resize opacity-0 group-hover:opacity-100 hover:opacity-100 transition-opacity duration-200 bg-gradient-to-r from-transparent to-gray-200"
              onMouseDown={(e) => handleColumnResize(e, header.id)}
            />
          )}
        </div>
      </th>
    ));
  };
  return (
    <div className="overflow-x-visible ">
      <div className="inline-block min-w-full">
        <table 
          ref={tableRef}
          className={`w-full max-w-full bg-white border border-[#E9ECF2] ${tableClassName}`}
        >
          <thead>
            <tr className={`bg-[#F4F5F7] h-[52px] ${headerClassName}`}>
              {renderHeaders()}
            </tr>
          </thead>
          <tbody>
          {loading ? (
            <tr>
              <td colSpan={headers.length} className="text-center">
              <div className="flex justify-center items-center w-full">
                <Spinner size="small" />
                </div>
              </td>
            </tr>
          ) : !children ? (
            <tr>
              <td colSpan={headers.length} className="text-sm leading-[21px] font-normal text-secondary text-center h-[52px]">
                {emptyText}
              </td>
            </tr>
          ) : (
            children
          )}
        </tbody>
        </table>
      </div>
    </div>
  );
}