import descriptionIcon from "assets/svgs/ic_description.svg";
import React, { useState } from "react";
import CustomPopover from "./CustomPopover";

interface DescriptionProps {
  description: string;
}

const Description: React.FC<DescriptionProps> = ({ description }) => {
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <CustomPopover
        show={open}
        onClose={() => {
          setOpen(!open);
        }}
        trigger={
          <div
            className="min-w-[16px] hover:border border-secondary rounded"
            onMouseEnter={() => {
              setOpen(true);
            }}
            onMouseLeave={() => {
              setOpen(false);
            }}
          >
            <img
              src={descriptionIcon}
              alt="task-icon"
              className="size-[16px]"
            />
          </div>
        }
        content={
          <div
            className="text-sm m-2"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        }
        className="max-h-[200px] "
      />
    </React.Fragment>
  );
};

export default Description;
