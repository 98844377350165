import Dropdown from "app/components/Dropdown";
import React from "react";
import StatusSelectionButton from "./StatusSelectionButton";
import { CheckIcon, StatusIcon } from "app/components/Icons";
import { StatusFilterEnum } from "../../../types";
import MemberProfile from "app/components/MemberProfile";

interface StatusDropdownProps {
  value?: StatusFilterEnum;
  setValue?: (value: StatusFilterEnum) => void;
}

const StatusDropdown: React.FC<StatusDropdownProps> = ({ setValue, value }) => {
  const selectedOptions = options.find((p) => p.value === value) || options[0];

  const MenuItems = options?.map((ele) => {
    if (ele)
      return {
        label: (
          <MemberProfile
            showStartIcon
            startIcon={ele.icon}
            name={ele.label}
            statusColor="#F00"
            endIcon={<CheckIcon color="#5A8DF1" />}
            showEndIcon={value === ele.value}
            titleClassName={`${ele.textColor}`}
            iconHide
          />
        ),
        action: () => {
          setValue && setValue(ele?.value);
        },
      };
    else return { label: "", action: () => {} };
  });

  return (
    <>
      <Dropdown
        trigger={
          <button className="flex items-center justify-between w-full px-2 py-2 rounded-md hover:bg-[#F4F5F7] rounded-[4px] data-[state=open]:border-[#79808A] data-[state=open]:outline-[#79808A] data-[state=open]:border-[1px] border-[#79808A]">
            <StatusSelectionButton
              label="Status:"
              icon={<StatusIcon size={18} color="#79808A" />}
              className="text-[12px] leading-[18px] font-medium text-secondary mt-[2px]"
              selectedIcon={selectedOptions.icon}
              selectedLabel={selectedOptions.label}
            />
          </button>
        }
        menuItems={MenuItems ? MenuItems : []}
        align="end"
        className="py-2"
      />
    </>
  );
};
const options = [
  {
    label: StatusFilterEnum.OPENED,
    value: StatusFilterEnum.OPENED,
    icon: <StatusIcon size={18} color="#43D98C" />,
    textColor: "text-[#43D98C]",
  },
  {
    label: StatusFilterEnum.IN_PROCESS,
    value: StatusFilterEnum.IN_PROCESS,
    icon: <StatusIcon size={18} color="#FEB355" />,
    textColor: "text-[#FEB355]",
  },
  {
    label: StatusFilterEnum.DUPLICATE,
    value: StatusFilterEnum.DUPLICATE,
    icon: <StatusIcon size={18} color="#FF8FC5" />,
    textColor: "text-[#FF8FC5]",
  },
  {
    label: StatusFilterEnum.CLOSED,
    value: StatusFilterEnum.CLOSED,
    icon: <StatusIcon size={18} color="#A394FB" />,
    textColor: "text-[#A394FB]",
  },
];
export default StatusDropdown;
