import React, { useState } from "react";
import { useQueryState } from "nuqs";
import menuIcon from "assets/svgs/ic_menu-grey.svg";
import { CogIcon, DuplicateIcon } from "app/components/Icons";

import IconText from "app/components/IconText";
import Dropdown from "app/components/Dropdown";

export default function SettingsButton() {
  const [settings, setSettings] = useQueryState("settings");
  const [isOpen, setIsOpen] = useState(false);

  const options = [
    {
      label: "Settings",
      value: "settings",
      icon: <CogIcon size={20} color="#79808A" />,
      action: () => setSettings("settings"),
    },
    {
      label: "Duplicate",
      value: "duplicate",
      icon: <DuplicateIcon size={20} color="#79808A" />,
      action: () => {},
    },
  ];

  const MenuItems = options?.map((ele) => {
    if (ele)
      return {
        label: (
          <>
            <div className={`flex items-start gap-3 p-1 w-[100%]`}>
              <div className="min-w-[20px]">{ele.icon}</div>

              <div className="text-sm leading-[18px] font-normal text-black text-ellipsis overflow-hidden whitespace-nowrap">
                {ele.label}
              </div>
            </div>
          </>
        ),
        action: () => {
          ele.action();
        },
      };
    else return { label: "", action: () => {} };
  });
  return (
    <Dropdown
      open={isOpen}
      setOpen={() => setIsOpen(!isOpen)}
      trigger={
        <button
          className={`w-[24px] h-[24px] flex items-center justify-center hover:bg-[#E9ECF2] focus:outline-none outline-none ${
            isOpen ? "border-[0.8px] border-[rgba(121, 128, 138, 1)] rounded" : ""
          }`}
        >
          <img src={menuIcon} alt="menuIcon" className="size-[24px]" />
        </button>
      }
      align="end"
      menuItems={MenuItems}
      className="!w-[150px] py-2"
    />
  );
}
