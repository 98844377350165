import StatusIcon from "app/components/Icons/StatusIcon";
import CustomTooltip from "app/components/Tooltip";
import React, { FC, useState } from "react";
import { TicketStatus } from "../types";
import checkMark from "assets/svgs/checkmark.svg";
import Dropdown from "app/components/Dropdown";

export const TicketStatusButton: FC = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<TicketStatus | null>(null);
    
    const TicketStatusList = [
        { label: "OPENED", type: TicketStatus.OPENED, icon: <StatusIcon color="#43D98C" />, color: "#43D98C" },
        { label: "IN_PROCESS", type: TicketStatus.IN_PROCESS, icon: <StatusIcon color="#FEB355" />, color: "#FEB355" },
        { label: "DUPLICATED", type: TicketStatus.DUPLICATED, icon: <StatusIcon color="#FF8FC5" />, color: "#FF8FC5" },
        { label: "CLOSED", type: TicketStatus.CLOSED, icon: <StatusIcon color="#A394FB" />, color: "#A394FB" },
    ];

    const handleSelect = (selected) => {
        setSelectedType(selected);
        setOpen(false);
    };

    const selectedOption = TicketStatusList.find(option => option.type === selectedType);

    const menuItems = TicketStatusList.map((option) => ({
        label: (
            <div className="cursor-pointer flex flex-row gap-[8px] items-center pt-1 py-3 text-sm text-secondary">
                {option.icon}
                <span style={{ color: option.color }}>{option.label}</span>
                {selectedType === option.type && (
                    <img src={checkMark} alt="selected" className="size-[20px] ml-auto" />
                )}
            </div>
        ),
        action: () => handleSelect(option.type),
    }));

    return (
        <Dropdown
            trigger={
                <button
                        className="flex flex-row items-center min-w-[28px] h-[28px] px-[6px] rounded-[4px] border border-[#E9ECF2] hover:bg-[#ECEEF2] hover:text-[#79808A] transition-all duration-200"
                    >
                    <CustomTooltip text="Set status" position="bottom">
                    <div className="flex items-center gap-1 min-w-[16px]">
                        {selectedOption ? selectedOption.icon : <StatusIcon color="#79808A" />}
                        {selectedOption && (
                            <span className="text-sm" style={{ color: selectedOption.color }}>
                            {selectedOption.label}
                            </span>
                        )}
                        </div>
                 </CustomTooltip>
                    </button>
            }
            menuItems={menuItems}
            align="start"
            className="max-w-[180px] py-2"
            projectInfoIcon={
                <div className="flex items-center gap-2 pl-4 pr-2 py-2 text-secondary text-sm font-semibold uppercase border-b border-[#E9ECF2]">
                    STATUSES
                </div>
            }
        />
    );
};
