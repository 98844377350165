import React from "react";

interface NoneIconProps {
  size?: number;
  color?: string;
}
const NoneIcon: React.FC<NoneIconProps> = ({
  color = "#253858",
  size = 20,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.21627 9.9972C4.21692 8.92688 4.51478 7.87776 5.0767 6.96667C5.63861 6.05559 6.44251 5.3183 7.39887 4.83695C8.35522 4.35559 9.42648 4.14906 10.4933 4.24035C11.5418 4.33007 12.5455 4.70398 13.3967 5.32132L5.66871 13.8208C4.7329 12.7676 4.21566 11.4072 4.21627 9.9972ZM4.21627 9.9972L4.16627 9.99717L4.21627 9.9972ZM16.3024 4.00844L16.3028 4.00799C16.4134 3.88336 16.4704 3.72017 16.4615 3.5538C16.4525 3.38743 16.3783 3.2313 16.255 3.11925C16.1316 3.00721 15.9691 2.94827 15.8026 2.95523C15.6361 2.96218 15.479 3.03446 15.3654 3.1564L15.365 3.15683L14.2525 4.37959C12.8564 3.32224 11.1159 2.82214 9.37024 2.97792C7.60928 3.13506 5.97174 3.94812 4.7825 5.25582C3.59327 6.56351 2.93922 8.27028 2.95013 10.0375C2.96095 11.7894 3.62444 13.4738 4.81013 14.7624L3.69799 15.9855C3.69792 15.9855 3.69784 15.9856 3.69777 15.9857C3.64077 16.047 3.59647 16.1189 3.56746 16.1974C3.53841 16.276 3.52525 16.3596 3.52875 16.4433C3.53225 16.527 3.55233 16.6092 3.58784 16.6851C3.62335 16.761 3.67358 16.8291 3.7356 16.8854C3.79763 16.9418 3.87023 16.9853 3.94919 17.0133C4.02815 17.0414 4.1119 17.0536 4.19559 17.0491C4.27928 17.0446 4.36125 17.0235 4.43674 16.9871C4.51214 16.9508 4.57958 16.8998 4.63516 16.8373C4.63523 16.8372 4.6353 16.8371 4.63537 16.837L5.74751 15.6147C7.14356 16.6721 8.88409 17.1722 10.6298 17.0164C12.3907 16.8593 14.0283 16.0462 15.2175 14.7385C16.4067 13.4308 17.0608 11.7241 17.0499 9.95682C17.039 8.20494 16.3756 6.52053 15.1899 5.23189L16.3024 4.00844ZM10 15.7785H9.99995C8.77855 15.7798 7.58896 15.3924 6.60321 14.6731L14.3312 6.17357C15.0589 6.99625 15.5373 8.00954 15.7097 9.09487C15.885 10.1984 15.7364 11.329 15.2819 12.3499C14.8275 13.3707 14.0866 14.2379 13.1492 14.8464C12.2117 15.455 11.1178 15.7788 10 15.7785Z"
        fill={color}
        stroke={color}
        stroke-width="0.1"
      />
    </svg>
  );
};

export default NoneIcon;
