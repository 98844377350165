import { format } from "date-fns";
import React from "react";
import { Calendar } from "../Calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";
import DateOptions from "./DateOptions";
import DatePill from "./DatePill";

interface DatePickerProps {
  label?: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  error?: string;
  labelClassName?: string;
  innerContainerClassName?: string;
  align?: "start" | "center" | "end";
  trigger?: React.ReactNode | null;
}

const Index: React.FC<DatePickerProps> = ({
  label,
  value,
  onChange,
  disabled,
  className,
  error,
  labelClassName,
  innerContainerClassName,
  align = "start",
  placeholder = "Choose date...",
  trigger
}) => {
  const [open, setOpen] = React.useState(false);

  const formatStartDate = () => {
    if (!value) return "";
    return `${format(value, "dd MMM, yyyy")}`;
  };
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        {trigger ?? (
          <div className="relative">
          <div className={`flex flex-col gap-[6px] ${innerContainerClassName}`}>
            {label && (
              <label
                className={`text-xs leading-[18px] text-[#79808A] ${labelClassName}`}
              >
                {label}
              </label>
            )}
            <button
              disabled={disabled}
              className={`flex items-center justify-between text-sm leading-[21px] gap-[8px] h-[48px] flex-row border rounded-[4px] py-[16px] px-[10px] ${className} ${
                open ? "!border-[#79808A]" : "border-[#E9ECF2]"
              } ${error && "border-danger"} ${disabled && "opacity-50"}`}
            >
              {value ? (
                format(value, "dd MMM, yyyy")
              ) : (
                <span>{placeholder}</span>
              )}
            </button>
          </div>

          {error && (
            <span className="text-[11px] leading-[18px] text-danger absolute bottom-[-18px]">
              {error}
            </span>
          )}
          </div>
        )}
      </PopoverTrigger>
      <PopoverContent
        align={align}
        className={`w-[377px] !px-0 !py-0 bg-white border border-[#E9ECF2]`}
      >
        <div className="flex gap-10 border-b-[#E9ECF2] ">
          <DatePill
            date={value !== null ? formatStartDate() : ""}
            onClear={() => {
              onChange(null);
            }}
            iconShow={value !== null}
          />
        </div>
        <div className="flex items-center justify-between">
          <DateOptions />
          <Calendar
            mode="single"
            selected={value as Date}
            onSelect={(val) => onChange(val)}
            required={true}
            className="w-[200px] h-full"
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default Index;
