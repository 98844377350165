import React, { useRef, useState } from "react";
import google from "../../../../assets/svgs/ic_google.svg";
import Button from "app/components/Button";
import { Link, useNavigate } from "react-router-dom";
import rightArrow from "../../../../assets/svgs/ic_right_arrow_primary.svg";
import Input from "app/components/Input";
import EmailIcon from "app/components/Icons/EmailIcon";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../redux/slice";
import { selectIsGoogleLogin, selectSignupForm } from "../redux/selector";
import { validateSignupForm } from "../helpers";
import Logo from "app/components/Icons/Logo";
import { auth, signInWithGoogle } from "utils/firebase";
import { userOnboardingStage } from "app/types";
import { actions as appActions } from "../../../redux/slice";



export default function Signup() {
  let unsubscribeFromAuth;
  const [isEmail, setIsEmail] = useState(false);
  const dispatch = useDispatch();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const signupForm = useSelector(selectSignupForm);
  const navigate = useNavigate();
const isGoogleLogin = useSelector(selectIsGoogleLogin);
  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const errors = validateSignupForm({
      email: {
        value: emailInputRef.current?.value || "",
        error: "",
      },
    });
    if (errors.length > 0) {
      dispatch(actions.setSignupFormErrors(errors));
      return;
    }
    dispatch(
      actions.signupRequest({
        email: emailInputRef.current?.value || "",
        callback: () => {
          navigate("/auth/verify-email");
        },
      })
    );
  };
  React.useEffect(() => {
    unsubscribeFromAuth = auth.onAuthStateChanged((user: any) => {
      if (user) {
        responseGoogle(user.providerData);
      }
    });

    return () => {
      unsubscribeFromAuth();
      auth.signOut();
    };
  }, []);
  const responseGoogle = (response: any) => {
    let googleData = {
      email: response[0].email,
      fullName: response[0].displayName,
      profilePic: response[0].photoURL,
      googleId: response[0].uid,
    };

    dispatch(
      actions.doGoogleLogin({
        data: googleData,
        callback: (nextPage: userOnboardingStage, fullName, token) => {
          localStorage.setItem("accessToken", token);
          if (nextPage === userOnboardingStage.CREATE_PROFILE) {
            navigate(
              `/onboarding/create-profile?isGoogleLogin=true&userName=${fullName}`
            );
          } else if (nextPage === userOnboardingStage.COMPLETED) {
            dispatch(appActions.doGetMyProfile());
            dispatch(appActions.doGetUserWorkspace());
            navigate(`/app/home`);
          }
        },
      })
    );
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center gap-[20px] mb-[56px]">
        <Logo size={72} />
        <h1 className="text-[40px] leading-[50px] font-semibold mb-4 text-center">
          Sign up to Teambox.Dev
        </h1>
      </div>
      <form className="flex flex-col gap-[20px] w-full max-w-[444px] min-w-[366px] mb-[56px]">
        <Button
          className="w-full h-[56px] flex items-center justify-center"
          type="button"
          isLoading={isGoogleLogin}
          onClick={() => {
            signInWithGoogle().catch((error: any) => {});
          }}
        >
          <div className="flex items-center gap-[10px]">
            <img sizes="24px" src={google} alt="Google" />
            <span>Continue with Google</span>
          </div>
        </Button>
        {isEmail && (
          <Input
            id="email"
            type="email"
            placeholder="Enter email address"
            icon={<EmailIcon />}
            defaultValue={signupForm.email.value}
            error={signupForm.email.error}
            disabled={signupForm.isLoading}
            inputRef={emailInputRef}
            onChange={(e) => {
              dispatch(actions.resetSignupFormError());
            }}
            helperText="Signing up with work email makes it easier for coworkers to join to your team"
          />
        )}
        <Button
          type="button"
          isLoading={signupForm.isLoading}
          loaderClassName="border-secondary"
          disabled={ isGoogleLogin ? isGoogleLogin : (signupForm.isLoading || !!signupForm.email.error)}
          disabledClassName="opacity-50"
          className="w-full h-[56px] !border !border-secondary bg-white text-secondary mt-[10px]"
          onClick={(e) => {
            setIsEmail(true);
            isEmail && handleSubmit(e);
          }}
        >
          Continue with email
        </Button>
      </form>
      <div className="flex flex-col items-center gap-[20px] max-w-[390px]">
        <p className="text-secondary text-md leading-[24px] text-center">
          By signing up, you agree to our{" "}
          <Link
            to="https://www.teambox.dev/policy?section=terms"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary font-medium"
          >
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link
            to="https://www.teambox.dev/policy?section=privacy"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary font-medium"
          >
            Data Processing Agreement
          </Link>
          .
        </p>
        <span>-</span>
        <p className="text-secondary text-md leading-[24px] flex flex-row items-center gap-[6px]">
          Already have an account?{" "}
          <Link
            to="/auth/login"
            className="text-primary text-md leading-[24px] font-medium flex flex-row items-center"
          >
            Log in <img sizes="16px" src={rightArrow} alt="Arrow" />
          </Link>
        </p>
      </div>
    </div>
  );
}
