import { createSlice } from "utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { ContainerState } from "../types";
import { Ticket, TicketGroup, TicketGroupStatus } from "../../../types";
// The initial state of the GithubRepoForm container
export const initialState: ContainerState = {
  groupForm: {
    name: {
      value: "",
      error: "",
    },
    description: {
      value: "",
      error: "",
    },
  },
  groups: [],
  isLoading: false,
};

const formSlice = createSlice({
  name: "TicketGroupsState",
  initialState,
  reducers: {
    setGroups: (state, action: PayloadAction<TicketGroup[]>) => {
      state.groups = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    fetchTicketGroups: (state) => {
      state.isLoading = true;
    },
    updateTicketStatus: (
      state,
      action: PayloadAction<{
        ticketId: string;
        sourceStatus: string;
        targetStatus: string;
      }>
    ) => {
      const { ticketId, sourceStatus, targetStatus } = action.payload;
      const sourceGroup = state.groups.find(
        (g) => g.status.name === sourceStatus
      );
      const targetGroup = state.groups.find(
        (g) => g.status.name === targetStatus
      );

      if (sourceGroup && targetGroup) {
        const ticketIndex = sourceGroup.tickets.findIndex(
          (t) => t.id === ticketId
        );
        if (ticketIndex !== -1) {
          const ticket = { ...sourceGroup.tickets[ticketIndex] };
          ticket.status = { ...ticket.status, name: targetStatus };

          // Remove from source group
          sourceGroup.tickets.splice(ticketIndex, 1);

          // Add to target group
          targetGroup.tickets.push(ticket);
        }
      }
    },
    updateTicketActivity: (
      state,
      action: PayloadAction<{ status: String; ticket: Ticket; value: boolean }>
    ) => {
      const ticketId = action?.payload?.ticket?.id;
    },
    toggleGroupsIsCollapse: (state, action: PayloadAction<TicketGroup>) => {
      const status = action.payload.status;
      const selectedGroup = state.groups.map((ele) => {
        if (ele.status?.name === status?.name) {
          ele.status.isCollapse = !ele.status.isCollapse;
        }
        return ele;
      });

      state.groups = selectedGroup;
    },
    toggleGroupsIsHidden: (state, action: PayloadAction<TicketGroup>) => {
      const status = action.payload.status;
      const selectedGroup = state.groups.map((ele) => {
        if (ele.status?.name === status?.name) {
          ele.status.isHidden = !ele.status.isHidden;
        }
        return ele;
      });

      state.groups = selectedGroup;
    },
    toggleGroupsUpdateTicketLimit: (
      state,
      action: PayloadAction<{ group: TicketGroupStatus; value: number }>
    ) => {
      const status = action.payload.group;
      const selectedGroup = state.groups.map((ele) => {
        if (ele.status?.name === status?.name) {
          ele.status.ticketLimit = action.payload.value;
        }
        return ele;
      });

      state.groups = selectedGroup;
    },
    setGroupFormValues: (
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      state.groupForm[action.payload.name].value = action.payload.value;
    },
    setGroupFormErrors: (
      state,
      action: PayloadAction<{ name: string; error: string }>
    ) => {
      state.groupForm[action.payload.name].error = action.payload.error;
    },
    resetGroupForm: (state) => {
      state.groupForm = initialState.groupForm;
    },
    createTicketGroup: (state) => {
      state.isLoading = true;
    },
    removeTicketGroup: (state) => {
      state.isLoading = true;
    },
  },
});

export const {
  actions,
  reducer: ticketGroupsReducer,
  name: ticketGroupsSlice,
} = formSlice;
